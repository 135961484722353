import React, { useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import dayjs, { Dayjs } from 'dayjs';
import * as Yup from 'yup';
import { restValidationToForm } from '../utils/formUtils';
import { BillingPeriodTypes } from '../api/billing.types';
import { createNewBillingPeriod } from '../api/billing';

interface Props {
  onClose: (created: boolean) => void;
  lastPeriod: BillingPeriodTypes;
}

interface Values {
  billing_year: string | undefined;
  billing_month: string | Dayjs | undefined;
}

const CreateNewBillingPeriodModal = ({ onClose, lastPeriod }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['billingPeriod', 'errors']);
  const [year, setYear] = useState<string | undefined>();
  const [month, setMonth] = useState<string | Dayjs | undefined>();
  const history = useHistory();
  const months = dayjs.months();
  const currentYear = dayjs().year();
  const years = [currentYear - 1, currentYear, currentYear + 1];
  // const firstTimeChoosingPeriod = !lastPeriod;

  const initialValues: Values = {
    billing_year: lastPeriod ? year : years[0].toString(),
    billing_month: lastPeriod ? month : '1',
  };

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      billing_month: Yup.string().required(t('errors:emptyInputField')),
      billing_year: Yup.string().required(t('errors:emptyInputField')),
    });
  }, [t, i18n.language]);

  useEffect(() => {
    if (lastPeriod) {
      setMonth((Number(lastPeriod.billing_month) + 1 === 13 ? 1 : Number(lastPeriod.billing_month) + 1).toString());
      if (lastPeriod.billing_month === '12') {
        setYear(dayjs(lastPeriod.billing_year).add(1, 'year').format('YYYY'));
      } else {
        setYear(dayjs(lastPeriod.billing_year).format('YYYY'));
      }
    }
  }, [lastPeriod]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await createNewBillingPeriod({ ...values, billing_month: dayjs(values.billing_month?.toString()).format('MM') });
            history.push('/reikningagerd');
            toast.success(t('periodCreated'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
            toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('createNewPeriodModalTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                {/* {!firstTimeChoosingPeriod ? (
                  <>
                    <Form.Group>
                      <Form.Label>
                        {t('billing_month')}
                        <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control className="capitalize" type="text" value={dayjs(values?.billing_month?.toString()).format('MMMM')} />
                      <ErrorMessage name="billing_month" component="div" className="invalid-feedback d-block text-left" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>
                        {t('billing_year')}
                        <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control type="text" value={values.billing_year?.toString()} />
                      <ErrorMessage name="billing_year" component="div" className="invalid-feedback d-block text-left" />
                    </Form.Group>
                  </>
                ) : ( */}
                <>
                  <Form.Group>
                    <Form.Label>
                      {t('chooseMonth')}
                      <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      style={{ textTransform: 'capitalize' }}
                      value={values?.billing_month?.toString()} // Number(lastPeriod.billing_month)
                      onChange={(event) => {
                        setFieldValue('billing_month', event.target.value);
                      }}
                    >
                      {months.map((bill_month, idx) => {
                        return (
                          <option key={idx} value={idx + 1} style={{ textTransform: 'capitalize' }}>
                            {bill_month}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <ErrorMessage name="billing_month" component="div" className="invalid-feedback d-block text-left" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {t('chooseYear')}
                      <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={values.billing_year?.toString()}
                      onChange={(event) => {
                        setFieldValue('billing_year', event.target.value);
                      }}
                    >
                      {years.map((bill_year, idx) => {
                        return (
                          <option key={idx} value={bill_year}>
                            {bill_year}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <ErrorMessage name="billing_year" component="div" className="invalid-feedback d-block text-left" />
                  </Form.Group>
                </>
                {/* )} */}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:create')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default CreateNewBillingPeriodModal;
