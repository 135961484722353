import { AxiosResponse } from 'axios';
import axios from './api';
import {
  AddExtraProducts,
  Attachment,
  BirthYearResponse,
  CheckSSNResponseType,
  CreateNamfusRegistrationResponse,
  CreateRegistrationResponse,
  DeleteExtraProduct,
  NamfusRegistration,
  NewRegistration,
  NotRegisteredStudentsResponse,
  RecentlyChangedRegistrationStudentList,
  RegistrationBillingInfo,
  RegistrationBillingResponse,
  RegistrationDates,
  RegistrationsType,
  RegistrationsTypeResponse,
  RemovedFileResponse,
  SchoolListResponse,
  SchoolProductsResponse,
  UpdateExtraProducts,
  UpdateInfo,
  UpdatePayer,
  UploadFileResponse,
} from './registrations.types';
import { SuccessfulResponse } from './access.types';

export const registerNotRegisteredStudent = (data: NewRegistration | null): Promise<AxiosResponse<CreateRegistrationResponse>> => {
  return axios.post(`/vala/registrations/create-registration`, data);
};

export const registerNamfusStudent = (data: NamfusRegistration): Promise<AxiosResponse<CreateNamfusRegistrationResponse>> => {
  return axios.post(`/vala/registrations/create-namfus-registration`, data);
};
export const getValidRegistrations = (data: RegistrationsType | null): Promise<AxiosResponse<RegistrationsTypeResponse>> => {
  return axios.post(`/vala/registrations/registrations-list`, data);
};

export const getInvalidRegistrations = (data: RegistrationsType | null): Promise<AxiosResponse<RegistrationsTypeResponse>> => {
  return axios.post(`/vala/registrations/closed-registration-list`, data);
};

export const getNotRegistrations = (data: RegistrationsType | null): Promise<AxiosResponse<NotRegisteredStudentsResponse>> => {
  return axios.get(`/vala/registrations/not-registered-for-food`, {
    params: data,
  });
};

export const getRecentRegistrations = (data: RegistrationsType | null): Promise<AxiosResponse<RecentlyChangedRegistrationStudentList>> => {
  return axios.get(`/vala/registrations/recently-registered`, {
    params: data,
  });
};

export const getRecentUnRegistrations = (data: RegistrationsType | null): Promise<AxiosResponse<RecentlyChangedRegistrationStudentList>> => {
  return axios.get(`/vala/registrations/recently-closed`, {
    params: data,
  });
};
export const getRecentRegistrationsExtraProducts = (): Promise<AxiosResponse<RecentlyChangedRegistrationStudentList>> => {
  return axios.get(`/vala/registrations/recently-registered-products`);
};

export const getRecentUnRegistrationsExtraProducts = (): Promise<AxiosResponse<RecentlyChangedRegistrationStudentList>> => {
  return axios.get(`/vala/registrations/recently-closed-products`);
};

export const getValidBirthYears = (): Promise<AxiosResponse<BirthYearResponse>> => {
  return axios.get(`/vala/registrations/birth-year-current-list`);
};

export const getInvalidBirthYears = (): Promise<AxiosResponse<BirthYearResponse>> => {
  return axios.get(`/vala/registrations/birth-year-closed-list`);
};

export const getRecentlyRegisteredBirthYears = (): Promise<AxiosResponse<BirthYearResponse>> => {
  return axios.get(`/vala/registrations/birth-year-recently-registered`);
};

export const getNotRegisteredBirthYears = (): Promise<AxiosResponse<BirthYearResponse>> => {
  return axios.get(`/vala/registrations/birth-year-not-registered`);
};

export const getRecentlyUnRegisteredBirthYears = (): Promise<AxiosResponse<BirthYearResponse>> => {
  return axios.get(`/vala/registrations/birth-year-recently-closed`);
};

export const getSchoolList = (): Promise<AxiosResponse<SchoolListResponse>> => {
  return axios.get(`/vala/registrations/school-list`);
};

export const getStudent = (registration_id: string): Promise<AxiosResponse<any>> => {
  return axios.get(`/vala/registrations/registration/${registration_id}`);
};

// Get Attachments for student
export const getAttachments = (registration_id: number): Promise<AxiosResponse<any>> => {
  return axios.get(`/vala/attachments/student/${registration_id}`);
};

// Download file from server
export const getFile = (data: Attachment): Promise<AxiosResponse<any>> => {
  return axios.get(`/vala/attachments/download/${data.id}`, {
    responseType: 'arraybuffer',
  });
};

export const uploadFile = (data: any): Promise<AxiosResponse<UploadFileResponse>> => {
  return axios.post(
    `/vala/attachments/upload-file?student_id=${data.student_id}&name=${data.name}&filename=${data.filename}&mime=${data.mime}&registration_id=${data.registration_id}`,
    data.body
  );
};

export const deleteFile = (attachment_id: number): Promise<AxiosResponse<RemovedFileResponse>> => {
  return axios.delete(`/vala/attachments/download/${attachment_id}`);
};

// Update registration info on allergies and/or intolernce
export const updateRegistrationInfo = (data: UpdateInfo): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.put(`/vala/registrations/registration-info`, data);
};

// Check if ssn exists
export const checkPayerSSN = (ssn: string): Promise<AxiosResponse<CheckSSNResponseType>> => {
  return axios.get(`/vala/registrations/check-ssn`, {
    params: { ssn },
  });
};

export const updatePayer = (data: UpdatePayer): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.post(`/vala/registrations/registration-payer`, data);
};

export const updateRegistrationDates = (data: RegistrationDates): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.put(`/vala/registrations/registration-start-end`, data);
};

export const deleteExtraProductRegistration = (data: DeleteExtraProduct): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.delete(`/vala/registrations/registration-extra`, {
    params: data,
  });
};

export const getExtraProductsForSchool = (school_id: number): Promise<AxiosResponse<SchoolProductsResponse>> => {
  return axios.get(`/vala/registrations/school-products`, {
    params: { school_id },
  });
};

export const updateRegisteredExtraProduct = (data: UpdateExtraProducts): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.put(`/vala/registrations/registration-extra`, data);
};

export const registerExtraProduct = (data: AddExtraProducts): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.post(`/vala/registrations/registration-extra`, data);
};

export const getRegistrationBilling = (data: RegistrationBillingInfo): Promise<AxiosResponse<RegistrationBillingResponse>> => {
  return axios.get(`/vala/registrations/registration-billing`, {
    params: data,
  });
};
