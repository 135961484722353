import React, { useCallback, useEffect, useState, useMemo } from 'react';
import DualListBox from 'react-dual-listbox';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleUp, faAngleDoubleDown, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { OptionTypeBase } from 'react-select';
import { useHistory, useParams } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import * as Yup from 'yup';
import { restValidationToForm } from '../utils/formUtils';
import { dateFormatter, infinityValueFormatter } from '../utils/tableUtils';
import DatePicker from '../components/DatePicker';
import { getSchoolList, getUser, getUserJobTypes, updateUser } from '../api/users';
import { JobTypeItem, UpdateLog } from '../api/registrations.types';
import { AccessSchoolsItem, EditUserDetails, UserAccess } from '../api/users.types';
import pagination from '../utils/paginationUtils';
import usePermission, { Permission } from '../hooks/usePermission';

interface Values {
  created_date: string;
  email: string;
  id: number | undefined;
  job_type: string | undefined;
  name: '';
  ssn: '';
  user_access: number[];
  valid_from: Date | undefined;
  valid_to: Date | undefined;
}

const EditUser = (): React.ReactElement => {
  const { t, i18n } = useTranslation(['updateUserView', 'registrations', 'createUserView', 'common']);
  const { hasReadPermission } = usePermission();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [jobTypeList, setJobTypeList] = useState<OptionTypeBase[]>();
  const [schoolList, setSchoolList] = useState<{ value: number; label: string }[]>();

  const [userDetails, setUserDetails] = useState<EditUserDetails>({
    created_date: '',
    email: '',
    id: undefined,
    job_type: undefined,
    name: '',
    ssn: '',
    user_access: [],
    update_log: [],
    valid_from: undefined,
    valid_to: undefined,
    weboptions: null,
  });

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      ssn: Yup.string().required(t('errors:emptyInputField')).min(10, t('errors:ssnInvalid')).max(10, t('errors:ssnInvalid')),
      email: Yup.string().email(t('errors:invalidEmail')).required(t('errors:emptyInputField')),
      job_type: Yup.string().required(t('errors:emptyInputField')),
      user_access: Yup.array().min(1, t('errors:chooseSchool')).required(t('errors:emptyInputField')),
      valid_from: Yup.string().required(t('errors:emptyInputField')).nullable(),
    });
  }, [t, i18n.language]);

  const initialValues = useMemo<Values>(
    () => ({
      created_date: userDetails.created_date,
      email: userDetails.email,
      id: userDetails.id,
      job_type: userDetails.job_type,
      name: userDetails.name,
      ssn: userDetails.ssn,
      user_access: userDetails.user_access.map((item: any) => item.school_id || 0),
      update_log: userDetails.update_log.map((item: UpdateLog) => ({
        created: item.created,
        description: item.description,
        old_value: item.old_value,
        new_value: item.new_value,
        created_name: item.created_name,
      })),
      valid_from: userDetails.valid_from ? dayjs(userDetails.valid_from).toDate() : undefined,
      valid_to: userDetails.valid_to ? dayjs(userDetails.valid_to).toDate() : undefined,
    }),
    [userDetails]
  );

  const loadJobTypes = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getUserJobTypes();
      setJobTypeList(
        response.data.items.map((item: JobTypeItem) => ({
          value: item.id,
          label: item.description,
          user_group: item.user_group,
        }))
      );
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const getUserDetails = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getUser(id);
      const data = response.data.items[0];
      setUserDetails({
        created_date: data.created_date,
        email: data.email,
        id: data.id,
        job_type: data.job_type,
        name: data.name,
        ssn: data.ssn,
        valid_from: data.valid_from === null ? undefined : data.valid_from,
        valid_to: data.valid_to === null ? undefined : data.valid_to,
        user_access: data.user_access.map((item: UserAccess) => ({
          can_change: item.can_change,
          created: item.created,
          id: item.id,
          school_id: item.school_id,
          userId: item.userid,
        })),
        update_log: data.update_log.map((item: UpdateLog) => ({
          created: item.created,
          description: item.description,
          old_value: item.old_value,
          new_value: item.new_value,
          created_name: item.created_name,
        })),
      });
    } catch (e) {
      setError(true);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadSchoolList = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getSchoolList();
      setSchoolList(
        response.data.items.map((item: AccessSchoolsItem) => ({
          value: item.school_id,
          label: item.school_name,
        }))
      );
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadJobTypes();
  }, [loadJobTypes]);

  useEffect(() => {
    loadSchoolList();
  }, [loadSchoolList]);

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  const columns = [
    {
      dataField: 'created',
      formatter: dateFormatter,
      text: t('logTables:created_date'),
    },
    {
      dataField: 'description',
      text: t('logTables:description'),
    },
    {
      dataField: 'new_value',
      text: t('logTables:new_value'),
    },
    {
      dataField: 'old_value',
      text: t('logTables:old_value'),
      formatter: infinityValueFormatter,
    },
    {
      dataField: 'created_name',
      text: t('logTables:created_name'),
    },
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await updateUser({
              ...values,
              user_access: values.user_access.map((k) => ({ school_id: k })),
              valid_to: values.valid_to === undefined ? null : dayjs(values.valid_to).format('DD.MM.YYYY'),
              valid_from: dayjs(values.valid_from).format('DD.MM.YYYY'),
            });
            history.push('/notendur');
            toast.success(t('userUpdated'));
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Form
            onReset={handleReset}
            onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
              setStatus('submitted');
              handleSubmit(e);
            }}
          >
            <table className="table">
              <tr>
                <td style={{ borderTop: 'none' }}>
                  <Form.Group>
                    <Form.Label>{t('registrations:ssn')}</Form.Label>
                    <Form.Control type="text" value={values.ssn} disabled />
                    <ErrorMessage name="ssn" component="div" className="invalid-feedback d-block text-left" />
                  </Form.Group>
                </td>
                <td style={{ borderTop: 'none' }}>
                  <Form.Group>
                    <Form.Label>{t('registrations:name')}</Form.Label>
                    <Form.Control type="text" disabled value={values.name} />
                    <ErrorMessage name="name" component="div" className="invalid-feedback d-block text-left" />
                  </Form.Group>
                </td>
                <td style={{ borderTop: 'none' }}>
                  <Form.Group>
                    <Form.Label>{t('registrations:email')}</Form.Label>
                    <Form.Control type="text" value={values.email} onChange={(e) => setFieldValue('email', e.currentTarget.value)} />
                    <ErrorMessage name="email" component="div" className="invalid-feedback d-block text-left" />
                  </Form.Group>
                </td>
              </tr>
              <tr>
                <td style={{ borderTop: 'none' }}>
                  <Form.Group>
                    <Form.Label>
                      {t('registrations:job_type')}
                      <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={values.job_type}
                      onChange={(event) => {
                        setFieldValue('job_type', event.target.value);
                      }}
                    >
                      <option key="empty" value="">
                        {t('createUserView:chooseJobType')}
                      </option>
                      {jobTypeList?.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <ErrorMessage name="job_type" component="div" className="invalid-feedback d-block text-left" />
                  </Form.Group>
                </td>
                <td style={{ borderTop: 'none' }}>
                  <Form.Group controlId="valid_from">
                    <Form.Label>
                      {t('registrations:startOfRegistration')}
                      <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <div className="dateWrapper">
                      <DatePicker
                        style={{ display: 'inline', width: '100%' }}
                        selectedDay={values.valid_from}
                        value={values.valid_from}
                        month={values.valid_from}
                        onDayChange={(day: Date) => {
                          setFieldValue('valid_from', day === undefined ? null : day);
                        }}
                      />
                    </div>
                    <ErrorMessage name="valid_from" component="div" className="invalid-feedback d-block text-left" />
                  </Form.Group>
                </td>
                <td style={{ borderTop: 'none' }}>
                  <Form.Group controlId="valid_to">
                    <Form.Label>{t('registrations:endOfRegistration')}</Form.Label>
                    <div className="dateWrapper">
                      <DatePicker
                        style={{ display: 'inline', width: '100%' }}
                        selectedDay={values.valid_to}
                        value={values.valid_to}
                        month={values.valid_to}
                        onDayChange={(day: Date) => {
                          setFieldValue('valid_to', day);
                        }}
                      />
                    </div>
                    <ErrorMessage name="valid_to" component="div" className="invalid-feedback d-block text-left" />
                  </Form.Group>
                </td>
              </tr>
              <tr>
                <td style={{ borderTop: 'none' }}>
                  <Form.Group>
                    <Form.Label>
                      {t('createUserView:schoolAccess')}
                      <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    {schoolList && (
                      <div>
                        <DualListBox
                          options={schoolList}
                          selected={values.user_access}
                          onChange={(e: string[]) => setFieldValue('user_access', e)}
                          icons={{
                            moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
                            moveAllLeft: [<FontAwesomeIcon key={0} icon={faChevronLeft} />, <FontAwesomeIcon key={1} icon={faChevronLeft} />],
                            moveRight: <FontAwesomeIcon icon={faChevronRight} />,
                            moveAllRight: [<FontAwesomeIcon key={0} icon={faChevronRight} />, <FontAwesomeIcon key={1} icon={faChevronRight} />],
                            moveTop: <FontAwesomeIcon icon={faAngleDoubleUp} />,
                            moveBottom: <FontAwesomeIcon icon={faAngleDoubleDown} />,
                            moveDown: <FontAwesomeIcon icon={faChevronDown} />,
                            moveUp: <FontAwesomeIcon icon={faChevronUp} />,
                          }}
                        />
                      </div>
                    )}
                    <ErrorMessage name="user_access" component="div" className="invalid-feedback d-block text-left" />
                  </Form.Group>
                </td>
              </tr>
            </table>
            <div className="pull-right">
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  // handleSubmit();
                }}
              >
                {t('common:submit')}
              </Button>
              &nbsp;
              <Button variant="secondary" onClick={() => history.push('/notendur')}>
                {t('common:cancel')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {hasReadPermission(Permission.BREYTINGARSAGA) && (
        <div className="whiteColumn">
          <h2>{t('logTables:changelog')}</h2>
          {userDetails && (
            <BootstrapTable
              bootstrap4
              keyField="created_date"
              noDataIndication={t('common:noDataFound')}
              data={userDetails.update_log}
              columns={columns}
              pagination={pagination}
              striped
              bordered={false}
            />
          )}
        </div>
      )}
    </>
  );
};

export default EditUser;
