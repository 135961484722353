/* eslint-disable */

import React from 'react';
import OtherToolkitProvider from 'react-bootstrap-table2-toolkit/lib/context';

import { getMetaInfo, transform, save } from './exporter';

const csvDefaultOptions = {
  fileName: 'spreadsheet.csv',
  separator: ';',
  ignoreHeader: false,
  ignoreFooter: true,
  noAutoBOM: true,
  blobType: 'text/plain;charset=utf-8',
  exportAll: true,
  onlyExportSelection: false,
};

class ToolkitProvider extends OtherToolkitProvider.Provider {
  handleExportCSV = (source) => {
    const { columns, exportCSV, keyField } = this.props;
    const meta = getMetaInfo(columns);
    const options =
      exportCSV === true
        ? csvDefaultOptions
        : {
            ...csvDefaultOptions,
            ...exportCSV,
          };

    // get data for csv export
    let data;
    if (typeof source !== 'undefined') {
      data = source;
    } else if (options.exportAll) {
      data = this.props.data;
    } else if (options.onlyExportFiltered) {
      const payload = {};
      this.tableExposedAPIEmitter.emit('get.filtered.rows', payload);
      data = payload.result;
    } else {
      const payload = {};
      this.tableExposedAPIEmitter.emit('get.table.data', payload);
      data = payload.result;
    }

    // filter data by row selection
    if (options.onlyExportSelection) {
      const payload = {};
      this.tableExposedAPIEmitter.emit('get.selected.rows', payload);
      const selections = payload.result;
      data = data.filter((row) => !!selections.find((sel) => row[keyField] === sel));
    }

    const content = transform(data, meta, columns, this._, options);
    save(content, options);
  };
}

export default {
  Provider: ToolkitProvider,
  Consumer: OtherToolkitProvider.Consumer,
};
