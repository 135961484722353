import React, { useMemo } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import { restValidationToForm } from '../utils/formUtils';
import { createJobType } from '../api/access';

interface Props {
  onClose: (created: boolean) => void;
}

interface Values {
  description: string;
}

const CreateNewAccessModal = ({ onClose }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['createNewAccessModal']);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      description: Yup.string().required(t('error')),
    });
  }, [t, i18n.language]);

  const initialValues = {
    description: '',
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await createJobType(values);
            toast.success(t('accessCreated'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Form.Group>
                  <Form.Label>{t('newAccessLabel')}</Form.Label>
                  <Form.Control type="text" onChange={(e) => setFieldValue('description', e.currentTarget.value)} />
                  <ErrorMessage name="description" component="div" className="invalid-feedback d-block text-left" />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('create')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default CreateNewAccessModal;
