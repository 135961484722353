/* eslint-disable no-param-reassign */

import { AxiosInstance } from 'axios';

import store from '../store/store';
import { logout as logoutAction, refresh as refreshAction } from '../store/auth/authSlice';
import TokenStorage from '../utils/tokenUtils';

const registerInterceptors = (instance: AxiosInstance): void => {
  instance.interceptors.request.use((config) => {
    (config.headers as any)['Accept-Language'] = 'is';

    if ('requireAuth' in config && config.requireAuth === false) {
      return config;
    }

    let skipRefresh = false;

    if ('skipRefresh' in config && config.skipRefresh === true) {
      skipRefresh = true;
    }

    const { authToken } = store.getState().auth;
    if (TokenStorage.isAccessTokenExpired()) {
      return new Promise((resolve, reject) => {
        store.dispatch(logoutAction()).finally(() => {
          window.location.href = '/login?loggedOut=true';
          reject(new Error('exp'));
        });
      });
    }

    if (!skipRefresh && TokenStorage.willAccessTokenExpireSoon()) {
      const originalRequest = config;

      return new Promise((resolve, reject) => {
        store
          .dispatch(refreshAction())
          .then((token: string) => {
            (config.headers as any).common.token = `${token}`;
            resolve(originalRequest);
          })
          .catch((e: any) => {
            reject(e);
          });
      });
    }

    if (authToken !== null) {
      (config.headers as any).common.token = `${authToken}`;
    }

    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }

      return new Promise((resolve, reject) => {
        store.dispatch(logoutAction()).finally(() => {
          window.location.href = '/login?loggedOut=true';
          reject(error);
        });
      });
    }
  );
};

export default registerInterceptors;
