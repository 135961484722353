import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faSearch, faTimes, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import Select, { OptionTypeBase } from 'react-select';
import debounce from 'lodash.debounce';
import { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import dayjs from 'dayjs';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { dateFormatter } from '../utils/tableUtils';
import { getUserJobTypes, getUsers, getUserSchoolList } from '../api/users';
import { User, UsersFilterType } from '../api/users.types';
import { JobTypeItem, SchoolListItem } from '../api/registrations.types';
import usePermission, { Permission } from '../hooks/usePermission';
import ToolkitProvider from '../utils/csv/provider';

const Users = (): React.ReactElement => {
  const { t } = useTranslation(['usersView', 'common']);
  const { hasReadPermission, hasWritePermission } = usePermission();
  const [data, setData] = useState<User[]>();
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [filter, setFilter] = useState<UsersFilterType>({ str: '', job_type_ids: '', schools: '', enabled: '1', disabled: '' });
  const [searchValue, setSearchValue] = useState<string>('');
  const [schoolList, setSchoolList] = useState<OptionTypeBase[]>();
  const [jobTypeList, setJobTypeList] = useState<OptionTypeBase[]>();
  const [selectSchoolValue, setSelectSchoolValue] = useState<OptionTypeBase[]>([]);
  const [selectJobTypeValue, setSelectJobTypeValue] = useState<OptionTypeBase[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { ExportCSVButton } = CSVExport;

  const loadUsers = useCallback(async (filterValues: any) => {
    setLoadingData(true);
    setError(false);
    try {
      const response = await getUsers(filterValues);
      setData(response.data.items);
    } catch {
      setError(true);
    } finally {
      setLoadingData(false);
    }
  }, []);

  const loadSchoolList = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getUserSchoolList();
      setSchoolList(
        response.data.items.map((item: SchoolListItem) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadJobTypes = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getUserJobTypes();
      setJobTypeList(
        response.data.items.map((item: JobTypeItem) => ({
          value: item.id,
          label: item.description,
          user_group: item.user_group,
        }))
      );
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const customTotal = (from: number, to: number, size: number) => (
    <span className="react-bootstrap-table-pagination-total">
      Raðir {from} til {to} af {size}
    </span>
  );

  const pagination = paginationFactory({
    page: currentPage,
    onPageChange: (page: number) => {
      setCurrentPage(page);
    },
    sizePerPage: 50,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
  });

  useEffect(() => {
    loadUsers(filter);
  }, [loadUsers]);

  useEffect(() => {
    loadSchoolList();
  }, [loadSchoolList]);

  useEffect(() => {
    loadJobTypes();
  }, [loadJobTypes]);

  const enabledDisabledFormatter = (cell: string) => {
    if (!cell) {
      return '';
    }
    if (cell === '1') {
      return <FontAwesomeIcon style={{ color: '#1cc88a' }} fixedWidth icon={faCheck} />;
    }
    if (cell === '0') {
      return <FontAwesomeIcon style={{ color: '#e74a3b' }} fixedWidth icon={faTimes} />;
    }
    return '';
  };

  const usersColumn = [
    {
      dataField: 'id',
      isDummyField: true,
      text: '',
      csvExport: false,
      headerStyle: { width: '35px' },
      formatter: (cell: string, row: any, rowIndex: any) => {
        const rowNumber = (currentPage - 1) * 50 + (rowIndex + 1);
        return `${rowNumber}.`;
      },
    },
    {
      dataField: 'name',
      text: t('name'),
      sort: true,
      formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
        if (searchValue !== '' && row.name && row.name.toUpperCase().includes(searchValue.toUpperCase())) {
          return <div dangerouslySetInnerHTML={{ __html: row.name.replace(new RegExp(searchValue, 'gi'), '<span class="highlight">$&</span>') }} />;
        }
        return <div>{row.name}</div>;
      },
    },
    {
      dataField: 'schools',
      text: t('school_name'),
      formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
        return (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip className="schoolTooltip" id="button-tooltip">
                <div>
                  {cell.map((item: string, idx: number) => {
                    if (idx < 5) {
                      return (
                        <div style={{ textAlign: 'left' }} key={idx}>
                          {item}
                        </div>
                      );
                    }
                    return '';
                  })}
                  {cell.length > 5 && '...'}
                </div>
              </Tooltip>
            }
          >
            <div style={{ maxWidth: 'fit-content' }}>
              {cell.length} &nbsp;
              <FontAwesomeIcon className="" icon={faSearch} fixedWidth />
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'job_type_name',
      text: t('job_type_name'),
      sort: true,
    },
    {
      dataField: 'email',
      csvFormatter: (cell: string) => (cell === null ? '' : cell),
      text: t('email'),
      sort: true,
    },
    {
      dataField: 'created_date',
      formatter: dateFormatter,
      csvFormatter: dateFormatter,
      text: t('created_date'),
      sort: true,
    },
    {
      dataField: 'enabled',
      formatter: enabledDisabledFormatter,
      csvFormatter: (cell: string) => (cell === '1' ? t('common:yes') : t('common:no')),
      text: t('enabled'),
      sort: true,
    },
    {
      dataField: 'edit',
      isDummyField: true,
      csvExport: false,
      text: '',
      formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
        if (hasWritePermission(Permission.NOTENDUR)) {
          return (
            <Link to={`/edit-user/${row.id}`}>
              <div role="button">
                <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faEdit} />
              </div>
            </Link>
          );
        }
        return '';
      },
    },
  ];

  const debouncedFilter = useCallback(
    debounce((filterValues: UsersFilterType) => loadUsers(filterValues), 2000),
    [loadUsers]
  );

  const onSearchChanged = (value: Partial<UsersFilterType>) => {
    const newFilter: UsersFilterType = {
      ...filter,
      ...value,
    };

    setFilter(newFilter);
    debouncedFilter(newFilter);
  };

  return (
    <>
      {hasReadPermission(Permission.NOTENDUR) && (
        <>
          {!error && (
            <Form>
              {hasWritePermission(Permission.NOTENDUR) && (
                <Button variant="primary" className="pull-right">
                  <Link to="/create-user" style={{ color: 'white' }}>
                    <FontAwesomeIcon className="sidebarToggleIcon" icon={faUserPlus} style={{ display: 'inline', marginTop: '2px' }} />
                    &nbsp;{t('createUser')}
                  </Link>
                </Button>
              )}
              <Row>
                <Col>
                  <Form.Group controlId="search">
                    <Form.Control
                      type="text"
                      disabled={loading}
                      placeholder={t('search')}
                      autoComplete="off"
                      onChange={(e) => {
                        setSearchValue(e.currentTarget.value);
                        onSearchChanged({ str: e.currentTarget.value });
                      }}
                      value={searchValue}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Select
                    placeholder={t('schools')}
                    options={schoolList}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix={t('schools')}
                    closeMenuOnSelect={false}
                    isClearable
                    isDisabled={loading}
                    value={selectSchoolValue}
                    onChange={(e) => {
                      setSelectSchoolValue(e.map((v) => v));
                      onSearchChanged({ schools: e.map((v) => v.value).join() });
                    }}
                  />
                </Col>
                <Col>
                  <Select
                    placeholder={t('jobtypes')}
                    options={jobTypeList}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix={t('jobtypes')}
                    closeMenuOnSelect={false}
                    isClearable
                    isDisabled={loading}
                    value={selectJobTypeValue}
                    onChange={(e) => {
                      setSelectJobTypeValue(e.map((v) => v));
                      onSearchChanged({ job_type_ids: e.map((v) => v.value).join() });
                    }}
                  />
                </Col>
                <Col style={{ paddingTop: '0.5rem' }}>
                  <span style={{ paddingRight: '1rem', paddingTop: '1rem' }}>
                    <input
                      type="checkbox"
                      disabled={loading}
                      checked={filter.enabled === '1'}
                      onChange={(e) => {
                        onSearchChanged({ enabled: e.currentTarget.checked ? '1' : '0' });
                      }}
                    />
                    &nbsp;
                    {t('enabled_users')}
                  </span>
                  <span>
                    <input
                      type="checkbox"
                      disabled={loading}
                      onChange={(e) => {
                        onSearchChanged({ disabled: e.currentTarget.checked ? '1' : '0' });
                      }}
                    />
                    &nbsp;
                    {t('disabled_users')}
                  </span>
                </Col>
              </Row>
            </Form>
          )}
          {loadingData && (
            <div className="spinner">
              <Spinner animation="border" role="status">
                <span className="sr-only">{t('common:loading')}</span>
              </Spinner>
            </div>
          )}
          {data && !loadingData && (
            <div className="whiteColumn">
              <ToolkitProvider
                keyField="name"
                bootstrap4
                data={data}
                columns={usersColumn}
                exportCSV={{
                  fileName: `${t('active_users_prefix')}${dayjs().format('DD.MM.YYYY')}.csv`,
                }}
              >
                {(props: any) => (
                  <div>
                    <ExportCSVButton {...props.csvProps} style={{ float: 'right', color: 'green' }}>
                      <FontAwesomeIcon v-if="icon" className="green-icon" fixedWidth icon={faFileExcel} />
                    </ExportCSVButton>
                    <BootstrapTable {...props.baseProps} keyField="name" bordered={false} pagination={pagination} noDataIndication={t('common:emptyDataMessage')} striped />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Users;
