import { string } from 'yup';

export interface CompanyList {
  id: number;
  name: string;
}

export interface CheckLoginResponse {
  successful: boolean;
  token?: string;
  ssn: string;
  name: string;
  company: string | null;
  // eslint-disable-next-line camelcase
  company_list: CompanyList[];
  timeleft: number;
  userid: number;
}

export interface GetMePermissionType {
  system_module: string;
  access_type: number;
}
export interface GetMeType {
  ssn: string;
  name: string;
  customer: string;
  customer_id: number;
  permissions: GetMePermissionType[];
}

export interface GetMeResponse {
  items: GetMeType[];
}

export interface GetNewTownToken {
  id: number;
  token: string;
}

export interface Extra {
  name: string;
  price: number;
  registered: boolean;
}

export interface School {
  name: string;
  registrationFee: number;
  registrationDay: number;
  extra?: Extra[];
}

export interface RegisteredChild {
  id: string;
  name: string;
  age: string;
  ssn: string;
  class: string;
  registered: boolean;
  intolerance: string | undefined;
  intoleranceReason: string;
  allergies: string | undefined;
  allergiesReason: string;
  school: School;
  totalPrice: number;
}

export interface Applications {
  id: string;
  name: string;
  age: string;
  ssn: string;
  registered: boolean;
  applied: boolean;
  status: string;
  school: School;
}

export interface PingResponse {
  successful: boolean;
  timeleft: number;
  error?: string;
  data?: string;
}

export enum Permission {
  ADGANGSSTYRINGAR = 'adgangsstyringar',
  AUKAVORUR = 'aukavorur',
  BREYTINGARSAGA = 'breytingarsaga',
  BUNKARTILBOKHALDS = 'bunkartilbokhalds',
  GJALDSKRA = 'gjaldskra',
  HANDVIRKURAFSLATTUR = 'handvirkurafslattur',
  OSKRADBORN = 'oskradborn',
  MAGNSKRAAFSLATT = 'magnskraafslatt',
  SKRANINGAR = 'skraningar',
  MANADARYFIRLIT = 'manadaryfirlit',
  NOTENDUR = 'notendur',
  OFNAEMISOTHOL = 'ofnaemiothol',
  REIKNINGAGERD = 'reikningagerd',
  REIKNINGATIMABIL = 'reikningatimabil',
  SKOLAR = 'skolar',
  SNIDMAT = 'snidmat',
  TEGUNDAFSLATTAR = 'tegundafslattar',
  TIMABIL = 'timabil',
  NAMFUSBORN = 'namfusborn',
  TOLFRAEDI = 'tolfraedi',
  LISTAR = 'listar',
}
