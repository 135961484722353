import React, { ReactNode } from 'react';
import { Accordion, Card } from 'react-bootstrap';

interface Props {
  title: string;
  children: ReactNode;
  idx: string;
}

const AccordionForLists = ({ title, children, idx }: Props): React.ReactElement => {
  return (
    <Accordion>
      <div style={{ marginTop: '5px' }}>
        <Card>
          <Card.Header>
            <Accordion.Toggle eventKey={idx} as={Card.Header} variant="link" style={{ color: '#6e707e' }} className="activetab">
              {title}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={idx}>
            <Card.Body>{children}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </div>
    </Accordion>
  );
};
export default AccordionForLists;
