import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import { restValidationToForm } from '../utils/formUtils';
import { DiscountAccKeysType } from '../api/tariffs.types';
import { editDiscountAccKeys } from '../api/tariffs';

interface Props {
  onClose: (created: boolean) => void;
  originalValues: DiscountAccKeysType[] | undefined;
}

interface DiscountAccKey {
  account_key: string | undefined;
  discount_acc_key_id: number | undefined;
  discount_name: string | undefined;
}

interface Values {
  items: DiscountAccKey[] | undefined;
}

const EditDiscountAccKeysTariffModal = ({ onClose, originalValues }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['tariff', 'common', 'errors']);
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      items: Yup.array().of(
        Yup.object().shape({
          account_key: Yup.string().required(t('errors:emptyInputField')),
        })
      ),
    });
  }, [t, i18n.language]);
  const initialValues = {
    items: originalValues?.map((item) => {
      return { account_key: item.account_key, discount_acc_key_id: item.discount_acc_key_id, discount_name: item.discount_name };
    }),
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            const modifiedItem = values.items?.map((item) => ({
              account_key: item.account_key,
              discount_acc_key_id: item.discount_acc_key_id,
            }));
            if (modifiedItem) {
              const promises = modifiedItem.map((i) => {
                return editDiscountAccKeys(i);
              });
              await Promise.all(promises);
            }

            toast.success(t('discountAccKeysSuccess'));
            onClose(true);
          } catch (e) {
            toast.error(`${t('common:informationChangedError')}. ${e.response.data.error}.`);
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue }) => (
          <Modal show size="lg" onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('editDiscountAccKeysTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <table className="table">
                  <tbody>
                    {values &&
                      values?.items?.map((item, idx) => {
                        return (
                          <>
                            <tr>
                              <td style={{ borderTop: 'none' }}>
                                {t('discount_name')}
                                <span style={{ color: 'red' }}>*</span>
                              </td>
                              <td style={{ borderTop: 'none' }}>
                                {t('account_key')}
                                <span style={{ color: 'red' }}>*</span>
                              </td>
                            </tr>
                            <tr>
                              <th style={{ borderTop: 'none' }}>{item.discount_name}</th>
                              <td style={{ borderTop: 'none' }}>
                                <Form.Group>
                                  <Form.Control type="text" value={item.account_key} onChange={(e) => setFieldValue(`items[${idx}].account_key`, e.currentTarget.value)} />
                                  <ErrorMessage name={`items[${idx}].account_key`} component="div" className="invalid-feedback d-block text-left" />
                                </Form.Group>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:save')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default EditDiscountAccKeysTariffModal;
