import React from 'react';
import 'react-day-picker/lib/style.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DayPickerInputProps, LocaleUtils } from 'react-day-picker';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const getFirstDayOfWeek = (locale?: string) => {
  return 1;
};

const formatDay = (d: Date, locale?: string) => {
  return dayjs(d).format('DD.MM.YYYY');
};

const formatMonthTitle = (d: Date, locale?: string) => {
  return dayjs(d).format('MMMM YYYY');
};

const formatWeekdayShort = (weekday: number, locale?: string) => {
  return dayjs.weekdaysShort()[weekday];
};

const formatWeekdayLong = (weekday: number, locale?: string) => {
  return dayjs.weekdays()[weekday];
};

const localeUtils: Partial<LocaleUtils> = {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
};

// eslint-disable-next-line react/require-default-props
type InputProps = DayPickerInputProps & { selectedDay?: Date; month?: Date; disabled?: boolean };

const DatePicker: React.FC<InputProps> = ({ selectedDay, month, disabled, ...props }: InputProps): React.ReactElement => {
  return (
    <>
      <DayPickerInput
        placeholder="Dags."
        inputProps={{ disabled }}
        formatDate={formatDay}
        dayPickerProps={{
          selectedDays: selectedDay,
          month,
          localeUtils: {
            ...LocaleUtils,
            ...localeUtils,
          },
        }}
        {...props}
      />
      <div className="calendarIcon">
        <FontAwesomeIcon className="sidebarToggleIcon" icon={faCalendarAlt} style={{ display: 'inline' }} />
      </div>
    </>
  );
};

export default DatePicker;
