import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { restValidationToForm } from '../utils/formUtils';
import { createNewDiscountType, getDiscountCategories } from '../api/billing';
import { DiscountCategoryType } from '../api/billing.types';

interface Props {
  onClose: (created: boolean) => void;
}

interface Values {
  category: number | undefined;
  name: string | undefined;
  description: string;
}

const CreateNewDiscountTypeModal = ({ onClose }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['discountTypes', 'errors']);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [discountCategories, setDiscountCategories] = useState<DiscountCategoryType[]>();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string().required(t('errors:emptyInputField')),
    });
  }, [t, i18n.language]);

  const initialValues: Values = {
    category: discountCategories ? discountCategories[0].id : undefined,
    name: '',
    description: '',
  };

  const loadDiscountCategories = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getDiscountCategories();
      setDiscountCategories(response.data.items);
    } catch {
      toast.error(t('discountCategoryError'));
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadDiscountCategories();
  }, [loadDiscountCategories]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await createNewDiscountType(values);
            toast.success(t('discountTypeCreated'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('createNewDiscountType')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                {discountCategories && (
                  <Form.Group>
                    <Form.Label>
                      {t('discountCategory')}
                      <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={values.category}
                      onChange={(event) => {
                        setFieldValue('category', event.target.value);
                      }}
                    >
                      {discountCategories?.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                )}
                <Form.Group>
                  <Form.Label>
                    {t('name')}
                    <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={values.name}
                    onChange={(event) => {
                      setFieldValue('name', event.target.value);
                    }}
                  />
                  <ErrorMessage name="name" component="div" className="invalid-feedback d-block text-left" />
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t('description')}</Form.Label>
                  <Form.Control
                    type="text"
                    value={values.description}
                    onChange={(event) => {
                      setFieldValue('description', event.target.value);
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:create')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default CreateNewDiscountTypeModal;
