import { useRef, useEffect } from 'react';

const useDebounce = () => {
  const timeout = useRef<ReturnType<typeof setTimeout | any>>();

  const debounce = (func: (...args: any[]) => void, delay: number) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(func, delay);
  };

  useEffect(() => {
    return () => {
      if (!timeout.current) return;
      clearTimeout(timeout.current);
    };
  }, []);

  return { debounce };
};

export default useDebounce;
