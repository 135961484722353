import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Col } from 'react-bootstrap';
import { restValidationToForm } from '../utils/formUtils';
import { getSchool, resetSchoolPin, updateSchool } from '../api/schools';
import { SchoolFoodTypes, SchoolItem } from '../api/schools.types';

interface Props {
  onClose: (created: boolean) => void;
  id: number | null;
}

interface Values {
  cost_place: string;
  email: string;
  enabled: string;
  id: number | null;
  name: string;
  ssn: string;
  school_pin: number | undefined;
  food_types: Record<number, boolean>;
}
const EditSchoolModal = ({ onClose, id }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['schoolsView', 'errors']);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [schoolPin, setSchoolPin] = useState<number | undefined>(undefined);
  const [schoolInfo, setSchoolInfo] = useState<SchoolItem>();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      cost_place: Yup.string().required(t('errors:emptyInputField')),
      email: Yup.string().email(t('errors:invalidEmail')).required(t('errors:emptyInputField')),
      name: Yup.string().required(t('errors:emptyInputField')),
      ssn: Yup.string().required(t('errors:emptyInputField')),
      // ssn: Yup.string().required(t('errors:emptyInputField')).min(10, t('errors:ssnInvalidForm')).max(10, t('errors:ssnInvalidForm')),
    });
  }, [t, i18n.language]);

  const getSchoolInfo = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getSchool(id);
      setSchoolInfo(response.data.items[0]);
    } catch {
      toast.error(t('resetPinError'));
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getSchoolInfo();
  }, [getSchoolInfo]);

  const handleResetPin = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await resetSchoolPin(id);
      setSchoolPin(response.data.school_pin);
      toast.success(t('resetPinSuccess'));
    } catch {
      toast.error(t('resetPinError'));
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  if (schoolInfo == null) {
    return <>Augnablik...</>;
  }

  return (
    <>
      <Formik
        initialValues={{
          cost_place: schoolInfo.cost_place,
          email: schoolInfo.email,
          name: schoolInfo.name,
          ssn: schoolInfo.ssn,
          enabled: schoolInfo.enabled,
          id: schoolInfo.id,
          school_pin: schoolInfo.school_pin,
          food_types:
            schoolInfo.food_types?.reduce((acc: Record<number, boolean>, curr: SchoolFoodTypes) => {
              acc[curr.food_type_school_id] = curr.enabled === '1';
              return acc;
            }, {}) || {},
        }}
        validationSchema={validationSchema}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await updateSchool({
              ...values,
              food_types: JSON.stringify(Object.entries(values.food_types).map(([k, v]) => ({ food_type_school_id: Number(k), enabled: v ? '1' : '0' }))),
            });
            toast.success(t('schoolUpdated'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show size="lg" onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('editModalTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      {t('ssn')}
                      <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control type="text" value={values.ssn} onChange={(e) => setFieldValue('ssn', e.currentTarget.value)} />
                    <ErrorMessage name="ssn" component="div" className="invalid-feedback d-block text-left" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>
                      {t('name')}
                      <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control type="text" value={values.name} onChange={(e) => setFieldValue('name', e.currentTarget.value)} />
                    <ErrorMessage name="name" component="div" className="invalid-feedback d-block text-left" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      {t('email')}
                      <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control type="text" value={values.email} onChange={(e) => setFieldValue('email', e.currentTarget.value)} />
                    <ErrorMessage name="email" component="div" className="invalid-feedback d-block text-left" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>
                      {t('cost_place')}
                      <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control type="text" value={values.cost_place} onChange={(e) => setFieldValue('cost_place', e.currentTarget.value)} />
                    <ErrorMessage name="cost_place" component="div" className="invalid-feedback d-block text-left" />
                  </Form.Group>
                </Form.Row>
                {schoolInfo?.food_types?.length > 0 && (
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>{t('availableSpecialFoodRequests')}</Form.Label>
                      {schoolInfo?.food_types?.map((type: SchoolFoodTypes) => (
                        <div key={`${type.food_type_school_id}`} className="mb-3">
                          <Form.Check
                            label={type.description}
                            name={type.description}
                            type="checkbox"
                            id={`${type.food_type_school_id}`}
                            checked={values.food_types[type.food_type_school_id]}
                            onChange={(event) => {
                              setFieldValue(`food_types.${type.food_type_school_id}`, event.currentTarget.checked);
                            }}
                          />
                        </div>
                      ))}
                    </Form.Group>
                  </Form.Row>
                )}
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      {t('pin')}
                      <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control type="text" value={schoolPin || values.school_pin} />
                    <ErrorMessage name="pin" component="div" className="invalid-feedback d-block text-left" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>&nbsp;</Form.Label>
                    <Button style={{ display: 'block' }} onClick={() => handleResetPin()}>
                      {t('resetPin')}
                    </Button>
                  </Form.Group>
                </Form.Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('save')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default EditSchoolModal;
