import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import { restValidationToForm } from '../utils/formUtils';
import { JobTypeNameMapped } from '../api/access.types';
import { deleteJobType } from '../api/access';

interface Props {
  data: JobTypeNameMapped;
  onClose: (created: boolean) => void;
}

interface Values {
  jobType: number | null;
  jobTypeName: string;
}

const DeleteCurrentJobType = ({ data, onClose }: Props): React.ReactElement => {
  const { t } = useTranslation(['deleteJobTypeModal']);

  const initialValues = {
    jobType: data.jobType,
    jobTypeName: data.jobTypeName,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await deleteJobType(values.jobType);
            toast.success(t('jobTypeDeleted'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('title', { jobTypeName: values.jobTypeName })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Form.Group controlId="makeSure">
                  <Form.Label>{t('makeSure', { jobTypeName: values.jobTypeName })}</Form.Label>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('delete')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default DeleteCurrentJobType;
