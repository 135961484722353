import React, { useMemo } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import { restValidationToForm } from '../utils/formUtils';
import { ProductTariff } from '../api/tariffs.types';
import { editProductTariffPrice } from '../api/tariffs';

interface Props {
  onClose: (created: boolean) => void;
  originalValues: ProductTariff | undefined;
}

interface Values {
  product_tariff_id: number | undefined;
  price: number | undefined;
}

const EditProductTariffModal = ({ onClose, originalValues }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['tariff', 'common']);
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      price: Yup.number().required(t('error')),
    });
  }, [t, i18n.language]);

  const initialValues = { price: originalValues?.price, product_tariff_id: originalValues?.product_tariff_id };

  if (initialValues == null) {
    return <>{t('loading')}</>;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await editProductTariffPrice(values);
            toast.success(t('productUpdated'));
            onClose(true);
          } catch (e) {
            toast.error(`${t('common:informationChangedError')}. ${e.response.data.error}.`);
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show size="lg" onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('editProductTariffTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <table className="table">
                  <tbody>
                    <tr>
                      <th style={{ borderTop: 'none' }}>{originalValues?.product}</th>
                      <td style={{ borderTop: 'none' }}>
                        <Form.Group>
                          <Form.Control
                            type="number"
                            {...getFieldProps('price')}
                            onChange={(e) => {
                              setFieldValue('price', e.currentTarget.value);
                            }}
                          />
                          <ErrorMessage name="price" component="div" className="invalid-feedback d-block text-left" />
                        </Form.Group>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:save')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default EditProductTariffModal;
