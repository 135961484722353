import dayjs from 'dayjs';

export const dateFormatter = (cell: string | null | Date): string => {
  if (!cell) {
    return '';
  }
  return dayjs(cell).format('DD.MM.YYYY');
};

export const dateTimeFormatter = (cell: string | null): string => {
  if (!cell) {
    return '';
  }
  return dayjs(cell).format('DD.MM.YYYY HH:mm:ss');
};

export const monthFormatter = (cell: string | undefined): string => {
  if (!cell) {
    return '';
  }
  return dayjs(cell).format('MMMM');
};

export const formatBoolean = (cell: number | string): string => {
  if (cell === 1 || cell === '1') {
    return 'Já';
  }
  return 'Nei';
};

export const sexFormatter = (cell: string): string => {
  switch (cell) {
    case '1':
      return 'KK';
    case '2':
      return 'KVK';
    case '3':
      return 'KK';
    case '4':
      return 'KVK';
    case '7':
      return 'Kynsegin/Annað';
    case '8':
      return 'Kynsegin/Annað';
    default:
      return 'Ekki vitað';
  }
};

export const infinityValueFormatter = (cell: string): string => {
  if (cell === '31.12.2100') {
    return '';
  }
  return cell;
};

export const currencyFormatter = (cell: number | undefined): string => {
  // Using german because IS doesn't work
  if (!cell && cell !== 0) {
    return '';
  }

  return `${new Intl.NumberFormat('de-DE').format(cell)} kr.`;
};

export const formatChosenPeriod = (dateFrom: string | undefined, dateTo: string | undefined): string => {
  return `${dayjs(dateFrom).format('DD.MM.YYYY')} - ${dayjs(dateTo).format('DD.MM.YYYY')}`;
};

export const formatPercentage = (percentage: number): string => {
  return `${Math.round(percentage * 100).toFixed(2)} %`;
};

export const numberToStringFormatter = (cell: string | null): string => {
  if (!cell) {
    return '';
  }
  return `="${cell}"`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localeSort(a1: any, b1: any) {
  let v1 = a1;
  let v2 = b1;

  const t1 = typeof v1;
  const t2 = typeof v2;
  if (t1 === t2) {
    if (t1 === 'string') {
      v1 = v1.toUpperCase();
      v2 = v2.toUpperCase();
      const order = ' 0123456789AÁBCDÐEÉĘFGHIÍJKLMNOÓPQRSTUÚVWXYÝZÞÆÖ.,:;';
      let counter = 0;
      let s1;
      let s2;
      while (counter < v1.length && counter < v2.length) {
        s1 = v1.substring(counter, counter + 1);
        s2 = v2.substring(counter, counter + 1);
        if (s1 === s2) {
          counter += 1;
        } else {
          break;
        }
      }
      const returnValue = order.indexOf(s1) - order.indexOf(s2);

      return returnValue;
    }
    if (t1 === 'number') {
      const returnValue = v1 - v2;
      return returnValue;
    }
    const a = v1 == null ? '' : v1.toLocaleString();
    const b = v2 == null ? '' : v2.toLocaleString();
    const returnValue = a.localeCompare(b);
    return returnValue;
  }
  return t1.localeCompare(t2);
}

export default { localeSort, dateFormatter, monthFormatter, currencyFormatter, formatChosenPeriod };
