import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import { restValidationToForm } from '../utils/formUtils';
import { deleteDiscountType } from '../api/billing';
import { DiscountType } from '../api/billing.types';

interface Props {
  onClose: (created: boolean) => void;
  originalValues: DiscountType;
}

interface Values {
  discount_type_id: number;
}

const DeleteDiscountTypeModal = ({ onClose, originalValues }: Props): React.ReactElement => {
  const { t } = useTranslation(['discountTypes', 'errors']);

  const initialValues: Values = {
    discount_type_id: originalValues.discount_type_id,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            // body is undefined so service can delete
            await deleteDiscountType(undefined, values.discount_type_id);
            toast.success(t('discountTypeDeleted'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('deleteDiscountCategory')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Form.Group controlId="makeSure">
                  <Form.Label>{t('makeSureDelete', { name: originalValues.name })}</Form.Label>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:delete')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default DeleteDiscountTypeModal;
