import { AxiosResponse } from 'axios';
import axios from './api';

import { DeleteBatchDiscount, DeleteBatchDiscountResponse, NatRegResponse, SchoolListResponse, SSN, UserExistsResponse, UsersFilterType, UsersResponse } from './users.types';
import { JobTypeListResponse } from './registrations.types';

export type { UsersResponse };

export const getUsers = (data: UsersFilterType): Promise<AxiosResponse<UsersResponse>> => {
  return axios.get('/vala/admin/userlist', {
    params: data,
  });
};

export const getUserSchoolList = (): Promise<AxiosResponse<any>> => {
  return axios.get('/vala/admin/user-school-list');
};

export const getUserJobTypes = (): Promise<AxiosResponse<JobTypeListResponse>> => {
  return axios.get('/vala/admin/user-job-types');
};

export const getSchoolList = (): Promise<AxiosResponse<SchoolListResponse>> => {
  return axios.get('/vala/admin/access');
};

export const getUserExists = (ssn: SSN): Promise<AxiosResponse<UserExistsResponse>> => {
  return axios.get('/vala/admin/user-exists', {
    params: ssn,
  });
};

export const getNatReg = (ssn: SSN): Promise<AxiosResponse<NatRegResponse>> => {
  return axios.get('/vala/admin/natreg', {
    params: ssn,
  });
};

export const createUser = (data: any): Promise<AxiosResponse<any>> => {
  return axios.post('/vala/admin/user', data);
};

export const getUser = (user_id: string): Promise<AxiosResponse<any>> => {
  return axios.get(`/vala/admin/user/${user_id}`);
};

export const updateUser = (data: any): Promise<AxiosResponse<any>> => {
  return axios.put('/vala/admin/user', data);
};
export const deleteBatchDiscounts = (data: DeleteBatchDiscount): Promise<AxiosResponse<DeleteBatchDiscountResponse>> => {
  return axios.post('/vala/discounts/delete-batch-discounts', data);
};
