import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import { restValidationToForm } from '../utils/formUtils';
import { BatchType } from '../api/invoicing.types';
import { deleteManualRecords } from '../api/billing';

interface Props {
  billing_item_id: number | undefined;
  studentName: string;
  batch: BatchType;
  onClose: (deleted: boolean) => void;
}

interface Values {
  billing_item_id: number | undefined;
  batch_id: string | undefined;
}

const DeleteManualRecordModal = ({ billing_item_id, studentName, batch, onClose }: Props): React.ReactElement => {
  const { t } = useTranslation(['recordsList', 'common']);
  const months = dayjs.months();
  const initialValues = {
    billing_item_id,
    batch_id: batch.batch_id,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await deleteManualRecords(values);
            toast.success(t('recDeletedSuccess'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
            toast.error(`${t('common:informationError')}. ${e.response.data.error ? e.response.data.error : ''}.`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('deleteManualRec')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Table>
                  <tbody>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('student_name')}</Form.Label>
                      </td>
                      <td>{studentName}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('school_name')}</Form.Label>
                      </td>
                      <td>{batch.school_name}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('period')}</Form.Label>
                      </td>
                      <td>
                        <span style={{ textTransform: 'capitalize' }}>{months[Number(batch.billing_month) - 1]}</span> {batch.billing_year}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:delete')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default DeleteManualRecordModal;
