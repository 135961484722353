import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import debounce from 'lodash.debounce';
import dayjs from 'dayjs';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import { Permission } from '../api/account.types';
import usePermission from '../hooks/usePermission';
import DatePicker from '../components/DatePicker';
import { getBirthYear, getClasses, getDiscountList, getDiscountTypes } from '../api/bulkDiscounts';
import { DiscountedStudentType, DiscountTypesType, DropdownType } from '../api/bulkDiscounts.types';
import { deleteBatchDiscounts, getSchoolList } from '../api/users';
import { dateFormatter } from '../utils/tableUtils';
import SpinnerCustom from '../components/Spinner';
import DeleteDiscountItemModal from './DeleteDiscountItemModal';
import ToolkitProvider from '../utils/csv/provider';

interface StudentFilter {
  school_id: string | undefined;
  start_date: Date | undefined;
  end_date: Date | undefined;
  str: string;
  discount_type: string;
  class: string;
  birth_year: string;
}
const Discounts = (): React.ReactElement => {
  const { t } = useTranslation('discounts');
  const { hasWritePermission, hasReadPermission } = usePermission();
  const [searchValue, setSearchValue] = useState<string>('');
  const [filter, setFilter] = useState<StudentFilter>({
    school_id: undefined,
    start_date: dayjs().toDate(),
    end_date: dayjs().toDate(),
    str: '',
    discount_type: '',
    class: '',
    birth_year: '',
  });
  const [loading, setLoading] = useState(false);
  const [loadingStudentList, setLoadingStudentList] = useState(false);
  const [error, setError] = useState(false);
  const [listData, setListData] = useState<DiscountedStudentType[]>();
  const [schoolList, setSchoolList] = useState<DropdownType[]>();
  const [selectedSchool, setSelectedSchool] = useState<DropdownType>();
  const [discountTypesList, setDiscountTypesList] = useState<DropdownType[]>();
  const [classesList, setClassesList] = useState<DropdownType[]>();
  const [birthYearList, setBirthYearList] = useState<DropdownType[]>();
  const [selectedDiscountType, setSelectedDiscountType] = useState<any>();
  const [selectedClasses, setSelectedClasses] = useState<any>();
  const [selectedBirthYear, setSelectedBirthYear] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedStudents, setSelectedStudents] = useState<any[]>([]);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState<{ show: boolean; selectedItem: DiscountedStudentType | undefined }>({ show: false, selectedItem: undefined });
  const { ExportCSVButton } = CSVExport;

  const customTotal = (from: number, to: number, size: number) => (
    // eslint-disable-next-line react/react-in-jsx-scope
    <span className="react-bootstrap-table-pagination-total">
      Raðir {from} til {to} af {size}
    </span>
  );

  const pagination = paginationFactory({
    page: currentPage,
    onPageChange: (page: number) => {
      setCurrentPage(page);
    },
    sizePerPage: 50,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
  });

  const loadStudentList = useCallback(async (values: StudentFilter) => {
    setLoadingStudentList(true);
    setError(false);
    try {
      const response = await getDiscountList({ ...values, start_date: dayjs(values.start_date).format('DD.MM.YYYY'), end_date: dayjs(values.end_date).format('DD.MM.YYYY') });
      setListData(response.data.items);
    } catch {
      setError(true);
    } finally {
      setLoadingStudentList(false);
    }
  }, []);

  const loadSchoolList = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getSchoolList();
      setSchoolList(
        response.data.items.map((item: any) => ({
          value: item.school_id,
          label: item.school_name,
        }))
      );
      setSelectedSchool({ value: response.data.items[0].school_id, label: response.data.items[0].school_name });
      setFilter({ ...filter, school_id: response.data.items[0].school_id.toString() });
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);
  const removeBatchDiscounts = useCallback(async () => {
    setLoading(true);
    setLoadingStudentList(true);
    setError(false);
    try {
      const formattedStudentsList = selectedStudents.map((k) => {
        return { student_discount_id: k };
      });
      await deleteBatchDiscounts({
        discounts: formattedStudentsList,
      });
      loadStudentList(filter);
    } catch {
      setError(true);
    } finally {
      setSelectedStudents([]);
      setLoadingStudentList(false);
      setLoading(false);
    }
  }, [selectedStudents, filter]);

  const loadDiscountTypes = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getDiscountTypes();
      setDiscountTypesList(
        response.data.items.map((item: DiscountTypesType) => ({
          value: item.id,
          label: `${item.category_name} - ${item.name}`,
        }))
      );
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadClasses = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getClasses(filter.school_id);
      setClassesList(
        response.data.items.map((item: { class: string }) => ({
          value: item.class,
          label: item.class,
        }))
      );
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [filter.school_id]);

  const loadBirthYears = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getBirthYear(filter.school_id);
      setBirthYearList(
        response.data.items.map((item: { get_birth_year: string }) => ({
          value: item.get_birth_year,
          label: item.get_birth_year,
        }))
      );
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [filter.school_id]);
  useEffect(() => {
    loadSchoolList();
  }, [loadSchoolList]);

  useEffect(() => {
    if (filter.school_id !== undefined) {
      loadStudentList(filter);
    }
  }, [filter.school_id, filter.class, filter.birth_year, filter.discount_type, loadStudentList]);

  useEffect(() => {
    loadDiscountTypes();
  }, [loadDiscountTypes]);
  useEffect(() => {
    loadClasses();
  }, [loadClasses]);
  useEffect(() => {
    loadBirthYears();
  }, [loadBirthYears]);
  const debouncedFilter = useCallback(
    debounce((filterValues: StudentFilter) => loadStudentList(filterValues), 5000),
    [loadStudentList]
  );

  const onCloseDeleteItem = (deleted: boolean) => {
    setShowDeleteItemModal({ show: false, selectedItem: undefined });
    if (deleted && filter.school_id !== undefined) {
      loadStudentList(filter);
    }
  };

  const onSearchChanged = (value: Partial<StudentFilter>) => {
    const newFilter: StudentFilter = {
      ...filter,
      ...value,
    };

    setFilter(newFilter);
    debouncedFilter(newFilter);
  };
  const bulkDiscountColumn = [
    {
      dataField: 'id',
      isDummyField: true,
      text: '',
      headerStyle: { width: '35px' },
      formatter: (cell: string, row: any, rowIndex: any) => {
        const rowNumber = (currentPage - 1) * 50 + (rowIndex + 1);
        return `${rowNumber}.`;
      },
      csvExport: false,
    },
    {
      dataField: 'student_name',
      text: t('name'),
      sort: true,
      formatter: (cell: any, row: DiscountedStudentType, rowIndex: any, extraData: any) => {
        return (
          <Link to={`/student/${row.registration_id}`}>
            <span>{row.student_name}</span>
          </Link>
        );
      },
    },
    {
      dataField: 'student_ssn',
      text: t('ssn'),
      sort: true,
      csvFormatter: (cell: string) => `="${cell}"`,
    },
    {
      dataField: 'class',
      text: t('class'),
      sort: true,
    },
    {
      dataField: 'get_birth_year',
      text: t('get_birth_year'),
      sort: true,
    },
    {
      dataField: 'date_from',
      text: t('date_from'),
      sort: true,
      formatter: dateFormatter,
      csvFormatter: dateFormatter,
    },
    {
      dataField: 'discount_category',
      text: t('discount_category'),
      sort: true,
    },
    {
      dataField: 'discount_type',
      text: t('discountType'),
      sort: true,
    },
    {
      dataField: 'delete',
      isDummyField: true,
      text: '',
      formatter: (cell: any, row: DiscountedStudentType, rowIndex: any, colIndex: any) => {
        if (hasWritePermission(Permission.MAGNSKRAAFSLATT)) {
          return (
            <Button
              variant="link"
              onClick={() => {
                setShowDeleteItemModal({ show: true, selectedItem: row });
              }}
            >
              <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faTrashAlt} />
            </Button>
          );
        }
        return '';
      },
      csvExport: false,
    },
  ];
  return (
    <>
      {hasReadPermission(Permission.MAGNSKRAAFSLATT) && (
        <>
          {showDeleteItemModal && showDeleteItemModal.selectedItem && <DeleteDiscountItemModal originalValues={showDeleteItemModal.selectedItem} onClose={onCloseDeleteItem} />}
          <Row>
            <Col md={3}>
              <label>{t('start_date')}</label>
              <div className="dateWrapper">
                <DatePicker
                  value={filter.start_date}
                  month={filter.start_date}
                  selectedDay={filter.start_date}
                  onDayChange={(day: Date) => {
                    setFilter({ ...filter, start_date: day });
                  }}
                />
              </div>
            </Col>
            <Col md={3}>
              <label>{t('end_date')}</label>
              <div className="dateWrapper">
                <DatePicker
                  value={filter.end_date}
                  month={filter.end_date}
                  selectedDay={filter.end_date}
                  onDayChange={(day: Date) => {
                    setFilter({ ...filter, end_date: day });
                  }}
                />
              </div>
            </Col>
            <Col md={3}>
              <label>
                {t('discountType')}
                <span style={{ color: 'red' }}>*</span>
              </label>
              <Select
                placeholder={t('discountType')}
                options={discountTypesList}
                isMulti
                closeMenuOnSelect={false}
                className="basic-multi-select"
                isClearable
                value={selectedDiscountType}
                onChange={(e) => {
                  setSelectedDiscountType(e.map((v) => v));
                  setFilter({ ...filter, discount_type: e.map((v) => v.value).join() });
                  // onSearchChanged({ discount_type: e.map((v) => v.value).join() });
                }}
              />
            </Col>
            <Col md={3}>
              {hasWritePermission(Permission.MAGNSKRAAFSLATT) && (
                <Button style={{ float: 'right' }}>
                  <Link to="/magnskra" style={{ color: 'white' }}>
                    <FontAwesomeIcon icon={faUser} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
                    &nbsp;{t('bulkDiscount')}
                  </Link>
                </Button>
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <label>{t('common:search')}</label>
              <input
                type="text"
                className="form-control"
                disabled={loading}
                placeholder={t('search')}
                autoComplete="off"
                onChange={(e) => {
                  setSearchValue(e.currentTarget.value);
                  onSearchChanged({ str: e.currentTarget.value });
                }}
                value={searchValue}
              />
            </Col>
            <Col md={3}>
              <label>{t('schools')}</label>
              <Select
                options={schoolList}
                className="basic-multi-select"
                value={selectedSchool}
                onChange={(e) => {
                  if (e) {
                    setSelectedSchool(e);
                    setFilter({ ...filter, school_id: e?.value.toString() });
                    // onSearchChanged({ school_id: e?.value.toString() });
                  }
                }}
              />
            </Col>
            <Col md={3}>
              <label>{t('class')}</label>
              <Select
                placeholder={t('class')}
                options={classesList}
                isMulti
                className="basic-multi-select"
                isClearable
                value={selectedClasses}
                onChange={(e) => {
                  setSelectedClasses(e.map((v) => v));
                  setFilter({ ...filter, class: e.map((v) => v.value).join() });
                }}
              />
            </Col>
            <Col md={3}>
              <label>{t('get_birth_year')}</label>
              <Select
                placeholder={t('get_birth_year')}
                options={birthYearList}
                isMulti
                className="basic-multi-select"
                isClearable
                value={selectedBirthYear}
                onChange={(e) => {
                  setSelectedBirthYear(e.map((v) => v));
                  setFilter({ ...filter, birth_year: e.map((v) => v.value).join() });
                  //  onSearchChanged({ birth_year: e.map((v) => v.value).join() });
                }}
              />
            </Col>
          </Row>
          <br />
          {loadingStudentList && <SpinnerCustom />}
          {listData && !loadingStudentList && (
            <div className="whiteColumn">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2>{t('confirmedRegistrations')}</h2>
                {hasWritePermission(Permission.MAGNSKRAAFSLATT) && selectedStudents.length > 0 && (
                  <Button onClick={() => removeBatchDiscounts()}>{t('deleteChosenDiscounts')}</Button>
                )}
              </div>
              <ToolkitProvider
                keyField="student_discount_id"
                bootstrap4
                data={listData}
                columns={bulkDiscountColumn}
                exportCSV={{
                  fileName: `${t('confirmed_registrations_prefix')}${dayjs().format('DD.MM.YYYY')}.csv`,
                }}
              >
                {(props: any) => (
                  <div>
                    <ExportCSVButton {...props.csvProps} style={{ float: 'right', color: 'green' }}>
                      <FontAwesomeIcon v-if="icon" className="green-icon" fixedWidth icon={faFileExcel} />
                    </ExportCSVButton>
                    <BootstrapTable
                      {...props.baseProps}
                      keyField="student_discount_id"
                      bordered={false}
                      pagination={pagination}
                      noDataIndication={t('common:noDataFound')}
                      striped
                      selectRow={{
                        mode: 'checkbox',
                        clickToSelect: true,
                        onSelect: (row, isSelect, rowIndex, e) => {
                          if (isSelect) {
                            setSelectedStudents((oldArray) => [...oldArray, row.student_discount_id]);
                          } else {
                            setSelectedStudents(selectedStudents.filter((item) => item !== row.student_discount_id));
                          }
                        },
                        onSelectAll: (isSelect, rows, e) => {
                          if (isSelect) {
                            setSelectedStudents(listData.map((x) => x.student_discount_id));
                          } else {
                            setSelectedStudents([]);
                          }
                        },
                      }}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Discounts;
