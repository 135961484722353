import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Modal, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSync, faTrashAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import debounce from 'lodash.debounce';
import paginationFactory from 'react-bootstrap-table2-paginator';
import dayjs from 'dayjs';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import { useDispatch, useSelector } from 'react-redux';
import { Permission } from '../api/account.types';
import usePermission from '../hooks/usePermission';
import { getUnlistedStudents, getUnlistedStudentsFromMentor } from '../api/unlistedStudents';
import AddFakeSSNForUnlistedStudentModal from './AddFakeSSNForUnlistedStudentModal';
import ToolkitProvider from '../utils/csv/provider';
import { UnlistedStudent, UnlistedStudentMentor } from '../api/unlistedStudents.types';
import { dateFormatter } from '../utils/tableUtils';
import AddRealSSNForUnlistedStudentModal from './AddRealSSNForUnlistedStudentModal';
import DeleteUnlistedStudentModal from './DeleteUnlistedStudentModal';
import EditUnlistedStudentSSNModal from './EditUnlistedStudentSSNModal';
import { RootState } from '../store/rootReducer';
import { AppDispatch } from '../store/store';
import { fetchWeboptions, postNewWeboptions } from '../store/weboptions/getWeboptionsSlice';

const UnlistedStudents = (): React.ReactElement => {
  const { t } = useTranslation('unlistedStudents');
  const { hasWritePermission, hasReadPermission } = usePermission();
  const [searchValue, setSearchValue] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [loadingStudentList, setLoadingStudentList] = useState(false);
  const [error, setError] = useState(false);
  const [key, setKey] = useState<string | null>('unlisted');
  const [listData, setListData] = useState<UnlistedStudent[]>();
  const [listDataFromMentor, setListDataFromMentor] = useState<UnlistedStudentMentor[]>();
  const [showAddFakeSSNModal, setShowAddFakeSSNModal] = useState<boolean>(false);
  const [showAddRealSSNModal, setShowAddRealSSNModal] = useState<boolean>(false);
  const [showDeleteUnlistedStudent, setShowDeleteUnlistedStudent] = useState<boolean>(false);
  const [showEditSSN, setShowEditSSN] = useState<boolean>(false);
  const [selectedStudent, setSelectedStudent] = useState<UnlistedStudent>();
  const [selectedUnlistedStudentFromMentor, setSelectedUnlistedStudentFromMentor] = useState<UnlistedStudentMentor | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageMentor, setCurrentPageMentor] = useState<number>(1);
  const { ExportCSVButton } = CSVExport;
  const dispatch = useDispatch<AppDispatch>();
  // const { weboptions } = useSelector((state: RootState) => state.weboptions);

  const loadUnregisteredStudents = useCallback(
    async (str: string) => {
      setLoadingStudentList(true);
      setError(false);
      try {
        if (key === 'unlisted') {
          const response = await getUnlistedStudents(str);
          setListData(response.data.items);
        } else if (key === 'unlistedMentor') {
          const response = await getUnlistedStudentsFromMentor(str);
          setListDataFromMentor(response.data.items);
        }
      } catch {
        setError(true);
      } finally {
        setLoadingStudentList(false);
      }
    },
    [key]
  );
  /* useEffect(() => {
    dispatch(fetchWeboptions());
  }, [dispatch]); */
  // useEffect(() => console.log('wo', weboptions), []);

  const onCloseAddFakeSSNOnClose = (added: boolean, type: string) => {
    if (type === 'fake') {
      setShowAddFakeSSNModal(false);
    }
    if (type === 'real') {
      setShowAddRealSSNModal(false);
      setSelectedUnlistedStudentFromMentor(undefined);
    }
    if (added) {
      loadUnregisteredStudents(filter);
    }
  };

  const onCloseDeleteUnlistedStudent = (deleted: boolean) => {
    setShowDeleteUnlistedStudent(false);
    if (deleted) {
      loadUnregisteredStudents(filter);
    }
  };
  // keeping incase needing later
  /*
  const onCloseEditUnlistedStudent = (edited: boolean) => {
    setShowEditUnlistedStudent(false);
    if (edited) {
      loadUnregisteredStudents(filter);
    }
  }; */

  const onCloseConfirmRealSSNModal = (edited: boolean) => {
    setShowEditSSN(false);
    if (edited) {
      loadUnregisteredStudents(filter);
    }
  };
  const customTotal = (from: number, to: number, size: number) => (
    // eslint-disable-next-line react/react-in-jsx-scope
    <span className="react-bootstrap-table-pagination-total">
      Raðir {from} til {to} af {size}
    </span>
  );

  const pagination = paginationFactory({
    page: currentPage,
    onPageChange: (page: number) => {
      setCurrentPage(page);
    },
    sizePerPage: 50,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
  });

  const paginationMentor = paginationFactory({
    page: currentPage,
    onPageChange: (page: number) => {
      setCurrentPageMentor(page);
    },
    sizePerPage: 50,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
  });
  useEffect(() => {
    loadUnregisteredStudents(filter);
  }, [loadUnregisteredStudents]);

  const debouncedFilter = useCallback(
    debounce((filterValues: string) => {
      loadUnregisteredStudents(filterValues);
    }, 1000),
    [loadUnregisteredStudents]
  );

  const onSearchChanged = (value: string) => {
    setFilter(value);
    debouncedFilter(value);
  };

  /* const handleSearch = () => {
    dispatch(postNewWeboptions());
  }; */
  const unlistedStudentsColumn = [
    {
      dataField: 'id',
      isDummyField: true,
      text: '',
      csvExport: false,
      headerStyle: { width: '35px' },
      formatter: (cell: string, row: any, rowIndex: any) => {
        const rowNumber = (currentPage - 1) * 50 + (rowIndex + 1);
        return `${rowNumber}.`;
      },
    },
    {
      dataField: 'name',
      text: t('name'),
      sort: true,
    },
    {
      dataField: 'ssn',
      text: t('ssn'),
      sort: true,
      csvFormatter: (cell: string) => `="${cell}"`,
      formatter: (cell: string, row: UnlistedStudent, rowIndex: any) => {
        return (
          <span>
            {row.ssn}
            {row.fake_ssn === '1' && (
              <Button
                variant="link"
                onClick={() => {
                  setShowEditSSN(true);
                  setSelectedStudent(row);
                }}
              >
                <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faSync} />
              </Button>
            )}
          </span>
        );
      },
    },
    {
      dataField: 'familynumber',
      text: t('familynumber'),
      sort: true,
      csvFormatter: (cell: string) => `="${cell}"`,
    },
    /*    {
      dataField: 'address',
      text: t('address'),
      sort: true,
    },
    {
      dataField: 'postalcode',
      text: t('postalcode'),
      sort: true,
    },
    {
      dataField: 'sex',
      text: t('sex'),
      sort: true,
      formatter: sexFormatter,
    },
    {
      dataField: 'fake_ssn',
      text: t('fakeSSN'),
      sort: true,
      csvFormatter: formatBoolean,
      formatter: formatBoolean,
    }, */
    {
      dataField: 'created_date',
      text: t('created'),
      sort: true,
      csvFormatter: dateFormatter,
      formatter: dateFormatter,
    },
    /*   {
      dataField: 'edit',
      isDummyField: true,
      text: '',
      csvExport: false,
      formatter: (cell: any, row: UnlistedStudent, rowIndex: any, colIndex: any) => {
        if (row.fake_ssn === '1') {
          return (
            <Button
              variant="link"
              onClick={() => {
                setShowEditUnlistedStuent(true);
                setSelectedStudent(row);
              }}
            >
              <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faEdit} />
            </Button>
          );
        }
        return '';
      },
    }, */
    {
      dataField: 'delete',
      isDummyField: true,
      text: '',
      csvExport: false,
      formatter: (cell: any, row: UnlistedStudent, rowIndex: any, colIndex: any) => {
        if (row.can_delete && hasWritePermission(Permission.OSKRADBORN)) {
          return (
            <Button
              variant="link"
              onClick={() => {
                setShowDeleteUnlistedStudent(true);
                setSelectedStudent(row);
              }}
            >
              <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faTrashAlt} />
            </Button>
          );
        }
        return '';
      },
    },
  ];
  const unlistedStudentsFromMentorColumn = [
    {
      dataField: 'id',
      isDummyField: true,
      text: '',
      csvExport: false,
      headerStyle: { width: '35px' },
      formatter: (cell: string, row: any, rowIndex: any) => {
        const rowNumber = (currentPageMentor - 1) * 50 + (rowIndex + 1);
        return `${rowNumber}.`;
      },
    },
    {
      dataField: 'student_name',
      text: t('name'),
      sort: true,
    },
    {
      dataField: 'student_ssn',
      text: t('ssn'),
      sort: true,
      csvFormatter: (cell: string) => `="${cell}"`,
    },

    {
      dataField: 'school_name',
      text: t('school_name'),
      sort: true,
    },
    {
      dataField: 'school_ssn',
      text: t('school_ssn'),
      sort: true,
      csvFormatter: (cell: string) => `="${cell}"`,
    },
    {
      dataField: 'class',
      text: t('class'),
      sort: true,
    },
    {
      dataField: 'edit',
      isDummyField: true,
      text: '',
      csvExport: false,
      formatter: (cell: any, row: UnlistedStudentMentor, rowIndex: any, colIndex: any) => {
        if (hasWritePermission(Permission.OSKRADBORN)) {
          return (
            <OverlayTrigger
              placement="top-start"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip className="schoolTooltip" id="button-tooltip">
                  <div>{t('addUnlistedStudent')}</div>
                </Tooltip>
              }
            >
              <Button
                variant="link"
                onClick={() => {
                  setSelectedUnlistedStudentFromMentor(row);
                  setShowAddRealSSNModal(true);
                }}
              >
                <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faUserPlus} />
              </Button>
            </OverlayTrigger>
          );
        }
        return '';
      },
    },
  ];
  return (
    <>
      {hasReadPermission(Permission.OSKRADBORN) && (
        <>
          {showAddFakeSSNModal && <AddFakeSSNForUnlistedStudentModal onClose={onCloseAddFakeSSNOnClose} />}
          {showAddRealSSNModal && <AddRealSSNForUnlistedStudentModal originalValues={selectedUnlistedStudentFromMentor} onClose={onCloseAddFakeSSNOnClose} />}
          {showDeleteUnlistedStudent && <DeleteUnlistedStudentModal originalValues={selectedStudent} onClose={onCloseDeleteUnlistedStudent} />}
          {/*  keeping incase needing later */}
          {/*  {showEditUnlistedStudent && <EditUnlistedStudentModal originalValues={selectedStudent} onClose={onCloseEditUnlistedStudent} />} */}
          {showEditSSN && <EditUnlistedStudentSSNModal originalValues={selectedStudent} onClose={onCloseConfirmRealSSNModal} />}
          <>
            <Row>
              <Col md={8}>
                <input
                  type="text"
                  className="form-control"
                  disabled={loading}
                  placeholder={t('common:search')}
                  autoComplete="off"
                  onChange={(e) => {
                    setSearchValue(e.currentTarget.value);
                    onSearchChanged(e.currentTarget.value);
                    // handleSearch();
                  }}
                  value={searchValue}
                />
              </Col>
              {/*
              <Col md={4}>
                {hasWritePermission(Permission.OSKRADBORN) && (
                  <Button type="submit" onClick={() => setShowAddRealSSNModal(true)} style={{ float: 'right' }}>
                    {t('addRealSSN')}
                  </Button>
                )}
              </Col> */}
              {/*   <Col md={2}>
                {hasWritePermission(Permission.OSKRADBORN) && (
                  <Button type="submit" onClick={() => setShowAddFakeSSNModal(true)}>
                    {t('addFakeSSN')}
                  </Button>
                )}
              </Col> */}
            </Row>
            <div style={{ marginTop: '0.5rem' }}>
              <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faInfoCircle} />
              Þegar barni er bætt við frá námskerfi yfir í &quot;Óskráð börn&quot; birtist það ekki undir &quot;Börn ekki skráð í mat&quot; eða á umsóknarvef fyrr en næsta dag
            </div>
            <br />
            <>
              <div className="whiteColumn">
                <Tabs
                  activeKey={key || undefined}
                  id="unlistedStudents"
                  onSelect={(k: string | null) => {
                    setKey(k);
                    setFilter('');
                    setSearchValue('');
                  }}
                >
                  <Tab eventKey="unlisted" title={t('unlistedStudents')}>
                    {!listData && loadingStudentList && (
                      <div className="spinner">
                        <Spinner animation="border" role="status">
                          <span className="sr-only">{t('common:loading')}</span>
                        </Spinner>
                      </div>
                    )}
                    {!loadingStudentList && listData && (
                      <ToolkitProvider
                        keyField="ssn"
                        bootstrap4
                        data={listData}
                        columns={unlistedStudentsColumn}
                        exportCSV={{
                          fileName: `${t('unregistered_users_prefix')}${dayjs().format('DD.MM.YYYY')}.csv`,
                        }}
                      >
                        {(props: any) => (
                          <div>
                            <ExportCSVButton {...props.csvProps} style={{ float: 'right', color: 'green' }}>
                              <FontAwesomeIcon v-if="icon" className="green-icon" fixedWidth icon={faFileExcel} />
                            </ExportCSVButton>
                            <BootstrapTable {...props.baseProps} keyField="ssn" bordered={false} pagination={pagination} noDataIndication={t('common:emptyDataMessage')} striped />
                          </div>
                        )}
                      </ToolkitProvider>
                    )}
                  </Tab>
                  <Tab eventKey="unlistedMentor" title={t('unlistedMentor')}>
                    {!listDataFromMentor && loadingStudentList && (
                      <div className="spinner">
                        <Spinner animation="border" role="status">
                          <span className="sr-only">{t('common:loading')}</span>
                        </Spinner>
                      </div>
                    )}
                    {!loadingStudentList && listDataFromMentor && (
                      <ToolkitProvider
                        keyField="student_ssn"
                        bootstrap4
                        data={listDataFromMentor}
                        columns={unlistedStudentsFromMentorColumn}
                        exportCSV={{
                          fileName: `${t('unregistered_users_mentor_prefix')}${dayjs().format('DD.MM.YYYY')}.csv`,
                        }}
                      >
                        {(props: any) => (
                          <div>
                            <ExportCSVButton {...props.csvProps} style={{ float: 'right', color: 'green' }}>
                              <FontAwesomeIcon v-if="icon" className="green-icon" fixedWidth icon={faFileExcel} />
                            </ExportCSVButton>
                            <BootstrapTable
                              {...props.baseProps}
                              keyField="student_ssn"
                              bordered={false}
                              pagination={paginationMentor}
                              noDataIndication={t('common:emptyDataMessage')}
                              striped
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    )}
                  </Tab>
                </Tabs>
              </div>
            </>
          </>
        </>
      )}
    </>
  );
};
export default UnlistedStudents;
