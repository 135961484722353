/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';

import { GetMeType, getMe, ping, switchTowns, getUserTowns } from '../../api/account';
import { GetNewTownToken } from '../../api/account.types';
import { GetUserTownListResponse, GetUserTownResponse } from '../../api/lists.types';
import TokenUtils from '../../utils/tokenUtils';
import { RootState } from '../rootReducer';
import { AppThunk } from '../store';

interface State {
  isLoadingAuth: boolean;
  authToken: string | null;
  authTokenExpire: string | null;

  currentUser: GetMeType | null;
  currentUserInitials: string | null;
  isLoading: boolean;
  error: string | null;

  userToken: GetNewTownToken | null;
  userTokenLoading: 'idle' | 'pending';
  userTokenError: null | SerializedError;

  userTowns: GetUserTownResponse[] | null;
  userTownsLoading: 'idle' | 'pending';
  userTownsError: null | SerializedError;
}

const initialState: State = {
  isLoadingAuth: true,
  authToken: null,
  authTokenExpire: null,

  currentUser: null,
  currentUserInitials: null,
  isLoading: false,
  error: null,

  userToken: null,
  userTokenLoading: 'idle',
  userTokenError: null,

  userTowns: null,
  userTownsLoading: 'idle',
  userTownsError: null,
};

function startLoading(state: State): void {
  state.isLoading = true;
}

function loadingFailed(state: State, action: PayloadAction<string>): void {
  state.isLoading = false;
  state.error = action.payload;
}

export const fetchNewTownToken = createAsyncThunk<
  GetNewTownToken,
  number,
  {
    state: RootState;
  }
>('townToken/fetchNewTownToken', async (company_id) => {
  const response = await switchTowns(company_id);
  return response.data;
});

export const fetchUserTowns = createAsyncThunk<
  // eslint-disable-next-line no-undef
  GetUserTownListResponse,
  void,
  {
    state: RootState;
  }
>('auth/fetchUserTowns ', async () => {
  const response = await getUserTowns();
  return response.data;
});

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initializeAuthStart(state: State) {
      state.isLoadingAuth = true;
    },
    initializeAuthCompleted(state: State, action: PayloadAction<string | null>) {
      state.authToken = action.payload;
      state.authTokenExpire = TokenUtils.getAccessTokenExpire();
      state.isLoadingAuth = false;
    },
    getMeStart: startLoading,
    getMeSuccess(state, { payload }: PayloadAction<GetMeType>): void {
      state.currentUser = payload;
      state.currentUserInitials = TokenUtils.getInitials(payload.name);
      state.isLoading = false;
      state.error = null;
    },
    getMeFailure: loadingFailed,
  },
  extraReducers: (builder) => {
    /*
     * fetchNewTownToken
     */
    builder.addCase(fetchNewTownToken.pending, (state) => {
      state.userToken = null;
      state.userTokenError = null;
      state.userTokenLoading = 'pending';
    });
    builder.addCase(fetchNewTownToken.fulfilled, (state, { payload }) => {
      state.userToken = payload;
      state.userTokenLoading = 'idle';
    });
    builder.addCase(fetchNewTownToken.rejected, (state, action) => {
      state.userTokenError = action.error;
      state.userTokenLoading = 'idle';
    });
    /*
     * fetchUserTowns
     */
    builder.addCase(fetchUserTowns.pending, (state) => {
      state.userTowns = null;
      state.userTownsError = null;
      state.userTownsLoading = 'pending';
    });
    builder.addCase(fetchUserTowns.fulfilled, (state, { payload }) => {
      state.userTowns = payload.items;
      state.userTownsLoading = 'idle';
    });
    builder.addCase(fetchUserTowns.rejected, (state, action) => {
      state.userTownsError = action.error;
      state.userTownsLoading = 'idle';
    });
  },
});

export const { getMeStart, getMeSuccess, getMeFailure, initializeAuthStart, initializeAuthCompleted } = slice.actions;

export default slice.reducer;

export const initializeAuth =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(initializeAuthStart());

    dispatch(initializeAuthCompleted(TokenUtils.getAccessToken()));
  };

export const fetchMe =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(getMeStart());
      const response = await getMe();
      dispatch(getMeSuccess(response));
    } catch (err) {
      dispatch(getMeFailure(err.toString()));
    }
  };

export const refresh =
  (): AppThunk<Promise<string>> =>
  async (dispatch): Promise<string> => {
    const token = TokenUtils.getAccessToken();

    if (token === null) {
      throw new Error();
    }
    const response = await ping();
    TokenUtils.storeAccessTokenExpire(response.timeleft);
    dispatch(initializeAuthCompleted(token));
    return token;
  };

export const logout = (): AppThunk<Promise<void>> => async (): Promise<void> => {
  TokenUtils.unsetToken();

  return Promise.resolve();
};
