import { AxiosResponse } from 'axios';
import axios from './api';

import { CreateJobType, SuccessfulResponse, GetJobTypeResponse, JobTypeDeletedResponse, UpdateAccess, GetChangeHistoryResponse } from './access.types';

export const createJobType = (data: CreateJobType): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.post('/vala/admin/authorization-list', data);
};

export const getAuthorizationList = (): Promise<AxiosResponse<GetJobTypeResponse>> => {
  return axios.get('/vala/admin/authorization-list');
};

export const getJobType = (job_type: number | null): Promise<AxiosResponse<GetJobTypeResponse>> => {
  return axios.get(`/vala/admin/authorization-job-type/${job_type}`);
};

export const deleteJobType = (job_type: number | null): Promise<AxiosResponse<JobTypeDeletedResponse>> => {
  return axios.delete(`/vala/admin/authorization-job-type/${job_type}`);
};

export const updateAccessType = (data: UpdateAccess): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.put('/vala/admin/authorization', data);
};

export const getSystemModule = (module: string): Promise<AxiosResponse<GetJobTypeResponse>> => {
  return axios.get(`/vala/admin/authorization-system-module/${module}`);
};

export const getJobTypeChangeHistory = (job_type_id: number): Promise<AxiosResponse<GetChangeHistoryResponse>> => {
  return axios.get(`/vala/admin/job-type-update-log`, {
    params: {
      job_type_id,
    },
  });
};

export const getSystemModuleChangeHistory = (system_module: string): Promise<AxiosResponse<GetChangeHistoryResponse>> => {
  return axios.get(`/vala/admin/system-module-update-log`, {
    params: {
      system_module,
    },
  });
};

export const getWeboptions = (): Promise<AxiosResponse<any>> => {
  return axios.get('/vala/admin/weboptions');
};

export const postWeboptions = (data: any): Promise<AxiosResponse<any>> => {
  return axios.post('/vala/admin/weboptions', data);
};
