import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';

const SpinnerCustom = (): React.ReactElement => {
  const { t } = useTranslation('common');
  return (
    <div className="spinner">
      <Spinner animation="border" role="status">
        <span className="sr-only">{t('common:loading')}</span>
      </Spinner>
    </div>
  );
};

export default SpinnerCustom;
