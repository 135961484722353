import React, { useMemo } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { restValidationToForm } from '../utils/formUtils';
import { addManualDiscount } from '../api/billing';

interface Props {
  onClose: (created: boolean) => void;
  student_name: string;
  student_ssn: string;
  billing_month: string;
  billing_year: string;
  batch_id: string;
}

interface Values {
  batch_id: string;
  student_ssn: string;
  discount: string;
  discount_description: string;
}

const AddDiscountToChildModal = ({ onClose, student_name, student_ssn, billing_month, billing_year, batch_id }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['invoiceView', 'errors']);
  const months = dayjs.months();
  const getBillingMonth = months[Number(billing_month) - 1];

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      discount: Yup.string().required(t('errors:emptyInputField')),
      discount_description: Yup.string().required(t('errors:emptyInputField')),
    });
  }, [t, i18n.language]);

  const initialValues: Values = {
    batch_id,
    student_ssn,
    discount: '',
    discount_description: '',
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await addManualDiscount(values);
            toast.success(t('discountAdded'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
            toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: '1.2rem' }}>
                {`${t('addDiscountToChild')} ${student_name} ${t('periodText')}`}
                <span style={{ textTransform: 'capitalize' }}>{` ${getBillingMonth} - ${billing_year}`}</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6>
                <FontAwesomeIcon className="nav-link-icon" icon={faInfoCircle} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
                {t('addingDiscountToChildAlertInfo')}
              </h6>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Table>
                  <tbody>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('description')}</Form.Label>
                        <span style={{ color: 'red' }}>*</span>
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={values.discount_description}
                          onChange={(event) => {
                            setFieldValue('discount_description', event.target.value);
                          }}
                        />
                        <ErrorMessage name="discount_description" component="div" className="invalid-feedback d-block text-left" />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('amount')}</Form.Label>
                        <span style={{ color: 'red' }}>*</span>
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={values.discount}
                          onChange={(event) => {
                            setFieldValue('discount', event.target.value);
                          }}
                        />
                        <ErrorMessage name="discount" component="div" className="invalid-feedback d-block text-left" />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:register')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddDiscountToChildModal;
