import React, { useCallback, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { faPlus, faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select, { OptionTypeBase } from 'react-select';
import debounce from 'lodash.debounce';
import dayjs from 'dayjs';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import pagination from '../utils/paginationUtils';
import { getBillingPeriods, getBillingYears } from '../api/billing';
import { dateFormatter, monthFormatter } from '../utils/tableUtils';
import CreateNewBillingPeriodModal from './CreateNewBillingPeriodModal';
import { BillingFilterType, BillingPeriodTypes, BillingYearType } from '../api/billing.types';
import DeleteBillingPeriodModal from './DeleteBillingPeriodModal';
import usePermission, { Permission } from '../hooks/usePermission';
import ToolkitProvider from '../utils/csv/provider';

const BillingPeriod = (): React.ReactElement => {
  const { t } = useTranslation(['billingPeriod', 'common']);
  const { hasWritePermission, hasReadPermission } = usePermission();
  const [data, setData] = useState<BillingPeriodTypes[]>();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(false);
  const [showDeleteBillingPeriod, setShowDeleteBillingPeriod] = useState(false);
  const [showCreateNewPeriodModal, setShowCreateNewPeriodModal] = useState(false);
  const [currentPeriod, setCurrentPeriod] = useState<BillingPeriodTypes>({
    billing_month: '',
    billing_year: '',
    deletable: null,
    end_date: '',
    id: null,
    start_date: '',
  });
  const [filter, setFilter] = useState<BillingFilterType>({ years: '' });
  const [billingYears, setBillingYears] = useState<OptionTypeBase[]>();
  const [selectBillingYearValue, setSelectBillingYearValue] = useState<OptionTypeBase[]>([]);
  const { ExportCSVButton } = CSVExport;

  const onCreateNewBillingPeriodModalClosed = (created: boolean) => {
    setShowCreateNewPeriodModal(false);
    if (created) {
      loadBillingPeriods(filter);
    }
  };
  const onDeleteBillingPeriodModalClosed = (deleted: boolean) => {
    setShowDeleteBillingPeriod(false);
    if (deleted) {
      loadBillingPeriods(filter);
    }
  };

  const loadBillingPeriods = useCallback(async (filterTypes: BillingFilterType) => {
    setLoadingData(true);
    setError(false);
    try {
      const response = await getBillingPeriods(filterTypes);
      setData(response.data.items);
    } catch {
      setError(true);
    } finally {
      setLoadingData(false);
    }
  }, []);

  const loadBillingYears = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getBillingYears();
      setBillingYears(
        response.data.items.map((item: BillingYearType) => ({
          value: item.billing_year,
          label: item.billing_year,
        }))
      );
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadBillingPeriods(filter);
  }, []);

  useEffect(() => {
    loadBillingYears();
  }, [loadBillingYears]);

  const invoicingColumn = [
    {
      dataField: 'billing_year',
      text: t('billing_year'),
      sort: true,
    },
    {
      dataField: 'billing_month',
      text: t('billing_month'),
      sort: true,
      classes: 'capitalize',
      formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
        return (
          <Link to={{ pathname: '/reikningagerd', state: { pass: row } }}>
            <div>{monthFormatter(row.billing_month)}</div>
          </Link>
        );
      },
      csvFormatter: monthFormatter,
    },
    {
      dataField: 'start_date',
      text: t('start_date'),
      sort: true,
      formatter: dateFormatter,
      csvFormatter: dateFormatter,
    },
    {
      dataField: 'end_date',
      text: t('end_date'),
      sort: true,
      formatter: dateFormatter,
      csvFormatter: dateFormatter,
    },
    {
      dataField: 'deletable',
      isDummyField: true,
      text: '',
      formatter: (cell: any, row: BillingPeriodTypes, rowIndex: any, extraData: any) => {
        if (hasWritePermission(Permission.REIKNINGATIMABIL) && row.deletable === 1 && rowIndex === 0) {
          return (
            <Button
              variant="link"
              onClick={() => {
                setShowDeleteBillingPeriod(true);
                setCurrentPeriod(row);
              }}
            >
              <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faTrashAlt} />
            </Button>
          );
        }
        return '';
      },
      csvExport: false,
    },
  ];
  const debouncedFilter = useCallback(
    debounce((filterValues: BillingFilterType) => loadBillingPeriods(filterValues), 500),
    [loadBillingPeriods]
  );

  const onSearchChanged = (value: Partial<BillingFilterType>) => {
    const newFilter: BillingFilterType = {
      ...filter,
      ...value,
    };
    setFilter(newFilter);
    debouncedFilter(newFilter);
  };

  if (error) {
    return <>{t('common:kerfisvilla')}</>;
  }

  if (loading) {
    return (
      <>
        <FontAwesomeIcon spin icon={faSpinner} />
      </>
    );
  }

  return (
    <>
      {hasReadPermission(Permission.REIKNINGATIMABIL) && (
        <>
          {data && showCreateNewPeriodModal && <CreateNewBillingPeriodModal onClose={onCreateNewBillingPeriodModalClosed} lastPeriod={data[0]} />}
          {showDeleteBillingPeriod && <DeleteBillingPeriodModal onClose={onDeleteBillingPeriodModalClosed} originalValues={currentPeriod} />}
          <Row>
            <Col lg="3">
              <Select
                placeholder={t('chooseBillingYear')}
                options={billingYears}
                isMulti
                closeMenuOnSelect={false}
                className="basic-multi-select"
                isClearable
                value={selectBillingYearValue}
                onChange={(e) => {
                  setSelectBillingYearValue(e.map((v) => v));
                  onSearchChanged({ years: e.map((v) => v.value).join() });
                }}
              />
            </Col>
            <Col lg="6" className="pull-right">
              {hasWritePermission(Permission.REIKNINGATIMABIL) && (
                <Button variant="primary" className="pull-right" onClick={() => setShowCreateNewPeriodModal(true)}>
                  <FontAwesomeIcon className="sidebarToggleIcon" icon={faPlus} style={{ display: 'inline', marginTop: '2px' }} />
                  &nbsp;{t('createNewPeriodModalTitle')}s
                </Button>
              )}
            </Col>
          </Row>
          {loadingData && (
            <div className="spinner">
              <Spinner animation="border" role="status">
                <span className="sr-only">{t('common:loading')}</span>
              </Spinner>
            </div>
          )}
          {data && !loadingData && (
            <div className="whiteColumn">
              <ToolkitProvider
                keyField="start_date"
                bootstrap4
                data={data}
                columns={invoicingColumn}
                exportCSV={{
                  fileName: `${t('billing_period_prefix_')}${dayjs().format('DD.MM.YYYY')}.csv`,
                }}
              >
                {(props: any) => (
                  <div>
                    <ExportCSVButton {...props.csvProps} style={{ float: 'right', color: 'green' }}>
                      <FontAwesomeIcon v-if="icon" className="green-icon" fixedWidth icon={faFileExcel} />
                    </ExportCSVButton>
                    <BootstrapTable {...props.baseProps} keyField="start_date" bordered={false} pagination={pagination} noDataIndication={t('common:noDataFound')} striped />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BillingPeriod;
