import { AxiosResponse } from 'axios';
import axios from './api';
import { TemplateItem, TemplatesResponse, UpdateTemplateResponse, VariablesResponse } from './templates.types';

export type { TemplatesResponse };

export const getTemplateList = (): Promise<AxiosResponse<TemplatesResponse>> => {
  return axios.get('vala/notifications/templates-list');
};

export const updateTemplate = (data: TemplateItem): Promise<AxiosResponse<UpdateTemplateResponse>> => {
  return axios.put('/vala/notifications/template', data);
};

export const getVariables = (): Promise<AxiosResponse<VariablesResponse>> => {
  return axios.get('/vala/notifications/variables');
};
