import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Col, Dropdown, DropdownButton, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash.debounce';
import DataTable, { TableColumn } from 'react-data-table-component';

import { currencyFormatter, dateTimeFormatter, localeSort } from '../utils/tableUtils';
import { CheckBoxesType } from '../api/billing.types';
import { BatchType } from '../api/invoicing.types';
import usePermission, { Permission } from '../hooks/usePermission';
import { useAppDispatch, useAppSelector } from '../hooks/useState';
import {
  fetchBatches,
  batchesSelector,
  billingPeriodsSelector,
  updateBillingPeriodsChosen,
  fetchBillingPeriods,
  billingPeriodsChosenSelector,
  updateBatchesSelected,
  fetchBatch,
  fetchBatchSummaries,
} from '../store/invoicing/invoicingSlice';
import InvoicingActionBar from '../components/invocing/InvoicingActionBar';
import InvoicingCellExtra from '../components/invocing/InvoicingCellExtra';
import InvoicingExcelRow from '../components/invocing/InvoicingExcelRow';
import InvoicingCellStatus from '../components/invocing/InvoicingCellStatus';
import InvoicingCellExternal from '../components/invocing/InvoicingCellExternal';
import TableRow from '../components/TableRow';

const Invoicing: React.FC = (props: any) => {
  const dispatch = useAppDispatch();
  const items = useAppSelector(batchesSelector.selectAll);
  const allBillingPeriods = useAppSelector(billingPeriodsSelector.selectAll);
  const loadingData = useAppSelector((state) => state.invoicing.batchesLoading);
  const batchSummaryError = useAppSelector((state) => state.invoicing.batchSummariesError);
  const billingPeriodsLoading = useAppSelector((state) => state.invoicing.billingPeriodsLoading);
  const billingPeriodsError = useAppSelector((state) => state.invoicing.billingPeriodsError);
  const chosenPeriod = useAppSelector(billingPeriodsChosenSelector);

  const [expandedId, setExpandedId] = useState<string | undefined>();

  const { t } = useTranslation(['invoiceView', 'errors', 'common']);
  const { hasReadPermission, hasWritePermission } = usePermission();
  const [showCheckBoxes, setShowCheckBoxes] = useState<CheckBoxesType | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const loadAllBatchesInfo = useCallback(
    async (str?: string) => {
      if (chosenPeriod) {
        dispatch(
          fetchBatches({
            chosenPeriod: chosenPeriod?.id,
            str,
          })
        );
      }
    },
    [dispatch, chosenPeriod]
  );

  useEffect(() => {
    dispatch(
      fetchBillingPeriods({
        initialChosen: props.location?.state?.pass ? props.location?.state?.pass : null,
      })
    );
  }, [dispatch, props.location?.state?.pass]);

  useEffect(() => {
    if (chosenPeriod !== undefined) {
      loadAllBatchesInfo();
    }
  }, [chosenPeriod]);

  const onSearchChanged = useCallback(
    debounce((filterValues: string) => loadAllBatchesInfo(filterValues), 1000),
    [loadAllBatchesInfo]
  );

  /*
   *  Hefur einhver bunki leiðréttingabunka.
   */
  const hasExtra = useMemo(() => {
    return hasWritePermission(Permission.REIKNINGAGERD) && items.some((b) => (b.status === 5 && b.batch_type !== 'Extra') || (b.status === 3 && b.batch_type === 'Extra'));
  }, [items, hasWritePermission]);

  /*
   * Dálkar í töflu.
   */
  const columns: TableColumn<BatchType>[] = useMemo(
    () => [
      {
        id: 'school_name',
        name: t('school_name'),
        selector: (row, idx) => `${(idx || 0) + 1}. ${row.school_name}`,
        sortable: true,
        sortFunction: (row1, row2) => localeSort(row1.school_name, row2.school_name),
      },
      {
        name: '',
        omit: !hasExtra,
        cell: (row) => <InvoicingCellExtra row={row} />,
      },
      {
        name: t('run_date'),
        selector: (row) => row.run_date || '',
        format: (row) => dateTimeFormatter(row.run_date),
        sortable: true,
      },
      {
        name: t('confirmed_date'),
        selector: (row) => row.confirmed_date || '',
        format: (row) => dateTimeFormatter(row.confirmed_date),
        sortable: true,
      },
      {
        name: t('confirmed_by'),
        selector: (row) => row.confirmed_by || '',
        sortable: true,
      },
      {
        name: t('num_records_num_children'),
        selector: (row) => row.num_children,
        format: (row) => `${row.num_children}/${row.num_records}`,
        sortable: true,
      },
      {
        name: t('total_amount'),
        selector: (row) => row.total_amount,
        format: (row) => currencyFormatter(row.total_amount),
        sortable: true,
      },
      {
        name: t('status'),
        selector: (row) => row.status,
        sortable: true,
        width: '6rem',
        cell: (row) => <InvoicingCellStatus row={row} />,
      },
      {
        name: t('external_system_date'),
        selector: (row) => row.external_system_date || '',
        sortable: true,
        cell: (row) => <InvoicingCellExternal row={row} />,
      },
    ],
    [t, hasExtra]
  );

  /*
   * Breytt er um val í töflu.
   */
  const onSelectedRowsChange = useCallback(
    (selected: { allSelected: boolean; selectedCount: number; selectedRows: BatchType[] }) => {
      dispatch(updateBatchesSelected(selected.selectedRows.map((s) => s.id)));
    },
    [dispatch]
  );

  /*
   * Stilla sjálfkrafa val í töflu þegar showCheckBoxes er breytt..
   */
  const selectableRowSelected = useCallback(
    (row: BatchType) => {
      switch (showCheckBoxes) {
        case 'RUN':
          return row.status === 3 || row.only_manual_rec === 1;
        case 'DELETE':
        case 'CONFIRM':
          return row.status === 1 && row.only_manual_rec === 0;
        case 'READY_FOR_EXTERNAL':
          return row.status === 2;
        case 'CORRECTION_BATCH':
          return row.status === 5 && row.batch_type !== 'Extra';
        default:
          return false;
      }
    },
    [showCheckBoxes]
  );

  /*
   * Hvort hægt sé að velja röð í töflu eða ekki.
   */
  const selectableRowDisabled = useCallback(
    (row: BatchType) => {
      switch (showCheckBoxes) {
        case 'RUN':
          return !(row.status === 3 || row.only_manual_rec === 1);
        case 'DELETE':
        case 'CONFIRM':
          return !(row.status === 1 && row.only_manual_rec === 0);
        case 'READY_FOR_EXTERNAL':
          return !(row.status === 2);
        case 'CORRECTION_BATCH':
          return !(row.status === 5 && row.batch_type !== 'Extra');
        default:
          return true;
      }
    },
    [showCheckBoxes]
  );

  /*
   * Bara ein expanded í einu
   */
  const expandableRowExpanded = useCallback((row: BatchType) => row.batch_id === expandedId, [expandedId]);

  /*
   * Expanded röðin er blá.
   */
  const conditionalRowStyles = useMemo(
    () => [
      {
        when: (row: BatchType) => row.batch_id === expandedId,
        classNames: ['rdt_TableCellHighlighted'],
      },
    ],
    [expandedId]
  );

  if (!hasReadPermission(Permission.REIKNINGAGERD)) {
    return null;
  }

  if (billingPeriodsError) {
    return <div>{t('common:informationError')}. Ekki tókst að sækja tímabil.</div>;
  }

  if (billingPeriodsLoading === 'pending') {
    return (
      <div className="whiteColumn spinner" style={{ marginTop: '10px' }}>
        <Spinner animation="border" role="status" size="sm">
          <span className="sr-only">{t('common:loading')}</span>
        </Spinner>
      </div>
    );
  }

  if (allBillingPeriods.length === 0) {
    return <div>{t('missingPeriods')}</div>;
  }

  return (
    <>
      <Row>
        <Col md={2}>
          <DropdownButton
            title={chosenPeriod ? `${dayjs(chosenPeriod.billing_month).format('MMMM')} - ${dayjs(chosenPeriod.billing_year).format('YYYY')}` : '-'}
            variant="secondary btn-med"
            style={{ display: 'inline', marginRight: '1rem' }}
          >
            {allBillingPeriods.map((item) => {
              return (
                <Dropdown.Item className="capitalize" key={`billingPeriod-${item.id}`} onSelect={() => dispatch(updateBillingPeriodsChosen(`${item.id}`))}>
                  {dayjs(item.billing_month).format('MMMM')} - {dayjs(item.billing_year).format('YYYY')}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </Col>

        <Col md={3}>
          <input
            type="text"
            className="form-control"
            disabled={loadingData === 'pending'}
            placeholder={t('common:search')}
            autoComplete="off"
            onChange={(e) => {
              setSearchValue(e.currentTarget.value);
              onSearchChanged(e.currentTarget.value);
            }}
            value={searchValue}
          />
        </Col>
      </Row>
      <br />
      {loadingData === 'idle' && items && hasWritePermission(Permission.REIKNINGAGERD) && (
        <InvoicingActionBar showCheckBoxes={showCheckBoxes} setShowCheckBoxes={setShowCheckBoxes} loadAllBatchesInfo={loadAllBatchesInfo} />
      )}
      {loadingData === 'idle' && (items || allBillingPeriods.length === 0) && <InvoicingExcelRow />}
      <DataTable
        highlightOnHover
        striped
        defaultSortFieldId="school_name"
        progressPending={loadingData === 'pending'}
        progressComponent={<FontAwesomeIcon spin icon={faSpinner} />}
        columns={columns}
        data={items}
        selectableRows={showCheckBoxes != null}
        onSelectedRowsChange={onSelectedRowsChange}
        selectableRowSelected={selectableRowSelected}
        selectableRowDisabled={selectableRowDisabled}
        expandableRows
        expandOnRowClicked
        expandableRowsHideExpander
        expandableRowsComponent={({ data }) => <TableRow rowData={data} />}
        noDataComponent={t('common:noDataFound')}
        persistTableHead
        fixedHeader
        onRowExpandToggled={(expanded, row) => {
          if (expanded) {
            setExpandedId(row.batch_id);
            dispatch(fetchBatch(row.batch_id));
            dispatch(
              fetchBatchSummaries({
                batchId: row.batch_id,
              })
            );
          }
        }}
        expandableRowExpanded={expandableRowExpanded}
        conditionalRowStyles={conditionalRowStyles}
      />
    </>
  );
};
export default Invoicing;
