import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, DropdownButton, Spinner, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import { AuthorizationType, GetChangeHistoryResponse, GetJobTypeResponse } from '../api/access.types';
import { getSystemModule, getSystemModuleChangeHistory, updateAccessType } from '../api/access';
import { dateFormatter, infinityValueFormatter } from '../utils/tableUtils';
import pagination from '../utils/paginationUtils';
import usePermission, { Permission } from '../hooks/usePermission';

interface AccessType {
  name: string;
  value: number;
}
const EditSystemModule = (): React.ReactElement => {
  const { t } = useTranslation(['editJobType', 'access']);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { hasReadPermission, hasWritePermission } = usePermission();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<GetJobTypeResponse>();
  const [logData, setLogData] = useState<GetChangeHistoryResponse>();
  const [modifiedData, setModifiedData] = useState<Record<number, number>>();

  const loadSystemModule = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getSystemModule(id);
      setData(response.data);
      setModifiedData(
        response.data.items.reduce<Record<number, number>>((acc, curr, idx) => {
          acc[idx] = curr.access_type;
          return acc;
        }, {})
      );
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadLoggingInfo = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getSystemModuleChangeHistory(id);
      setLogData(response.data);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadSystemModule();
  }, [loadSystemModule]);

  useEffect(() => {
    loadLoggingInfo();
  }, [loadLoggingInfo]);

  const accessTypeValues: AccessType[] = [
    { name: 'Enginn', value: 0 },
    { name: 'Les', value: 1 },
    { name: 'Les+Skrif', value: 2 },
  ];

  const onHandleSubmit = async () => {
    if (data && modifiedData) {
      if (data.items.filter((item, idx) => item.access_type !== modifiedData[idx]).length === 0) {
        history.push('/adgangur');
      } else {
        try {
          const modifiedItems = data.items.map((item, idx) => ({
            job_type: item.job_type,
            system_module: item.system_module,
            new_access: modifiedData[idx],
          }));
          const promises = modifiedItems.map((i) => {
            return updateAccessType(i);
          });

          await Promise.all(promises);
          toast.success(t('jobTypeEdited'));
          history.push('/adgangur');
        } catch (e) {
          setError(error);
          toast.error(t('common:kerfisvilla'));
        }
      }
    }
  };

  const columns = [
    {
      dataField: 'created_date',
      formatter: dateFormatter,
      text: t('logTables:created_date'),
    },
    {
      dataField: 'description',
      text: t('logTables:description'),
    },
    {
      dataField: 'new_value',
      text: t('logTables:new_value'),
    },
    {
      dataField: 'old_value',
      text: t('logTables:old_value'),
      formatter: infinityValueFormatter,
    },
    {
      dataField: 'created_name',
      text: t('logTables:created_name'),
    },
  ];

  return (
    <>
      {hasWritePermission(Permission.ADGANGSSTYRINGAR) && (
        <>
          {loading && !data && (
            <div className="spinner">
              <Spinner animation="border" role="status">
                <span className="sr-only">{t('common:loading')}</span>
              </Spinner>
            </div>
          )}
          {data && (
            <>
              <div className="whiteColumn">
                <Table striped>
                  <thead>
                    <tr>
                      <th>{t('systemModule')}</th>
                      <th>{t('jobType')}</th>
                      <th>{t('access')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.items?.map((item: AuthorizationType, itemIdx: number) => {
                      return (
                        <tr key={itemIdx}>
                          <td>{item.system_module_name}</td>
                          <td>{item.job_type_name}</td>
                          <td>
                            <DropdownButton title={accessTypeValues.find((a) => a.value === modifiedData?.[itemIdx])?.name || ''} variant="secondary btn-med jobTypeBtn">
                              {accessTypeValues.map((v: AccessType, index: number) => {
                                return (
                                  <Dropdown.Item key={`${index}${v.name}`} onSelect={() => setModifiedData({ ...modifiedData, [itemIdx]: v.value })}>
                                    {v.name}
                                  </Dropdown.Item>
                                );
                              })}
                            </DropdownButton>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="button-wrapper">
                  <Button variant="primary" type="submit" onClick={onHandleSubmit}>
                    {t('common:submit')}
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setModifiedData(modifiedData);
                      history.push('/adgangur');
                    }}
                  >
                    {t('common:cancel')}
                  </Button>
                </div>
              </div>

              {hasReadPermission(Permission.BREYTINGARSAGA) && (
                <div className="whiteColumn">
                  <h2>{t('logTables:changelog')}</h2>
                  {logData && <BootstrapTable bootstrap4 keyField="id" data={logData.items} columns={columns} pagination={pagination} striped bordered={false} />}
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default EditSystemModule;
