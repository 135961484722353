import React, { useMemo } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Table, Row, Col } from 'react-bootstrap';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { restValidationToForm, sexOptions } from '../utils/formUtils';
import { getFakeSSN, postUnlistedStudent } from '../api/unlistedStudents';

interface Props {
  onClose: (created: boolean, type: string) => void;
}
interface Values {
  ssn: string;
  name: string;
  day: number | undefined;
  month: number | undefined;
  year: number | undefined;
  familynumber: string;
  address: string;
  postalcode: string;
  sex: string;
  fake_ssn: string;
}

const AddFakeSSNForUnlistedStudentModal = ({ onClose }: Props): React.ReactElement => {
  const { t } = useTranslation(['unlistedStudents', 'common']);
  const initialValues = {
    ssn: '',
    name: '',
    day: undefined,
    month: undefined,
    year: undefined,
    familynumber: '',
    address: '',
    postalcode: '',
    sex: '',
    fake_ssn: '1',
  };

  const days: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  const months = dayjs.months();

  const getYears = () => {
    const years: number[] = [];
    const maxYear = dayjs().year();
    const minYear = maxYear - 16;
    // eslint-disable-next-line no-plusplus
    for (let i = maxYear; i >= minYear; i--) {
      years.push(i);
    }
    return years;
  };

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string().required(t('errors:emptyInputField')),
      day: Yup.string().required(t('errors:emptyInputField')),
      month: Yup.string().required(t('errors:emptyInputField')),
      year: Yup.string().required(t('errors:emptyInputField')),
      familynumber: Yup.string().required(t('errors:emptyInputField')),
      address: Yup.string().required(t('errors:emptyInputField')),
      postalcode: Yup.string().required(t('errors:emptyInputField')),
      sex: Yup.string().required(t('errors:emptyInputField')),
    });
  }, [t]);

  return (
    <>
      <Formik<Values>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<Values>) => {
          try {
            const fakessnResponse = await getFakeSSN({ year: values.year?.toString(), month: values.month?.toString(), day: values.day?.toString() });
            await postUnlistedStudent({ ...values, ssn: fakessnResponse.data.newssn });
            toast.success(t('fakeSSNAdded'));
            onClose(true, 'fake');
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
            toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue }) => (
          <Modal show onHide={() => onClose(false, 'fake')} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{t('addFakeSSNTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Table>
                  <tbody>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('name')}</Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={values.name}
                          onChange={(event) => {
                            setFieldValue('name', event.target.value);
                          }}
                        />
                        <ErrorMessage name="name" component="div" className="invalid-feedback d-block text-left" />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('birthday')}</Form.Label>
                      </td>
                      <td>
                        <Row>
                          <Col>
                            <Form.Control
                              as="select"
                              value={values.day}
                              onChange={(event) => {
                                setFieldValue('day', event.target.value);
                              }}
                            >
                              <option key="undefined" value={undefined}>
                                {t('Dag')}
                              </option>
                              {days?.map((item) => {
                                return (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                            </Form.Control>
                            <ErrorMessage name="day" component="div" className="invalid-feedback d-block text-left" />
                          </Col>
                          <Col>
                            <Form.Control
                              as="select"
                              value={values.month}
                              onChange={(event) => {
                                setFieldValue('month', event.target.value);
                              }}
                            >
                              <option key="undefined" value={undefined}>
                                {t('Mán')}
                              </option>
                              {months?.map((item, idx: number) => {
                                return (
                                  <option key={item} value={idx + 1}>
                                    {item}
                                  </option>
                                );
                              })}
                            </Form.Control>
                            <ErrorMessage name="month" component="div" className="invalid-feedback d-block text-left" />
                          </Col>
                          <Col>
                            <Form.Control
                              as="select"
                              value={values.year}
                              onChange={(event) => {
                                setFieldValue('year', event.target.value);
                              }}
                            >
                              <option key="undefined" value={undefined}>
                                {t('year')}
                              </option>
                              {getYears()?.map((item) => {
                                return (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                            </Form.Control>
                            <ErrorMessage name="year" component="div" className="invalid-feedback d-block text-left" />
                          </Col>
                        </Row>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('familynumber')}</Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={values.familynumber}
                          onChange={(event) => {
                            setFieldValue('familynumber', event.target.value);
                          }}
                        />
                        <ErrorMessage name="familynumber" component="div" className="invalid-feedback d-block text-left" />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('address')}</Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={values.address}
                          onChange={(event) => {
                            setFieldValue('address', event.target.value);
                          }}
                        />
                        <ErrorMessage name="address" component="div" className="invalid-feedback d-block text-left" />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('postalcode')}</Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={values.postalcode}
                          onChange={(event) => {
                            setFieldValue('postalcode', event.target.value);
                          }}
                        />
                        <ErrorMessage name="postalcode" component="div" className="invalid-feedback d-block text-left" />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('sex')}</Form.Label>
                      </td>
                      <td>
                        <Form.Group>
                          {sexOptions.map((item, idx) => (
                            <div key={`inline-${idx}`} className="mb-3">
                              <Form.Check
                                inline
                                type="radio"
                                id={`inline-${idx}`}
                                value={item.value}
                                name="radio"
                                label={item.label}
                                onChange={(e) => {
                                  setFieldValue('sex', e.currentTarget.value);
                                }}
                              />
                            </div>
                          ))}
                        </Form.Group>
                        <ErrorMessage name="sex" component="div" className="invalid-feedback d-block text-left" />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:save')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false, 'fake')}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddFakeSSNForUnlistedStudentModal;
