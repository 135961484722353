import React, { useCallback, useState } from 'react';
import { Button, OverlayTrigger, Spinner, Table, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faEdit, faSearch, faSync, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { BatchSummary, StudentSummaryType } from '../api/billing.types';
import { currencyFormatter } from '../utils/tableUtils';
import { deleteStudentExtraLine, getStudentSummary } from '../api/billing';
import { BatchType } from '../api/invoicing.types';
import RecordsList from './RecordsList';
import AddDiscountToChildModal from '../views/AddDiscountToChildModal';
import ReCalculateChildModal from '../views/ReCalculatechildModal';
import usePermission, { Permission } from '../hooks/usePermission';

interface Props {
  studentData: BatchSummary;
  idx: number;
  batchData: BatchType;
  expandedStudent: string[];
  onClick: (event: React.MouseEvent, row_id: string) => void;
  onClose: (closed: boolean, deleted: boolean) => void;
}

const StudentSummaryTableRow = ({ studentData, idx, batchData, expandedStudent, onClick, onClose }: Props): React.ReactElement => {
  const { t } = useTranslation(['invoiceView', 'common', 'errors']);
  const { hasWritePermission } = usePermission();
  const [addDiscountModalOpen, setAddDiscountModalOpen] = useState<boolean>(false);
  const [recalculateChildModalOpen, setRecalculateChildModalOpen] = useState<boolean>(false);
  const [studentSummary, setStudentSummary] = useState<StudentSummaryType>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onCloseAddDiscountModal = (added: boolean) => {
    setAddDiscountModalOpen(false);

    if (added) {
      loadStudentSummary(studentData.student_id, batchData?.batch_id);
      onClose(true, false);
    }
  };

  const onCloseRecalculateChildModal = (recalculated: boolean) => {
    setRecalculateChildModalOpen(false);

    if (recalculated) {
      loadStudentSummary(studentData.student_id, batchData?.batch_id);
      onClose(true, false);
    }
  };

  const onCloseDeleteManualRecModal = (deleted: boolean) => {
    if (deleted) {
      loadStudentSummary(studentData.student_id, batchData?.batch_id);
      onClose(true, true);
    }
  };

  const loadStudentSummary = useCallback(
    async (student_id: number, batch_id: string) => {
      setLoading(true);
      setError(false);
      try {
        const response = await getStudentSummary({ student_id, batch_id });
        setStudentSummary(response.data.items[0]);
      } catch (e) {
        if (e.response) {
          toast.error(`${t('common:informationError')}. ${e.response}.`);
        }
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [t]
  );
  const removeStudentExtraLine = useCallback(async (student_id: number, batch_id: string) => {
    setLoading(true);
    setError(false);
    try {
      await deleteStudentExtraLine({ student_id, batch_id });
      onClose(true, true);
    } catch (e) {
      if (e.response) {
        toast.error(`${t('common:informationError')}. ${e.response}.`);
      }
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);
  return (
    <>
      {addDiscountModalOpen && (
        <AddDiscountToChildModal
          onClose={onCloseAddDiscountModal}
          student_name={studentData.student_name}
          student_ssn={studentData.student_ssn}
          billing_month={batchData.billing_month}
          billing_year={batchData.billing_year}
          batch_id={batchData.batch_id}
        />
      )}
      {recalculateChildModalOpen && (
        <ReCalculateChildModal
          student_name={studentData.student_name}
          batch_id={batchData.batch_id}
          month={batchData.billing_month}
          year={batchData.billing_year}
          student_id={studentData.student_id}
          onClose={onCloseRecalculateChildModal}
        />
      )}
      <tr
        data-index={idx % 2}
        key={`row-data-${studentData.student_id}`}
        onClick={(event) => {
          onClick(event, studentData.student_id.toString());
          if (!expandedStudent.includes(studentData.student_id.toString())) {
            loadStudentSummary(studentData.student_id, batchData?.batch_id);
          }
        }}
        className={classNames('tableRow studentSummaryTableRow', { isOpen: expandedStudent.includes(studentData.student_id.toString()) })} // studentSummaryOpen
      >
        <td>
          <Link to={`/student/${studentData.registration_id}`}>
            <Button variant="link">
              {expandedStudent.includes(studentData.student_id.toString()) && (
                <FontAwesomeIcon icon={faSearch} fixedWidth style={{ display: 'inline', marginTop: '3px', color: 'white' }} className="checkIcon" />
              )}
              {!expandedStudent.includes(studentData.student_id.toString()) && <FontAwesomeIcon icon={faSearch} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />}
            </Button>
          </Link>
        </td>
        <td>
          {idx + 1}. {studentData.student_name}
          {studentData.payer_registered === 0 && (
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{t('payerNotRegistered')}</Tooltip>}>
              <FontAwesomeIcon icon={faDollarSign} fixedWidth style={{ display: 'inline', marginTop: '3px', color: '#e74a3b' }} className="checkIcon" />
            </OverlayTrigger>
          )}
        </td>
        <td />
        <td>
          <>
            {studentData.student_ssn}
            {studentData.payer_age === '1' ?? (
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{t('payerNotRegistered')}</Tooltip>}>
                <FontAwesomeIcon icon={faDollarSign} fixedWidth style={{ display: 'inline', marginTop: '3px', color: '#e74a3b' }} className="checkIcon" />
              </OverlayTrigger>
            )}
          </>
        </td>
        <td />
        <td>{studentData.discounts.map((discount) => discount)}</td>
        <td />
        <td>{currencyFormatter(studentData.amount)}</td>
        <td>
          {hasWritePermission(Permission.REIKNINGAGERD) && batchData.status === 1 && batchData.only_manual_rec !== 1 && (
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{t('recalculateChild')}</Tooltip>}>
              <Button
                variant="link"
                type="submit"
                style={{ float: 'right' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setRecalculateChildModalOpen(true);
                }}
              >
                {!expandedStudent.includes(studentData.student_id.toString()) && (
                  <FontAwesomeIcon icon={faSync} fixedWidth style={{ display: 'inline', marginTop: '3px', float: 'right' }} />
                )}
                {expandedStudent.includes(studentData.student_id.toString()) && (
                  <FontAwesomeIcon icon={faSync} fixedWidth style={{ display: 'inline', marginTop: '3px', float: 'right', color: 'white' }} />
                )}
              </Button>
            </OverlayTrigger>
          )}
        </td>
        <td>
          {hasWritePermission(Permission.REIKNINGAGERD) && batchData.status === 1 && batchData.only_manual_rec !== 1 && (
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{t('deleteChild')}</Tooltip>}>
              <Button
                variant="link"
                type="submit"
                style={{ float: 'right' }}
                onClick={(e) => {
                  e.stopPropagation();
                  removeStudentExtraLine(studentData.student_id, batchData.batch_id);
                }}
              >
                {!expandedStudent.includes(studentData.student_id.toString()) && (
                  <FontAwesomeIcon icon={faTrashAlt} fixedWidth style={{ display: 'inline', marginTop: '3px', float: 'right' }} />
                )}
                {expandedStudent.includes(studentData.student_id.toString()) && (
                  <FontAwesomeIcon icon={faTrashAlt} fixedWidth style={{ display: 'inline', marginTop: '3px', float: 'right', color: 'white' }} />
                )}
              </Button>
            </OverlayTrigger>
          )}
        </td>
      </tr>
      {loading && !studentSummary && expandedStudent.includes(studentData.student_id.toString()) && (
        <tr>
          <td colSpan={8}>
            <div className="spinner">
              <Spinner animation="border" role="status" size="sm">
                <span className="sr-only">{t('common:loading')}</span>
              </Spinner>
            </div>
          </td>
        </tr>
      )}
      {studentSummary && !loading && expandedStudent.includes(studentData.student_id.toString()) && (
        <tr className={classNames('content gray', { show: expandedStudent.includes(studentData.student_id.toString()) })}>
          <td colSpan={10}>
            {batchData.status === 1 && batchData.only_manual_rec !== 1 && (
              <Button variant="link" type="submit" style={{ float: 'right' }} onClick={() => setAddDiscountModalOpen(true)}>
                <FontAwesomeIcon icon={faEdit} fixedWidth style={{ display: 'inline', marginTop: '3px', float: 'right' }} />
                &nbsp;{t('addDiscount')}
              </Button>
            )}
            <Table className="tableStriped">
              <thead>
                <tr>
                  <th colSpan={8}>{t('type')}</th>
                  <th colSpan={2} style={{ textAlign: 'right' }}>
                    {t('amount')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <RecordsList studentSummary={studentSummary} studentName={studentData.student_name} batch={batchData} onClose={onCloseDeleteManualRecModal} />
              </tbody>
            </Table>
          </td>
        </tr>
      )}
    </>
  );
};

export default StudentSummaryTableRow;
