/* eslint-disable no-param-reassign */
import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, SerializedError } from '@reduxjs/toolkit';
import {
  getInvalidBirthYears,
  getInvalidRegistrations,
  getNotRegisteredBirthYears,
  getNotRegistrations,
  getRecentlyRegisteredBirthYears,
  getRecentlyUnRegisteredBirthYears,
  getRecentRegistrations,
  getRecentUnRegistrations,
  getSchoolList,
  getValidBirthYears,
  getValidRegistrations,
} from '../../api/registrations';
import { BirthYear, RegistrationsKeyDataType, RegistrationsType, RegistrationTabKeysType, SchoolListItem } from '../../api/registrations.types';
import { RootState } from '../../store/rootReducer';

const schoolsAdapter = createEntityAdapter<SchoolListItem>();
const birthYearsAdapter = createEntityAdapter<BirthYear>({
  selectId: (b) => b.get_birth_year,
});

interface State {
  schools: EntityState<SchoolListItem>;
  schoolsLoading: 'idle' | 'pending';
  schoolsError: null | SerializedError;

  birthYears: EntityState<BirthYear>;
  birthYearsLoading: 'idle' | 'pending';
  birthYearsError: null | SerializedError;

  data: RegistrationsKeyDataType | null;
  dataLoading: 'idle' | 'pending';
  dataError: null | SerializedError;
}

/*
 * fetchSchoolList
 */
export const fetchSchoolList = createAsyncThunk<
  SchoolListItem[],
  void,
  {
    state: RootState;
  }
>('registrations/fetchSchoolList', async () => {
  const response = await getSchoolList();
  return response.data.items;
});

/*
 * fetchBirthYearList
 */
export const fetchBirthYearList = createAsyncThunk<
  BirthYear[],
  RegistrationTabKeysType,
  {
    state: RootState;
  }
>('registrations/fetchBirthYearList', async (key, { rejectWithValue }) => {
  if (key === 'validRegistrations') {
    return (await getValidBirthYears()).data.items;
  }
  if (key === 'invalidRegistrations') {
    return (await getInvalidBirthYears()).data.items;
  }
  if (key === 'recentlyRegistered') {
    return (await getRecentlyRegisteredBirthYears()).data.items;
  }
  if (key === 'recentlyUnRegistered') {
    return (await getRecentlyUnRegisteredBirthYears()).data.items;
  }
  if (key === 'notRegistered') {
    return (await getNotRegisteredBirthYears()).data.items;
  }

  return rejectWithValue('key');
});

/*
 * fetchRegistrationsData
 */
export const fetchRegistrationsData = createAsyncThunk<
  RegistrationsKeyDataType,
  {
    key: RegistrationTabKeysType;
    filter: RegistrationsType;
  },
  {
    state: RootState;
  }
>('registrations/fetchRegistrationsData', async ({ key, filter }, { rejectWithValue }) => {
  if (key === 'validRegistrations') {
    return {
      key,
      data: (await getValidRegistrations(filter)).data,
    };
  }
  if (key === 'invalidRegistrations') {
    return {
      key,
      data: (await getInvalidRegistrations(filter)).data,
    };
  }
  if (key === 'notRegistered') {
    return {
      key,
      data: (await getNotRegistrations(filter)).data,
    };
  }
  if (key === 'recentlyRegistered') {
    return {
      key,
      data: (await getRecentRegistrations(filter)).data,
    };
  }
  if (key === 'recentlyUnRegistered') {
    return {
      key,
      data: (await getRecentUnRegistrations(filter)).data,
    };
  }

  return rejectWithValue('key');
});

const initialState: State = {
  schools: schoolsAdapter.getInitialState(),
  schoolsLoading: 'idle',
  schoolsError: null,

  birthYears: birthYearsAdapter.getInitialState(),
  birthYearsLoading: 'idle',
  birthYearsError: null,

  data: null,
  dataLoading: 'idle',
  dataError: null,
};

const slice = createSlice({
  name: 'registrations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /*
     * fetchSchoolList
     */
    builder.addCase(fetchSchoolList.pending, (state) => {
      state.schoolsError = null;
      state.schoolsLoading = 'pending';

      schoolsAdapter.setAll(state.schools, []);
    });
    builder.addCase(fetchSchoolList.fulfilled, (state, { payload }) => {
      state.schoolsLoading = 'idle';
      schoolsAdapter.setAll(state.schools, payload);
    });
    builder.addCase(fetchSchoolList.rejected, (state, action) => {
      state.schoolsError = action.error;
      state.schoolsLoading = 'idle';
    });

    /*
     * fetchBirthYearList
     */
    builder.addCase(fetchBirthYearList.pending, (state) => {
      state.birthYearsError = null;
      state.birthYearsLoading = 'pending';

      birthYearsAdapter.setAll(state.birthYears, []);
    });
    builder.addCase(fetchBirthYearList.fulfilled, (state, { payload }) => {
      state.birthYearsLoading = 'idle';
      birthYearsAdapter.setAll(state.birthYears, payload);
    });
    builder.addCase(fetchBirthYearList.rejected, (state, action) => {
      state.birthYearsError = action.error;
      state.birthYearsLoading = 'idle';
    });

    /*
     * fetchRegistrationsData
     */
    builder.addCase(fetchRegistrationsData.pending, (state) => {
      state.dataError = null;
      state.dataLoading = 'pending';
      state.data = null;
    });
    builder.addCase(fetchRegistrationsData.fulfilled, (state, { payload }) => {
      state.dataLoading = 'idle';
      state.data = payload;
    });
    builder.addCase(fetchRegistrationsData.rejected, (state, action) => {
      state.dataError = action.error;
      state.dataLoading = 'idle';
    });
  },
});

export const schoolsSelector = schoolsAdapter.getSelectors((state: RootState) => state.registrations.schools);
export const birthYearsSelector = birthYearsAdapter.getSelectors((state: RootState) => state.registrations.birthYears);

export default slice.reducer;
