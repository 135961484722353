/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import {
  getBirthYearsFromDate,
  getClasses,
  getExtraProducts,
  getExtraProductsForSpecificSchool,
  getIntoleranceAndAllergies,
  getPinList,
  getSpecialDietaries,
} from '../../api/lists';
import { SchoolsFilterType, SchoolsListResponse } from '../../api/schools.types';
import { getSchoolsList } from '../../api/schools';
import {
  BirthYearsResponseType,
  ClassesResponseType,
  ListsFilterData,
  IntoleranceAllergiesStudentResponse,
  PinNumberTypeResponse,
  ExtraProductsResponse,
  SpecialDietaryResponse,
  ExtraProductsListResponse,
} from '../../api/lists.types';

interface State {
  birthYears: BirthYearsResponseType | null;
  birthYearsLoading: 'idle' | 'pending';
  birthYearsError: null | SerializedError;

  schools: SchoolsListResponse | null;
  schoolsLoading: 'idle' | 'pending';
  schoolsError: null | SerializedError;

  classes: ClassesResponseType | null;
  classesLoading: 'idle' | 'pending';
  classesError: null | SerializedError;

  extraProductsForSchool: ExtraProductsListResponse | null;
  extraProductsForSchoolLoading: 'idle' | 'pending';
  extraProductsForSchoolError: null | SerializedError;

  intoleranceAllergiesTableData: IntoleranceAllergiesStudentResponse[] | null;
  intoleranceAllergiesTableDataLoading: 'idle' | 'pending';
  intoleranceAllergiesTableDataError: null | SerializedError;

  pinNumbersTableData: PinNumberTypeResponse[] | null;
  pinNumbersTableDataLoading: 'idle' | 'pending';
  pinNumbersTableDataError: null | SerializedError;

  extraProductsTableData: ExtraProductsResponse[] | null;
  extraProductsTableDataLoading: 'idle' | 'pending';
  extraProductsTableDataError: null | SerializedError;

  specialDietaryTableData: SpecialDietaryResponse[] | null;
  specialDietaryTableDataLoading: 'idle' | 'pending';
  specialDietaryTableDataError: null | SerializedError;
}

export const fetchBirthYears = createAsyncThunk<
  BirthYearsResponseType,
  string,
  {
    state: RootState;
  }
>('birthYears/birthYears', async (date) => {
  const response = await getBirthYearsFromDate(date);
  return response.data;
});

export const fetchSchools = createAsyncThunk<
  SchoolsListResponse,
  SchoolsFilterType,
  {
    state: RootState;
  }
>('schoolsList/schoolsList', async (data) => {
  const response = await getSchoolsList(data);
  return response.data;
});

export const fetchClasses = createAsyncThunk<
  ClassesResponseType,
  { date: string; schoolId: string },
  {
    state: RootState;
  }
>('classes/classe', async (data) => {
  const response = await getClasses(data.date, data.schoolId);
  return response.data;
});

export const fetchExtraProductsForSchool = createAsyncThunk<
  ExtraProductsListResponse,
  { lookup_date: string; schoolId: string },
  {
    state: RootState;
  }
>('extraProducts/extraProducts', async (data) => {
  const response = await getExtraProductsForSpecificSchool({ lookup_date: data.lookup_date, school_id: data.schoolId });
  return response.data;
});

export const fetchIntoleranceAllergiesData = createAsyncThunk<
  IntoleranceAllergiesStudentResponse[],
  ListsFilterData,
  {
    state: RootState;
  }
>('intoleranceAllergies/intoleranceAllergies', async (data) => {
  const response = await getIntoleranceAndAllergies(data);
  return response.data.items;
});

export const fetchPinNumbersListData = createAsyncThunk<
  PinNumberTypeResponse[],
  ListsFilterData,
  {
    state: RootState;
  }
>('pinNumbersList/pinNumbersList', async (data) => {
  const response = await getPinList(data);
  return response.data.items;
});

export const fetchExtraProductsListData = createAsyncThunk<
  ExtraProductsResponse[],
  ListsFilterData,
  {
    state: RootState;
  }
>('extraProductsList/extraProductsList', async (data) => {
  const response = await getExtraProducts(data);
  return response.data.items;
});

export const fetchSpecialDietaryListData = createAsyncThunk<
  any[],
  ListsFilterData,
  {
    state: RootState;
  }
>('specialDietaries/specialDietariesList', async (data) => {
  const response = await getSpecialDietaries(data);
  return response.data.items;
});

const initialState: State = {
  birthYears: null,
  birthYearsLoading: 'idle',
  birthYearsError: null,

  schools: null,
  schoolsLoading: 'idle',
  schoolsError: null,

  classes: null,
  classesLoading: 'idle',
  classesError: null,

  extraProductsForSchool: null,
  extraProductsForSchoolLoading: 'idle',
  extraProductsForSchoolError: null,

  intoleranceAllergiesTableData: null,
  intoleranceAllergiesTableDataLoading: 'idle',
  intoleranceAllergiesTableDataError: null,

  pinNumbersTableData: null,
  pinNumbersTableDataLoading: 'idle',
  pinNumbersTableDataError: null,

  extraProductsTableData: null,
  extraProductsTableDataLoading: 'idle',
  extraProductsTableDataError: null,

  specialDietaryTableData: null,
  specialDietaryTableDataLoading: 'idle',
  specialDietaryTableDataError: null,
};

const slice = createSlice({
  name: 'getLists',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /*
     * fetchBirthYears
     */
    builder.addCase(fetchBirthYears.pending, (state) => {
      state.birthYears = null;
      state.birthYearsError = null;
      state.birthYearsLoading = 'pending';
    });
    builder.addCase(fetchBirthYears.fulfilled, (state, { payload }) => {
      state.birthYears = payload;
      state.birthYearsLoading = 'idle';
    });
    builder.addCase(fetchBirthYears.rejected, (state, action) => {
      state.birthYearsError = action.error;
      state.birthYearsLoading = 'idle';
    });

    /*
     * fetchSchools
     */
    builder.addCase(fetchSchools.pending, (state) => {
      state.schools = null;
      state.schoolsError = null;
      state.schoolsLoading = 'pending';
    });
    builder.addCase(fetchSchools.fulfilled, (state, { payload }) => {
      state.schools = payload;
      state.schoolsLoading = 'idle';
    });
    builder.addCase(fetchSchools.rejected, (state, action) => {
      state.schoolsError = action.error;
      state.schoolsLoading = 'idle';
    });

    /*
     * fetchClasses
     */
    builder.addCase(fetchClasses.pending, (state) => {
      state.classes = null;
      state.classesError = null;
      state.classesLoading = 'pending';
    });
    builder.addCase(fetchClasses.fulfilled, (state, { payload }) => {
      state.classes = payload;
      state.classesLoading = 'idle';
    });
    builder.addCase(fetchClasses.rejected, (state, action) => {
      state.classesError = action.error;
      state.classesLoading = 'idle';
    });

    /*
     * fetch extra products for schools
     */
    builder.addCase(fetchExtraProductsForSchool.pending, (state) => {
      state.extraProductsForSchool = null;
      state.extraProductsForSchoolError = null;
      state.extraProductsForSchoolLoading = 'pending';
    });
    builder.addCase(fetchExtraProductsForSchool.fulfilled, (state, { payload }) => {
      state.extraProductsForSchool = payload;
      state.extraProductsForSchoolLoading = 'idle';
    });
    builder.addCase(fetchExtraProductsForSchool.rejected, (state, action) => {
      state.extraProductsForSchoolError = action.error;
      state.extraProductsForSchoolLoading = 'idle';
    });

    /*
     * fetch intolerance and allergies
     */
    builder.addCase(fetchIntoleranceAllergiesData.pending, (state) => {
      state.intoleranceAllergiesTableData = null;
      state.intoleranceAllergiesTableDataError = null;
      state.intoleranceAllergiesTableDataLoading = 'pending';
    });
    builder.addCase(fetchIntoleranceAllergiesData.fulfilled, (state, { payload }) => {
      state.intoleranceAllergiesTableData = payload;
      state.intoleranceAllergiesTableDataLoading = 'idle';
    });
    builder.addCase(fetchIntoleranceAllergiesData.rejected, (state, action) => {
      state.intoleranceAllergiesTableDataError = action.error;
      state.intoleranceAllergiesTableDataLoading = 'idle';
    });

    /*
     * fetch pin number list
     */
    builder.addCase(fetchPinNumbersListData.pending, (state) => {
      state.pinNumbersTableData = null;
      state.pinNumbersTableDataError = null;
      state.pinNumbersTableDataLoading = 'pending';
    });
    builder.addCase(fetchPinNumbersListData.fulfilled, (state, { payload }) => {
      state.pinNumbersTableData = payload;
      state.intoleranceAllergiesTableDataLoading = 'idle';
    });
    builder.addCase(fetchPinNumbersListData.rejected, (state, action) => {
      state.pinNumbersTableDataError = action.error;
      state.pinNumbersTableDataLoading = 'idle';
    });

    /*
     * fetch extra products list
     */
    builder.addCase(fetchExtraProductsListData.pending, (state) => {
      state.extraProductsTableData = null;
      state.extraProductsTableDataError = null;
      state.extraProductsTableDataLoading = 'pending';
    });
    builder.addCase(fetchExtraProductsListData.fulfilled, (state, { payload }) => {
      state.extraProductsTableData = payload;
      state.extraProductsTableDataLoading = 'idle';
    });
    builder.addCase(fetchExtraProductsListData.rejected, (state, action) => {
      state.extraProductsTableDataError = action.error;
      state.extraProductsTableDataLoading = 'idle';
    });

    /*
     * fetch special dietary list
     */
    builder.addCase(fetchSpecialDietaryListData.pending, (state) => {
      state.specialDietaryTableData = null;
      state.specialDietaryTableDataError = null;
      state.specialDietaryTableDataLoading = 'pending';
    });
    builder.addCase(fetchSpecialDietaryListData.fulfilled, (state, { payload }) => {
      state.specialDietaryTableData = payload;
      state.specialDietaryTableDataLoading = 'idle';
    });
    builder.addCase(fetchSpecialDietaryListData.rejected, (state, action) => {
      state.specialDietaryTableDataError = action.error;
      state.specialDietaryTableDataLoading = 'idle';
    });
  },
});

export default slice.reducer;
