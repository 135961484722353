import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { fetchPublishingText } from '../store/PublishingManagement/getPublishingManagementSlice';
import { AppDispatch } from '../store/store';
import { useAppSelector } from '../hooks/useState';
import { VersionsText } from '../api/versions.types';

interface Props {
  onClose: (created: boolean) => void;
}

const CreateNewDiscountTypeModal = ({ onClose }: Props): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const textForPublishing = useAppSelector((state) => state.publishingManagement.text);
  const { t } = useTranslation(['versions', 'common']);

  useEffect(() => {
    dispatch(fetchPublishingText());
  }, [dispatch]);

  return (
    <>
      <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('versions:valaMatarVersionsTitle')}</Modal.Title>
        </Modal.Header>
        <div>
          {textForPublishing &&
            textForPublishing.items.map((i: VersionsText, idx: number) => (
              <Table key={idx}>
                <thead>
                  <th>
                    <div>
                      Útgáfa: <span dangerouslySetInnerHTML={{ __html: i.version_nr }} />
                    </div>
                  </th>
                  <th>
                    <div style={{ float: 'right' }}>
                      <span dangerouslySetInnerHTML={{ __html: dayjs(i.date_of_version).format('DD.MM.YYYY') }} />
                    </div>
                  </th>
                </thead>
                <tbody>
                  <td colSpan={2}>
                    <tr>
                      <div dangerouslySetInnerHTML={{ __html: i.description }} />
                    </tr>
                  </td>
                </tbody>
              </Table>
            ))}
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => onClose(false)}>
            {t('common:close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CreateNewDiscountTypeModal;
