import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../store/store';
import { logout as logoutAction } from '../store/auth/authSlice';

const Logout = (): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(logoutAction()).finally(() => {
      window.location.href = '/login?loggedOut=true';
    });
  }, [dispatch]);

  return <div />;
};

export default Logout;
