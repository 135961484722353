import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from './store/store';
import PrivateRouter from './router';
import Login from './views/Login';
import { initializeAuth } from './store/auth/authSlice';
import Logout from './views/Logout';
import { RootState } from './store/rootReducer';

function App(): React.ReactElement | null {
  const dispatch = useDispatch<AppDispatch>();
  const { authToken, isLoadingAuth } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(initializeAuth());
  }, [dispatch]);

  if (isLoadingAuth) {
    return null;
  }

  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />
        <Route
          path="/"
          render={({ location, match: { url } }): React.ReactElement =>
            authToken ? (
              <PrivateRouter url={url} />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location },
                }}
              />
            )
          }
        />
      </Switch>
    </Router>
  );
}

export default App;
