import React, { useState, useRef, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import { BatchType } from '../../api/invoicing.types';
import { dateTimeFormatter } from '../../utils/tableUtils';
import { excelBillingHeader } from '../../utils/headersUtils';
import { ExcelDataType } from '../../api/billing.types';
import { erpLines } from '../../api/billing';

interface Props {
  row: BatchType;
}

const InvoicingCellExternal: React.FC<Props> = ({ row }) => {
  const { t } = useTranslation(['invoiceView']);
  const [excelData, setExcelData] = useState<ExcelDataType[]>([]);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [loading, setLoading] = useState(false);

  const loadExcelData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await erpLines(row.batch_id);
      setExcelData(
        response.data.items.map((i) => ({
          ...i,
          bokunar_byrjun: dayjs(i.bokunar_byrjun).format('DD.MM.YYYY'),
          bokunar_endir: dayjs(i.bokunar_endir).format('DD.MM.YYYY'),
          dags_stofnad: dayjs(i.bokunar_endir).format('DD.MM.YYYY'),
          farid_dags: dayjs(i.bokunar_endir).format('DD.MM.YYYY'),
          timabil_byrjar: dayjs(i.bokunar_endir).format('DD.MM.YYYY'),
          timabil_endar: dayjs(i.bokunar_endir).format('DD.MM.YYYY'),
          kennitala_nemanda: `${i.kennitala_nemanda}${String.fromCharCode(8203)}`,
          kennitala_greidanda: `${i.kennitala_greidanda}${String.fromCharCode(8203)}`,
        }))
      );
    } catch (e) {
      if (e.response) {
        toast.error(e.response.data.error);
      } else {
        toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
      }
    } finally {
      setLoading(false);
      if (csvLinkRef.current) {
        csvLinkRef.current.link.click();
      }
    }
  }, [row.batch_id, t]);

  return (
    <>
      {dateTimeFormatter(row.external_system_date)}
      {row.external_system_date && (
        <>
          <Button
            variant="link"
            className="icon greenIcon"
            value="Export to CSV (Async)"
            onClick={(e) => {
              loadExcelData();
            }}
          >
            <FontAwesomeIcon icon={loading ? faSpinner : faFileExcel} fixedWidth style={{ display: 'inline', marginTop: '3px' }} spin={loading} />
          </Button>
          <CSVLink
            enclosingCharacter="'"
            separator=";"
            headers={excelBillingHeader}
            filename={`Bokhalds-skra-${dayjs().format('DD.MM.YYYY')}.csv`}
            data={excelData}
            ref={csvLinkRef}
          />
        </>
      )}
    </>
  );
};

export default InvoicingCellExternal;
