import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { Spinner } from 'react-bootstrap';
import { Registration, RegistrationsTypeResponse } from '../../api/registrations.types';
import ToolkitProvider from '../../utils/csv/provider';

interface Props {
  loadingData: boolean;
  searchValue: string;
  initialPage: number;
  data: RegistrationsTypeResponse | undefined;
  onChangePage: (p: number) => void;
}

const InvalidRegistrationsTab: React.FC<Props> = ({ loadingData, initialPage, searchValue, data, onChangePage }) => {
  const { t } = useTranslation('registrations');
  const { ExportCSVButton } = CSVExport;
  const [currentPage, setCurrentPage] = useState<number>(initialPage);

  const columns = useMemo(
    () => [
      {
        dataField: 'id',
        isDummyField: true,
        text: '',
        headerStyle: { width: '35px' },
        formatter: (cell: string, row: any, rowIndex: any) => {
          const rowNumber = (currentPage - 1) * 50 + (rowIndex + 1);
          return `${rowNumber}.`;
        },
      },
      {
        dataField: 'student_name',
        text: t('student_name'),
        sort: true,
        formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
          if (searchValue !== '' && row.student_name && row.student_name.toUpperCase().includes(searchValue.toUpperCase())) {
            return (
              <Link to={`/student/${row.registration_id}`}>
                <div dangerouslySetInnerHTML={{ __html: row.student_name.replace(new RegExp(searchValue, 'gi'), '<span class="highlight">$&</span>') }} />
              </Link>
            );
          }
          return (
            <Link to={`/student/${row.registration_id}`}>
              <div>{row.student_name}</div>
            </Link>
          );
        },
      },
      {
        dataField: 'ssn',
        text: t('ssn'),
        sort: true,
        sortValue: (cell: string, row: Registration) => {
          return row.ssn_order;
        },
        csvFormatter: (cell: string): string => `="${cell}"`,
        formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
          if (searchValue !== '' && row.ssn && row.ssn.includes(searchValue)) {
            return <div dangerouslySetInnerHTML={{ __html: row.ssn.replace(new RegExp(searchValue, 'gi'), '<span class="highlight">$&</span>') }} />;
          }
          return <div>{row.ssn}</div>;
        },
      },
      {
        dataField: 'get_birth_year',
        text: t('get_birth_year'),
        sort: true,
      },
      {
        dataField: 'school_name',
        text: t('school_name'),
        sort: true,
      },
      {
        dataField: 'payer_ssn',
        text: t('payer_ssn'),
        sort: true,
        csvFormatter: (cell: string) => `="${cell}"`,
        formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
          if (searchValue !== '' && row.payer_ssn && row.payer_ssn.includes(searchValue)) {
            return <div dangerouslySetInnerHTML={{ __html: row.payer_ssn.replace(new RegExp(searchValue, 'gi'), '<span class="highlight">$&</span>') }} />;
          }
          return <div>{row.payer_ssn}</div>;
        },
      },
      {
        dataField: 'allergies',
        text: t('allergies_reason'),
        sort: true,
        formatter: (cell: string) => (cell === '1' ? t('common:yes') : t('common:no')),
        csvFormatter: (cell: string) => (cell === '1' ? t('common:yes') : t('common:no')),
      },
      {
        dataField: 'intolerance',
        text: t('intolerance_reason'),
        sort: true,
        formatter: (cell: string) => (cell === '1' ? t('common:yes') : t('common:no')),
        csvFormatter: (cell: string) => (cell === '1' ? t('common:yes') : t('common:no')),
      },
      {
        dataField: 'extra_products',
        text: t('extra_products'),
        sort: true,
        formatter: (cell: string) => (cell === '1' ? t('common:yes') : t('common:no')),
        csvFormatter: (cell: string) => (cell === '1' ? t('common:yes') : t('common:no')),
      },
    ],
    [currentPage, searchValue, t]
  );

  const pagination = paginationFactory({
    page: currentPage,
    onPageChange: (page: number) => {
      setCurrentPage(page);
      onChangePage(page);
    },
    sizePerPage: 50,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: (from: number, to: number, size: number) => (
      <span className="react-bootstrap-table-pagination-total">
        Raðir {from} til {to} af {size}
      </span>
    ),
  });

  if (loadingData || !data) {
    return (
      <div className="spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">{t('common:loading')}</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="whiteColumn">
      <ToolkitProvider
        keyField="registration_id"
        bootstrap4
        data={data.items}
        columns={columns}
        exportCSV={{
          fileName: `${t('invalidRegistrations_prefix')}${dayjs().format('DD.MM.YYYY')}.csv`,
        }}
      >
        {(props: any) => (
          <div>
            <ExportCSVButton {...props.csvProps} style={{ float: 'right', color: 'green' }}>
              <FontAwesomeIcon v-if="icon" className="green-icon" fixedWidth icon={faFileExcel} />
            </ExportCSVButton>
            <BootstrapTable {...props.baseProps} keyField="registration_id" bordered={false} pagination={pagination} noDataIndication={t('common:emptyDataMessage')} striped />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default InvalidRegistrationsTab;
