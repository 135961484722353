import React, { useMemo, useState } from 'react';
import { Button, ButtonGroup, Col, Form, Modal, Row, Table, ToggleButton } from 'react-bootstrap';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { restValidationToForm } from '../utils/formUtils';
import { updateRegistrationInfo, uploadFile } from '../api/registrations';
import { Attachment, FileType, RegisteredStudent } from '../api/registrations.types';
import FileInput from '../components/FileInput';

interface Values {
  registration_id: number;
  intolerance: number | undefined;
  intolerance_reason: string;
  allergies: number | undefined;
  allergies_reason: string;
  attachments: Attachment[] | undefined;
}

interface Props {
  onClose: (created: boolean) => void;
  originalValues: RegisteredStudent;
  attachments: Attachment[] | undefined;
}
const EditStudentInfo = ({ originalValues, attachments, onClose }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['errors', 'registrations', 'editStudentViewModals', 'common']);
  const [files, setFiles] = useState<any[]>([]);
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      allergies: Yup.string().required(t('errors:errorMessage')),
      allergies_reason: Yup.string().when('allergies', {
        is: '1',
        then: Yup.string().required(t('errors:emptyInputAllergies')),
        otherwise: Yup.string(),
      }),
      intolerance: Yup.string().required(t('errors:errorMessage')),
      intolerance_reason: Yup.string().when('intolerance', {
        is: '1',
        then: Yup.string().required(t('errors:emptyInputIntolerance')),
        otherwise: Yup.string(),
      }),
    });
  }, [t, i18n.language]);

  const initialValues: Values = {
    registration_id: originalValues.registration_id,
    intolerance: originalValues.intolerance,
    intolerance_reason: originalValues.intolerance_reason ?? '',
    allergies: originalValues.allergies,
    allergies_reason: originalValues.allergies_reason ?? '',
    attachments,
  };

  const radios = [
    { name: 'Já', value: 1 },
    { name: 'Nei', value: 0 },
  ];
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        // enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<Values>) => {
          try {
            if (files.length > 0) {
              const modifiedItems = files.map((item: FileType) => {
                return {
                  student_id: originalValues.student_id,
                  registration_id: originalValues.registration_id,
                  name: item.name,
                  filename: item.name,
                  mime: item.type && item.type.length > 0 ? item.type : 'application/octet-stream',
                  body: item.data,
                };
              });
              const promises = modifiedItems.map((i) => {
                return uploadFile(i);
              });
              await Promise.all(promises);
            }
            await updateRegistrationInfo(values);

            toast.success(t('common:informationChangedSuccess'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
            toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{t('editStudentViewModals:editAllergiesTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Form.Group className="allergiesRadio">
                  <Table className="table" style={{ tableLayout: 'fixed' }}>
                    <tbody>
                      <tr>
                        <th style={{ borderTop: 'none' }}>
                          <Form.Label>{t('registrations:allergies')}</Form.Label>
                        </th>
                        <td style={{ borderTop: 'none' }}>
                          <ButtonGroup toggle>
                            {radios.map((radio) => (
                              <ToggleButton
                                key={radio.value}
                                type="radio"
                                variant="secondary"
                                name="allergies"
                                className="buttonHover"
                                value={radio.value}
                                checked={values.allergies === radio.value}
                                onChange={(e) => {
                                  setFieldValue('allergies', Number(e.currentTarget.value));
                                  if (e.currentTarget.value === '0') {
                                    setFieldValue('allergies_reason', '');
                                  }
                                }}
                              >
                                {radio.name}
                              </ToggleButton>
                            ))}
                          </ButtonGroup>
                          <ErrorMessage name="allergies" component="div" className="invalid-feedback d-block text-left" />
                        </td>
                      </tr>
                      {values.allergies === 1 && (
                        <tr>
                          <th>
                            <Form.Label>{t('registrations:allergies_reason')}</Form.Label>
                          </th>
                          <td>
                            <Form.Group>
                              <Form.Control as="textarea" {...getFieldProps('allergies_reason')} />
                              <ErrorMessage name="allergies_reason" component="div" className="invalid-feedback d-block text-left" />
                            </Form.Group>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th>
                          <Form.Label>{t('registrations:intolerance')}</Form.Label>
                        </th>
                        <td>
                          <ButtonGroup toggle>
                            {radios.map((radio) => (
                              <ToggleButton
                                key={radio.value}
                                type="radio"
                                variant="secondary"
                                name="intolerance"
                                className="buttonHover"
                                value={radio.value}
                                checked={values.intolerance === radio.value}
                                onChange={(e) => {
                                  setFieldValue('intolerance', Number(e.currentTarget.value));
                                  if (e.currentTarget.value === '0') {
                                    setFieldValue('intolerance_reason', '');
                                  }
                                }}
                              >
                                {radio.name}
                              </ToggleButton>
                            ))}
                          </ButtonGroup>
                          <ErrorMessage name="intolerance" component="div" className="invalid-feedback d-block text-left" />
                        </td>
                      </tr>
                      {values.intolerance === 1 && (
                        <tr>
                          <th>
                            <Form.Label>{t('registrations:intolerance_reason')}</Form.Label>
                          </th>
                          <td>
                            <Form.Group>
                              <Form.Control as="textarea" {...getFieldProps('intolerance_reason')} />
                              <ErrorMessage name="intolerance_reason" component="div" className="invalid-feedback d-block text-left" />
                            </Form.Group>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Row>
                    <Col>{(values.intolerance === 1 || values.allergies === 1) && <FileInput setFiles={setFiles} files={files} />}</Col>
                    <Col>
                      {(values.intolerance === 1 || values.allergies === 1) && (
                        <Table style={{ margin: 0 }}>
                          <thead>
                            <tr>
                              <th>{t('editStudentViewModals:attachments')}</th>
                              <th />
                            </tr>
                          </thead>
                          {files.length === 0 && <div style={{ textAlign: 'center', paddingTop: '1rem' }}>{t('studentView:clickToAddFiles')}</div>}
                          {files.length > 0 && (
                            <tbody>
                              {files.map((item: FileType, idx: number) => {
                                return (
                                  <tr key={idx}>
                                    <td>{item.name}</td>
                                    <td>
                                      <Button variant="link" onClick={() => setFiles(files.filter((i) => i.name !== item.name))} style={{ float: 'right' }}>
                                        <FontAwesomeIcon icon={faTrashAlt} style={{ color: '#e74a3b' }} />
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          )}
                        </Table>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:submit')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default EditStudentInfo;
