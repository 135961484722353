/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';
import { getMonthlyBillPeriods, getMonthlyBillSum, getMonthlyBillTotalSum } from '../api/monthlyBillOverview';
import { MonthlyBillPeriodList, MonthlyBillSumList, MonthlyBillTotalSumType } from '../api/monthlyBillOverview.types';

interface State {
  monthlyBillPeriods: MonthlyBillPeriodList | null;
  monthlyBillPeriodsLoading: 'idle' | 'pending';
  monthlyBillPeriodsError: null | SerializedError;

  monthlyBillSum: MonthlyBillSumList | null;
  monthlyBillSumLoading: 'idle' | 'pending';
  monthlyBillSumError: null | SerializedError;

  monthlyBillTotalSum: MonthlyBillTotalSumType[] | null;
  monthlyBillTotalSumLoading: 'idle' | 'pending';
  monthlyBillTotalSumError: null | SerializedError;
}

export const fetchMonthlyBillPeriods = createAsyncThunk<
  MonthlyBillPeriodList,
  void,
  {
    state: RootState;
  }
>('monthlyBillOverview/fetchMonthlyBillPeriods', async () => {
  const response = await getMonthlyBillPeriods();
  return response.data;
});

export const fetchMonthlyBillSum = createAsyncThunk<
  MonthlyBillSumList,
  {
    id: number;
  },
  {
    state: RootState;
  }
>('monthlyBillOverview/fetchMonthlyBillSum', async (payload) => {
  const response = await getMonthlyBillSum(payload.id);
  return response.data;
});

export const fetchMonthlyBillTotalSum = createAsyncThunk<
  MonthlyBillTotalSumType[],
  {
    id: number;
  },
  {
    state: RootState;
  }
>('monthlyBillOverview/fetchMonthlyBillTotalSum', async (payload) => {
  const response = await getMonthlyBillTotalSum(payload.id);
  return response.data.items;
});

const initialState: State = {
  monthlyBillPeriods: null,
  monthlyBillPeriodsLoading: 'idle',
  monthlyBillPeriodsError: null,
  monthlyBillSum: null,
  monthlyBillSumLoading: 'idle',
  monthlyBillSumError: null,
  monthlyBillTotalSum: null,
  monthlyBillTotalSumLoading: 'idle',
  monthlyBillTotalSumError: null,
};

const slice = createSlice({
  name: 'monthlyBillPeriods',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /*
     * fetchMonthlyBillPeriods
     */
    builder.addCase(fetchMonthlyBillPeriods.pending, (state) => {
      state.monthlyBillPeriods = null;
      state.monthlyBillPeriodsError = null;
      state.monthlyBillPeriodsLoading = 'pending';
    });
    builder.addCase(fetchMonthlyBillPeriods.fulfilled, (state, { payload }) => {
      state.monthlyBillPeriods = payload;
      state.monthlyBillPeriodsLoading = 'idle';
    });
    builder.addCase(fetchMonthlyBillPeriods.rejected, (state, action) => {
      state.monthlyBillPeriodsError = action.error;
      state.monthlyBillPeriodsLoading = 'idle';
    });

    /* Data for table */
    builder.addCase(fetchMonthlyBillSum.pending, (state) => {
      state.monthlyBillSum = null;
      state.monthlyBillSumError = null;
      state.monthlyBillSumLoading = 'pending';
    });
    builder.addCase(fetchMonthlyBillSum.fulfilled, (state, { payload }) => {
      state.monthlyBillSum = payload;
      state.monthlyBillSumLoading = 'idle';
    });
    builder.addCase(fetchMonthlyBillSum.rejected, (state, action) => {
      state.monthlyBillSumError = action.error;
      state.monthlyBillSumLoading = 'idle';
    });

    /* Total sum underneath table */
    builder.addCase(fetchMonthlyBillTotalSum.pending, (state) => {
      state.monthlyBillTotalSum = null;
      state.monthlyBillTotalSumError = null;
      state.monthlyBillTotalSumLoading = 'pending';
    });
    builder.addCase(fetchMonthlyBillTotalSum.fulfilled, (state, { payload }) => {
      state.monthlyBillTotalSum = payload;
      state.monthlyBillTotalSumLoading = 'idle';
    });
    builder.addCase(fetchMonthlyBillTotalSum.rejected, (state, action) => {
      state.monthlyBillTotalSumError = action.error;
      state.monthlyBillTotalSumLoading = 'idle';
    });
  },
});

export default slice.reducer;
