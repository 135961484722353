/* eslint-disable */

import React from 'react';
import ToolkitContext from './context';

const Toolkitprovider = (props) => (
  <ToolkitContext.Provider {...props}>
    <ToolkitContext.Consumer>{(tookKitProps) => props.children(tookKitProps)}</ToolkitContext.Consumer>
  </ToolkitContext.Provider>
);

export default Toolkitprovider;
