import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AccordionForLists from '../components/AccordionForLists';
import { dateFormatter } from '../utils/tableUtils';
import { RootState } from '../store/rootReducer';
import ToolkitProvider from '../utils/csv/provider';
import SpinnerCustom from '../components/Spinner';
import usePermission, { Permission } from '../hooks/usePermission';
import { ListsType } from '../api/lists.types';
import ListsFilter from '../components/ListsFilter';

const Lists = (): React.ReactElement => {
  const { t } = useTranslation('lists');
  const { hasReadPermission } = usePermission();
  const { ExportCSVButton } = CSVExport;
  const [showData, setShowData] = useState<ListsType>({ intoleranceAllergies: false, pin: false, extraProducts: false, specialDietary: false });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {
    intoleranceAllergiesTableData,
    intoleranceAllergiesTableDataLoading,
    pinNumbersTableData,
    pinNumbersTableDataLoading,
    extraProductsTableData,
    extraProductsTableDataLoading,
    specialDietaryTableData,
    specialDietaryTableDataLoading,
  } = useSelector((state: RootState) => state.lists);
  const customTotal = (from: number, to: number, size: number) => (
    // eslint-disable-next-line react/react-in-jsx-scope
    <span className="react-bootstrap-table-pagination-total">
      Raðir {from} til {to} af {size}
    </span>
  );
  const pagination = paginationFactory({
    page: currentPage,
    onPageChange: (page: number) => {
      setCurrentPage(page);
    },
    sizePerPage: 10,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
  });
  const intoleranceAllergiesColumns = [
    {
      dataField: 'student_name',
      text: t('student_name'),
      sort: true,
    },
    {
      dataField: 'student_ssn',
      text: t('student_ssn'),
      sort: true,
      csvFormatter: (cell: string) => `="${cell}"`,
    },
    {
      dataField: 'school_name',
      text: t('school_name'),
      sort: true,
    },
    {
      dataField: 'years',
      text: t('years'),
      sort: true,
    },
    {
      dataField: 'class',
      text: t('class'),
      sort: true,
    },
    {
      dataField: 'allergies',
      text: t('allergies'),
      sort: true,
      csvFormatter: (cell: string | null) => (cell === null ? '' : cell),
    },
    {
      dataField: 'intolerance',
      text: t('intolerance'),
      sort: true,
      csvFormatter: (cell: string | null) => (cell === null ? '' : cell),
    },
  ];
  const pinNumbersColumns = [
    {
      dataField: 'student_name',
      text: t('student_name'),
      sort: true,
    },
    {
      dataField: 'student_ssn',
      text: t('student_ssn'),
      sort: true,
      csvFormatter: (cell: string) => `="${cell}"`,
    },
    {
      dataField: 'school_name',
      text: t('school_name'),
      sort: true,
    },
    {
      dataField: 'years',
      text: t('years'),
      sort: true,
    },
    {
      dataField: 'class',
      text: t('class'),
      sort: true,
    },
    {
      dataField: 'pin_number',
      text: t('pin_number'),
      sort: true,
      csvFormatter: (cell: string) => `="${cell}"`,
    },
  ];
  const extraProductsColumns = [
    {
      dataField: 'student_name',
      text: t('student_name'),
      sort: true,
    },
    {
      dataField: 'student_ssn',
      text: t('student_ssn'),
      sort: true,
      csvFormatter: (cell: string) => `="${cell}"`,
    },
    {
      dataField: 'school_name',
      text: t('school_name'),
      sort: true,
    },
    {
      dataField: 'years',
      text: t('years'),
      sort: true,
    },
    {
      dataField: 'class',
      text: t('class'),
      sort: true,
    },
    {
      dataField: 'product',
      text: t('product'),
      sort: true,
    },
    {
      dataField: 'start_date',
      text: t('start_date'),
      sort: true,
      formatter: dateFormatter,
      csvFormatter: dateFormatter,
    },
    {
      dataField: 'end_date',
      text: t('end_date'),
      sort: true,
      formatter: dateFormatter,
      csvFormatter: (cell: string | null) => (cell === null ? '' : dateFormatter(cell)),
    },
  ];
  const specialDietaryColumns = [
    {
      dataField: 'student_name',
      text: t('student_name'),
      sort: true,
    },
    {
      dataField: 'student_ssn',
      text: t('student_ssn'),
      sort: true,
      csvFormatter: (cell: string) => `="${cell}"`,
    },
    {
      dataField: 'school_name',
      text: t('school_name'),
      sort: true,
    },
    {
      dataField: 'years',
      text: t('years'),
      sort: true,
    },
    {
      dataField: 'class',
      text: t('class'),
      sort: true,
    },
    {
      dataField: 'special_food_types',
      text: t('specialDietary'),
      sort: true,
    },
  ];

  return (
    <>
      {hasReadPermission(Permission.SKOLAR) && (
        <>
          <AccordionForLists title={t('allergiesIntoleranceListTitle')} idx="1">
            <ListsFilter showData={showData} setShowData={setShowData} type="intolerance" />
            {!intoleranceAllergiesTableData && intoleranceAllergiesTableDataLoading === 'pending' && <SpinnerCustom />}
            {intoleranceAllergiesTableData && (
              <div className="whiteColumn">
                <ToolkitProvider
                  keyField="student_ssn"
                  bootstrap4
                  data={intoleranceAllergiesTableData}
                  columns={intoleranceAllergiesColumns}
                  exportCSV={{
                    fileName: `${t('student_list_intolerance_allergies_')}${dayjs().format('DD.MM.YYYY')}.csv`,
                  }}
                >
                  {(props: any) => (
                    <div>
                      <ExportCSVButton {...props.csvProps} style={{ float: 'right', color: 'green' }}>
                        <FontAwesomeIcon v-if="icon" className="green-icon" fixedWidth icon={faFileExcel} />
                      </ExportCSVButton>
                      <BootstrapTable
                        {...props.baseProps}
                        pagination={pagination}
                        keyField="student_ssn"
                        bordered={false}
                        noDataIndication={t('common:emptyDataMessage')}
                        striped
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            )}
          </AccordionForLists>
          <AccordionForLists title={t('pinNumbersListTitle')} idx="2">
            <ListsFilter showData={showData} setShowData={setShowData} type="pin" />
            {!pinNumbersTableData && pinNumbersTableDataLoading === 'pending' && <SpinnerCustom />}
            {pinNumbersTableData && (
              <div className="whiteColumn">
                <ToolkitProvider
                  keyField="student_ssn"
                  bootstrap4
                  data={pinNumbersTableData}
                  columns={pinNumbersColumns}
                  exportCSV={{
                    fileName: `${t('student_list_pin_numbers_')}${dayjs().format('DD.MM.YYYY')}.csv`,
                  }}
                >
                  {(props: any) => (
                    <div>
                      <ExportCSVButton {...props.csvProps} style={{ float: 'right', color: 'green' }}>
                        <FontAwesomeIcon v-if="icon" className="green-icon" fixedWidth icon={faFileExcel} />
                      </ExportCSVButton>
                      <BootstrapTable
                        {...props.baseProps}
                        pagination={pagination}
                        keyField="student_ssn"
                        bordered={false}
                        noDataIndication={t('common:emptyDataMessage')}
                        striped
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            )}
          </AccordionForLists>
          <AccordionForLists title={t('extraProductsTitle')} idx="3">
            <ListsFilter showData={showData} setShowData={setShowData} type="extraProducts" />
            {!extraProductsTableData && extraProductsTableDataLoading === 'pending' && <SpinnerCustom />}
            {extraProductsTableData && (
              <div className="whiteColumn">
                <ToolkitProvider
                  keyField="student_ssn"
                  bootstrap4
                  data={extraProductsTableData}
                  columns={extraProductsColumns}
                  exportCSV={{
                    fileName: `${t('student_list_extra_products_')}${dayjs().format('DD.MM.YYYY')}.csv`,
                  }}
                >
                  {(props: any) => (
                    <div>
                      <ExportCSVButton {...props.csvProps} style={{ float: 'right', color: 'green' }}>
                        <FontAwesomeIcon v-if="icon" className="green-icon" fixedWidth icon={faFileExcel} />
                      </ExportCSVButton>
                      <BootstrapTable
                        {...props.baseProps}
                        pagination={pagination}
                        keyField="student_ssn"
                        bordered={false}
                        noDataIndication={t('common:emptyDataMessage')}
                        striped
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            )}
          </AccordionForLists>
          <AccordionForLists title={t('specialDietary')} idx="4">
            <ListsFilter showData={showData} setShowData={setShowData} type="specialDietary" />
            {!specialDietaryTableData && specialDietaryTableDataLoading === 'pending' && <SpinnerCustom />}
            {specialDietaryTableData && (
              <div className="whiteColumn">
                <ToolkitProvider
                  keyField="student_ssn"
                  bootstrap4
                  data={specialDietaryTableData}
                  columns={specialDietaryColumns}
                  exportCSV={{
                    fileName: `${t('student_list_special_dietary_')}${dayjs().format('DD.MM.YYYY')}.csv`,
                  }}
                >
                  {(props: any) => (
                    <div>
                      <ExportCSVButton {...props.csvProps} style={{ float: 'right', color: 'green' }}>
                        <FontAwesomeIcon v-if="icon" className="green-icon" fixedWidth icon={faFileExcel} />
                      </ExportCSVButton>
                      <BootstrapTable
                        {...props.baseProps}
                        pagination={pagination}
                        keyField="student_ssn"
                        bordered={false}
                        noDataIndication={t('common:emptyDataMessage')}
                        striped
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            )}
          </AccordionForLists>
        </>
      )}
    </>
  );
};
export default Lists;
