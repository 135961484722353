import React, { useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Navbar, Button, NavDropdown, Nav } from 'react-bootstrap';
// import ReykjavikLogo from '../assets/reykjavik-logo.png';
// import Logo from '../assets/vala-logo.svg';
import { RootState } from '../store/rootReducer';
import { AppDispatch } from '../store/store';
import { fetchUserTowns } from '../store/auth/authSlice';
import TokenStorage from '../utils/tokenUtils';
import { switchTowns } from '../api/account';

interface Props {
  sidebarOpen: boolean;
  setSidebarOpen: (v: boolean) => void;
}

const Header = ({ setSidebarOpen, sidebarOpen }: Props): React.ReactElement => {
  const { t } = useTranslation(['header', 'common']);
  const { currentUser, userTowns } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();

  const doCheckToken = async (customer_id: number) => {
    try {
      const response = await switchTowns(customer_id);

      if (response.data.token) {
        TokenStorage.storeAccessToken(response.data.token);
        // TokenStorage.storeAccessTokenExpire(response.data.timeleft);
        window.location.reload();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    dispatch(fetchUserTowns());
  }, [dispatch]);

  return (
    <Navbar className="topbar mb-4 static-top shadow">
      <Button id="sidebarToggleTop" variant="link" className="d-md-none rounded-circle mr-3" onClick={() => setSidebarOpen(!sidebarOpen)}>
        <FontAwesomeIcon className="nav-link-icon" icon={faBars} fixedWidth />
      </Button>
      <div className="sidebar-heading">
        <a href="/">
          {/* <img src={Logo} alt="Vala logo" className="logo" /> */}
          <span className="name">{t('common:systemName')}</span>
          <span className="subname">{t('common:systemSubname')}</span>
        </a>
      </div>
      {userTowns && userTowns.length > 1 ? (
        <Nav className="ml-auto">
          <NavDropdown
            title={
              <>
                <span className="mr-2 d-none d-lg-inline text-white large">{currentUser && currentUser.customer} - </span>
                <span className="mr-2 d-none d-lg-inline text-white large">{currentUser && currentUser.name}</span>
                <FontAwesomeIcon icon={faUser} fixedWidth style={{ color: 'white' }} />
              </>
            }
            id="basic-nav-dropdown"
            className="no-arrow"
          >
            {userTowns?.map((town, idx: number) => (
              <NavDropdown.Item key={idx}>
                <Button key={idx} type="button" className="dropdown-item" onClick={() => doCheckToken(town.customer)}>
                  {town.name}
                </Button>
              </NavDropdown.Item>
            ))}
            <NavDropdown.Divider />
            <NavDropdown.Item>
              <Link to="/logout" className="dropdown-item">
                <FontAwesomeIcon icon={faSignOutAlt} fixedWidth size="sm" className="mr-2 text-gray-400" /> {t('logout')}
              </Link>
            </NavDropdown.Item>
          </NavDropdown>
          {/* <div className="reykjavik-logo">
          <img src={ReykjavikLogo} alt="Reykjavík logo" />
        </div> */}
        </Nav>
      ) : (
        <div className="ml-auto">
          <div className="mr-2 d-none d-lg-inline text-white large">
            <span className="mr-2 d-none d-lg-inline text-white large">
              {currentUser && currentUser.customer}
              {/* <span v-if="currentUser.customer">&nbsp;-</span> */}
            </span>
            <span className="mr-2 d-none d-lg-inline text-white large">{currentUser && currentUser.name}</span>
            <span style={{ fontSize: '1.3em', fontWeight: 'lighter' }}>|</span>
            {/* <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderLogOutButtonTooltip}> */}
            <Link to="/logout" style={{ padding: '10px' }}>
              <FontAwesomeIcon icon={faSignOutAlt} size="1x" className="mr-2 d-none d-lg-inline text-white pl-8" />
            </Link>
            {/* </OverlayTrigger> */}
          </div>
        </div>
      )}
    </Navbar>
  );
};

export default Header;
