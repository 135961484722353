import React, { useCallback, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { Button, Col, OverlayTrigger, Row, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { faEdit, faPlus, faSync, faTrashAlt, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import paginationFactory from 'react-bootstrap-table2-paginator';
import dayjs from 'dayjs';
import { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import { getDiscountGroups } from '../api/billing';
import { dateFormatter } from '../utils/tableUtils';
import { DiscountType } from '../api/billing.types';
import CreateNewDiscountTypeModal from './CreateNewDiscountTypeModal';
import EditDiscountTypeModal from './EditDiscountTypeModal';
import DeleteDiscountTypeModal from './DeleteDiscountTypeModal';
import EnableDiscountTypeModal from './EnableDiscountTypeModal';
import usePermission, { Permission } from '../hooks/usePermission';
import ToolkitProvider from '../utils/csv/provider';

const DiscountGroups = (): React.ReactElement => {
  const { t } = useTranslation(['discountTypes', 'common']);
  const { hasWritePermission, hasReadPermission } = usePermission();
  const [enabledData, setEnabledData] = useState<DiscountType[]>();
  const [disabledData, setDisabledData] = useState<DiscountType[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showDeleteDiscountType, setShowDeleteDiscountCategory] = useState(false);
  const [showEnableDiscountTypeModal, setShowEnableDiscountTypeModal] = useState(false);
  const [showEditDiscountTypeModal, setShowEditDiscountCategoryModal] = useState(false);
  const [showCreateNewDiscountTypeModal, setShowCreateDiscountGroupsModal] = useState(false);
  const [currentDiscountType, setCurrentDiscountType] = useState<DiscountType>({
    category: undefined,
    category_name: '',
    created_by: undefined,
    created_date: '',
    description: '',
    enabled: '',
    discount_type_id: 0,
    name: '',
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { ExportCSVButton } = CSVExport;

  const onCreateNewDiscountTypeModalClosed = (created: boolean) => {
    setShowCreateDiscountGroupsModal(false);
    if (created) {
      loadDiscountGroups();
    }
  };
  const onEditDiscountTypeModalClosed = (edited: boolean) => {
    setShowEditDiscountCategoryModal(false);
    if (edited) {
      loadDiscountGroups();
    }
  };
  const onDeleteBillingPeriodModalClosed = (deleted: boolean) => {
    setShowDeleteDiscountCategory(false);
    if (deleted) {
      loadDiscountGroups();
    }
  };
  const onEnableBillingPeriodModalClosed = (enabled: boolean) => {
    setShowEnableDiscountTypeModal(false);
    if (enabled) {
      loadDiscountGroups();
    }
  };

  const loadDiscountGroups = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getDiscountGroups();
      setEnabledData(response.data.items.filter((item) => item.enabled === '1'));
      setDisabledData(response.data.items.filter((item) => item.enabled === '0'));
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadDiscountGroups();
  }, [loadDiscountGroups]);

  const customTotal = (from: number, to: number, size: number) => (
    // eslint-disable-next-line react/react-in-jsx-scope
    <span className="react-bootstrap-table-pagination-total">
      Raðir {from} til {to} af {size}
    </span>
  );

  const pagination = paginationFactory({
    page: currentPage,
    onPageChange: (page: number) => {
      setCurrentPage(page);
    },
    sizePerPage: 50,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
  });

  const invoicingColumn = [
    {
      dataField: 'id',
      isDummyField: true,
      text: '',
      headerStyle: { width: '35px' },
      formatter: (cell: string, row: any, rowIndex: any) => {
        const rowNumber = (currentPage - 1) * 50 + (rowIndex + 1);
        return `${rowNumber}.`;
      },
      csvExport: false,
    },
    {
      dataField: 'category_name',
      text: t('category_name'),
      sort: true,
    },
    {
      dataField: 'name',
      text: t('name'),
      sort: true,
    },
    {
      dataField: 'description',
      text: t('description'),
      sort: true,
    },
    {
      dataField: 'created_date',
      text: t('created_date'),
      sort: true,
      formatter: dateFormatter,
      csvFormatter: dateFormatter,
    },
    {
      dataField: 'edit',
      isDummyField: true,
      text: '',
      formatter: (cell: any, row: DiscountType, rowIndex: any, extraData: any) => {
        if (row.enabled === '1' && hasWritePermission(Permission.TEGUNDAFSLATTAR)) {
          return (
            <OverlayTrigger
              placement="top-start"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip className="schoolTooltip" id="button-tooltip">
                  <div>{t('common:edit')}</div>
                </Tooltip>
              }
            >
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => {
                  setShowEditDiscountCategoryModal(true);
                  setCurrentDiscountType(row);
                }}
                onClick={() => {
                  setShowEditDiscountCategoryModal(true);
                  setCurrentDiscountType(row);
                }}
              >
                <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faEdit} />
              </div>
            </OverlayTrigger>
          );
        }
        return '';
      },
      csvExport: false,
    },
    {
      dataField: 'deletable',
      isDummyField: true,
      text: '',
      formatter: (cell: any, row: DiscountType, rowIndex: any, extraData: any) => {
        if (row.enabled === '1' && hasWritePermission(Permission.TEGUNDAFSLATTAR)) {
          return (
            <OverlayTrigger
              placement="top-start"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip className="schoolTooltip" id="button-tooltip">
                  <div>{t('common:delete')}</div>
                </Tooltip>
              }
            >
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => {
                  setShowDeleteDiscountCategory(true);
                  setCurrentDiscountType(row);
                }}
                onClick={() => {
                  setShowDeleteDiscountCategory(true);
                  setCurrentDiscountType(row);
                }}
              >
                <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faTrashAlt} />
              </div>
            </OverlayTrigger>
          );
        }
        return '';
      },
      csvExport: false,
    },
    {
      dataField: 'enable',
      isDummyField: true,
      text: '',
      formatter: (cell: any, row: DiscountType, rowIndex: any, extraData: any) => {
        if (row.enabled === '0' && hasWritePermission(Permission.TEGUNDAFSLATTAR)) {
          return (
            <OverlayTrigger
              placement="top-start"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip className="schoolTooltip" id="button-tooltip">
                  <div>{t('common:enable')}</div>
                </Tooltip>
              }
            >
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => {
                  setShowEnableDiscountTypeModal(true);
                  setCurrentDiscountType(row);
                }}
                onClick={() => {
                  setShowEnableDiscountTypeModal(true);
                  setCurrentDiscountType(row);
                }}
              >
                <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faSync} />
              </div>
            </OverlayTrigger>
          );
        }
        return '';
      },
      csvExport: false,
    },
  ];

  return (
    <>
      {hasReadPermission(Permission.TEGUNDAFSLATTAR) && (
        <>
          {showCreateNewDiscountTypeModal && <CreateNewDiscountTypeModal onClose={onCreateNewDiscountTypeModalClosed} />}
          {showEditDiscountTypeModal && <EditDiscountTypeModal onClose={onEditDiscountTypeModalClosed} id={currentDiscountType.discount_type_id} />}
          {showDeleteDiscountType && <DeleteDiscountTypeModal onClose={onDeleteBillingPeriodModalClosed} originalValues={currentDiscountType} />}
          {showEnableDiscountTypeModal && <EnableDiscountTypeModal onClose={onEnableBillingPeriodModalClosed} originalValues={currentDiscountType} />}
          <Row>
            <Col lg="6" className="pull-right">
              {hasWritePermission(Permission.TEGUNDAFSLATTAR) && (
                <Button variant="primary" className="pull-right" onClick={() => setShowCreateDiscountGroupsModal(true)}>
                  <FontAwesomeIcon className="sidebarToggleIcon" icon={faPlus} style={{ display: 'inline', marginTop: '2px' }} />
                  &nbsp;{t('createNewDiscountType')}
                </Button>
              )}
            </Col>
          </Row>
          <Tabs defaultActiveKey="enabled" id="uncontrolled-tab-example">
            <Tab eventKey="enabled" title={t('enabled')}>
              {enabledData && (
                <div className="whiteColumn">
                  <ToolkitProvider
                    keyField="name"
                    bootstrap4
                    data={enabledData}
                    columns={invoicingColumn}
                    exportCSV={{
                      fileName: `${t('enabled_discount_group_prefix')}${dayjs().format('DD.MM.YYYY')}.csv`,
                    }}
                  >
                    {(props: any) => (
                      <div>
                        <ExportCSVButton {...props.csvProps} style={{ float: 'right', color: 'green' }}>
                          <FontAwesomeIcon v-if="icon" className="green-icon" fixedWidth icon={faFileExcel} />
                        </ExportCSVButton>
                        <BootstrapTable {...props.baseProps} keyField="name" bordered={false} noDataIndication={t('common:emptyDataMessage')} striped />
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              )}
            </Tab>
            <Tab eventKey="disabled" title={t('disabled')}>
              {disabledData && (
                <div className="whiteColumn">
                  <ToolkitProvider
                    keyField="name"
                    bootstrap4
                    data={disabledData}
                    columns={invoicingColumn}
                    exportCSV={{
                      fileName: `${t('disabled_discount_group_prefix')}${dayjs().format('DD.MM.YYYY')}.csv`,
                    }}
                  >
                    {(props: any) => (
                      <div>
                        <ExportCSVButton {...props.csvProps} style={{ float: 'right', color: 'green' }}>
                          <FontAwesomeIcon v-if="icon" className="green-icon" fixedWidth icon={faFileExcel} />
                        </ExportCSVButton>
                        <BootstrapTable {...props.baseProps} keyField="name" bordered={false} noDataIndication={t('common:emptyDataMessage')} striped />
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              )}
            </Tab>
          </Tabs>
        </>
      )}
    </>
  );
};
export default DiscountGroups;
