import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Dropdown, DropdownButton, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { CSVLink } from 'react-csv';
import { currencyFormatter, dateTimeFormatter, monthFormatter } from '../utils/tableUtils';
import usePermission, { Permission } from '../hooks/usePermission';
import { AppDispatch } from '../store/store';
import { fetchMonthlyBillPeriods, fetchMonthlyBillSum, fetchMonthlyBillTotalSum } from '../store/getMonthlyBillOverview';
import { RootState } from '../store/rootReducer';
import { MonthlyBillOverviewExcelDataType, MonthlyBillPeriod, MonthlyBillTotalSumType } from '../api/monthlyBillOverview.types';
import SpinnerCustom from '../components/Spinner';
import { excelMonthlyBillingOverview } from '../utils/headersUtils';

const BillingOverview = (): React.ReactElement => {
  const { t } = useTranslation('billingOverview');
  const { hasReadPermission } = usePermission();
  const dispatch = useDispatch<AppDispatch>();
  const [chosenPeriod, setChosenPeriod] = useState<MonthlyBillPeriod>();
  const [excelData, setExcelData] = useState<MonthlyBillOverviewExcelDataType[]>([]);
  const { monthlyBillPeriods, monthlyBillPeriodsError, monthlyBillSum, monthlyBillTotalSum } = useSelector((state: RootState) => state.monthlyBillOverview);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  useEffect(() => {
    dispatch(fetchMonthlyBillPeriods());
  }, [dispatch]);

  useEffect(() => {
    if (!chosenPeriod && monthlyBillPeriods != null && monthlyBillPeriods.items.length > 0) {
      setChosenPeriod(monthlyBillPeriods.items[0]);
    }
  }, [monthlyBillPeriods, chosenPeriod, setChosenPeriod]);

  useEffect(() => {
    if (chosenPeriod) {
      dispatch(fetchMonthlyBillSum({ id: chosenPeriod.id }));
      dispatch(fetchMonthlyBillTotalSum({ id: chosenPeriod.id }));
    }
  }, [chosenPeriod, dispatch]);

  const clickExcelButton = () => {
    if (csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  };
  useEffect(() => {
    const arr1 = monthlyBillSum?.items[0].finance_data;
    if (arr1 !== undefined && monthlyBillTotalSum !== null) {
      setExcelData([...arr1, ...monthlyBillTotalSum]);
    }
  }, [monthlyBillSum?.items, monthlyBillTotalSum]);
  const onSelectChosenPeriod = (item: MonthlyBillPeriod) => {
    setChosenPeriod(item);
  };

  if (monthlyBillPeriods !== null && monthlyBillPeriods.items.length === 0) {
    return <div>{t('missingBillingPeriods')}</div>;
  }

  return (
    <>
      {hasReadPermission(Permission.MANADARYFIRLIT) && (
        <>
          {monthlyBillPeriodsError === 'pending' ? (
            <SpinnerCustom />
          ) : monthlyBillPeriodsError || monthlyBillPeriods == null ? (
            t('common:internalError')
          ) : (
            <>
              <Row>
                {monthlyBillPeriods?.items && (
                  <>
                    <Col lg="3" style={{ marginBottom: '1rem' }}>
                      <DropdownButton
                        title={`${chosenPeriod?.billing_year} - ${monthFormatter(chosenPeriod?.billing_month)}`}
                        variant="secondary btn-med"
                        style={{ display: 'inline' }}
                      >
                        {monthlyBillPeriods?.items?.map((item, idx) => {
                          return (
                            <Dropdown.Item key={idx} title={chosenPeriod ? chosenPeriod?.id.toString() : t('period')} onSelect={() => onSelectChosenPeriod(item)}>
                              {`${item.billing_year} - ${monthFormatter(item?.billing_month)}`}
                            </Dropdown.Item>
                          );
                        })}
                      </DropdownButton>
                    </Col>
                  </>
                )}
              </Row>
              {monthlyBillSum && monthlyBillTotalSum && (
                <div className="whiteColumn">
                  <div style={{ marginBottom: '1rem', fontWeight: 'bold', display: 'inline' }}>
                    {t('dataRetrieved')} {dateTimeFormatter(monthlyBillSum.items[0].system_time)}
                  </div>
                  {monthlyBillSum?.items[0].finance_data && monthlyBillTotalSum && (
                    <>
                      <Button
                        variant="link"
                        className="icon greenIcon"
                        style={{ float: 'right', display: 'inline' }}
                        value="Export to CSV (Async)"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          clickExcelButton();
                        }}
                      >
                        <FontAwesomeIcon icon={faFileExcel} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
                      </Button>
                      <CSVLink
                        enclosingCharacter="'"
                        separator=";"
                        headers={excelMonthlyBillingOverview}
                        filename={`Manadaryfirlit-${dayjs().format('DD.MM.YYYY')}.csv`}
                        data={excelData}
                        ref={csvLinkRef}
                      />
                    </>
                  )}
                  <Table>
                    <thead>
                      <tr>
                        <th>{t('billingOverview:school')}</th>
                        <th>{t('billingOverview:unverified_amount')}</th>
                        <th>{t('billingOverview:verified_amount')}</th>
                        <th>{t('billingOverview:ready_amount')}</th>
                        <th>{t('billingOverview:total_amount')}</th>
                        <th>{t('billingOverview:total_sum')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {monthlyBillTotalSum.map((item: MonthlyBillTotalSumType, idx: number) => (
                        <tr style={{ borderTop: '1.5px solid darkgrey', borderBottom: '1.5px solid darkgrey' }} key={idx}>
                          <td style={{ fontWeight: 'bold' }}>{t('total')}</td>
                          <td>{currencyFormatter(item.unverified_amount)}</td>
                          <td>{currencyFormatter(item.verified_amount)}</td>
                          <td>{currencyFormatter(item.ready_amount)}</td>
                          <td>{currencyFormatter(item.total_amount)}</td>
                          <td>{currencyFormatter(item.total_sum)}</td>
                        </tr>
                      ))}
                      {monthlyBillSum.items[0].finance_data.map((item, idx) => (
                        <tr key={idx}>
                          <td>{item.school}</td>
                          <td>{currencyFormatter(item.unverified_amount)}</td>
                          <td>{currencyFormatter(item.verified_amount)}</td>
                          <td>{currencyFormatter(item.ready_amount)}</td>
                          <td>{currencyFormatter(item.total_amount)}</td>
                          <td>{currencyFormatter(item.total_sum)}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      {monthlyBillTotalSum.map((item: MonthlyBillTotalSumType, idx: number) => (
                        <tr style={{ borderTop: '1.5px solid darkgrey' }} key={idx}>
                          <td style={{ fontWeight: 'bold' }}>{t('total')}</td>
                          <td>{currencyFormatter(item.unverified_amount)}</td>
                          <td>{currencyFormatter(item.verified_amount)}</td>
                          <td>{currencyFormatter(item.ready_amount)}</td>
                          <td>{currencyFormatter(item.total_amount)}</td>
                          <td>{currencyFormatter(item.total_sum)}</td>
                        </tr>
                      ))}
                    </tfoot>
                  </Table>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default BillingOverview;
