import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { currencyFormatter } from '../utils/tableUtils';
import { StudentSummaryType } from '../api/billing.types';
import Records from './Records/Records';
import { BatchType } from '../api/invoicing.types';

interface Props {
  studentSummary: StudentSummaryType;
  studentName: string;
  batch: BatchType;
  onClose: (deleted: boolean) => void;
}

const RecordsList = ({ studentSummary, studentName, batch, onClose }: Props): React.ReactElement => {
  const { t } = useTranslation(['recordsList', 'common', 'errors']);
  const [recManOpen, setRecManOpen] = useState<boolean>(false);
  const [recOpen, setRecOpen] = useState<boolean>(false);
  const [recReverseOpen, setRecReverseOpen] = useState<boolean>(false);
  const [recRerollOpen, setRecRerollOpen] = useState<boolean>(false);
  return (
    <>
      {studentSummary.rec_normal.length > 0 && (
        <>
          <tr onClick={() => setRecOpen(!recOpen)} className={classNames('tableRow recordsRow', { recIsOpen: recOpen })}>
            <td colSpan={8}>
              {!recOpen && <FontAwesomeIcon icon={faPlus} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />}
              {recOpen && <FontAwesomeIcon icon={faMinus} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />}
              {t('recordsList:rec_normal')}
            </td>
            <td colSpan={2} style={{ textAlign: 'right' }}>
              {currencyFormatter(studentSummary.rec_normal_sum)}
            </td>
          </tr>
          <tr className={classNames('content cyan', { show: recOpen })}>
            <td colSpan={10}>
              <Records records={studentSummary.rec_normal} showTrash={false} batch={batch} studentName={studentName} onClose={onClose} />
            </td>
          </tr>
        </>
      )}
      {studentSummary.rec_reversal.length > 0 && (
        <>
          <tr onClick={() => setRecReverseOpen(!recReverseOpen)} className={classNames('tableRow', { recIsOpen: recReverseOpen })}>
            <td colSpan={8}>
              {!recReverseOpen && <FontAwesomeIcon icon={faPlus} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />}
              {recReverseOpen && <FontAwesomeIcon icon={faMinus} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />}
              Bakfærslur
            </td>
            <td colSpan={2} style={{ textAlign: 'right' }}>
              {currencyFormatter(studentSummary.rec_reversal_sum)}
            </td>
          </tr>
          <tr className={classNames('content cyan', { show: recReverseOpen })}>
            <td colSpan={10}>
              <Records records={studentSummary.rec_reversal} showTrash={false} studentName={studentName} batch={batch} onClose={onClose} />
            </td>
          </tr>
        </>
      )}
      {studentSummary.rec_reroll.length > 0 && (
        <>
          <tr onClick={() => setRecRerollOpen(!recRerollOpen)} className={classNames('tableRow', { recIsOpen: recRerollOpen })}>
            <td colSpan={8}>
              {!recRerollOpen && <FontAwesomeIcon icon={faPlus} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />}
              {recRerollOpen && <FontAwesomeIcon icon={faMinus} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />}
              Endurútreikningur
            </td>
            <td colSpan={2} style={{ textAlign: 'right' }}>
              {currencyFormatter(studentSummary.rec_reroll_sum)}
            </td>
          </tr>
          <tr className={classNames('content cyan', { show: recRerollOpen })}>
            <td colSpan={10}>
              <Records records={studentSummary.rec_reroll} showTrash={false} studentName={studentName} batch={batch} onClose={onClose} />
            </td>
          </tr>
        </>
      )}
      {studentSummary.rec_manual.length > 0 && (
        <>
          <tr onClick={() => setRecManOpen(!recManOpen)} className={classNames('tableRow', { recIsOpen: recManOpen })}>
            <td colSpan={8}>
              {!recManOpen && <FontAwesomeIcon icon={faPlus} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />}
              {recManOpen && <FontAwesomeIcon icon={faMinus} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />}
              {t('manual_records')}
            </td>
            <td colSpan={2} style={{ textAlign: 'right' }}>
              {currencyFormatter(studentSummary.rec_manual_sum)}
            </td>
          </tr>
          <tr className={classNames('content cyan', { show: recManOpen })}>
            <td colSpan={10}>
              <Records records={studentSummary.rec_manual} showTrash studentName={studentName} batch={batch} onClose={onClose} />
            </td>
          </tr>
        </>
      )}
    </>
  );
};
export default RecordsList;
