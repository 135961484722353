import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Formik, FormikHelpers, getIn, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import { restValidationToForm } from '../utils/formUtils';
import { getNatReg } from '../api/users';
import * as Yup from 'yup';
import debounce from 'lodash.debounce';
import { getStudentIdfromSSN, getValidMonths, reCalculateMonth, recalculateStudent } from '../api/billing';
import { ValidMonthsType } from '../api/billing.types';
import { monthFormatter } from '../utils/tableUtils';

interface Props {
  onClose: (created: boolean) => void;
  batch_id: string;
  school_id: number;
}

interface Values {
  student_id: number;
  name: string;
  ssn: string;
  month_id: string | undefined;
}

const AddStudentToBatchModal = ({ onClose, batch_id, school_id }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['invoiceView', 'errors']);
  const [loadingSSN, setLoadingSSN] = useState<boolean>(false);
  const [loadingValidMonths, setLoadingValidMonths] = useState<boolean>(false);
  const [validPeriods, setValidPeriods] = useState<ValidMonthsType[]>();
  const [error, setError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [selectedValidPeriod, setSelectedValidPeriod] = useState<string>();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      ssn: Yup.string().required(t('errors:emptyInputField')),
      month_id: Yup.string().required(t('errors:emptyInputField')),
    });
  }, [t, i18n.language]);

  const formik = useFormik<Values>({
    initialValues: {
      student_id: NaN,
      month_id: '',
      name: '',
      ssn: '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
      try {
        const formattedChosenPeriod = validPeriods?.find((item: ValidMonthsType) => item.id.toString() === selectedValidPeriod);
        const formattedValues = { month: formattedChosenPeriod?.billing_month, year: formattedChosenPeriod?.billing_year, student_id: values.student_id, batch_id: batch_id };
        await reCalculateMonth(formattedValues);
        toast.success(t('recalcSuccess'));
        onClose(true);
      } catch (e) {
        restValidationToForm({
          t,
          errorResponse: e.response,
          setFieldError,
        });
        toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const loadStudentIdFromSSN = useCallback(async (ssn: string, school_id: number) => {
    setLoadingSSN(true);
    setError(false);
    try {
      const response = await getStudentIdfromSSN(ssn, school_id);
      const data = response.data.items[0];
      if (data) {
        formik.setFieldValue('name', data.name);
        formik.setFieldValue('student_id', data.id);
      } else {
        formik.setFieldValue('name', t('nameNotFound'));
        setNameError(true);
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoadingSSN(false);
    }
  }, []);

  const loadValidMonths = useCallback(async () => {
    setLoadingValidMonths(true);
    setError(false);
    try {
      const response = await getValidMonths();
      setValidPeriods(response.data.items);
    } catch {
      setError(true);
    } finally {
      setLoadingValidMonths(false);
    }
  }, []);

  useEffect(() => {
    loadValidMonths();
  }, [loadValidMonths]);

  const debouncedSSN = useCallback(
    debounce((ssn: string, school_id: number) => loadStudentIdFromSSN(ssn, school_id), 1000),
    []
  );
  const onCheckSSN = (ssn: string, school_id: number) => {
    debouncedSSN(ssn, school_id);
  };

  return (
    <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false} size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: '1.2rem' }}>{t('addStudentToBatch')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
            formik.setStatus('submitted');
            formik.handleSubmit(e);
          }}
        >
          <Form.Group>
            <Form.Label>
              {t('ssn')}
              <span style={{ color: 'red' }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={formik.values.ssn}
              onChange={(e) => {
                if (e.currentTarget.value.length === 10) {
                  onCheckSSN(e.currentTarget.value, school_id);
                } else {
                  formik.setFieldValue('name', 'Kennitala er á röngu formi');
                }
                formik.setFieldValue('ssn', e.currentTarget.value);
              }}
            />
            {!!getIn(formik.touched, 'ssn') && !!getIn(formik.errors, 'ssn') && <div className="invalid-feedback d-block text-left">{formik.errors.ssn}</div>}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('name')}
              <span style={{ color: 'red' }}>*</span>
            </Form.Label>
            <Form.Control type="text" disabled value={formik.values.name} />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('month')}
              <span style={{ color: 'red' }}>*</span>
            </Form.Label>
            <Form.Control
              as="select"
              value={selectedValidPeriod}
              className="capitalize"
              onChange={(event) => {
                if (event.target.value === 'undefined') {
                  formik.setFieldValue('month_id', '');
                } else {
                  formik.setFieldValue('month_id', event.target.value);
                }
                setSelectedValidPeriod(event.target.value);
              }}
              disabled={!validPeriods || loadingValidMonths}
            >
              <option key={0} value={'undefined'} className="capitalize">
                {t('chooseMonth')}
              </option>
              {validPeriods?.map((item: ValidMonthsType, idx) => {
                return (
                  <option key={idx} value={item.id} className="capitalize">
                    {monthFormatter(item.billing_month)} {item.billing_year}
                  </option>
                );
              })}
            </Form.Control>
            {!!getIn(formik.touched, 'month_id') && !!getIn(formik.errors, 'month_id') && <div className="invalid-feedback d-block text-left">{formik.errors.month_id}</div>}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="submit"
          disabled={nameError}
          onClick={() => {
            formik.setStatus('submitted');
            formik.handleSubmit();
          }}
        >
          {t('recalculate')}
        </Button>
        <Button variant="secondary" onClick={() => onClose(false)}>
          {t('common:cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddStudentToBatchModal;
