import React, { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { faInfoCircle, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateNewAccessModal from './CreateNewAccessModal';
import { fetchAuthorizationList } from '../store/Authorization/getAuthorizationListSlice';
import { AppDispatch } from '../store/store';
import { RootState } from '../store/rootReducer';
import { AuthorizationType, JobTypeNameMapped, SystemModuleMapped } from '../api/access.types';
import DeleteCurrentJobType from './DeleteCurrentJobType';
import usePermission, { Permission } from '../hooks/usePermission';

const Access = (): React.ReactElement => {
  const { t } = useTranslation('access');
  const { hasReadPermission, hasWritePermission } = usePermission();
  const [showCreateNewAccess, setShowCreateNewAccess] = useState<boolean>(false);
  const [showDeleteJobTypeModal, setShowDeleteJobTypeModal] = useState<boolean>(false);
  const [currentJobType, setCurrentJobType] = useState<JobTypeNameMapped>({
    jobTypeName: '',
    jobType: null,
  });
  const { authorizationList } = useSelector((state: RootState) => state.authorizationList);
  const dispatch = useDispatch<AppDispatch>();
  const onCreateNewAccessModalClosed = (created: boolean) => {
    setShowCreateNewAccess(false);
    if (created) {
      dispatch(fetchAuthorizationList());
    }
  };
  const onDeleteJobTypeModalClosed = (deleted: boolean) => {
    setShowDeleteJobTypeModal(false);
    if (deleted) {
      dispatch(fetchAuthorizationList());
    }
  };

  useEffect(() => {
    dispatch(fetchAuthorizationList());
  }, [dispatch]);

  const jobTypeList = authorizationList?.items;

  const jobTypeNameMapped = jobTypeList?.map((obj: AuthorizationType) => {
    return { jobTypeName: obj.job_type_name, jobType: obj.job_type };
  });
  const jobTypeNameFiltered = jobTypeNameMapped?.reduce((acc: any, current: any) => {
    const x = acc.find((item: JobTypeNameMapped) => item.jobTypeName === current.jobTypeName);
    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, []);

  const systemModuleMapped = jobTypeList?.map((obj: AuthorizationType) => {
    return { systemModuleName: obj.system_module_name, systemModule: obj.system_module, systemModuleDescription: obj.system_module_description };
  });
  const systemModuleFiltered = systemModuleMapped?.reduce((acc: any, current: any) => {
    const x = acc.find((item: SystemModuleMapped) => item.systemModule === current.systemModule);
    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, []);

  const getAccess = (j: number | null, systemModule: string) => {
    return jobTypeList?.find((x: AuthorizationType) => x.job_type === j && x.system_module === systemModule)?.access_type;
  };

  const getAccessType = (accessType: number) => {
    switch (accessType) {
      case 0:
        return t('accessType.none');
      case 1:
        return t('accessType.read');
      case 2:
        return t('accessType.readWrite');
      default:
        return '';
    }
  };

  const handleDeleteJobType = (currJobType: JobTypeNameMapped) => {
    setShowDeleteJobTypeModal(true);
    setCurrentJobType(currJobType);
  };
  return (
    <>
      {hasReadPermission(Permission.ADGANGSSTYRINGAR) && (
        <>
          {showDeleteJobTypeModal && <DeleteCurrentJobType data={currentJobType} onClose={onDeleteJobTypeModalClosed} />}
          {showCreateNewAccess && <CreateNewAccessModal onClose={onCreateNewAccessModalClosed} />}
          {hasWritePermission(Permission.ADGANGSSTYRINGAR) && (
            <Button
              variant="primary"
              type="submit"
              className="pull-right"
              onClick={() => {
                setShowCreateNewAccess(true);
              }}
            >
              <FontAwesomeIcon className="nav-link-icon" icon={faPlus} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
              &nbsp;{t('createNewAccessButton')}
            </Button>
          )}
          <div className="zui-wrapper">
            <div className="zui-scroller">
              <Table className="zui-table">
                <thead>
                  <tr>
                    <th className="zui-sticky-col">&nbsp;</th>
                    {systemModuleFiltered?.map((sm: SystemModuleMapped, idx: number) => {
                      return (
                        <th key={idx}>
                          {hasWritePermission(Permission.ADGANGSSTYRINGAR) && (
                            <>
                              <Link to={`/editSystemModule/${sm.systemModule}`}>{sm.systemModuleName}</Link>
                              {sm.systemModuleDescription && (
                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{sm.systemModuleDescription}</Tooltip>}>
                                  <FontAwesomeIcon className="nav-link-icon" icon={faInfoCircle} fixedWidth />
                                </OverlayTrigger>
                              )}
                            </>
                          )}
                          {!hasWritePermission(Permission.ADGANGSSTYRINGAR) && sm.systemModuleName}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {jobTypeNameFiltered?.map((jt: JobTypeNameMapped, idx: number) => {
                    return (
                      <tr key={idx}>
                        <td className="zui-sticky-col">
                          {hasWritePermission(Permission.ADGANGSSTYRINGAR) && (
                            <>
                              <Link to={`/editJobType/${jt.jobType}`}>{jt.jobTypeName}</Link>
                              <div role="button" tabIndex={0} className="deleteJobTypeIcon" onKeyDown={() => handleDeleteJobType(jt)} onClick={() => handleDeleteJobType(jt)}>
                                <FontAwesomeIcon className="nav-link-icon" icon={faTrashAlt} fixedWidth />
                              </div>
                            </>
                          )}
                          {!hasWritePermission(Permission.ADGANGSSTYRINGAR) && jt.jobTypeName}
                        </td>
                        {systemModuleFiltered?.map((j: SystemModuleMapped, index: number) => {
                          const accessType = getAccess(jt.jobType, j.systemModule);
                          return (
                            <td key={`${jt.jobTypeName}${j.systemModuleName}${index}`} className={`at${accessType}`}>
                              {getAccessType(accessType)}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Access;
