import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Spinner } from 'react-bootstrap';
import usePermission, { Permission } from '../hooks/usePermission';
import { deleteBatchNewDiscount, deleteOriginalDiscount, getBatchDiscountLook } from '../api/bulkDiscounts';
import { BatchDiscountLookType } from '../api/bulkDiscounts.types';
import ShowConfirmOrCancelBatchAttendanceModal from './ShowConfirmOrCancelBatchAttendanceModal';
import { dateFormatter } from '../utils/tableUtils';

interface Props {
  discount_group_id: number | undefined;
  group_id: number | undefined;
  hideConfirmView: (hide: boolean) => void;
}
const ConfirmBulkDiscountRecords = ({ discount_group_id, group_id, hideConfirmView }: Props): React.ReactElement => {
  const { t } = useTranslation(['discounts', 'common']);
  const { hasWritePermission } = usePermission();
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [error, setError] = useState(false);
  const [listData, setListData] = useState<BatchDiscountLookType[]>();
  const [showConfirmOrCancelBatchAttendanceModal, setShowConfirmOrCancelBatchAttendanceModal] = useState<{ show: boolean; key: string | undefined }>({
    show: false,
    key: undefined,
  });

  const loadListToConfirm = useCallback(async () => {
    setListLoading(true);
    setError(false);
    try {
      // Parse 0 if group_id is undefined
      const id = group_id || 0;
      const response = await getBatchDiscountLook(discount_group_id, id);
      setListData(response.data.items);
    } catch {
      setError(true);
    } finally {
      setListLoading(false);
    }
  }, [discount_group_id, group_id]);

  const handleDeleteBatchNewDiscount = useCallback(
    async (deleteOriginal: boolean, id: number) => {
      setLoading(true);
      setError(false);
      try {
        if (deleteOriginal) {
          await deleteOriginalDiscount(id);
        } else {
          await deleteBatchNewDiscount(id);
        }
        loadListToConfirm();
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [loadListToConfirm]
  );
  useEffect(() => {
    if (discount_group_id !== undefined) {
      loadListToConfirm();
    }
  }, [loadListToConfirm]);

  const handleOnClose = (confirmed: boolean) => {
    setShowConfirmOrCancelBatchAttendanceModal({ show: false, key: undefined });

    if (confirmed) {
      hideConfirmView(true);
    }
  };
  const confirmBulkDiscountColumn = [
    {
      dataField: 'id',
      isDummyField: true,
      text: '',
      headerStyle: { width: '35px' },
      formatter: (cell: string, row: any, rowIndex: any) => {
        return `${rowIndex + 1}.`;
      },
    },
    {
      dataField: 'student_name',
      text: t('name'),
      sort: true,
    },
    {
      dataField: 'attendance_name',
      text: t('attendance_name'),
      sort: true,
    },
    {
      dataField: 'student_ssn',
      text: t('ssn'),
      sort: true,
    },
    {
      dataField: 'class',
      text: t('class'),
      sort: true,
    },
    {
      dataField: 'get_birth_year',
      text: t('get_birth_year'),
      sort: true,
    },
    {
      dataField: 'date_from',
      text: t('date_from'),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: 'conflict',
      isDummyField: true,
      text: '',
      formatter: (cell: any, row: BatchDiscountLookType, rowIndex: any, colIndex: any) => {
        if (row.conflict.length > 0) {
          return (
            <>
              <Button variant="danger" onClick={() => handleDeleteBatchNewDiscount(true, row.conflict[0].old_id)} style={{ marginRight: '1rem', float: 'right' }}>
                {t('deleteOriginalBatchAttendance')}
              </Button>
              <Button variant="danger" onClick={() => handleDeleteBatchNewDiscount(false, row.conflict[0].new_id)} style={{ marginRight: '1rem', float: 'right' }}>
                {t('deleteBatchAttendance')}
              </Button>
            </>
          );
        }
        return '';
      },
    },
    {
      dataField: 'delete',
      isDummyField: true,
      text: '',
      formatter: (cell: any, row: BatchDiscountLookType, rowIndex: any, colIndex: any) => {
        if (row.conflict.length === 0) {
          return (
            <Button variant="link" onClick={() => handleDeleteBatchNewDiscount(false, row.id)}>
              <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faTrashAlt} />
            </Button>
          );
        }
        return '';
      },
    },
  ];
  return (
    <>
      {showConfirmOrCancelBatchAttendanceModal.show && listData && showConfirmOrCancelBatchAttendanceModal.key && (
        <ShowConfirmOrCancelBatchAttendanceModal
          action={showConfirmOrCancelBatchAttendanceModal.key}
          ids_number={listData.length}
          discount_group_id={discount_group_id}
          onClose={handleOnClose}
        />
      )}
      {hasWritePermission(Permission.MAGNSKRAAFSLATT) && (
        <>
          {listData && (
            <div className="whiteColumn">
              <h2>{t('confirmBatchDiscountTitle')}</h2>
              {listLoading && !listData && (
                <div className="spinner">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">{t('common:loading')}</span>
                  </Spinner>
                </div>
              )}
              {!listLoading && listData && (
                <>
                  <div className="confirmCancelButtons">
                    <Button disabled={listData.length === 0} onClick={() => setShowConfirmOrCancelBatchAttendanceModal({ show: true, key: 'confirm' })}>
                      {t('confirmBatchAttendance')}
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        if (listData?.length > 0) {
                          setShowConfirmOrCancelBatchAttendanceModal({ show: true, key: 'cancel' });
                        } else {
                          handleOnClose(true);
                        }
                      }}
                    >
                      {t('common:cancel')}
                    </Button>
                  </div>
                  <BootstrapTable
                    bootstrap4
                    keyField="student_ssn"
                    data={listData}
                    columns={confirmBulkDiscountColumn}
                    noDataIndication={t('common:noDataFound')}
                    striped
                    bordered={false}
                  />
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ConfirmBulkDiscountRecords;
