import React, { useMemo } from 'react';

import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faCalendarAlt,
  faCheck,
  faDollarSign,
  faHistory,
  faListAlt,
  faListOl,
  faLock,
  faPercentage,
  faSortAlphaDown,
  faTable,
  faUser,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import usePermission, { Permission } from '../hooks/usePermission';
import ReleaseManagement from './ReleaseManagement';

interface Props {
  sidebarOpen: boolean;
  setSidebarOpen: (v: boolean) => void;
}
const Sidebar = ({ sidebarOpen, setSidebarOpen }: Props): React.ReactElement => {
  const { t } = useTranslation('sidebar');
  const { hasReadPermission } = usePermission();
  const navItems = useMemo(() => {
    return [
      {
        id: 'yfirlit',
        name: t('yfirlit.name'),
        show: () => hasReadPermission(Permission.SKRANINGAR),
        items: [
          {
            id: 'skraningar',
            name: t('yfirlit.skraningar'),
            to: '/skraningar',
            icon: faCheck,
            show: () => hasReadPermission(Permission.SKRANINGAR),
          },
        ],
      },
      {
        id: 'umsjon',
        name: t('umsjon.name'),
        show: () =>
          hasReadPermission(Permission.ADGANGSSTYRINGAR) || hasReadPermission(Permission.NOTENDUR) || hasReadPermission(Permission.SKOLAR) || hasReadPermission(Permission.SNIDMAT),
        items: [
          {
            id: 'adgangur',
            name: t('umsjon.adgangur'),
            to: '/adgangur',
            icon: faLock,
            show: () => hasReadPermission(Permission.ADGANGSSTYRINGAR),
          },
          {
            id: 'notendur',
            name: t('umsjon.notendur'),
            to: '/notendur',
            icon: faUser,
            show: () => hasReadPermission(Permission.NOTENDUR),
          },
          {
            id: 'oskradir',
            name: t('umsjon.oskradir'),
            to: '/oskradir',
            icon: faUserPlus,
            show: () => hasReadPermission(Permission.OSKRADBORN),
          },
          {
            id: 'skolar',
            name: t('umsjon.skolar'),
            to: '/skolar',
            icon: faBuilding,
            show: () => hasReadPermission(Permission.SKOLAR),
          },
          {
            id: 'snidmat',
            name: t('umsjon.snidmat'),
            to: '/snidmat',
            icon: faTable,
            show: () => hasReadPermission(Permission.SNIDMAT),
          },
          {
            id: 'timabil',
            name: t('umsjon.timabil'),
            to: '/timabil',
            icon: faCalendarAlt,
            show: () => hasReadPermission(Permission.TIMABIL),
          },
          {
            id: 'namfusborn',
            name: t('umsjon.namfusborn'),
            to: '/namfus-born',
            icon: faUserPlus,
            show: () => hasReadPermission(Permission.NAMFUSBORN),
          },
        ],
      },
      {
        id: 'uppgjor',
        name: t('uppgjor.name'),
        show: () =>
          hasReadPermission(Permission.GJALDSKRA) ||
          hasReadPermission(Permission.MAGNSKRAAFSLATT) ||
          hasReadPermission(Permission.REIKNINGAGERD) ||
          hasReadPermission(Permission.REIKNINGATIMABIL) ||
          hasReadPermission(Permission.TEGUNDAFSLATTAR),
        items: [
          {
            id: 'gjaldskra',
            name: t('uppgjor.gjaldskra'),
            to: '/gjaldskra',
            icon: faDollarSign,
            show: () => hasReadPermission(Permission.GJALDSKRA),
          },
          {
            id: 'afslattur',
            name: t('uppgjor.magnskra-afslatt'),
            to: '/magnskra-afslatt',
            icon: faPercentage,
            show: () => hasReadPermission(Permission.MAGNSKRAAFSLATT),
          },
          {
            id: 'reikningagerd',
            name: t('uppgjor.reikningagerd'),
            to: '/reikningagerd',
            icon: faCheckCircle,
            show: () => hasReadPermission(Permission.REIKNINGAGERD),
          },
          {
            id: 'reikningatimabil',
            name: t('uppgjor.reikningatimabil'),
            to: '/reikningatimabil',
            icon: faHistory,
            show: () => hasReadPermission(Permission.REIKNINGATIMABIL),
          },
          {
            id: 'tegundAfslattar',
            name: t('uppgjor.tegundAfslattar'),
            to: '/tegund-afslattar',
            icon: faSortAlphaDown,
            show: () => hasReadPermission(Permission.TEGUNDAFSLATTAR),
          },
          {
            id: 'manadaryfirlit',
            name: t('uppgjor.manadaryfirlit'),
            to: '/manadar-yfirlit',
            icon: faCheckCircle,
            show: () => hasReadPermission(Permission.TEGUNDAFSLATTAR),
          },
        ],
      },
      {
        id: 'tolfraedi',
        name: t('tolfraedi.name'),
        show: () => hasReadPermission(Permission.TOLFRAEDI) || hasReadPermission(Permission.LISTAR),
        items: [
          {
            id: 'listar',
            name: t('tolfraedi.listar'),
            to: '/listar',
            icon: faListAlt,
            show: () => hasReadPermission(Permission.LISTAR),
          },
          // {
          //   id: 'tolfraedi',
          //   name: t('tolfraedi.tolfraedi'),
          //   to: '/tolfraedi',
          //   icon: faListOl,
          //   show: () => hasReadPermission(Permission.TOLFRAEDI),
          // },
        ],
      },
    ];
  }, [t, hasReadPermission]);

  return (
    <div
      className={classNames('sidebar-wrapper', {
        toggled: sidebarOpen,
      })}
    >
      <ul
        className={classNames('navbar-nav sidebar accordion', {
          toggled: sidebarOpen,
        })}
      >
        {navItems
          .filter((i) => i.show())
          .map((navItem) => (
            <div key={navItem.id}>
              <div className="list-wrapper">
                <div className="sidebar-headers">{navItem.name}</div>
                {navItem.items
                  .filter((i) => i.show())
                  .map((item) => (
                    <li key={item.id} className={classNames('nav-item')}>
                      <NavLink exact to={item.to} className="nav-link" activeClassName="active">
                        {item.icon && <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={item.icon} />}
                        <span>{item.name}</span>
                      </NavLink>
                    </li>
                  ))}
                <hr className="sidebar-divider" />
              </div>
            </div>
          ))}
        <ReleaseManagement />
        {/* <div className="toggle-button text-center d-none d-md-inline">
          <button type="button" className="rounded-circle border-0 sidebarToggle" onClick={() => setSidebarOpen(!sidebarOpen)}>
            <FontAwesomeIcon className="sidebarToggleIcon" icon={sidebarOpen ? faAngleRight : faAngleLeft} />
          </button>
        </div> */}
      </ul>
    </div>
  );
};
export default Sidebar;
