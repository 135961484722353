import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { BirthYear, RegistrationsType, RegistrationTabKeysType, SchoolListItem } from '../../api/registrations.types';
import usePermission, { Permission } from '../../hooks/usePermission';
import RegisterStudentModal from '../RegisterStudentModal';

import ValidRegistrationsTab from './ValidRegistrationsTab';
import InvalidRegistrationsTab from './InvalidRegistrationsTab';
import NotRegisteredTab from './NotRegisteredTab';
import RecentlyRegisteredTab from './RecentlyRegisteredTab';
import RecentlyUnregisteredTab from './RecentlyUnregisteredTab';
import RegistrationsFilters from './RegistrationsFilters';
import { fetchRegistrationsData } from './registrationsSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/useState';

const Registrations: React.FC = () => {
  const dispatch = useAppDispatch();
  const [initializing, setInitializing] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation('registrations');
  const { hasReadPermission } = usePermission();
  const [key, setKey] = useState<RegistrationTabKeysType>('validRegistrations');
  const [filter, setFilter] = useState<RegistrationsType>({ str: '', years: '', schools: '', allergies: '', intolerance: '', extra_products: '' });
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectBirthYearValue, setSelectBirthYearValue] = useState<BirthYear[]>([]);
  const [selectSchoolValue, setSelectSchoolValue] = useState<SchoolListItem[]>([]);
  const [showRegisterStudentModal, setShowRegisterStudentModal] = useState<boolean>(false);
  const [selectedStudentToRegister, setSelectedStudentToRegister] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const data = useAppSelector((s) => s.registrations.data);
  const loading = useAppSelector((s) => s.registrations.dataLoading);
  const error = useAppSelector((s) => s.registrations.dataError);

  const loadRegistrations = async (filterKey: RegistrationTabKeysType, filterValues: RegistrationsType, firstLoad = false) => {
    await dispatch(
      fetchRegistrationsData({
        key: filterKey,
        filter: filterValues,
      })
    );
  };

  useEffect(() => {
    const qsParsed = qs.parse(location.search, { ignoreQueryPrefix: true });

    let firstKey: RegistrationTabKeysType = 'validRegistrations';
    if (qsParsed.key) {
      firstKey = `${qsParsed.key || 'validRegistrations'}` as RegistrationTabKeysType;
      setKey(firstKey);
    }

    const firstFilter = {
      str: `${qsParsed.str || ''}`,
      years: `${qsParsed.years || ''}`,
      schools: `${qsParsed.schools || ''}`,
      allergies: `${qsParsed.allergies || ''}`,
      intolerance: `${qsParsed.intolerance || ''}`,
      extra_products: `${qsParsed.extra_products || ''}`,
    };

    setSearchValue(firstFilter.str);

    if (firstFilter.years) {
      setSelectBirthYearValue(
        firstFilter.years
          .split(',')
          .map((a) => a.trim())
          .map((y) => ({
            get_birth_year: y,
          }))
      );
    }

    if (firstFilter.schools) {
      setSelectSchoolValue(
        firstFilter.schools
          .split(',')
          .map((a) => a.trim())
          .map((y) => ({
            id: y,
            name: y,
          }))
      );
    }

    setCurrentPage(Number(`${qsParsed.page || '1'}`));

    setFilter(firstFilter);

    loadRegistrations(firstKey, firstFilter, true);
    setInitializing(false);
  }, []);

  useEffect(() => {
    const qsString = qs.stringify(
      {
        key,
        page: currentPage > 1 ? currentPage : null,
        ...filter,
      },
      { skipNulls: true, filter: (_, v) => v || undefined }
    );

    history.replace(`/skraningar?${qsString}`);
  }, [key, filter, history, currentPage]);

  const onCloseRegisterStudentModal = (added: boolean) => {
    setShowRegisterStudentModal(false);

    if (added) {
      loadRegistrations(key, filter);
    }
  };

  if (initializing) {
    return null;
  }

  return (
    <>
      {hasReadPermission(Permission.SKRANINGAR) && (
        <>
          {showRegisterStudentModal && <RegisterStudentModal student={selectedStudentToRegister} onClose={onCloseRegisterStudentModal} />}
          {!error && (
            <RegistrationsFilters
              selectedKey={key}
              disabled={loading === 'pending'}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              filter={filter}
              setFilter={setFilter}
              loadRegistrations={loadRegistrations}
              selectBirthYearValue={selectBirthYearValue}
              setSelectBirthYearValue={setSelectBirthYearValue}
              selectSchoolValue={selectSchoolValue}
              setSelectSchoolValue={setSelectSchoolValue}
            />
          )}
          {!error && (
            <Tabs
              activeKey={key || undefined}
              id="uncontrolled-tab-example"
              mountOnEnter
              unmountOnExit
              onSelect={(k: string | null) => {
                if (k) {
                  setCurrentPage(1);
                  setKey(k as RegistrationTabKeysType);
                  loadRegistrations(k as RegistrationTabKeysType, filter);
                }
              }}
            >
              <Tab eventKey="validRegistrations" title={t('validRegistrations')}>
                <ValidRegistrationsTab
                  loadingData={loading === 'pending'}
                  initialPage={currentPage}
                  searchValue={searchValue}
                  data={data && data.key === 'validRegistrations' ? data.data : undefined}
                  onChangePage={setCurrentPage}
                />
              </Tab>
              <Tab eventKey="invalidRegistrations" title={t('invalidRegistrations')}>
                <InvalidRegistrationsTab
                  loadingData={loading === 'pending'}
                  data={data && data.key === 'invalidRegistrations' ? data.data : undefined}
                  initialPage={currentPage}
                  searchValue={searchValue}
                  onChangePage={setCurrentPage}
                />
              </Tab>
              <Tab eventKey="notRegistered" title={t('notRegistered')}>
                <NotRegisteredTab
                  loadingData={loading === 'pending'}
                  data={data && data.key === 'notRegistered' ? data.data : undefined}
                  initialPage={currentPage}
                  searchValue={searchValue}
                  setShowRegisterStudentModal={setShowRegisterStudentModal}
                  setSelectedStudentToRegister={setSelectedStudentToRegister}
                  onChangePage={setCurrentPage}
                />
              </Tab>
              <Tab eventKey="recentlyRegistered" title={t('recentlyRegistered')}>
                <RecentlyRegisteredTab
                  loadingData={loading === 'pending'}
                  data={data && data.key === 'recentlyRegistered' ? data.data : undefined}
                  initialPage={currentPage}
                  searchValue={searchValue}
                  onChangePage={setCurrentPage}
                />
              </Tab>
              <Tab eventKey="recentlyUnRegistered" title={t('recentlyUnRegistered')}>
                <RecentlyUnregisteredTab
                  loadingData={loading === 'pending'}
                  data={data && data.key === 'recentlyUnRegistered' ? data.data : undefined}
                  initialPage={currentPage}
                  searchValue={searchValue}
                  onChangePage={setCurrentPage}
                />
              </Tab>
            </Tabs>
          )}
        </>
      )}
    </>
  );
};
export default Registrations;
