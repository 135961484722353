import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchMe } from './store/auth/authSlice';

import Page from './components/Page';
import Registrations from './views/registrations/Registrations';
import Tariff from './views/Tariff';
import Users from './views/Users';
import Schools from './views/Schools';
import Template from './views/Template';
import DiscountGroups from './views/DiscountGroups';
import Discounts from './views/Discounts';
import BillingPeriod from './views/BillingPeriod';
import Invoicing from './views/Invoicing';
import Statistics from './views/Statistics';
import Access from './views/Access';
import EditJobType from './views/EditJobType';
import EditSystemModule from './views/EditSystemModule';
import ViewStudent from './views/ViewStudent';
import CreateUser from './views/CreateUser';
import EditUser from './views/EditUser';
import SchoolPeriods from './views/SchoolPeriods';
import Home from './views/Home';
import BulkDiscounts from './views/BulkDiscounts';
import UnlistedStudents from './views/UnlistedStudents';
import BillingOverview from './views/BillingOverview';
import Lists from './views/Lists';
import { AppDispatch } from './store/store';
import CreateNewStudentFromNamfus from './views/CreateNewStudentFromNamfus';

interface Props {
  url: string;
}

const PrivateRouter = ({ url }: Props): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchMe());
  }, [dispatch]);

  return (
    <Page>
      <Route path={`${url}/`} component={Home} />
      <Route path={`${url}skraningar`} component={Registrations} />
      <Route path={`${url}adgangur`} component={Access} />
      <Route path={`${url}notendur`} component={Users} />
      <Route path={`${url}snidmat`} component={Template} />
      <Route path={`${url}skolar`} component={Schools} />
      <Route path={`${url}timabil`} component={SchoolPeriods} />
      <Route path={`${url}tegund-afslattar`} component={DiscountGroups} />
      <Route path={`${url}gjaldskra`} component={Tariff} />
      <Route path={`${url}magnskra-afslatt`} component={Discounts} />
      <Route path={`${url}magnskra`} component={BulkDiscounts} />
      <Route path={`${url}reikningatimabil`} component={BillingPeriod} />
      <Route path={`${url}reikningagerd`} component={Invoicing} />
      <Route path={`${url}tolfraedi`} component={Statistics} />
      <Route path={`${url}listar`} component={Lists} />
      <Route path={`${url}editJobType/:id`} component={EditJobType} />
      <Route path={`${url}editSystemModule/:id`} component={EditSystemModule} />
      <Route path={`${url}student/:id`} component={ViewStudent} />
      <Route path={`${url}create-user`} component={CreateUser} />
      <Route path={`${url}edit-user/:id`} component={EditUser} />
      <Route path={`${url}oskradir`} component={UnlistedStudents} />
      <Route path={`${url}manadar-yfirlit`} component={BillingOverview} />
      <Route path={`${url}namfus-born`} component={CreateNewStudentFromNamfus} />
    </Page>
  );
};

export default PrivateRouter;
