import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface Props {
  color: string;
}

const CardHeader: React.FC<Props> = ({ color, children }: PropsWithChildren<Props>): React.ReactElement => {
  return <div className={classNames(`cardHeader ${color}`)}>{children}</div>;
};

export default CardHeader;
