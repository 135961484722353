import React, { useCallback, useState, useRef, useEffect } from 'react';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import { getAllErpLines } from '../../api/billing';
import { excelBillingHeader } from '../../utils/headersUtils';
import { useAppSelector } from '../../hooks/useState';
import { billingPeriodsChosenSelector } from '../../store/invoicing/invoicingSlice';
import { ExcelDataType } from '../../api/billing.types';

const InvoicingExcelRow: React.FC = () => {
  const { t } = useTranslation(['invoiceView']);
  const chosenPeriod = useAppSelector(billingPeriodsChosenSelector);
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState<ExcelDataType[]>([]);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const loadExcelDataForAll = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getAllErpLines(chosenPeriod?.billing_month, chosenPeriod?.billing_year);
      setExcelData(
        response.data.items.map((i) => ({
          ...i,
          bokunar_byrjun: dayjs(i.bokunar_byrjun).format('DD.MM.YYYY'),
          bokunar_endir: dayjs(i.bokunar_endir).format('DD.MM.YYYY'),
          dags_stofnad: dayjs(i.bokunar_endir).format('DD.MM.YYYY'),
          farid_dags: dayjs(i.bokunar_endir).format('DD.MM.YYYY'),
          timabil_byrjar: dayjs(i.bokunar_endir).format('DD.MM.YYYY'),
          timabil_endar: dayjs(i.bokunar_endir).format('DD.MM.YYYY'),
          kennitala_nemanda: `${i.kennitala_nemanda}${String.fromCharCode(8203)}`,
          kennitala_greidanda: `${i.kennitala_greidanda}${String.fromCharCode(8203)}`,
        }))
      );
    } catch (e) {
      if (e.response) {
        toast.error(e.response.data.error);
      } else {
        toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
      }
    } finally {
      setLoading(false);
      if (csvLinkRef.current) {
        csvLinkRef.current.link.click();
      }
    }
  }, [t, chosenPeriod]);

  return (
    <div className="p-1" style={{ marginTop: '10px', backgroundColor: '#fff', textAlign: 'right' }}>
      <Button
        variant="link"
        className="icon greenIcon"
        value="Export to CSV (Async)"
        onClick={(e) => {
          e.stopPropagation();
          loadExcelDataForAll();
        }}
      >
        <FontAwesomeIcon icon={loading ? faSpinner : faFileExcel} fixedWidth spin={loading} />
      </Button>
      <CSVLink enclosingCharacter="" separator=";" headers={excelBillingHeader} filename={`Bokhalds-skra-${dayjs().format('DD.MM.YYYY')}.csv`} data={excelData} ref={csvLinkRef} />
    </div>
  );
};

export default InvoicingExcelRow;
