import React, { useMemo } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import * as Yup from 'yup';
import { restValidationToForm } from '../utils/formUtils';
import { editSiblingDiscounts } from '../api/tariffs';

interface Props {
  onClose: (created: boolean) => void;
  originalValues: string[] | undefined;
  id: number | undefined;
}

interface Values {
  items: string[];
}

const EditSiblingDiscountModal = ({ onClose, originalValues, id }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['tariff', 'common', 'errors']);
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      items: Yup.array().of(Yup.number().required(t('errors:emptyInputField')).min(0, t('errors:discountRangeError')).max(100, t('errors:discountRangeError'))),
    });
  }, [t, i18n.language]);
  const initialValues = {
    items:
      originalValues?.map((item) => {
        return item;
      }) || [],
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await editSiblingDiscounts(id, values.items.toString());
            toast.success(t('siblingDiscountSuccess'));
            onClose(true);
          } catch (e) {
            toast.error(`${t('common:informationChangedError')}. ${e.response.data.error}.`);
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue }) => (
          <Modal show size="lg" onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('editDiscountAccKeysTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <table className="table borderTopNoneTable">
                  <thead>
                    <tr>
                      <th>{t('siblingNumber')}</th>
                      <th>{t('discount_name')}</th>
                      <th> &nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.items &&
                      values?.items?.map((item, idx: number) => {
                        return (
                          <tr key={idx}>
                            <th style={{ borderTop: 'none' }}>{values?.items && values.items.length - 1 === idx ? `${idx + 1}+` : idx + 1}</th>
                            <td style={{ borderTop: 'none' }}>
                              <Form.Group>
                                <Form.Control type="number" value={item} onChange={(e) => setFieldValue(`items[${idx}]`, e.currentTarget.value)} />
                                <ErrorMessage name={`items[${idx}]`} component="div" className="invalid-feedback d-block text-left" />
                              </Form.Group>
                            </td>
                            <td style={{ borderTop: 'none' }}>
                              {idx > 0 && (
                                <FontAwesomeIcon
                                  onClick={() => {
                                    const array = [...values.items];
                                    array.splice(idx, 1);
                                    setFieldValue('items', array);
                                  }}
                                  v-if="icon"
                                  className="icon"
                                  fixedWidth
                                  icon={faTrashAlt}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Form>
              <button
                className="btn btn-link"
                type="button"
                style={{
                  margin: '0 auto',
                  display: 'block',
                }}
                onClick={() => {
                  setFieldValue('items', [...values.items, '0']);
                }}
              >
                <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faPlus} />
                Bæta við
              </button>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:save')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default EditSiblingDiscountModal;
