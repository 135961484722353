import { AxiosResponse } from 'axios';
import axios from './api';

import { CheckLoginResponse, GetMeType, GetMeResponse, PingResponse, GetMePermissionType, GetNewTownToken } from './account.types';
import { GetUserTownListResponse } from './lists.types';

export type { GetMeType, GetMePermissionType };

/*
 * Sækja innskráðan notanda
 */
export const getMe = async (): Promise<GetMeType> => {
  const response = await axios.get<GetMeResponse>('/vala/auth/logged-in-user');

  if (response.data.items.length === 0) {
    throw new Error('NotFound');
  }

  return response.data.items[0];
};

/*
 * Ping sem extendar token
 */
export const ping = async (): Promise<PingResponse> => {
  const response = await axios.get<PingResponse>('/vala/auth/ping', { skipRefresh: true });

  if (!response.data.timeleft) {
    throw new Error('NotFound');
  }

  return response.data;
};

export const checkLogin = (token: string, company: number | null): Promise<AxiosResponse<CheckLoginResponse>> => {
  return axios.post<CheckLoginResponse>(
    '/vala/auth/authenticate-token',
    {
      token,
      company,
    },
    {
      requireAuth: false,
    }
  );
};

/*
 * Skráir notanda úr einu sveitarfélagi, skráir inn í annað og sendir token til baka
 */
export const switchTowns = (customer_id: number): Promise<AxiosResponse<GetNewTownToken>> => {
  return axios.post(`/vala/auth/switch-towns`, {
    customer_id,
  });
};

/*
 * Sækja sveitarfélög sem notandi hefur aðganga að
 */
export const getUserTowns = (): Promise<AxiosResponse<GetUserTownListResponse>> => {
  return axios.get('/vala/admin/towns');
};
