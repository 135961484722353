import React from 'react';

import { Button, Form, Modal } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { restValidationToForm } from '../utils/formUtils';
import { deleteExtraProductRegistration } from '../api/registrations';

interface Values {
  registration_extra_id: number;
}

interface Props {
  onClose: (created: boolean) => void;
  originalValues: any;
}
const DeleteExtraProducts = ({ originalValues, onClose }: Props): React.ReactElement => {
  const { t } = useTranslation(['deleteExtraProducts', 'registrations']);

  const initialValues: Values = {
    registration_extra_id: originalValues.registration_extra_id,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<Values>) => {
          try {
            await deleteExtraProductRegistration(values);
            toast.success(t('extraProductDeleted'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{t('deleteExtraProductsTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Form.Group>
                  <table className="table">
                    <tr>
                      <td>
                        <Form.Label>{t('product')}</Form.Label>
                        <Form.Text style={{ fontSize: '19px' }}>{originalValues.product_name}</Form.Text>
                      </td>
                      <td>
                        <Form.Label>{t('registrations:startOfRegistration')}</Form.Label>
                        <Form.Text style={{ fontSize: '19px' }}>{dayjs(originalValues.start_date).format('DD.MM.YYYY')}</Form.Text>
                      </td>
                      <td>
                        <Form.Label>{t('registrations:endOfRegistration')}</Form.Label>
                        <Form.Text style={{ fontSize: '19px' }}>{originalValues.end_date ? dayjs(originalValues.end_date).format('DD.MM.YYYY') : ''}</Form.Text>
                      </td>
                    </tr>
                  </table>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:delete')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default DeleteExtraProducts;
