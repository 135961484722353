import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import { restValidationToForm } from '../utils/formUtils';
import { BatchDiscountType } from '../api/bulkDiscounts.types';
import { setBatchDiscount } from '../api/bulkDiscounts';

interface Props {
  data: BatchDiscountType;
  onClose: (confirmed: boolean, discount_group_id: number | undefined, group_id: number | undefined) => void;
}

interface Values {
  start_date: string;
  end_date: string;
  discount_type: string;
  students: string;
}

const ConfirmBulkDiscountModal = ({ data, onClose }: Props): React.ReactElement => {
  const { t } = useTranslation(['discounts', 'common']);
  const stringifiedStudents = JSON.stringify(data?.students?.map((k) => ({ student_id: k.student_id, registration_id: k.registration_id })));
  const initialValues = {
    start_date: data.start_date,
    end_date: data.end_date,
    discount_type: data.discount_type,
    students: stringifiedStudents,
  };
  const [loadingBulkSave, setLoadingBulkSave] = useState(false);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<Values>) => {
          try {
            setLoadingBulkSave(true);
            const response = await setBatchDiscount(values);
            if (response.data.successful) {
              onClose(true, response.data.discount_group_id, response.data.group_id);
            } else {
              onClose(false, undefined, undefined);
            }
            onClose(true, undefined, undefined);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
            toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
            setLoadingBulkSave(false);
          } finally {
            setSubmitting(false);
            setLoadingBulkSave(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values }) => (
          <Modal show onHide={() => onClose(false, undefined, undefined)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('bulkRegisterDiscountModalTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <div> {t('confirmBulkDiscountWithDates', { start_date: values.start_date, end_date: values.end_date })}</div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                disabled={loadingBulkSave}
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {loadingBulkSave && (
                  <div>
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                    {t('common:loading...')}
                  </div>
                )}
                {!loadingBulkSave && <div>{t('common:confirm')}</div>}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false, undefined, undefined)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default ConfirmBulkDiscountModal;
