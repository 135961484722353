import React, { useCallback, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { BatchType } from '../../api/invoicing.types';
import { addCorrectionBatch, removeCorrectionBatch } from '../../api/billing';
import { useAppDispatch } from '../../hooks/useState';
import { loadAllBatchesInfo } from '../../store/invoicing/invoicingSlice';

interface Props {
  row: BatchType;
}

const InvoicingCellExtra: React.FC<Props> = ({ row }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['invoiceView']);
  const [loading, setLoading] = useState(false);

  const createCorrectionBatch = useCallback(async () => {
    setLoading(true);
    try {
      await addCorrectionBatch({ school_id: row.school_id, billing_period: row.billing_period });
      toast.success(t('creatingCorrectionBatchesSuccess'));
      dispatch(loadAllBatchesInfo());
    } catch (e) {
      if (e.response) {
        toast.error(e.response.data.error);
      } else {
        toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
      }
    } finally {
      setLoading(false);
    }
  }, [t, dispatch, row.school_id, row.billing_period]);

  const removeAddedCorrectionBatch = useCallback(async () => {
    setLoading(true);
    try {
      await removeCorrectionBatch({
        batch_id: row.batch_id,
      });
      dispatch(loadAllBatchesInfo());
    } catch (e) {
      if (e.response) {
        toast.error(e.response.data.error);
      } else {
        toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
      }
    } finally {
      setLoading(false);
    }
  }, [t, dispatch, row.batch_id]);

  if (loading) {
    return (
      <Button className="rowButton">
        <FontAwesomeIcon spin icon={faSpinner} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
      </Button>
    );
  }

  if (row.status === 5 && row.batch_type !== 'Extra') {
    return (
      <Button onClick={createCorrectionBatch} className="rowButton">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="button-tooltip">
              <div>{t('createExtraBatch')}</div>
            </Tooltip>
          }
        >
          <FontAwesomeIcon icon={faPlus} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
        </OverlayTrigger>
      </Button>
    );
  }

  if (row.status === 3 && row.batch_type === 'Extra') {
    return (
      <Button
        onClick={(event) => {
          event.stopPropagation();
          removeAddedCorrectionBatch();
        }}
        className="rowButton"
      >
        <FontAwesomeIcon icon={faTrashAlt} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
      </Button>
    );
  }

  return null;
};

export default InvoicingCellExtra;
