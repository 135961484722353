import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import { restValidationToForm } from '../utils/formUtils';
import { BatchType } from '../api/invoicing.types';
import { openBatch } from '../api/billing';

interface Props {
  batch: BatchType | undefined;
  onClose: (created: boolean) => void;
}

interface Values {
  batch_id: string | undefined;
}

const OpenBatchModal = ({ batch, onClose }: Props): React.ReactElement => {
  const { t } = useTranslation(['invoiceView', 'common']);
  const initialValues = {
    batch_id: batch?.batch_id,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<Values>) => {
          try {
            await openBatch(values.batch_id);
            toast.success(t('openRecordsSuccess'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
            toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('openBatch')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <div>{t('makeSureOpenBatch')}</div>
                <Table>
                  <tbody>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('school_name')}</Form.Label>
                      </td>
                      <td>
                        <Form.Text style={{ fontSize: '19px' }}>{batch?.school_name}</Form.Text>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('period')}</Form.Label>
                      </td>
                      <td>
                        <Form.Text style={{ fontSize: '19px' }}>
                          <span style={{ textTransform: 'capitalize' }}>
                            {batch?.billing_year} - {dayjs(batch?.billing_month).format('MMMM')}
                          </span>
                        </Form.Text>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('num_records')}</Form.Label>
                      </td>
                      <td>
                        <Form.Text style={{ fontSize: '19px' }}>{batch?.num_records}</Form.Text>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('openBatch')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default OpenBatchModal;
