import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { restValidationToForm } from '../utils/formUtils';
import { recalculateStudent } from '../api/billing';

interface Props {
  onClose: (created: boolean) => void;
  student_id: number;
  month: string;
  year: string;
  batch_id: string;
  student_name: string;
}

interface Values {
  batch_id: string;
  student_id: number;
  month: string;
  year: string;
  months_count: number;
}

const ReCalculateChildModal = ({ onClose, student_id, month, year, batch_id, student_name }: Props): React.ReactElement => {
  const { t } = useTranslation(['invoiceView', 'errors']);

  const initialValues: Values = {
    batch_id,
    student_id,
    month,
    year,
    months_count: 2,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await recalculateStudent(values);
            toast.success(t('recalcSuccess'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
            toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: '1.2rem' }}>{t('recalculateChildName', { student_name })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Table>
                  <tbody>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <FontAwesomeIcon className="nav-link-icon" icon={faInfoCircle} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
                        <Form.Label>{t('numberOfMonths')}</Form.Label>
                        <span style={{ color: 'red' }}>*</span>
                      </td>
                      <td>
                        <Form.Control
                          as="select"
                          value={values.months_count}
                          onChange={(event) => {
                            setFieldValue('months_count', event.target.value);
                          }}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {[1, 2].map((value: number, index: number) => {
                            return (
                              <option key={value} value={index + 1}>
                                {value}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('recalculate')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default ReCalculateChildModal;
