import paginationFactory from 'react-bootstrap-table2-paginator';

const customTotal = (from: number, to: number, size: number) => (
  // eslint-disable-next-line react/react-in-jsx-scope
  <span className="react-bootstrap-table-pagination-total">
    Raðir {from} til {to} af {size}
  </span>
);
const pagination = paginationFactory({
  page: 1,
  sizePerPage: 50,
  hideSizePerPage: true,
  showTotal: true,
  paginationTotalRenderer: customTotal,
});
export default pagination;
