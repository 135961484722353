import React from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface Props {
  setFiles: React.Dispatch<React.SetStateAction<any>>;
  files: any[];
}

const FileInput = ({ setFiles, files }: Props): React.ReactElement => {
  const { t } = useTranslation(['editStudentViewModals', 'errors', 'common']);
  /* 10meg max */
  return (
    <Dropzone
      minSize={0}
      maxSize={10485760}
      accept="image/*, .doc, .pdf, .csv, .xlsx, .xls, .docx, .txt"
      onDrop={(acceptedFiles, fileRejections) => {
        const data = new FormData();
        data.append('file', acceptedFiles[0]);
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === 'file-too-large') {
              toast.error(`${t('common:informationError')}. ${t('errors:fileTooLargeError')}`);
            }
            if (err.code === 'file-invalid-type') {
              toast.error(`${t('common:informationError')}. ${t('errors:fileInvalidType')}`);
            }
          });
        });
        acceptedFiles.forEach((file) => {
          setFiles([
            ...files,
            {
              lastModified: file.lastModified,
              name: file.name,
              filename: file.name,
              size: file.size,
              type: file.type,
              data,
            },
          ]);
        });
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <section style={{ textAlign: 'center' }}>
          <div {...getRootProps({ className: 'dropzone' })} className="dropzone">
            <input {...getInputProps()} />
            <p>{t('addFiles')}</p>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default FileInput;
