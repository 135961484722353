import en from 'yup/lib/locale';
import { TFunction } from 'i18next';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

export function setYupLocale(yup: any, lang: string): void {
  if (lang === 'is') {
    /* eslint-disable no-template-curly-in-string */
    yup.setLocale({
      mixed: {
        default: '${path} er ógildur',
        required: '${path} er skyldureitur',
      },
      string: {
        max: '${path} má vera hámark ${max} stafir að lengd',
        url: '${path} verður að vera gild vefslóð',
        length: '${path} verður að vera nákvæmlega ${length} stafir að lengd',
        min: '${path} verður að vera að minnsta kosti ${min} stafir að lengd',
        // matches: '${path} must match the following: "${regex}"',
        email: '${path} verður a vera gilt netfang',
        // uuid: '${path} must be a valid UUID',
        // trim: '${path} must be a trimmed string',
        // lowercase: '${path} must be a lowercase string',
        // uppercase: '${path} must be a upper case string',
      },
    });
    /* eslint-enable */
  } else {
    yup.setLocale(en);
  }
}

export const restValidationToForm = ({
  t,
  errorResponse,
  setFieldError,
  errorFieldMap,
}: {
  t: TFunction;
  errorResponse: AxiosResponse;
  setFieldError: (field: string, message: string) => void;
  errorFieldMap?: Record<string, string>;
}): void => {
  if (!errorResponse || !errorResponse.data || errorResponse.status === 500) {
    toast.error(t('serverError'));
  } else if (setFieldError) {
    const { data } = errorResponse;

    Object.entries(data)
      // .filter(([k, v]) => k !== 'response')
      .forEach(([k, v]) => {
        if (errorFieldMap && k in errorFieldMap) {
          if (Array.isArray(v)) {
            setFieldError(errorFieldMap[k], v[0]);
          } else {
            setFieldError(errorFieldMap[k], `${v}`);
          }
        } else if (Array.isArray(v)) {
          setFieldError(k, v[0]);
        } else {
          setFieldError(k, `${v}`);
        }
      });
  }
};

export const sexOptions = [
  { label: 'KK', value: 3 },
  { label: 'KVK', value: 4 },
  { label: 'Kynsegin/annað', value: 8 },
];
