import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { restValidationToForm } from '../utils/formUtils';
import { RegisteredStudent } from '../api/registrations.types';
import { updateRegistrationDates } from '../api/registrations';
import DatePicker from '../components/DatePicker';
import { getSchoolFoodTypes } from '../api/schools';

interface Values {
  registration_id: number;
  start_date: Date | undefined;
  end_date?: Date | undefined;
  food_types: number | string | undefined;
}

interface Props {
  onClose: (created: boolean) => void;
  originalValues: RegisteredStudent;
}
const EditRegistrationDates = ({ originalValues, onClose }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['editStudentViewModals', 'registrations', 'common']);
  const [schoolFoodTypes, setSchoolFoodTypes] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const original_food_type_school_id = originalValues.food_type_school_id;

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      start_date: Yup.string().required(t('errors:errorMessage')).nullable(),
    });
  }, [t, i18n.language]);

  const loadSchoolFoodTypes = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getSchoolFoodTypes(originalValues.school_id);
      setSchoolFoodTypes(response.data.items);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [originalValues.school_id]);

  useEffect(() => {
    loadSchoolFoodTypes();
  }, [loadSchoolFoodTypes]);

  const initialValues: Values = {
    registration_id: originalValues.registration_id,
    start_date: dayjs(originalValues.registration_start_date).toDate(),
    end_date: originalValues.registration_end_date === null ? undefined : dayjs(originalValues.registration_end_date).toDate(),
    food_types: original_food_type_school_id !== null ? original_food_type_school_id : undefined,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<Values>) => {
          try {
            await updateRegistrationDates({
              ...values,
              start_date: dayjs(values.start_date).format('DD.MM.YYYY'),
              end_date: values.end_date === undefined ? undefined : dayjs(values.end_date).format('DD.MM.YYYY'),
              food_types: values.food_types === 'undefined' ? null : values.food_types,
            });
            toast.success(t('common:informationChangedSuccess'));
            onClose(true);
          } catch (e) {
            toast.error(`${t('common:informationChangedError')}. ${e.response.data.error}`);
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{t('editRegistrationDatesTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <table className="table">
                  <tbody>
                    <tr>
                      <td style={{ borderTop: 'none' }}>
                        <Form.Group controlId="startDay">
                          <Form.Label>
                            {t('registrations:startOfRegistration')}
                            <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <div className="dateWrapper">
                            <DatePicker
                              style={{ display: 'inline', width: '100%' }}
                              value={values.start_date}
                              month={values.start_date}
                              selectedDay={values.start_date}
                              onDayChange={(day: Date) => {
                                setFieldValue('start_date', day === undefined ? null : day);
                              }}
                            />
                          </div>
                          <ErrorMessage name="start_date" component="div" className="invalid-feedback d-block text-left" />
                        </Form.Group>
                      </td>
                      <td style={{ borderTop: 'none' }}>
                        <Form.Group controlId="endDate">
                          <Form.Label>
                            {t('registrations:endOfRegistration')}
                            <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <div className="dateWrapper">
                            <DatePicker
                              style={{ display: 'inline', width: '100%' }}
                              value={values.end_date}
                              month={values.end_date}
                              selectedDay={values.end_date}
                              onDayChange={(day: Date) => {
                                setFieldValue('end_date', day);
                              }}
                            />
                          </div>
                        </Form.Group>
                      </td>
                    </tr>
                    {schoolFoodTypes && schoolFoodTypes?.length > 0 && (
                      <tr>
                        <td>
                          <Form.Group>
                            <Form.Label>
                              {t('specialFoodType')}
                              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{t('specialFoodTypeChangeInfo')}</Tooltip>}>
                                <FontAwesomeIcon className="nav-link-icon" icon={faInfoCircle} fixedWidth />
                              </OverlayTrigger>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              value={values.food_types}
                              onChange={(event) => {
                                setFieldValue('food_types', event.target.value);
                              }}
                            >
                              <option key="undefined" value="undefined">
                                {t('registrations:noSpecialFoodTypeSelected')}
                              </option>
                              {schoolFoodTypes.map((item: any) => {
                                return (
                                  <option key={item.food_type_school_id} value={item.food_type_school_id}>
                                    {item.food_type}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Form.Group>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:submit')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default EditRegistrationDates;
