import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './auth/authSlice';
import getAuthorizationList from './Authorization/getAuthorizationListSlice';
import getStudentSlice from './Student/getStudentSlice';
import getMonthlyBillOverview from './getMonthlyBillOverview';
import getListsSlice from './Lists/getListsSlice';
import invoicingReducer from './invoicing/invoicingSlice';
import registrationsReducer from '../views/registrations/registrationsSlice';
import getPublishingManagementSlice from './PublishingManagement/getPublishingManagementSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  authorizationList: getAuthorizationList,
  studentList: getStudentSlice,
  monthlyBillOverview: getMonthlyBillOverview,
  lists: getListsSlice,
  invoicing: invoicingReducer,
  registrations: registrationsReducer,
  publishingManagement: getPublishingManagementSlice,
});
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
