import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import dayjs from 'dayjs';
import { restValidationToForm } from '../utils/formUtils';
import { TariffPeriodType } from '../api/tariffs.types';
import { deleteTariff } from '../api/tariffs';

interface Props {
  originalValues: TariffPeriodType | undefined;
  onClose: (created: boolean) => void;
}

interface Values {
  id: number | undefined;
}

const DeleteTariffPeriodModal = ({ originalValues, onClose }: Props): React.ReactElement => {
  const { t } = useTranslation(['tariff', 'common']);
  const initialValues = {
    id: originalValues ? originalValues.tariff_period_id : undefined,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await deleteTariff(values.id);
            toast.success(t('tariffDeleted'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
            toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('deleteModalTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Form.Group>
                  <Form.Label>
                    {t('deletingInfoInModal')}
                    <br />
                    {`${dayjs(originalValues?.valid_from).format('DD.MM.YYYY')} - ${dayjs(originalValues?.valid_to).format('DD.MM.YYYY')}`}
                  </Form.Label>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:delete')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default DeleteTariffPeriodModal;
