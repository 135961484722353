import { AxiosResponse } from 'axios';
import axios from './api';
import {
  BatchDiscountDataType,
  BatchDiscountLookResponseType,
  BatchDiscountResponse,
  DiscountedStudentTypeResponse,
  DiscountTypesTypeResponse,
  StudentListFilters,
  StudentTypeResponse,
  SuccessfulResponse,
} from './bulkDiscounts.types';

export const getStudentList = (values: StudentListFilters): Promise<AxiosResponse<StudentTypeResponse>> => {
  return axios.get(`/vala/discounts/student-list/${values.school_id}/${values.start_date}/${values.end_date}`, {
    params: { str: values.str, class: values.class, birth_year: values.birth_year },
  });
};

export const getDiscountTypes = (): Promise<AxiosResponse<DiscountTypesTypeResponse>> => {
  return axios.get('/vala/discounts/discount-types');
};

export const getClasses = (school_id: string | undefined): Promise<AxiosResponse<any>> => {
  return axios.get('/vala/discounts/class-batch-discount', {
    params: { school_id },
  });
};

export const getBirthYear = (school_id: string | undefined): Promise<AxiosResponse<any>> => {
  return axios.get('/vala/discounts/birth-year-batch-discount', {
    params: { school_id },
  });
};

export const setBatchDiscount = (data: BatchDiscountDataType | null): Promise<AxiosResponse<BatchDiscountResponse>> => {
  return axios.post(`/vala/discounts/set-batch-discount`, data);
};

export const getBatchDiscountLook = (id: number | undefined, group_id: number | undefined): Promise<AxiosResponse<BatchDiscountLookResponseType>> => {
  return axios.get(`/vala/discounts/batch-discount-look/${id}/${group_id}`);
};

export const deleteBatchNewDiscount = (id: number): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.delete(`/vala/discounts/batch-new-discount/${id}`);
};

export const deleteOriginalDiscount = (id: number): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.delete(`/vala/discounts/delete-discount/${id}`);
};

export const confirmBatchDiscount = (id: number | undefined): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.post(`/vala/discounts/batch-discount-confirm/${id}`);
};

export const deleteTempDiscountBatch = (id: number | undefined): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.delete(`/vala/discounts/batch-discount-confirm/${id}`);
};

export const getDiscountList = (body: StudentListFilters): Promise<AxiosResponse<DiscountedStudentTypeResponse>> => {
  return axios.get('/vala/discounts/discount-list', {
    params: body,
  });
};
