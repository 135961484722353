import React, { useMemo, useState } from 'react';

import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { restValidationToForm } from '../utils/formUtils';
import { RegisteredStudent } from '../api/registrations.types';
import { checkPayerSSN, updatePayer } from '../api/registrations';

interface Values {
  payer_email: string;
  payer_ssn: string;
  registration_id: number;
}

interface Props {
  onClose: (created: boolean) => void;
  originalValues: RegisteredStudent;
}
const EditStudentInfo = ({ originalValues, onClose }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['errors', 'registrations', 'editStudentViewModals']);
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      payer_ssn: Yup.string().required(t('errors:errorMessage')).min(10, t('errors:ssnInvalid')).max(10, t('errors:ssnInvalid')),
      payer_email: Yup.string().email(t('errors:invalidEmail')).required(t('errors:emptyInputEmail')),
    });
  }, [t, i18n.language]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [payerName, setPayerName] = useState<string | undefined>(originalValues.payer_name);
  const [underAgeMessage, setUnderAgeMessage] = useState<string>();

  const initialValues: Values = {
    payer_email: originalValues.payer_email,
    payer_ssn: originalValues.payer_ssn,
    registration_id: originalValues.registration_id,
  };

  const handleSSN = async (ssn: string) => {
    if (ssn.length === 10) {
      setLoading(true);
      setError(false);
      try {
        const response = await checkPayerSSN(ssn);
        setPayerName(response.data.name);
        if (response.data.undarage === '1') {
          setUnderAgeMessage('greiðandi er undir 18 ára');
        }
      } catch (e) {
        toast.error(t('errors:ssnInvalid'));
        setError(true);
      } finally {
        setLoading(false);
      }
    } else {
      setPayerName('');
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<Values>) => {
          try {
            await updatePayer(values);

            toast.success(t('common:informationChangedSuccess'));
            onClose(true);
          } catch (e) {
            toast.error(`${t('common:informationChangedError')}. ${e.response.data.error}.`);
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{t('editStudentViewModals:editPayerInfoTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <table className="table">
                  <tbody>
                    <tr>
                      <th style={{ borderTop: 'none' }}>{t('registrations:name')}</th>
                      <td style={{ borderTop: 'none' }}>
                        {!loading && <Form.Label>{payerName}</Form.Label>}
                        {loading && (
                          <div className="spinner">
                            <Spinner animation="border" role="status" size="sm">
                              <span className="sr-only">{t('common:loading')}</span>
                            </Spinner>
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>{t('registrations:ssn')}</th>
                      <td>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            {...getFieldProps('payer_ssn')}
                            onChange={(e) => {
                              setFieldValue('payer_ssn', e.currentTarget.value);
                              handleSSN(e.currentTarget.value);
                            }}
                          />
                          <span>{underAgeMessage}</span>
                          <ErrorMessage name="payer_ssn" component="div" className="invalid-feedback d-block text-left" />
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <th>{t('registrations:email')}</th>
                      <td>
                        <Form.Group>
                          <Form.Control type="text" {...getFieldProps('payer_email')} />
                          <ErrorMessage name="payer_email" component="div" className="invalid-feedback d-block text-left" />
                        </Form.Group>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:submit')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default EditStudentInfo;
