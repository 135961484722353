import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import { confirmBatchDiscount, deleteTempDiscountBatch } from '../api/bulkDiscounts';

interface Props {
  ids_number: number;
  discount_group_id: number | undefined;
  onClose: (confirmed: boolean) => void;
  action: string;
}

interface Values {
  discount_group_id: number | undefined;
}

const ShowConfirmOrCancelBatchAttendanceModal = ({ ids_number, discount_group_id, action, onClose }: Props): React.ReactElement => {
  const { t } = useTranslation(['discounts', 'common']);
  const initialValues = {
    discount_group_id,
  };
  const [loadingBulkSave, setLoadingBulkSave] = useState(false);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
          try {
            setLoadingBulkSave(true);
            if (action === 'confirm') {
              await confirmBatchDiscount(discount_group_id);
              toast.success(t('confirmBulkDiscountSuccess'));
            } else if (action === 'cancel') {
              await deleteTempDiscountBatch(discount_group_id);
              toast.success(t('deleteBulkDiscountSuccess'));
            }
            onClose(true);
          } catch (e) {
            toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
          } finally {
            setSubmitting(false);
            setLoadingBulkSave(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{action === 'confirm' ? t('confirmBatchAttendanceModalTitle') : t('cancelBatchAttendanceModalTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                {action === 'confirm' && <div> {t('areYouSureToConfirm', { ids: ids_number })}</div>}
                {action === 'cancel' && <div> {t('areYouSureToCancel', { ids: ids_number })}</div>}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant={action === 'confirm' ? 'primary' : 'danger'}
                type="submit"
                disabled={loadingBulkSave}
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {loadingBulkSave && (
                  <div>
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                    {t('common:loading...')}
                  </div>
                )}
                {action === 'confirm' && !loadingBulkSave && t('common:confirm')}
                {action === 'delete' && !loadingBulkSave && t('common:delete')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default ShowConfirmOrCancelBatchAttendanceModal;
