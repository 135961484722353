import { AxiosResponse } from 'axios';
import axios from './api';
import {
  BillingFilterType,
  BillingPeriodTypesResponse,
  CreateNewBillingPeriod,
  CreateNewBillingPeriodResponse,
  CreateDiscountCategoryType,
  SuccessfulResponse,
  DiscountCategoryTypeResponse,
  DiscountTypesList,
  UpdateDiscountCategoryType,
  BatchResponse,
  StudentBatchSummaryLoad,
  AddDiscountType,
  RecalculateStudent,
  StudentSummaryResponse,
  DeleteManualRec,
  CorrectionBatch,
  RemoveCorrectionBatch,
  ExcelDataResponseType,
  DeleteStudentExtraLine,
  BatchSummary,
  AddManualDiscountMultiData,
  ValidMonthsTypesResponse,
  StudentIdResponseType,
  RecalculateMonthData,
} from './billing.types';

import { Batch, BatchType, BatchTypeResponse } from './invoicing.types';

export const getBillingPeriods = (data: BillingFilterType | null): Promise<AxiosResponse<BillingPeriodTypesResponse>> => {
  return axios.get('/vala/billing/periods', {
    params: data,
  });
};

export const getValidMonths = (): Promise<AxiosResponse<ValidMonthsTypesResponse>> => {
  return axios.get('/vala/billing/valid-months');
};

export const getStudentIdfromSSN = (ssn: string, schoolid: number): Promise<AxiosResponse<StudentIdResponseType>> => {
  return axios.get('/vala/billing/studentid', {
    params: { ssn, schoolid },
  });
};

export const createNewBillingPeriod = (data: CreateNewBillingPeriod): Promise<AxiosResponse<CreateNewBillingPeriodResponse>> => {
  return axios.post('/vala/billing/periods', data);
};

export const deleteBillingPeriod = (id: number | null): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.delete('/vala/billing/periods', {
    params: { id },
  });
};

export const getBillingYears = (): Promise<AxiosResponse<BillingPeriodTypesResponse>> => {
  return axios.get('/vala/billing/periods-billing-year');
};

export const getDiscountGroups = (): Promise<AxiosResponse<DiscountTypesList>> => {
  return axios.get('/vala/billing/discount-types-list');
};

export const getDiscountCategories = (): Promise<AxiosResponse<DiscountCategoryTypeResponse>> => {
  return axios.get('/vala/billing/discount-category');
};

export const createNewDiscountType = (data: CreateDiscountCategoryType): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.post('/vala/billing/discount-type', data);
};

export const getDiscountType = (discount_type_id: number | undefined): Promise<AxiosResponse<DiscountTypesList>> => {
  return axios.get(`/vala/billing/discount-type/${discount_type_id}`);
};

export const updateDiscountType = (data: UpdateDiscountCategoryType, discount_type_id: number): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.put(`/vala/billing/discount-type/${discount_type_id}`, data);
};

// body is undefined so service can delete
export const deleteDiscountType = (body: undefined, discount_type_id: number): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.delete(`/vala/billing/discount-type/${discount_type_id}`, body);
};

export const getAllBillingBatches = (period_id: number | null | undefined, str: string | undefined): Promise<AxiosResponse<{ items: BatchType[] }>> => {
  return axios.get(`/vala/billing/all-batches/${period_id}`, {
    params: { str },
  });
};

export const getBatchInfo = (batch_id: string | undefined): Promise<AxiosResponse<BatchTypeResponse>> => {
  return axios.get(`/vala/billing/batch-school/${batch_id}`);
};

export const runBatches = (data: Batch): Promise<AxiosResponse<BatchResponse>> => {
  return axios.post('/vala/billing/batch', data);
};

export const getBatchSummary = (batch_id: string | undefined, str: string | undefined): Promise<AxiosResponse<{ items: BatchSummary[]; errors: Record<string, string> }>> => {
  return axios.get(`/vala/billing/batch_summary`, {
    params: { batch_id, str },
  });
};

export const getStudentSummary = (data: StudentBatchSummaryLoad): Promise<AxiosResponse<StudentSummaryResponse>> => {
  return axios.get(`/vala/billing/student_summary`, {
    params: data,
  });
};
export const deleteRecords = (batch_id: string | undefined): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.delete(`/vala/billing/batch`, {
    params: { batch_id },
  });
};

export const confirmBatch = (batch_id: string | undefined): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.post('/vala/billing/confirm-batch', { batch_id });
};

export const openBatch = (batch_id: string | undefined): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.post('/vala/billing/deconfirm-batch', { batch_id });
};

export const addManualDiscount = (data: AddDiscountType): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.post('/vala/billing/add-manual-discount', data);
};

export const recalculateStudent = (data: RecalculateStudent): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.post('/vala/billing/rerun_student', data);
};

export const deleteStudentExtraLine = (data: DeleteStudentExtraLine): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.delete('/vala/billing/remove-student-line-extra', {
    params: data,
  });
};
export const deleteManualRecords = (data: DeleteManualRec): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.post('/vala/billing/remove-manual-discount', data);
};

export const addCorrectionBatch = (data: CorrectionBatch): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.post('/vala/billing/correction_batch', data);
};

export const removeCorrectionBatch = (data: RemoveCorrectionBatch): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.post('/vala/billing/remove-correction-batch', data);
};

export const recallBatchFromExternalSystem = (batch_id: string): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.post('/vala/billing/withdraw-from-external', { batch_id });
};
export const makeBatchReadyForExternal = (batch_id: string): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.post('/vala/billing/ready-for-external', { batch_id });
};

export const getAllErpLines = (month: string | undefined, year: string | undefined): Promise<AxiosResponse<ExcelDataResponseType>> => {
  return axios.get(`/vala/billing/erp-lines-all`, {
    params: { month, year },
  });
};

export const erpLines = (batch_id: string): Promise<AxiosResponse<ExcelDataResponseType>> => {
  return axios.get(`/vala/billing/erp-lines`, {
    params: { batch_id },
  });
};

export const addManualDiscountMulti = (data: AddManualDiscountMultiData): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.post('/vala/billing/add-manual-discount-multi', data);
};

export const reCalculateMonth = (data: RecalculateMonthData): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.post('/vala/billing/recalculate-month', data);
};
