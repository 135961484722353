import { AxiosResponse } from 'axios';
import axios from './api';
import {
  AvailableSchoolFoodTypeResponse,
  CreateNewSchoolType,
  ExtraProductList,
  ExtraProductsToRegisterList,
  FoodTypesResponse,
  NewSchoolPeriod,
  ResetSchoolPinResponse,
  SchoolItem,
  SchoolPeriodsList,
  SchoolResponse,
  SchoolsFilterType,
  SchoolsListResponse,
  SchoolsNotInPeriodResponse,
} from './schools.types';

export const getSchoolsList = (filter: SchoolsFilterType): Promise<AxiosResponse<SchoolsListResponse>> => {
  return axios.get('/vala/schools/schools-list', {
    params: filter,
  });
};

export const getSchool = (school_id: number | null): Promise<AxiosResponse<SchoolsListResponse>> => {
  return axios.get('/vala/schools/school', {
    params: { school_id },
  });
};

export const getAvailableFoodTypes = (): Promise<AxiosResponse<FoodTypesResponse>> => {
  return axios.get('/vala/schools/food-types');
};

export const getSchoolFoodTypes = (school_id: number): Promise<AxiosResponse<AvailableSchoolFoodTypeResponse>> => {
  return axios.get('/vala/registrations/food-types-school', {
    params: { school_id },
  });
};
export const getExtraProductsToRegister = (school_id: number | null): Promise<AxiosResponse<ExtraProductsToRegisterList>> => {
  return axios.get('/vala/schools/extra-products', {
    params: { school_id },
  });
};

export const getExtraProducts = (school_id: number | null): Promise<AxiosResponse<ExtraProductList>> => {
  return axios.get('/vala/schools/extra-products-prices', {
    params: { school_id },
  });
};

export const updateExtraProducts = (data: any): Promise<AxiosResponse<SchoolResponse>> => {
  return axios.post(`/vala/schools/extra-products-prices`, data);
};

export const createNewSchool = (data: CreateNewSchoolType): Promise<AxiosResponse<SchoolResponse>> => {
  return axios.post(`/vala/schools/school`, data);
};

export const updateSchool = (data: SchoolItem): Promise<AxiosResponse<SchoolResponse>> => {
  return axios.put(`/vala/schools/school`, data);
};

export const disableSchool = (data: SchoolItem): Promise<AxiosResponse<SchoolResponse>> => {
  return axios.put(`/vala/schools/school`, data);
};

export const resetSchoolPin = (school_id: number | null | undefined): Promise<AxiosResponse<ResetSchoolPinResponse>> => {
  return axios.post(`/vala/schools/generate-school-pin`, { school_id });
};

export const getAllSchoolPeriods = (): Promise<AxiosResponse<SchoolPeriodsList>> => {
  return axios.get('/vala/school-year-periods/all');
};

export const getSchoolsNotInPeriod = (date_from: string, date_to: string, period_id: number | undefined | null): Promise<AxiosResponse<SchoolsNotInPeriodResponse>> => {
  return axios.get('/vala/school-year-periods/schools-not-in-period', {
    params: { date_from, date_to, period_id },
  });
};

export const createNewSchoolPeriod = (body: NewSchoolPeriod): Promise<AxiosResponse<SchoolResponse>> => {
  return axios.post(`/vala/school-year-periods/all`, body);
};

export const deleteSchoolPeriod = (period_id: number | undefined): Promise<AxiosResponse<SchoolResponse>> => {
  return axios.delete(`/vala/school-year-periods/all/${period_id}`);
};

export const updateSchoolPeriod = (body: NewSchoolPeriod): Promise<AxiosResponse<any>> => {
  return axios.put(`/vala/school-year-periods/all`, body);
};
