import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Field, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import DualListBox from 'react-dual-listbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faAngleDoubleUp, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { restValidationToForm } from '../utils/formUtils';
import DatePicker from '../components/DatePicker';
import { getSchoolsNotInPeriod, updateSchoolPeriod } from '../api/schools';
import { School, SchoolPeriod } from '../api/schools.types';
import SpinnerCustom from '../components/Spinner';

interface Props {
  onClose: (created: boolean) => void;
  originalValues: SchoolPeriod;
}

interface Values {
  name: string;
  description: string;
  is_open: number | undefined;
  start_date: Date | undefined;
  end_date: Date | undefined;
  id: number | undefined;
  schools: number[] | undefined;
  deletable: number | undefined;
}
const EditSchoolPeriodModal = ({ onClose, originalValues }: Props): React.ReactElement => {
  const { t } = useTranslation(['schoolPeriods', 'errors', 'common']);
  const [schoolsNotInPeriodList, setSchoolsNotInPeriodList] = useState<School[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string().required(t('errors:emptyInputField')),
      description: Yup.string().required(t('errors:emptyInputField')),
      is_open: Yup.number().required(t('errors:emptyInputField')),
      start_date: Yup.string().required(t('errors:emptyInputField')),
      end_date: Yup.string().required(t('errors:emptyInputField')),
      schools: Yup.array().min(1, t('errors:chooseSchool')).required(t('errors:emptyInputField')),
    });
  }, [t]);

  const initialValues: Values = {
    name: originalValues.name,
    description: originalValues.description,
    is_open: originalValues.is_open,
    start_date: dayjs(originalValues.start_date).toDate(),
    end_date: dayjs(originalValues.end_date).toDate(),
    id: originalValues.id,
    schools: originalValues.schools?.map((i) => i.id),
    deletable: originalValues.deletable,
  };

  const schoolsSelect = schoolsNotInPeriodList.map((i: School) => ({
    value: i.id,
    label: `${i.name} ${i.deletable === 0 ? 'X' : ''}`,
  }));

  const loadSchoolsNotInPeriod = useCallback(async (start_date: Date | undefined, end_date: Date | undefined, period_id: number | undefined) => {
    setLoading(true);
    setError(false);
    try {
      const startFormatted = dayjs(start_date).format('DD.MM.YYYY');
      const endFormatted = dayjs(end_date).format('DD.MM.YYYY');
      const response = await getSchoolsNotInPeriod(startFormatted, endFormatted, period_id);
      setSchoolsNotInPeriodList(response.data.items);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadSchoolsNotInPeriod(initialValues.start_date, initialValues.end_date, initialValues.id);
  }, []);

  if (error) {
    return <>{t('common:kerfisvilla')}</>;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await updateSchoolPeriod({
              ...values,
              start_date: dayjs(values.start_date).format('DD.MM.YYYY'),
              end_date: dayjs(values.end_date).format('DD.MM.YYYY'),
              schools: values.schools?.toString(),
            });
            toast.success(t('schoolPeriodEdited'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
            toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{t('editSchoolPeriodModalTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        {t('name')}
                        <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={values.name}
                        onChange={(event) => {
                          setFieldValue('name', event.target.value);
                        }}
                      />
                      <ErrorMessage name="name" component="div" className="invalid-feedback d-block text-left" />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        {t('is_open')}
                        <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <div role="group" aria-labelledby="my-radio-group">
                        <label style={{ paddingRight: '1rem' }}>
                          <Field type="radio" name="is_open" value="1" style={{ marginRight: '0.3rem' }} />
                          {t('common:yes')}
                        </label>
                        <label>
                          <Field type="radio" name="is_open" value="0" style={{ marginRight: '0.3rem' }} />
                          {t('common:no')}
                        </label>
                      </div>
                    </Form.Group>
                    <ErrorMessage name="is_open" component="div" className="invalid-feedback d-block text-left" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="startDay">
                      <Form.Label>
                        {t('registrations:startOfRegistration')}
                        <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <div className="dateWrapper">
                        <DatePicker
                          style={{ display: 'inline', width: '100%' }}
                          value={values.start_date}
                          month={values.start_date}
                          selectedDay={values.start_date}
                          disabled={values.deletable === 0}
                          onDayChange={(day: Date) => {
                            setFieldValue('start_date', day === undefined ? null : day);
                            loadSchoolsNotInPeriod(day, values.end_date, values.id);
                          }}
                        />
                      </div>
                      <ErrorMessage name="start_date" component="div" className="invalid-feedback d-block text-left" />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="endDate">
                      <Form.Label>
                        {t('registrations:endOfRegistration')}
                        <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <div className="dateWrapper">
                        <DatePicker
                          style={{ display: 'inline', width: '100%' }}
                          value={values.end_date}
                          month={values.end_date}
                          selectedDay={values.end_date}
                          onDayChange={(day: Date) => {
                            setFieldValue('end_date', day);
                            loadSchoolsNotInPeriod(values.start_date, day, values.id);
                          }}
                        />
                      </div>
                      <ErrorMessage name="start_date" component="div" className="invalid-feedback d-block text-left" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        {t('description')}
                        <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        value={values.description}
                        onChange={(event) => {
                          setFieldValue('description', event.target.value);
                        }}
                      />
                      <ErrorMessage name="description" component="div" className="invalid-feedback d-block text-left" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        {t('schoolsNotInPeriod')}
                        <span style={{ color: 'red' }}>*</span>
                        <Alert variant="warning" className="alert-box">
                          <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '5px' }} />
                          <span>{t('schoolsNotInPeriodNotDeletable')}</span>
                        </Alert>
                      </Form.Label>
                      {loading && <SpinnerCustom />}
                      {schoolsNotInPeriodList && (
                        <div>
                          <DualListBox
                            options={schoolsSelect}
                            selected={values.schools}
                            onChange={(e: string[]) => setFieldValue('schools', e)}
                            icons={{
                              moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
                              moveAllLeft: [<FontAwesomeIcon key={0} icon={faChevronLeft} />, <FontAwesomeIcon key={1} icon={faChevronLeft} />],
                              moveRight: <FontAwesomeIcon icon={faChevronRight} />,
                              moveAllRight: [<FontAwesomeIcon key={0} icon={faChevronRight} />, <FontAwesomeIcon key={1} icon={faChevronRight} />],
                              moveTop: <FontAwesomeIcon icon={faAngleDoubleUp} />,
                              moveBottom: <FontAwesomeIcon icon={faAngleDoubleDown} />,
                              moveDown: <FontAwesomeIcon icon={faChevronDown} />,
                              moveUp: <FontAwesomeIcon icon={faChevronUp} />,
                            }}
                          />
                        </div>
                      )}
                      <ErrorMessage name="schools" component="div" className="invalid-feedback d-block text-left" />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:edit')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default EditSchoolPeriodModal;
