import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// eslint-disable-next-line camelcase
import locale_is from 'dayjs/locale/is';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import api from './api/api';
import registerInterceptors from './api/interceptors';

import 'react-dual-listbox/lib/react-dual-listbox.css';
import './style/bootstrap.scss';
import './style/main.scss';

import i18n from './i18n';

registerInterceptors(api);

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(updateLocale);
dayjs.locale(locale_is);
dayjs().localeData();

toast.configure({
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
        <ToastContainer />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
