export default class TokenStorage {
  private static readonly ACCESS_TOKEN = 'access_token_admin';

  private static readonly ACCESS_TOKEN_EXPIRE = 'access_token_expire_admin';

  private static readonly REFRESH_TOKEN = 'refresh_token';

  private static parseToken(token: string | null): string | null {
    if (token) {
      return JSON.parse(token);
    }

    return token;
  }

  public static getAccessToken(): string | null {
    return TokenStorage.parseToken(localStorage.getItem(TokenStorage.ACCESS_TOKEN));
  }

  public static getRefreshToken(): string | null {
    return TokenStorage.parseToken(localStorage.getItem(TokenStorage.REFRESH_TOKEN));
  }

  public static getAccessTokenExpire(): string | null {
    return TokenStorage.parseToken(localStorage.getItem(TokenStorage.ACCESS_TOKEN_EXPIRE));
  }

  public static unsetToken(): void {
    localStorage.removeItem(TokenStorage.ACCESS_TOKEN);
    localStorage.removeItem(TokenStorage.REFRESH_TOKEN);
    localStorage.removeItem(TokenStorage.ACCESS_TOKEN_EXPIRE);
  }

  public static storeAccessToken(accessToken: string): void {
    localStorage.setItem(TokenStorage.ACCESS_TOKEN, JSON.stringify(accessToken));
  }

  public static storeAccessTokenExpire(timeleft: number): void {
    const d = new Date();
    d.setTime(d.getTime() + timeleft * 1000);
    localStorage.setItem(TokenStorage.ACCESS_TOKEN_EXPIRE, JSON.stringify(d));
  }

  public static isAccessTokenExpired(): boolean {
    const expireDate = TokenStorage.getAccessTokenExpire();
    if (expireDate === null) {
      return false;
    }
    const d = new Date(expireDate);
    return !(d.valueOf() > new Date().valueOf());
  }

  public static willAccessTokenExpireSoon(): boolean {
    const expireDate = TokenStorage.getAccessTokenExpire();
    if (expireDate === null) {
      return true;
    }
    const chkDate = new Date(expireDate);
    const sysDate = new Date();
    chkDate.setSeconds(chkDate.getSeconds() - 1800);

    return sysDate.valueOf() > chkDate.valueOf();
  }

  public static getInitials(str: string): string {
    const names = str.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  }
}
