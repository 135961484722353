import React, { useCallback, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { faEdit, faPlus, faSearch, faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pagination from '../utils/paginationUtils';
import { dateFormatter } from '../utils/tableUtils';
import usePermission, { Permission } from '../hooks/usePermission';
import { getAllSchoolPeriods } from '../api/schools';
import { School, SchoolPeriod } from '../api/schools.types';
import CreateNewSchoolPeriodModal from './CreateNewSchoolPeriodModal';
import DeleteSchoolPeriodModal from './DeleteSchoolPeriodModal';
import EditSchoolPeriodModal from './EditSchoolPeriodModal';

const SchoolPeriods = (): React.ReactElement => {
  const { t } = useTranslation(['schoolPeriods', 'common']);
  const { hasWritePermission, hasReadPermission } = usePermission();
  const [data, setData] = useState<SchoolPeriod[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showDeleteSchoolPeriod, setShowDeleteSchoolPeriod] = useState<boolean>(false);
  const [showEditSchoolPeriod, setShowEditSchoolPeriod] = useState<boolean>(false);
  const [showCreateNewSchoolPeriodModal, setShowCreateNewSchoolPeriodModal] = useState<boolean>(false);
  const [currentSchoolPeriod, setCurrentSchoolPeriod] = useState<SchoolPeriod>({
    created_by: undefined,
    created_date: undefined,
    deletable: undefined,
    description: '',
    end_date: '',
    id: undefined,
    is_open: undefined,
    name: '',
    start_date: undefined,
    schools: undefined,
  });

  const onCreateNewSchoolPeriodModalClosed = (created: boolean) => {
    setShowCreateNewSchoolPeriodModal(false);
    if (created) {
      loadSchoolPeriods();
    }
  };
  const onDeleteSchoolPeriodModalClosed = (deleted: boolean) => {
    setShowDeleteSchoolPeriod(false);
    if (deleted) {
      loadSchoolPeriods();
    }
  };
  const onEditSchoolPeriodModalClosed = (edited: boolean) => {
    setShowEditSchoolPeriod(false);
    if (edited) {
      loadSchoolPeriods();
    }
  };

  const loadSchoolPeriods = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getAllSchoolPeriods();
      setData(response.data.items);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadSchoolPeriods();
  }, [loadSchoolPeriods]);

  if (error) {
    return <>{t('common:kerfisvilla')}</>;
  }

  if (loading) {
    return (
      <>
        <FontAwesomeIcon spin icon={faSpinner} />
      </>
    );
  }

  const schoolPeriodsColumns = [
    {
      dataField: 'name',
      text: t('name'),
      sort: true,
    },
    {
      dataField: 'description',
      text: t('description'),
      sort: true,
    },
    {
      dataField: 'schools',
      text: t('schools'),
      formatter: (cell: School[]) => {
        return (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip className="schoolTooltip" id="button-tooltip">
                <div>
                  {cell.map((item: School, idx: number) => {
                    return (
                      <div key={idx} style={{ textAlign: 'left' }}>
                        {item.name}
                      </div>
                    );
                  })}
                </div>
              </Tooltip>
            }
          >
            <div style={{ maxWidth: 'fit-content' }}>
              {cell.length} &nbsp;
              <FontAwesomeIcon className="" icon={faSearch} fixedWidth />
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'start_date',
      text: t('start_date'),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: 'end_date',
      text: t('end_date'),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: 'is_open',
      text: t('is_open'),
      sort: true,
      formatter: (cell: string) => (cell === '1' ? t('common:yes') : t('common:no')),
      csvFormatter: (cell: string) => (cell === '1' ? t('common:yes') : t('common:no')),
    },
    {
      dataField: 'edit',
      isDummyField: true,
      text: '',
      formatter: (cell: any, row: SchoolPeriod, rowIndex: number, extraData: any) => {
        if (hasWritePermission(Permission.TIMABIL)) {
          return (
            <OverlayTrigger
              placement="top-start"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip className="schoolTooltip" id="button-tooltip">
                  <div>{t('common:edit')}</div>
                </Tooltip>
              }
            >
              <Button
                variant="link"
                onClick={() => {
                  setShowEditSchoolPeriod(true);
                  setCurrentSchoolPeriod(row);
                }}
              >
                <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faEdit} />
              </Button>
            </OverlayTrigger>
          );
        }
        return '';
      },
    },
    {
      dataField: 'deletable',
      isDummyField: true,
      text: '',
      formatter: (cell: any, row: SchoolPeriod, rowIndex: any, extraData: any) => {
        if (row.deletable === 1 && hasWritePermission(Permission.TIMABIL)) {
          return (
            <Button
              variant="link"
              onClick={() => {
                setShowDeleteSchoolPeriod(true);
                setCurrentSchoolPeriod(row);
              }}
            >
              <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faTrashAlt} />
            </Button>
          );
        }
        return '';
      },
    },
  ];
  return (
    <>
      {hasReadPermission(Permission.TIMABIL) && (
        <>
          {data && showCreateNewSchoolPeriodModal && <CreateNewSchoolPeriodModal onClose={onCreateNewSchoolPeriodModalClosed} />}
          {showDeleteSchoolPeriod && <DeleteSchoolPeriodModal onClose={onDeleteSchoolPeriodModalClosed} originalValues={currentSchoolPeriod} />}
          {showEditSchoolPeriod && <EditSchoolPeriodModal onClose={onEditSchoolPeriodModalClosed} originalValues={currentSchoolPeriod} />}
          <Row>
            <Col lg="6" className="pull-right">
              {hasWritePermission(Permission.TIMABIL) && (
                <Button variant="primary" className="pull-right" onClick={() => setShowCreateNewSchoolPeriodModal(true)}>
                  <FontAwesomeIcon className="sidebarToggleIcon" icon={faPlus} style={{ display: 'inline', marginTop: '2px' }} />
                  &nbsp;{t('createNewSchoolPeriodModal')}
                </Button>
              )}
            </Col>
          </Row>
          {data && (
            <div className="whiteColumn">
              <BootstrapTable
                bootstrap4
                keyField="name"
                data={data}
                columns={schoolPeriodsColumns}
                noDataIndication={t('common:noDataFound')}
                striped
                bordered={false}
                pagination={pagination}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
export default SchoolPeriods;
