import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Dropdown, DropdownButton, Row, Spinner, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CreateNewTariffModal from './CreateNewTariffModal';
import { getTariffInfo, getTariffPeriods } from '../api/tariffs';
import { TariffPeriodType, TariffProductsType } from '../api/tariffs.types';
import { currencyFormatter, dateFormatter, formatChosenPeriod } from '../utils/tableUtils';
import EditTariffPeriodModal from './EditTariffPeriodModal';
import DeleteTariffPeriodModal from './DeleteTariffPeriodModal';
import EditProductTariffModal from './EditProductTariffModal';
import EditProductAccKeysTariffModal from './EditProductAccKeysTariffModal';
import EditDiscountAccKeysModal from './EditDiscountAccKeysModal';
import EditSiblingDiscountModal from './EditSiblingDiscountModal';
import { Permission } from '../api/account.types';
import usePermission from '../hooks/usePermission';

const Tariff = (): React.ReactElement => {
  const { t } = useTranslation('tariff');
  const { hasWritePermission, hasReadPermission } = usePermission();
  const [showCreateNewTariff, setShowCreateNewTariffPeriod] = useState<boolean>(false);
  const [showEditTariffPeriod, setShowEditTariffPeriod] = useState<boolean>(false);
  const [showDeleteTariffPeriod, setShowDeleteTariffPeriod] = useState<boolean>(false);
  const [showEditProductTariffModal, setShowEditProductTariffModal] = useState<boolean>(false);
  const [showEditProductAccKeysTariffModal, setShowEditProductAccKeysTariffModal] = useState<boolean>(false);
  const [showEditDiscountAccKeysTariffModal, setShowEditDiscountAccKeysTariffModal] = useState<boolean>(false);
  const [showEditSiblingDiscountModal, setShowEditSiblingDiscountModal] = useState<boolean>(false);
  const [siblingDiscount, setSiblingDiscount] = useState<string[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [tariffs, setTariffs] = useState<TariffPeriodType[]>();
  const [data, setData] = useState<TariffProductsType>();
  const [chosenPeriod, setChosenPeriod] = useState<TariffPeriodType | undefined>(undefined);
  const priceText: string = data?.billing_type || t('price');
  const [currentPage, setCurrentPage] = useState<number>(1);

  const onCreateNewTariffModalClosed = (created: boolean) => {
    setShowCreateNewTariffPeriod(false);
    if (created) {
      loadTariffPeriods();
    }
  };
  const onEditTariffPeriodModalClosed = (edited: boolean) => {
    setShowEditTariffPeriod(false);
    if (edited) {
      loadTariffPeriods();
    }
  };

  const onDeleteTariffPeriodModalClosed = (deleted: boolean) => {
    setShowDeleteTariffPeriod(false);
    if (deleted) {
      loadTariffPeriods();
    }
  };

  const onEditProductTariffModalClosed = (edited: boolean) => {
    setShowEditProductTariffModal(false);
    if (edited) {
      loadTariffPeriods();
    }
  };

  const onEditProductAccKeysTariffModalClosed = (edited: boolean) => {
    setShowEditProductAccKeysTariffModal(false);
    if (edited) {
      loadTariffPeriods();
    }
  };

  const onEditDiscountAccKeysTariffModalClosed = (edited: boolean) => {
    setShowEditDiscountAccKeysTariffModal(false);
    if (edited) {
      loadTariffPeriods();
    }
  };

  const onEditSiblingDiscountModalClosed = (edited: boolean) => {
    setShowEditSiblingDiscountModal(false);
    if (edited) {
      loadTariffPeriods();
    }
  };
  const loadTariffPeriods = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getTariffPeriods();
      const resp = response.data.items;
      setTariffs(resp);
      setChosenPeriod(resp.length > 0 ? resp[0] : undefined);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);
  const loadTariffInfo = useCallback(async () => {
    setLoadingData(true);
    setError(false);
    try {
      const response = await getTariffInfo(chosenPeriod?.tariff_period_id);
      setData(response.data.items[0]);
      setSiblingDiscount(response.data.items[0].sibling_discount.split(','));
    } catch {
      setError(true);
    } finally {
      setLoadingData(false);
    }
  }, [chosenPeriod]);

  useEffect(() => {
    loadTariffPeriods();
  }, [loadTariffPeriods]);

  useEffect(() => {
    if (chosenPeriod !== undefined) {
      loadTariffInfo();
    }
  }, [chosenPeriod]);

  const customTotal = (from: number, to: number, size: number) => (
    // eslint-disable-next-line react/react-in-jsx-scope
    <span className="react-bootstrap-table-pagination-total">
      Raðir {from} til {to} af {size}
    </span>
  );

  const pagination = paginationFactory({
    page: currentPage,
    onPageChange: (page: number) => {
      setCurrentPage(page);
    },
    sizePerPage: 50,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
  });
  const productTariffColumn = [
    {
      dataField: 'product',
      text: t('product'),
      sort: true,
    },
    {
      dataField: 'price',
      text: priceText,
      sort: true,
      formatter: currencyFormatter,
    },
    {
      dataField: 'edit',
      isDummyField: true,
      text: '',
      headerFormatter: () => {
        if (hasWritePermission(Permission.GJALDSKRA)) {
          return (
            <div className="pull-right">
              <FontAwesomeIcon className="sidebarToggleIcon" icon={faEdit} style={{ display: 'inline', cursor: 'pointer' }} onClick={() => setShowEditProductTariffModal(true)} />
            </div>
          );
        }
        return '';
      },
    },
  ];
  const extraProductsColumn = [
    {
      dataField: 'id',
      isDummyField: true,
      text: '',
      csvExport: false,
      headerStyle: { width: '35px' },
      formatter: (cell: string, row: any, rowIndex: any) => {
        const rowNumber = (currentPage - 1) * 50 + (rowIndex + 1);
        return `${rowNumber}.`;
      },
    },
    {
      dataField: 'school_name',
      text: t('school_name'),
      sort: true,
    },
    {
      dataField: 'product',
      text: t('product'),
      sort: true,
    },
    {
      dataField: 'start_date',
      text: t('start_date'),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: 'end_date',
      text: t('end_date'),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: 'external_account_key',
      text: t('external_account_key'),
      sort: true,
    },
    {
      dataField: 'product_category',
      text: t('product_category'),
      sort: true,
    },
    {
      dataField: 'discount_category',
      text: t('discount_category'),
      sort: true,
    },
    {
      dataField: 'price',
      text: priceText,
      sort: true,
      formatter: currencyFormatter,
    },
  ];
  const productAccKeysColumn = [
    {
      dataField: 'product',
      text: t('product'),
      sort: true,
    },
    {
      dataField: 'discount_category',
      text: t('discount_category'),
      sort: true,
    },
    {
      dataField: 'external_account_key',
      text: t('external_account_key'),
      sort: true,
    },

    {
      dataField: 'product_category',
      text: t('product_category'),
      sort: true,
    },
    {
      dataField: 'edit',
      isDummyField: true,
      text: '',
      headerFormatter: () => {
        if (hasWritePermission(Permission.GJALDSKRA)) {
          return (
            <div className="pull-right">
              <FontAwesomeIcon
                className="sidebarToggleIcon"
                icon={faEdit}
                style={{ display: 'inline', cursor: 'pointer' }}
                onClick={() => setShowEditProductAccKeysTariffModal(true)}
              />
            </div>
          );
        }
        return '';
      },
    },
  ];
  const discountAccKeysColumn = [
    {
      dataField: 'discount_name',
      text: t('discount_name'),
      sort: true,
    },
    {
      dataField: 'account_key',
      text: t('account_key'),
      sort: true,
    },
    {
      dataField: 'edit',
      isDummyField: true,
      text: '',
      headerFormatter: () => {
        if (hasWritePermission(Permission.GJALDSKRA)) {
          return (
            <div className="pull-right">
              <FontAwesomeIcon
                className="sidebarToggleIcon"
                icon={faEdit}
                style={{ display: 'inline', cursor: 'pointer' }}
                onClick={() => setShowEditDiscountAccKeysTariffModal(true)}
              />
            </div>
          );
        }
        return '';
      },
    },
  ];
  return (
    <>
      {hasReadPermission(Permission.GJALDSKRA) && (
        <>
          {showCreateNewTariff && <CreateNewTariffModal onClose={onCreateNewTariffModalClosed} lastTariff={tariffs ? tariffs[0] : undefined} />}
          {showEditTariffPeriod && <EditTariffPeriodModal id={chosenPeriod?.tariff_period_id} onClose={onEditTariffPeriodModalClosed} />}
          {showDeleteTariffPeriod && <DeleteTariffPeriodModal originalValues={chosenPeriod} onClose={onDeleteTariffPeriodModalClosed} />}
          {showEditProductTariffModal && <EditProductTariffModal originalValues={data?.product_tariff[0]} onClose={onEditProductTariffModalClosed} />}
          {showEditProductAccKeysTariffModal && <EditProductAccKeysTariffModal originalValues={data?.product_acc_keys[0]} onClose={onEditProductAccKeysTariffModalClosed} />}
          {showEditDiscountAccKeysTariffModal && <EditDiscountAccKeysModal originalValues={data?.discount_acc_keys} onClose={onEditDiscountAccKeysTariffModalClosed} />}
          {showEditSiblingDiscountModal && (
            <EditSiblingDiscountModal originalValues={siblingDiscount} id={chosenPeriod?.tariff_period_id} onClose={onEditSiblingDiscountModalClosed} />
          )}
          <Row>
            <Col>
              {chosenPeriod !== undefined && (
                <DropdownButton title={formatChosenPeriod(chosenPeriod?.valid_from, chosenPeriod?.valid_to)} variant="secondary btn-med" style={{ display: 'inline' }}>
                  {tariffs &&
                    tariffs?.length > 0 &&
                    tariffs?.map((item, idx) => {
                      return (
                        <Dropdown.Item key={idx} title={chosenPeriod ? chosenPeriod?.tariff_period_id : t('period')} onSelect={() => setChosenPeriod(item)}>
                          {formatChosenPeriod(item?.valid_from, item?.valid_to)}
                        </Dropdown.Item>
                      );
                    })}
                </DropdownButton>
              )}
              {tariffs &&
                tariffs?.length > 0 &&
                chosenPeriod !== undefined &&
                chosenPeriod.tariff_period_id === tariffs[0].tariff_period_id &&
                hasWritePermission(Permission.GJALDSKRA) && (
                  <>
                    <Button variant="link" onClick={() => setShowEditTariffPeriod(true)} style={{ display: 'inline', paddingLeft: '1rem' }}>
                      <FontAwesomeIcon className="nav-link-icon" icon={faEdit} fixedWidth />
                    </Button>
                    {chosenPeriod.deletable === 1 && (
                      <Button variant="link" onClick={() => setShowDeleteTariffPeriod(true)} style={{ display: 'inline' }}>
                        <FontAwesomeIcon className="nav-link-icon" icon={faTrashAlt} fixedWidth />
                      </Button>
                    )}
                  </>
                )}
            </Col>
            <Col>
              {hasWritePermission(Permission.GJALDSKRA) && (
                <Button
                  variant="primary"
                  type="submit"
                  className="pull-right"
                  onClick={() => {
                    setShowCreateNewTariffPeriod(true);
                  }}
                >
                  {t('createNewTariffTitle')}
                </Button>
              )}
            </Col>
          </Row>
          {loadingData && (
            <div className="spinner">
              <Spinner animation="border" role="status">
                <span className="sr-only">{t('common:loading')}</span>
              </Spinner>
            </div>
          )}
          {tariffs && tariffs?.length > 0 && chosenPeriod !== undefined && data && !loadingData && (
            <>
              <Row style={{ marginBottom: '1rem' }}>
                <Col lg={6} md={6} sm={6}>
                  <div className="whiteColumn">
                    <h4>{t('productTariffTitle')}</h4>
                    <BootstrapTable
                      bootstrap4
                      keyField="product"
                      data={data.product_tariff}
                      columns={productTariffColumn}
                      noDataIndication={t('common:noDataFound')}
                      striped
                      bordered={false}
                    />
                  </div>
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <div className="whiteColumn">
                    <h4>{t('siblingDiscountTitle')}</h4>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>{t('siblingNumber')}</th>
                          <th>{t('discount_name')}</th>
                          <th>
                            {hasWritePermission(Permission.GJALDSKRA) && (
                              <div className="pull-right">
                                <FontAwesomeIcon
                                  className="sidebarToggleIcon"
                                  icon={faEdit}
                                  style={{ display: 'inline', cursor: 'pointer' }}
                                  onClick={() => setShowEditSiblingDiscountModal(true)}
                                />
                              </div>
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {siblingDiscount?.map((item, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{siblingDiscount[idx + 1] ? idx + 1 : `${idx + 1}+`}</td>
                              <td>{`${item} %`}</td>
                              <td />
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
              <div className="whiteColumn">
                <h4>{t('schoolExtraProductsTitle')}</h4>
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={data.extra_products}
                  columns={extraProductsColumn}
                  noDataIndication={t('common:noDataFound')}
                  striped
                  bordered={false}
                  pagination={pagination}
                />
              </div>
              <Row style={{ marginBottom: '1rem' }}>
                <Col lg={6} md={6} sm={6}>
                  <div className="whiteColumn">
                    <h4>{t('productAccKeyTitle')}</h4>
                    <h5>{t('productAccKeySubTitle')}</h5>
                    <BootstrapTable
                      bootstrap4
                      keyField="product"
                      data={data.product_acc_keys}
                      columns={productAccKeysColumn}
                      noDataIndication={t('common:noDataFound')}
                      striped
                      bordered={false}
                    />
                  </div>
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <div className="whiteColumn">
                    <h4>{t('productAccKeyTitle')}</h4>
                    <h5>{t('discountAccKeySubTitle')}</h5>
                    <BootstrapTable
                      bootstrap4
                      keyField="account_key"
                      data={data.discount_acc_keys}
                      columns={discountAccKeysColumn}
                      noDataIndication={t('common:noDataFound')}
                      striped
                      bordered={false}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};
export default Tariff;
