import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { Button, Form, Modal } from 'react-bootstrap';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import dayjs from 'dayjs';
import * as Yup from 'yup';
import { restValidationToForm } from '../utils/formUtils';
import { getExtraProductsForSchool, registerExtraProduct } from '../api/registrations';
import { Product } from '../api/registrations.types';
import DatePicker from '../components/DatePicker';

interface Values {
  end_date: string | undefined;
  start_date: string | undefined;
  product_id: number | undefined;
  registration_id?: number | undefined;
}

interface Props {
  onClose: (created: boolean) => void;
  originalValues: any;
}
const AddExtraProduct = ({ originalValues, onClose }: Props): React.ReactElement => {
  const { t } = useTranslation(['addExtraProduct', 'registrations']);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [schoolProducts, setSchoolProducts] = useState<Product[]>();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      start_date: Yup.string().required(t('errors:errorMessageDate')),
    });
  }, [t]);

  const initialValues: Values = useMemo(
    () => ({
      end_date: undefined,
      start_date: undefined,
      product_id: schoolProducts && schoolProducts[0] ? schoolProducts[0].product_id : undefined,
      registration_id: originalValues.registration_id,
    }),
    [schoolProducts]
  );

  const getSchoolExtraProducts = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getExtraProductsForSchool(originalValues.school_id);
      setSchoolProducts(response.data.items);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getSchoolExtraProducts();
  }, [getSchoolExtraProducts]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<Values>) => {
          try {
            await registerExtraProduct(values);
            toast.success(t('addExtraProduct:extraProductRegistered'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
            toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, errors }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{t('addExtraProductTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                {!loading && (
                  <table className="table">
                    <tbody>
                      <tr>
                        <td style={{ borderTop: 'none' }}>
                          <Form.Group>
                            <Form.Label>
                              {t('product')}
                              <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              value={values.product_id}
                              onChange={(event) => {
                                setFieldValue('product_id', event.target.value);
                              }}
                            >
                              {schoolProducts?.map((item) => {
                                return (
                                  <option key={item.product_id} value={item.product_id}>
                                    {item.product_name}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Form.Group>
                        </td>
                        <td style={{ borderTop: 'none' }}>
                          <Form.Group controlId="startDay">
                            <Form.Label>
                              {t('registrations:startOfRegistration')}
                              <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <div className="dateWrapper">
                              <DatePicker
                                style={{ display: 'inline', width: '100%' }}
                                onDayChange={(day: Date) => {
                                  setFieldValue('start_date', dayjs(day).format('DD.MM.YYYY'));
                                }}
                              />
                            </div>
                            <ErrorMessage name="start_date" component="div" className="invalid-feedback d-block text-left" />
                          </Form.Group>
                        </td>
                        <td style={{ borderTop: 'none' }}>
                          <Form.Group controlId="endDate">
                            <Form.Label>{t('registrations:endOfRegistration')}</Form.Label>
                            <div className="dateWrapper">
                              <DatePicker
                                style={{ display: 'inline', width: '100%' }}
                                onDayChange={(day: Date) => {
                                  setFieldValue('end_date', dayjs(day).format('DD.MM.YYYY'));
                                }}
                              />
                            </div>
                          </Form.Group>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:submit')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddExtraProduct;
