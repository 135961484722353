/* eslint-disable no-param-reassign */
import React, { useState, useMemo } from 'react';

// Röðun sem höndlar íslenska stafi
function localeSort(key: string, v1: any, v2: any) {
  v1 = v1[key];
  v2 = v2[key];

  const t1 = typeof v1;
  const t2 = typeof v2;
  if (t1 === t2) {
    if (t1 === 'string') {
      v1 = v1.toUpperCase();
      v2 = v2.toUpperCase();
      const order = ' 0123456789AÁBCDÐEÉĘFGHIÍJKLMNOÓPQRSTUÚVWXYÝZÞÆÖ.,:;';
      let counter = 0;
      let s1;
      let s2;
      while (counter < v1.length && counter < v2.length) {
        s1 = v1.substring(counter, counter + 1);
        s2 = v2.substring(counter, counter + 1);
        if (s1 === s2) {
          counter += 1;
        } else {
          break;
        }
      }
      const returnValue = order.indexOf(s1) - order.indexOf(s2);

      return returnValue;
    }
    if (t1 === 'number') {
      const returnValue = v1 - v2;
      return returnValue;
    }
    const a = v1 == null ? '' : v1.toLocaleString();
    const b = v2 == null ? '' : v2.toLocaleString();
    const returnValue = a.localeCompare(b);
    return returnValue;
  }
  return t1.localeCompare(t2);
}

export default function useSortableData(items: any) {
  const [sortKey, setSortKey] = useState<string | undefined>();
  const [sortDirection, setSortDirection] = useState<string | undefined>();
  const [column, setColumn] = useState<string | undefined>();

  const sortedItems = useMemo(() => {
    const sortableItems = JSON.parse(JSON.stringify(items));

    if (!sortKey) {
      return sortableItems;
    }

    if (sortDirection === 'ascending' && !column) {
      setColumn(sortKey);
      sortableItems.sort((a: any, b: any) => localeSort(sortKey, a, b));
    } else if (sortDirection === 'descending' && column === sortKey) {
      sortableItems.sort((a: any, b: any) => localeSort(sortKey, a, b));
      sortableItems.reverse();
    } else {
      setColumn(sortKey);
      sortableItems.sort((a: any, b: any) => localeSort(sortKey, a, b));
    }
    return sortableItems;
  }, [items, sortKey, sortDirection, column]);

  const requestSort = (key: string | undefined) => {
    let direction = 'ascending';
    if (sortKey === key && sortDirection === 'ascending') {
      direction = 'descending';
    }
    setSortKey(key);
    setSortDirection(direction);
  };

  return { items: sortedItems, requestSort, sortKey, sortDirection };
}
