import { AxiosResponse } from 'axios';
import axios from './api';
import { FakeSSNType, NatRegAllResponse, PostUnlistedStudent, UnlistedStudentsListMentorType, UnlistedStudentsListType, UpdateUnlistedStudentBody } from './unlistedStudents.types';
import { SuccessfulResponse } from './access.types';

export const getUnlistedStudents = (str: string): Promise<AxiosResponse<UnlistedStudentsListType>> => {
  return axios.get('/vala/unlisted/unlisted-list', {
    params: { str },
  });
};

export const getUnlistedStudentsFromMentor = (str: string): Promise<AxiosResponse<UnlistedStudentsListMentorType>> => {
  return axios.get('/vala/unlisted/unlisted-list-mentor', {
    params: { str },
  });
};

export const getFakeSSN = (data: FakeSSNType): Promise<AxiosResponse<{ newssn: string }>> => {
  return axios.post(`/vala/unlisted/get-fake-ssn`, data);
};

export const postUnlistedStudent = (data: PostUnlistedStudent): Promise<AxiosResponse<UnlistedStudentsListType>> => {
  return axios.post(`/vala/unlisted/unlisted-list`, data);
};

export const getNatRegAll = (ssn: string): Promise<AxiosResponse<NatRegAllResponse>> => {
  return axios.get('/vala/admin/natreg-all', {
    params: { ssn },
  });
};

export const deleteUnlistedStudent = (unlist_id: number | undefined): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.delete(`/vala/unlisted/unlisted/${unlist_id}`);
};

export const editUnlistedStudent = (unlist_id: number | undefined, body: UpdateUnlistedStudentBody): Promise<AxiosResponse<SuccessfulResponse>> => {
  return axios.put(`/vala/unlisted/unlisted/${unlist_id}`, body);
};

export const postRealSSN = (unlistid: number | undefined, realssn: string): Promise<AxiosResponse<UnlistedStudentsListType>> => {
  return axios.post(`/vala/unlisted/confirm-real-ssn`, { unlistid, realssn });
};
