import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'react-bootstrap';
import { faMinus, faTrashAlt, faSortAlphaUp, faSortAlphaDownAlt } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RecordType } from '../../api/billing.types';
import { currencyFormatter, dateFormatter, formatPercentage } from '../../utils/tableUtils';
import DeleteManualRecordModal from '../../views/DeleteManualRecordModal';
import { BatchType } from '../../api/invoicing.types';
import useSortableData from '../../hooks/useSortableData';

interface Props {
  records: RecordType[];
  studentName: string;
  batch: BatchType;
  showTrash: boolean;
  onClose: (deleted: boolean) => void;
}

const Records = ({ records, showTrash, studentName, batch, onClose }: Props): React.ReactElement => {
  const { t } = useTranslation(['invoiceView', 'common', 'errors']);
  const [showDeleteManualRecordsModal, setShowDeleteManualRecordsModal] = useState<boolean>(false);
  const [currentBillingItem, setCurrentBillingItem] = useState<RecordType | undefined>();
  const { items, requestSort, sortKey, sortDirection } = useSortableData(records || []);

  const onCloseDeleteManualRecordModal = (deleted: boolean) => {
    setShowDeleteManualRecordsModal(false);

    if (deleted) {
      onClose(true);
    }
  };

  const getClassNamesFor = (name: any) => {
    return sortKey === name ? sortDirection : undefined;
  };

  return (
    <>
      {showDeleteManualRecordsModal && (
        <DeleteManualRecordModal billing_item_id={currentBillingItem?.billing_item_id} studentName={studentName} batch={batch} onClose={onCloseDeleteManualRecordModal} />
      )}
      <Table className="tableStriped">
        <thead>
          <tr>
            <th>
              <button type="button" onClick={() => requestSort('description')} className={classNames(`dataSortButton ${getClassNamesFor('description')}`)}>
                {t('description')}
              </button>
              {getClassNamesFor('description') === 'ascending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaUp} fixedWidth />}
              {getClassNamesFor('description') === 'descending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaDownAlt} fixedWidth />}
            </th>
            <th>
              <button type="button" onClick={() => requestSort('item_date_from')} className={classNames(`dataSortButton ${getClassNamesFor('item_date_from')}`)}>
                {t('item_date_from')}
              </button>
              {getClassNamesFor('item_date_from') === 'ascending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaUp} fixedWidth />}
              {getClassNamesFor('item_date_from') === 'descending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaDownAlt} fixedWidth />}
            </th>
            <th>
              <button type="button" onClick={() => requestSort('item_date_to')} className={classNames(`dataSortButton ${getClassNamesFor('item_date_to')}`)}>
                {t('item_date_to')}
              </button>
              {getClassNamesFor('item_date_to') === 'ascending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaUp} fixedWidth />}
              {getClassNamesFor('item_date_to') === 'descending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaDownAlt} fixedWidth />}
            </th>
            <th>
              <button type="button" onClick={() => requestSort('percentage')} className={classNames(`dataSortButton ${getClassNamesFor('percentage')}`)}>
                {t('percentage')}
              </button>
              {getClassNamesFor('percentage') === 'ascending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaUp} fixedWidth />}
              {getClassNamesFor('percentage') === 'descending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaDownAlt} fixedWidth />}
            </th>
            <th>
              <button type="button" onClick={() => requestSort('record_type')} className={classNames(`dataSortButton ${getClassNamesFor('record_type')}`)}>
                {t('record_type')}
              </button>
              {getClassNamesFor('record_type') === 'ascending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaUp} fixedWidth />}
              {getClassNamesFor('record_type') === 'descending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaDownAlt} fixedWidth />}
            </th>
            <th style={{ textAlign: 'right' }}>
              <button type="button" onClick={() => requestSort('amount')} className={classNames(`dataSortButton ${getClassNamesFor('amount')}`)}>
                {t('amount')}
              </button>
              {getClassNamesFor('amount') === 'ascending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaUp} fixedWidth />}
              {getClassNamesFor('amount') === 'descending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaDownAlt} fixedWidth />}
            </th>
            {showTrash && <th />}
          </tr>
        </thead>
        <tbody>
          {items.map((item: any, index: number) => {
            return (
              <tr data-index={index % 2} key={index}>
                <td>{item.description}</td>
                <td>{dateFormatter(item.item_date_from)}</td>
                <td>{dateFormatter(item.item_date_to)}</td>
                <td>{formatPercentage(item.percentage)}</td>
                <td>{item.record_type}</td>
                <td style={{ textAlign: 'right' }}>{currencyFormatter(item.amount)}</td>
                {/* Only show trash column for manual records */}
                {item?.record_type_id.includes('M') && (
                  <td>
                    <Button
                      style={{ float: 'right' }}
                      variant="link"
                      onClick={() => {
                        setShowDeleteManualRecordsModal(true);
                        setCurrentBillingItem(item);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
                    </Button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default Records;
