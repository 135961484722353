import { AxiosResponse } from 'axios';
import axios from './api';

import {
  DiscountAccKeysResponse,
  DiscountAccKeysType,
  ProudctAccKeysResponse,
  TarffifProductResponse,
  Tariff,
  TariffPeriodListType,
  TariffProduct,
  TariffProductAccKeys,
  TariffProductsTypeResponse,
  TariffResponse,
} from './tariffs.types';

export type { TariffResponse };

export const createTariffPeriod = (data: Tariff): Promise<AxiosResponse<TariffResponse>> => {
  return axios.post('/vala/billing/tariff-period', data);
};

export const editTariffPeriod = (id: number | undefined, data: Tariff): Promise<AxiosResponse<TariffResponse>> => {
  return axios.put(`/vala/billing/tariff-period/${id}`, data);
};

export const getTariffPeriods = (): Promise<AxiosResponse<TariffPeriodListType>> => {
  return axios.get('/vala/billing/tariff-period');
};

export const getTariff = (id: number | undefined): Promise<AxiosResponse<TariffPeriodListType>> => {
  return axios.get(`/vala/billing/tariff-period/${id}`);
};

export const getTariffInfo = (tariff_id: number | undefined): Promise<AxiosResponse<TariffProductsTypeResponse>> => {
  return axios.get('/vala/billing/tariff-products', {
    params: { tariff_id },
  });
};

export const deleteTariff = (id: number | undefined): Promise<AxiosResponse<TariffResponse>> => {
  return axios.delete(`/vala/billing/tariff-period/${id}`);
};

export const editProductTariffPrice = (data: TariffProduct): Promise<AxiosResponse<TarffifProductResponse>> => {
  return axios.put('vala/billing/tariff-product-put', data);
};

export const editProductAccKeys = (data: TariffProductAccKeys): Promise<AxiosResponse<ProudctAccKeysResponse>> => {
  return axios.put('/vala/billing/product-account-key', data);
};

export const editDiscountAccKeys = (data: DiscountAccKeysType | undefined): Promise<AxiosResponse<DiscountAccKeysResponse>> => {
  return axios.put('/vala/billing/discount-account-key', data);
};

export const editSiblingDiscounts = (tariff_id: number | undefined, sibling_discount: string): Promise<AxiosResponse<TariffResponse>> => {
  return axios.put(`/vala/billing/sibling-discount/${tariff_id}`, { sibling_discount });
};
