import React, { useCallback, useState } from 'react';
import { Button, Spinner, Row, Col, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faCheck, faTrashAlt, faEnvelope, faRecycle, faSortAlphaUp, faSortAlphaDownAlt, faCopy, faEdit, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useSortableData from '../hooks/useSortableData';
import StudentSummaryTableRow from './StudentSummaryTableRow';

import usePermission, { Permission } from '../hooks/usePermission';
import { BatchType } from '../api/invoicing.types';
import { BatchSummary } from '../api/billing.types';
import { useAppDispatch } from '../hooks/useState';
import { runBatches } from '../api/billing';
import { fetchBatch, fetchBatchSummaries } from '../store/invoicing/invoicingSlice';
import AddStudentToBatchModal from '../views/AddStudentToBatchModal';

interface Props {
  batchData: BatchType;
  batchSummary: BatchSummary[] | undefined;
  setShowConfirmBatchModal: (value: boolean) => void;
  setShowAddDiscountChildMultiModal: (value: boolean) => void;
  setShowAddMultiChildrenDiscountModal: (value: boolean) => void;
  setShowOpenBatchModal: (value: boolean) => void;
  deleteBatchRecords: (batchId: string) => void;
  sendRecallBatchFromExternalSystem: (batchId: string) => void;
  onSearchChanged: (value: string) => void;
  loading: boolean;
  expandedStudent: string[];
  handleExpandStudent: (event: React.MouseEvent, rowId: string) => void;
  fetchBatchSummary: (str?: string) => void;
}

const TableRowExpanded: React.FC<Props> = ({
  batchData,
  batchSummary,
  setShowConfirmBatchModal,
  setShowAddDiscountChildMultiModal,
  setShowAddMultiChildrenDiscountModal,
  setShowOpenBatchModal,
  deleteBatchRecords,
  sendRecallBatchFromExternalSystem,
  onSearchChanged,
  loading,
  expandedStudent,
  handleExpandStudent,
  fetchBatchSummary,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['invoiceView', 'common', 'errors']);
  const { hasWritePermission } = usePermission();
  const { items, requestSort, sortKey, sortDirection } = useSortableData(batchSummary || []);
  const [runLastMonth, setRunLastMonth] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [showAddStudentToBatchModal, setShowAddStudentToBatchModal] = useState<boolean>(false);

  const getClassNamesFor = (name: string) => {
    return sortKey === name ? sortDirection : undefined;
  };

  const postSingleBatchData = useCallback(async () => {
    try {
      await runBatches({
        run_last_month: runLastMonth ? '1' : '0',
        batches: JSON.stringify([{ batch_id: batchData.batch_id }]),
      });

      await dispatch(fetchBatch(batchData.batch_id));
      await dispatch(
        fetchBatchSummaries({
          batchId: batchData.batch_id,
        })
      );
    } catch (e) {
      toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
    }
  }, [batchData.batch_id, t, dispatch, runLastMonth]);

  const onAddStudentToBatchModalClosed = useCallback(
    async (confirmed: boolean) => {
      setShowAddStudentToBatchModal(false);
      try {
        if (confirmed) {
          await dispatch(fetchBatch(batchData.batch_id));
          await dispatch(
            fetchBatchSummaries({
              batchId: batchData.batch_id,
            })
          );
        }
      } catch (e) {
        if (e.response) {
          toast.error(e.response.data.error);
        }
      }
    },
    [fetchBatchSummary]
  );

  return (
    <>
      {showAddStudentToBatchModal && <AddStudentToBatchModal onClose={onAddStudentToBatchModalClosed} batch_id={batchData.batch_id} school_id={batchData.school_id} />}
      {(batchData.status === 3 || batchData.only_manual_rec === 1) && (
        <>
          {hasWritePermission(Permission.REIKNINGAGERD) && (
            <Button variant="primary" type="submit" onClick={postSingleBatchData}>
              <FontAwesomeIcon icon={faSync} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
              &nbsp;{t('runMonth')}
            </Button>
          )}
          {hasWritePermission(Permission.REIKNINGAGERD) && (
            <Button variant="link" type="submit" onClick={() => setShowAddStudentToBatchModal(true)} className="ml-5">
              <FontAwesomeIcon icon={faPlus} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
              &nbsp;{t('addStudentToBatch')}
            </Button>
          )}
          <div className="p-1" style={{ float: 'right' }}>
            <span>
              <input type="checkbox" checked={runLastMonth} onChange={(e) => setRunLastMonth(e.currentTarget.checked)} />
              &nbsp;
              {t('rerunLastMonth')}
            </span>
          </div>
        </>
      )}
      {hasWritePermission(Permission.REIKNINGAGERD) && batchSummary && batchSummary?.length > 0 && batchData.status === 1 && batchData.only_manual_rec === 0 && (
        <div>
          {batchData.billing_error === null && (
            <Button variant="link" type="submit" onClick={() => setShowAddMultiChildrenDiscountModal(true)}>
              <FontAwesomeIcon icon={faPencil} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
              &nbsp;{t('addDiscountChildMulti')}
            </Button>
          )}
          {batchData.billing_error === null && (
            <Button variant="link" type="submit" onClick={() => setShowConfirmBatchModal(true)}>
              <FontAwesomeIcon icon={faCheck} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
              &nbsp;{t('confirmRecords')}
            </Button>
          )}
          <Button variant="link" type="submit" onClick={() => setShowAddStudentToBatchModal(true)} className="ml-5">
            <FontAwesomeIcon icon={faPlus} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
            &nbsp;{t('addStudentToBatch')}
          </Button>

          <Button variant="link" type="submit" style={{ float: 'right' }} onClick={() => deleteBatchRecords(batchData?.batch_id)}>
            <FontAwesomeIcon icon={faTrashAlt} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
            &nbsp;{t('deleteRecords')}
          </Button>
        </div>
      )}
      {hasWritePermission(Permission.REIKNINGAGERD) && batchSummary && batchSummary?.length > 0 && batchData.status === 4 && batchData.only_manual_rec === 0 && (
        <Button variant="link" type="submit" onClick={() => sendRecallBatchFromExternalSystem(batchData.batch_id)}>
          <FontAwesomeIcon icon={faEnvelope} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
          &nbsp;{t('reCallBatchFromExternalSystem')}
        </Button>
      )}
      {batchData.status === 6 && (
        <>
          <div style={{ display: 'inline', marginRight: '5px' }}>
            <Spinner animation="border" role="status" size="sm">
              <span className="sr-only">{t('common:loading')}</span>
            </Spinner>
          </div>
          <span>{t('batchIsUploading')}</span>
        </>
      )}
      {hasWritePermission(Permission.REIKNINGAGERD) && batchData.status === 2 && (
        <Button variant="link" type="submit" onClick={() => setShowOpenBatchModal(true)}>
          <FontAwesomeIcon icon={faRecycle} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />
          &nbsp;{t('openBatch')}
        </Button>
      )}
      {batchData.status !== 6 && batchData.status !== 3 && (
        <>
          {batchSummary && (
            <>
              <Row>
                <Col md={3}>
                  <input
                    type="text"
                    className="form-control"
                    style={{ margin: '1rem' }}
                    disabled={loading}
                    placeholder={t('common:search')}
                    autoComplete="off"
                    onChange={(e) => {
                      setSearchValue(e.currentTarget.value);
                      onSearchChanged(e.currentTarget.value);
                    }}
                    value={searchValue}
                  />
                </Col>
              </Row>
              <Table className="tableStriped">
                <thead style={{ position: 'sticky', top: '0' }}>
                  <tr>
                    <th>&nbsp;</th>
                    <th>
                      <button type="button" onClick={() => requestSort('student_name')} className={classNames(`dataSortButton ${getClassNamesFor('student_name')}`)}>
                        {t('name')}
                      </button>
                      {getClassNamesFor('student_name') === 'ascending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaUp} fixedWidth />}
                      {getClassNamesFor('student_name') === 'descending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaDownAlt} fixedWidth />}
                    </th>
                    <th />
                    <th>
                      <button type="button" onClick={() => requestSort('student_ssn')} className={classNames(`dataSortButton ${getClassNamesFor('student_ssn')}`)}>
                        {t('ssn')}
                      </button>
                      {getClassNamesFor('student_ssn') === 'ascending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaUp} fixedWidth />}
                      {getClassNamesFor('student_ssn') === 'descending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaDownAlt} fixedWidth />}
                    </th>
                    <th />
                    <th>
                      <button type="button" onClick={() => requestSort('discounts')} className={classNames(`dataSortButton ${getClassNamesFor('discounts')}`)}>
                        {t('discounts')}
                      </button>
                      {getClassNamesFor('discounts') === 'ascending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaUp} fixedWidth />}
                      {getClassNamesFor('discounts') === 'descending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaDownAlt} fixedWidth />}
                    </th>
                    <th />
                    <th>
                      <button type="button" onClick={() => requestSort('amount')} className={classNames(`dataSortButton ${getClassNamesFor('amount')}`)}>
                        {t('amount')}
                      </button>
                      {getClassNamesFor('amount') === 'ascending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaUp} fixedWidth />}
                      {getClassNamesFor('amount') === 'descending' && <FontAwesomeIcon className="nav-link-icon" icon={faSortAlphaDownAlt} fixedWidth />}
                    </th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {batchSummary.length > 0 &&
                    items.map((child: any, index: number) => {
                      return (
                        <StudentSummaryTableRow
                          key={index}
                          studentData={child}
                          batchData={batchData}
                          onClick={handleExpandStudent}
                          expandedStudent={expandedStudent}
                          idx={index}
                          onClose={() => fetchBatchSummary(searchValue)}
                        />
                      );
                    })}
                  {batchSummary.length === 0 && (
                    <tr>
                      <td colSpan={9} style={{ textAlign: 'center' }}>
                        {t('common:noDataFound')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </>
          )}
        </>
      )}
    </>
  );
};
export default TableRowExpanded;
