import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { Equalize, EqualizerContainer } from 'react-equalize';
import { useTranslation } from 'react-i18next';
import { Button, Col, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../store/rootReducer';

import { AppDispatch } from '../store/store';
import { fetchStudent } from '../store/Student/getStudentSlice';
import CardHeader from '../components/CardHeader';
import { dateFormatter, dateTimeFormatter, formatBoolean, infinityValueFormatter } from '../utils/tableUtils';
import EditStudentInfo from './EditStudentInfo_allergies';
import EditPayerInfo from './EditPayerInfo';
import EditExtraProducts from './EditExtraProducts';
import DeleteExtraProducts from './DeleteExtraProducts';
import AddExtraProduct from './AddExtraProduct';
import ChooseMonthYear from '../components/ChooseMonthYear';
import { Attachment, ExtraProductInfo, RegisteredStudent } from '../api/registrations.types';
import EditRegistrationDates from './EditRegistrationDates';
import pagination from '../utils/paginationUtils';
import usePermission, { Permission } from '../hooks/usePermission';
import { getAttachments, getFile } from '../api/registrations';
import DeleteAttachmentFromStudent from './DeleteAttachmentFromStudent';

const ViewStudent = (): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const { hasWritePermission, hasReadPermission } = usePermission();
  const { t } = useTranslation(['common', 'studentView', 'logTables', 'registrations']);
  const { student } = useSelector((state: RootState) => state.studentList);
  const { id } = useParams<{ id: string }>();
  const [loadingAttachments, setLoadingAttachments] = useState<boolean>(false);
  const [errorAttachments, setErrorAttachments] = useState<boolean>(false);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [errorFile, setErrorFile] = useState<boolean>(false);
  const [attachmentList, setAttachmentList] = useState<Attachment[]>([]);
  const [showEditAllergiesModal, setShowEditAllergiesModal] = useState<boolean>(false);
  const [showEditPayerInfoModal, setShowEditPayerInfoModal] = useState<boolean>(false);
  const [showEditExtraProductsModal, setShowEditExtraProductsModal] = useState<boolean>(false);
  const [showDeletetExtraProductsModal, setShowDeleteExtraProductsModal] = useState<boolean>(false);
  const [showAddExtraProductModal, setShowAddExtraProductModal] = useState<boolean>(false);
  const [showEditRegistrationDates, setShowEditRegistrationDates] = useState<boolean>(false);
  const [extraProductInfo, setExtraProductInfo] = useState<ExtraProductInfo | undefined>();
  const [showDeleteAttachmentModal, setShowDeleteAttachmentModal] = useState<boolean>(false);
  const [selectedAttachmentToDelete, setSelectedAttachmentToDelete] = useState<Attachment>();

  useEffect(() => {
    dispatch(fetchStudent(id));
  }, [dispatch, id]);

  const onEditAllergiesModalClosed = (edited: boolean) => {
    setShowEditAllergiesModal(false);
    if (edited) {
      dispatch(fetchStudent(id));
    }
  };
  const onEditPayerInfoModalClosed = (edited: boolean) => {
    setShowEditPayerInfoModal(false);
    if (edited) {
      dispatch(fetchStudent(id));
    }
  };
  const onEditExtraProductsModalClosed = (edited: boolean) => {
    setShowEditExtraProductsModal(false);
    if (edited) {
      dispatch(fetchStudent(id));
    }
  };
  const onDeleteExtraProductsModalClosed = (deleted: boolean) => {
    setShowDeleteExtraProductsModal(false);
    if (deleted) {
      dispatch(fetchStudent(id));
    }
  };
  const onAddExtraProductsModalClosed = (added: boolean) => {
    setShowAddExtraProductModal(false);
    if (added) {
      dispatch(fetchStudent(id));
    }
  };
  const onEditRegistrationDatesModalClosed = (edited: boolean) => {
    setShowEditRegistrationDates(false);
    if (edited) {
      dispatch(fetchStudent(id));
    }
  };

  const onDeleteAttachmentModalClosed = (deleted: boolean) => {
    setShowDeleteAttachmentModal(false);
    if (deleted) {
      dispatch(fetchStudent(id));
    }
  };
  const st: RegisteredStudent[] = student?.items;

  const loadAttachments = useCallback(async () => {
    setLoadingAttachments(true);
    setErrorAttachments(false);
    try {
      const response = await getAttachments(st[0].registration_id);
      setAttachmentList(response.data.items);
    } catch {
      setErrorAttachments(true);
    } finally {
      setLoadingAttachments(false);
    }
  }, [st]);

  useEffect(() => {
    loadAttachments();
  }, [loadAttachments]);

  const loadFile = useCallback(async (attachment: Attachment) => {
    setLoadingFile(true);
    setErrorFile(false);
    try {
      const response = await getFile(attachment);
      const link = document.createElement('a');
      const file = new Blob([response.data], { type: response.headers.contentType });
      const url = URL.createObjectURL(file);
      link.href = url;
      link.download = attachment.filename;

      document.body.appendChild(link);

      link.click();
      setTimeout(function () {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 0);
    } catch {
      setErrorFile(true);
    } finally {
      setLoadingFile(false);
    }
  }, []);
  useEffect(() => {
    loadAttachments();
  }, [loadAttachments]);

  const colspan = (cell: any, row: any, rowIndex: any, colIndex: any) => {
    if (row.product_valid === 0) {
      return { colspan: `2` };
    }
    return null;
  };

  const extraProductsColumn = [
    {
      dataField: 'product_name',
      text: t('registrations:product_name'),
    },
    {
      dataField: 'start_date',
      formatter: dateFormatter,
      text: t('registrations:startOfRegistration'),
    },
    {
      dataField: 'end_date',
      formatter: dateFormatter,
      text: t('registrations:endOfRegistration'),
    },
    {
      dataField: 'edit',
      isDummyField: true,
      text: '',
      formatter: (cell: any, row: ExtraProductInfo, rowIndex: any, extraData: any) => {
        if (hasWritePermission(Permission.AUKAVORUR)) {
          if (row.product_valid === 1 || (row.product_valid === 0 && row.end_date === null)) {
            return (
              <div
                role="button"
                onClick={() => {
                  setExtraProductInfo(row);
                  setShowEditExtraProductsModal(true);
                }}
                tabIndex={0}
                onKeyDown={() => {
                  setExtraProductInfo(row);
                  setShowEditExtraProductsModal(true);
                }}
              >
                <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faEdit} />
              </div>
            );
          }
        }
        if (row.product_valid === 0 && row.end_date !== null) {
          return <div>{t('studentView:productNotAvailable')}</div>;
        }
        return '';
      },
    },
    {
      dataField: 'delete',
      isDummyField: true,
      text: '',
      style: (cell: any, row: ExtraProductInfo, rowIndex: any, colIndex: any) => {
        if (row.product_valid === 0 && row.end_date !== null) {
          return { display: 'none' };
        }
        return { display: 'table-cell' };
      },
      formatter: (cell: any, row: ExtraProductInfo, rowIndex: any, colIndex: any) => {
        if (hasWritePermission(Permission.AUKAVORUR)) {
          if (row.product_valid === 1 || (row.product_valid === 0 && row.end_date === null)) {
            return (
              <div
                role="button"
                onClick={() => {
                  setExtraProductInfo(row);
                  setShowDeleteExtraProductsModal(true);
                }}
                tabIndex={0}
                onKeyDown={() => {
                  setExtraProductInfo(row);
                  setShowDeleteExtraProductsModal(true);
                }}
              >
                <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faTrashAlt} />
              </div>
            );
          }
        }
        return '';
      },
    },
  ];
  const updateLogColumns = [
    {
      dataField: 'created',
      formatter: dateTimeFormatter,
      text: t('logTables:created_date'),
    },
    {
      dataField: 'description',
      text: t('logTables:description'),
    },
    {
      dataField: 'new_value',
      text: t('logTables:new_value'),
    },
    {
      dataField: 'old_value',
      text: t('logTables:old_value'),
      formatter: infinityValueFormatter,
    },
    {
      dataField: 'created_name',
      text: t('logTables:created_name'),
    },
  ];
  return (
    <>
      {st && st[0] && (
        <>
          {showEditAllergiesModal && <EditStudentInfo originalValues={st[0]} attachments={attachmentList} onClose={onEditAllergiesModalClosed} />}
          {showEditPayerInfoModal && <EditPayerInfo originalValues={st[0]} onClose={onEditPayerInfoModalClosed} />}
          {showEditExtraProductsModal && <EditExtraProducts originalValues={extraProductInfo} onClose={onEditExtraProductsModalClosed} />}
          {showDeletetExtraProductsModal && <DeleteExtraProducts originalValues={extraProductInfo} onClose={onDeleteExtraProductsModalClosed} />}
          {showAddExtraProductModal && <AddExtraProduct originalValues={st[0]} onClose={onAddExtraProductsModalClosed} />}
          {showEditRegistrationDates && <EditRegistrationDates onClose={onEditRegistrationDatesModalClosed} originalValues={st[0]} />}
          {showDeleteAttachmentModal && selectedAttachmentToDelete && (
            <DeleteAttachmentFromStudent originalValues={selectedAttachmentToDelete} onClose={onDeleteAttachmentModalClosed} />
          )}
          <Row>
            <EqualizerContainer>
              <Col lg={6} md={6} sm={6} style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                <Col lg={12} md={12} sm={12} className="placement-column has-heading">
                  <Equalize>
                    <CardHeader color="pinkHeader">
                      <Row>
                        {/*   <Col lg={2} md={2} sm={2}>
                          <UploadImage image={NoUser} imageAltText={t('imageAlt')} />
                        </Col> */}
                        <Col lg={7} md={7} sm={7}>
                          <div className="cardHeader-title">{st[0].student_name}</div>
                          <div className="cardHeader-subText">
                            {st[0].student_ssn} <br />
                            {st[0].student_age} {t('studentView:age')}
                          </div>
                        </Col>
                        <Col lg={3} md={3} sm={3} className="pull-down">
                          <div className="float-right">
                            <div className="cardHeader-subText">
                              {st[0].school_name}
                              <br />
                              {st[0].class && st[0].class}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardHeader>
                    <div className="body equalizerScrollX">
                      <Row>
                        <Col>
                          <table className="table">
                            <tr>
                              <th style={{ borderTop: 'none' }}>{t('registrations:registration')}</th>
                              <td style={{ borderTop: 'none' }} colSpan={1}>
                                {t('registrations:foodRegistration')} &nbsp;
                                <span style={{ textTransform: 'lowercase' }}>{st[0].food_type}</span>
                              </td>
                              <td style={{ borderTop: 'none' }}>
                                {dateFormatter(st[0].registration_start_date)} - {dateFormatter(st[0].registration_end_date)}
                              </td>
                              <td style={{ borderTop: 'none' }}>
                                {hasWritePermission(Permission.SKRANINGAR) && (
                                  <div role="button" onClick={() => setShowEditRegistrationDates(true)} tabIndex={0} onKeyDown={() => setShowEditPayerInfoModal(true)}>
                                    <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faEdit} />
                                  </div>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>{t('registrations:payer')}</th>
                              <td>
                                <div>{st[0].payer_name}</div>
                                <div className="ssn-bold">{st[0].payer_ssn}</div>
                                <div>{st[0].payer_email}</div>
                              </td>
                              <td />
                              <td>
                                {hasWritePermission(Permission.SKRANINGAR) && (
                                  <div role="button" onClick={() => setShowEditPayerInfoModal(true)} tabIndex={0} onKeyDown={() => setShowEditPayerInfoModal(true)}>
                                    <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faEdit} />
                                  </div>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>{t('studentView:pin_number')}</th>
                              <td>{st[0].pin_number}</td>
                              <td />
                              <td />
                            </tr>
                          </table>
                        </Col>
                      </Row>
                    </div>
                  </Equalize>
                </Col>
              </Col>
              {hasReadPermission(Permission.OFNAEMISOTHOL) && (
                <Col lg={6} md={6} sm={6} style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                  <Col lg={12} md={12} sm={12} className="placement-column has-heading">
                    <Equalize>
                      <CardHeader color="greenHeader">
                        <div className="cardHeader-title">
                          {t('studentView:allergiesAndIntoleranceTitle')}
                          {hasWritePermission(Permission.SKRANINGAR) && hasWritePermission(Permission.OFNAEMISOTHOL) && (
                            <Button variant="link" style={{ color: 'white' }} className="addItemInCardHeader" onClick={() => setShowEditAllergiesModal(true)}>
                              <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faEdit} />
                            </Button>
                          )}
                        </div>
                      </CardHeader>
                      <div className="body equalizerScroll">
                        <Table className="table">
                          <tbody>
                            {st[0].allergies === 0 && (
                              <tr>
                                <th style={{ borderTop: 'none' }}>{t('registrations:allergies')}</th>
                                <td style={{ borderTop: 'none' }}>{formatBoolean(st[0].allergies)}</td>
                              </tr>
                            )}
                            {st[0].allergies === 1 && (
                              <tr>
                                <th style={{ borderTop: 'none' }}>{t('registrations:allergies_reason')}</th>
                                <td style={{ borderTop: 'none' }}>{st[0].allergies_reason}</td>
                              </tr>
                            )}
                            {st[0].intolerance === 0 && (
                              <tr>
                                <th>{t('registrations:intolerance')}</th>
                                <td>{formatBoolean(st[0].intolerance)}</td>
                              </tr>
                            )}
                            {st[0].intolerance === 1 && (
                              <tr>
                                <th>{t('registrations:intolerance_reason')}</th>
                                <td>{st[0].intolerance_reason}</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                        <div style={{ paddingTop: '1rem' }}>
                          <Table>
                            <thead>
                              <tr>
                                <th>{t('studentView:attachments')}</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {!loadingAttachments && attachmentList.length === 0 && (
                                <div style={{ textAlign: 'center', paddingTop: '1rem' }}>{t('studentView:noAttachmentUploaded')}</div>
                              )}
                              {!loadingAttachments &&
                                attachmentList.length > 0 &&
                                attachmentList.map((item: Attachment, itemIdx: number) => {
                                  return (
                                    <tr key={itemIdx}>
                                      <td>
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                            <Tooltip id="button-tooltip">
                                              <div>
                                                <span>Skráð af: {item.created_by_name} </span>
                                                <span>Dags: {dateFormatter(item.created_date)}</span>
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <Button variant="link" onClick={() => loadFile(item)} style={{ paddingLeft: 0 }}>
                                            {item.name}
                                          </Button>
                                        </OverlayTrigger>
                                      </td>
                                      <td>
                                        <Button
                                          variant="link"
                                          onClick={() => {
                                            setSelectedAttachmentToDelete(item);
                                            setShowDeleteAttachmentModal(true);
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faTrashAlt} style={{ color: '#e74a3b' }} />
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Equalize>
                  </Col>
                </Col>
              )}
            </EqualizerContainer>
          </Row>
          <Row>
            <EqualizerContainer>
              <Col lg={6} md={6} sm={6} style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                <Col lg={12} md={12} sm={12} className="placement-column has-heading">
                  <Equalize>
                    <CardHeader color="purpleHeader">
                      <>
                        <div className="cardHeader-title">{t('studentView:extraProducts')}</div>
                        {hasWritePermission(Permission.AUKAVORUR) && st[0].has_extra_product === 1 && (
                          <div
                            role="button"
                            tabIndex={0}
                            className="addItemInCardHeader"
                            onClick={() => setShowAddExtraProductModal(true)}
                            onKeyDown={() => setShowAddExtraProductModal(true)}
                          >
                            <FontAwesomeIcon className="nav-link-icon" icon={faPlus} fixedWidth />
                          </div>
                        )}
                      </>
                    </CardHeader>
                    <div className="body equalizerScroll">
                      {st[0].has_extra_product === 1 ? (
                        <BootstrapTable
                          bootstrap4
                          keyField="registration_extra_id"
                          data={st[0].registration_extra}
                          columns={extraProductsColumn}
                          noDataIndication={t('studentView:noExtraProductsRegistered')}
                          striped
                          bordered={false}
                        />
                      ) : (
                        <div style={{ textAlign: 'center' }}>{t('studentView:noExtraProductsAvailable')}</div>
                      )}
                    </div>
                  </Equalize>
                </Col>
              </Col>
              <Col lg={6} md={6} sm={6} style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                <Col lg={12} md={12} sm={12} className="placement-column has-heading">
                  <Equalize>
                    <CardHeader color="pinkHeader">
                      <div className="cardHeader-title">{t('studentView:billing')}</div>
                    </CardHeader>
                    <div className="body equalizerScroll">
                      <ChooseMonthYear registration_id={id} />
                    </div>
                  </Equalize>
                </Col>
              </Col>
            </EqualizerContainer>
          </Row>
          {hasReadPermission(Permission.BREYTINGARSAGA) && (
            <Row>
              <Col lg={12} md={12} sm={12} style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                <Col lg={12} md={12} sm={12} className="placement-column has-heading">
                  <CardHeader color="greenHeader">
                    <div className="cardHeader-title">{t('studentView:changelog')}</div>
                  </CardHeader>
                  <div className="whiteColumn">
                    <BootstrapTable bootstrap4 keyField="created_date" data={st[0].update_log} columns={updateLogColumns} pagination={pagination} striped bordered={false} />
                  </div>
                </Col>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};
export default ViewStudent;
