import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { BatchType } from '../api/invoicing.types';
import { deleteRecords, recallBatchFromExternalSystem } from '../api/billing';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/js/src/collapse.js';
import ConfirmBatchModal from '../views/ConfirmBatchModal';
import OpenBatchModal from '../views/OpenBatchModal';
import { useAppDispatch, useAppSelector } from '../hooks/useState';
import { batchSummariesSelector, fetchBatchSummaries, fetchBatch } from '../store/invoicing/invoicingSlice';
import TableRowExpanded from './TableRowExpanded';
import AddDiscountChildMultiModal from '../views/AddDiscountChildMultiModal';
import AddMultiChildrenDiscountModal from '../views/AddMultiChildrenDiscountModal';
import { AddMultiStudentType } from '../api/billing.types';

interface Props {
  rowData: BatchType;
}
const TableRow = ({ rowData: batchData }: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const batchSummary = useAppSelector((state) => batchSummariesSelector.selectById(state, batchData.batch_id));
  const batchLoading = useAppSelector((state) => state.invoicing.batchLoading);
  const batchSummariesLoading = useAppSelector((state) => state.invoicing.batchSummariesLoading);
  // const batchSummariesError = useAppSelector((state) => state.invoicing.batchSummariesError);

  const { t } = useTranslation(['invoiceView', 'common', 'errors']);
  const [loading, setLoading] = useState(false);
  const [showConfirmBatchModal, setShowConfirmBatchModal] = useState<boolean>(false);
  const [showAddDiscountChildMultiModal, setShowAddDiscountChildMultiModal] = useState<boolean>(false);
  const [showAddMultiChildrenDiscountModal, setShowAddMultiChildrenDiscountModal] = useState<boolean>(false);
  const [showOpenBatchModal, setShowOpenBatchModal] = useState<boolean>(false);
  const [expandedStudent, setExpandedStudent] = useState<string[]>([]);

  const fetchBatchData = useCallback(async () => {
    dispatch(fetchBatch(batchData.batch_id));
  }, [dispatch, batchData.batch_id]);

  // fetch batch summary (students from school)
  const fetchBatchSummary = useCallback(
    async (str?: string) => {
      const fetchBatchSummariesResponse = await dispatch(
        fetchBatchSummaries({
          batchId: batchData.batch_id,
          str,
        })
      );

      if (fetchBatchSummaries.fulfilled.match(fetchBatchSummariesResponse)) {
        if (fetchBatchSummariesResponse.payload.summary.length === 0) {
          await fetchBatchData();
        }
      } else if (fetchBatchSummariesResponse.payload) {
        toast.error(`${t('common:informationError')}. ${fetchBatchSummariesResponse.payload}.`);
      } else {
        toast.error(`${t('common:informationError')}.`);
      }
    },
    [fetchBatchData, t, dispatch, batchData.batch_id]
  );

  useEffect(() => {
    // while running a batch we call for the batch summary every 10 seconds to update the table with data from the service
    if (batchData && batchData.status === 6) {
      const interval = setInterval(() => {
        fetchBatchData();
        fetchBatchSummary();
      }, 10000);
      return () => clearInterval(interval);
    }
    return () => null;
  }, [batchData, fetchBatchData, fetchBatchSummary]);

  // Use when button is used to run

  const deleteBatchRecords = useCallback(
    async (batch_id: string) => {
      setLoading(true);
      try {
        await deleteRecords(batch_id);
        fetchBatchData();
      } catch (e) {
        if (e.response) {
          toast.error(e.response.data.error);
        }
      } finally {
        setLoading(false);
      }
    },
    [fetchBatchData]
  );

  const sendRecallBatchFromExternalSystem = useCallback(
    async (batch_id: string) => {
      setLoading(true);
      try {
        const response = await recallBatchFromExternalSystem(batch_id);
        await fetchBatchData();
        await fetchBatchSummary();
        if (!response.data.successful) {
          toast.error(response.data.error);
        }
      } catch (e) {
        if (e.response) {
          toast.error(e.response.data.error);
        }
      }
    },
    [fetchBatchSummary, fetchBatchData]
  );
  const handleExpandStudent = (event: React.MouseEvent, row_id: string) => {
    const currentExpandedStudent = expandedStudent;
    const isStudentExpanded = currentExpandedStudent.includes(row_id);
    const obj = {} as any;
    if (isStudentExpanded) {
      obj[row_id] = false;
    } else {
      obj[row_id] = true;
    }

    if (!isStudentExpanded) {
      const newExpandedStudent = currentExpandedStudent.splice(1).concat(row_id);
      setExpandedStudent(newExpandedStudent);
    } else {
      const newExpandedStudent = currentExpandedStudent.filter((id) => id !== row_id);
      setExpandedStudent(newExpandedStudent);
    }
  };

  const onConfirmBatchModalClosed = useCallback(
    async (confirmed: boolean) => {
      setShowConfirmBatchModal(false);
      setLoading(true);
      try {
        if (confirmed) {
          await fetchBatchData();
          await fetchBatchSummary();
        }
      } finally {
        setLoading(false);
      }
    },
    [fetchBatchData, fetchBatchSummary]
  );
  const onOpenBatchModalClosed = (open: boolean) => {
    setShowOpenBatchModal(false);

    if (open) {
      fetchBatchData();
    }
  };

  const onAddDiscountChildMultihModalClosed = useCallback(
    async (confirmed: boolean) => {
      setShowAddDiscountChildMultiModal(false);
      setLoading(true);
      try {
        if (confirmed) {
          await fetchBatchData();
          await fetchBatchSummary();
        }
      } finally {
        setLoading(false);
      }
    },
    [fetchBatchData, fetchBatchSummary]
  );

  const onAddMultiChildrenDiscountModalClosed = useCallback(
    async (confirmed: boolean) => {
      setShowAddMultiChildrenDiscountModal(false);
      setLoading(true);
      try {
        if (confirmed) {
          await fetchBatchData();
          await fetchBatchSummary();
        }
      } finally {
        setLoading(false);
      }
    },
    [fetchBatchData, fetchBatchSummary]
  );


  const debouncedFilter = useCallback(
    debounce((filterValues: string) => fetchBatchSummary(filterValues), 1000),
    [fetchBatchSummary]
  );

  const onSearchChanged = (value: string) => {
    debouncedFilter(value);
  };

  const formattedBatchSummaryInfoForMultiDiscount: AddMultiStudentType[] | undefined = batchSummary?.summary.map((i) => {
    return { student: i.student_id, student_name: i.student_name, student_ssn: i.student_ssn, discount: '0', selected: false };
  });

  return (
    <>
      {showConfirmBatchModal && <ConfirmBatchModal batch={batchData} onClose={onConfirmBatchModalClosed} />}
      {showAddDiscountChildMultiModal && <AddDiscountChildMultiModal batch={batchData} onClose={onAddDiscountChildMultihModalClosed} />}
      {showAddMultiChildrenDiscountModal && (
        <AddMultiChildrenDiscountModal batch={batchData} batchSummary={formattedBatchSummaryInfoForMultiDiscount} onClose={onAddMultiChildrenDiscountModalClosed} />
      )}
      {showOpenBatchModal && <OpenBatchModal batch={batchData} onClose={onOpenBatchModalClosed} />}

      {batchData?.billing_error && !loading && (
        <div className="p-2">
          <Alert variant="danger" className="alert-box">
            <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '5px' }} />
            <span>{batchData.billing_error}</span>
          </Alert>
        </div>
      )}
      <div className="p-2">
        <TableRowExpanded
          batchData={batchData}
          batchSummary={batchSummary?.summary}
          setShowConfirmBatchModal={setShowConfirmBatchModal}
          setShowAddDiscountChildMultiModal={setShowAddDiscountChildMultiModal}
          setShowOpenBatchModal={setShowOpenBatchModal}
          setShowAddMultiChildrenDiscountModal={setShowAddMultiChildrenDiscountModal}
          deleteBatchRecords={deleteBatchRecords}
          sendRecallBatchFromExternalSystem={sendRecallBatchFromExternalSystem}
          onSearchChanged={onSearchChanged}
          loading={loading}
          expandedStudent={expandedStudent}
          handleExpandStudent={handleExpandStudent}
          fetchBatchSummary={fetchBatchSummary}
        />
      </div>

      {batchLoading[batchData.batch_id] === 'pending' ||
        (batchSummariesLoading[batchData.batch_id] === 'pending' && (
          <div className="spinner">
            <Spinner animation="border" role="status" size="sm">
              <span className="sr-only">{t('common:loading')}</span>
            </Spinner>
          </div>
        ))}
    </>
  );
};
export default TableRow;
