import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRecycle } from '@fortawesome/free-solid-svg-icons';

import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { RegistrationsType, SchoolListItem, BirthYear, RegistrationTabKeysType } from '../../api/registrations.types';
import { AppDispatch } from '../../store/store';
import { birthYearsSelector, fetchBirthYearList, fetchSchoolList, schoolsSelector } from './registrationsSlice';

interface Props {
  selectedKey: RegistrationTabKeysType;
  disabled: boolean;
  filter: RegistrationsType;
  searchValue: string;
  setSearchValue: (v: string) => void;
  setFilter: (f: RegistrationsType) => void;
  loadRegistrations: (k: RegistrationTabKeysType, f: RegistrationsType) => void;
  selectBirthYearValue: BirthYear[] | undefined;
  setSelectBirthYearValue: (o: BirthYear[]) => void;

  selectSchoolValue: SchoolListItem[] | undefined;
  setSelectSchoolValue: (o: SchoolListItem[]) => void;
}

const RegistrationsFilters: React.FC<Props> = ({
  selectedKey,
  disabled,
  filter,
  searchValue,
  setSearchValue,
  setFilter,
  loadRegistrations,
  selectBirthYearValue,
  setSelectBirthYearValue,
  selectSchoolValue,
  setSelectSchoolValue,
}) => {
  const { t } = useTranslation('registrations');
  const dispatch = useDispatch<AppDispatch>();

  const birthYears = useSelector(birthYearsSelector.selectAll);
  const schoolList = useSelector(schoolsSelector.selectAll);

  useEffect(() => {
    dispatch(fetchSchoolList())
      .unwrap()
      .then((newSchoolList) => {
        if (selectSchoolValue) {
          selectSchoolValue.map((s) => {
            if (s.id === s.name) {
              // eslint-disable-next-line no-param-reassign, eqeqeq
              s.name = newSchoolList.find((n) => n.id == s.id)?.name || s.id;
            }

            return s;
          });
        }
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchBirthYearList(selectedKey));
  }, [dispatch, selectedKey]);

  const onSearch = (filterValues: RegistrationsType) => {
    loadRegistrations(selectedKey, filterValues);
  };

  const debouncedSearch = useMemo(() => debounce(onSearch, 500), [selectedKey]);

  const onSearchChanged = (value: Partial<RegistrationsType>) => {
    const newFilter: RegistrationsType = {
      ...filter,
      ...value,
    };

    setFilter(newFilter);
    debouncedSearch(newFilter);
  };

  const resetFilters = () => {
    const newFilter = { str: '', years: '', schools: '', allergies: '', intolerance: '', extra_products: '' };
    setFilter(newFilter);
    setSelectBirthYearValue([]);
    setSelectSchoolValue([]);
    setSearchValue('');
    onSearch(newFilter);
  };

  return (
    <Form>
      <Row>
        <Col>
          <Form.Group controlId="search">
            <Form.Control
              type="text"
              // disabled={disabled}
              placeholder={t('common:search')}
              autoComplete="off"
              onChange={(e) => {
                setSearchValue(e.currentTarget.value);
                onSearchChanged({ str: e.currentTarget.value });
              }}
              value={searchValue}
            />
          </Form.Group>
        </Col>
        <Col>
          <Select<BirthYear, true>
            placeholder={t('birthYear')}
            options={birthYears}
            getOptionLabel={(b) => b.get_birth_year}
            getOptionValue={(b) => b.get_birth_year}
            isMulti
            className="basic-multi-select"
            classNamePrefix={t('birthYear')}
            isClearable
            // isDisabled={disabled}
            value={selectBirthYearValue}
            closeMenuOnSelect={false}
            onChange={(e) => {
              setSelectBirthYearValue(e.map((v) => v));
              onSearchChanged({ years: e.map((v) => v.get_birth_year).join() });
            }}
          />
        </Col>
        <Col>
          <Select<SchoolListItem, true>
            placeholder={t('schools')}
            options={schoolList}
            getOptionLabel={(b) => b.name}
            getOptionValue={(b) => b.id}
            isMulti
            className="basic-multi-select"
            classNamePrefix={t('schools')}
            isClearable
            closeMenuOnSelect={false}
            // isDisabled={disabled}
            value={selectSchoolValue}
            onChange={(e) => {
              setSelectSchoolValue(e.map((v) => v));
              onSearchChanged({ schools: e.map((v) => v.id).join() });
            }}
          />
        </Col>
        {selectedKey !== 'notRegistered' && (
          <Col style={{ paddingTop: '0.5rem' }}>
            <span style={{ paddingRight: '1rem', paddingTop: '1rem' }}>
              <input
                type="checkbox"
                // disabled={disabled}
                checked={filter.allergies === '1'}
                onChange={(e) => {
                  onSearchChanged({ allergies: e.currentTarget.checked ? '1' : '0' });
                }}
              />
              &nbsp;
              {t('allergies_reason')}
            </span>
            <span style={{ paddingRight: '1rem', paddingTop: '1rem' }}>
              <input
                type="checkbox"
                // disabled={disabled}
                checked={filter.intolerance === '1'}
                onChange={(e) => {
                  onSearchChanged({ intolerance: e.currentTarget.checked ? '1' : '0' });
                }}
              />
              &nbsp;
              {t('intolerance_reason')}
            </span>
            <span>
              <input
                type="checkbox"
                // disabled={disabled}
                checked={filter.extra_products === '1'}
                onChange={(e) => {
                  onSearchChanged({ extra_products: e.currentTarget.checked ? '1' : '0' });
                }}
              />
              &nbsp;
              {t('extra_products')}
            </span>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Button title="Hreinsa síur" size="sm" className="pull-right" onClick={resetFilters}>
            <FontAwesomeIcon fixedWidth icon={faRecycle} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default RegistrationsFilters;
