import React, { useMemo } from 'react';

import { Button, Form, Modal } from 'react-bootstrap';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { restValidationToForm } from '../utils/formUtils';
import DatePicker from '../components/DatePicker';
import { updateRegisteredExtraProduct } from '../api/registrations';

interface Values {
  end_date: Date | undefined;
  start_date: Date | undefined;
  registration_extra_id: number | undefined;
}

interface Props {
  onClose: (created: boolean) => void;
  originalValues: any;
}
const EditExtraProducts = ({ originalValues, onClose }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['editStudentViewModals', 'editExtraProducts', 'registrations']);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      start_date: Yup.string().required(t('errors:emptyInputField')).nullable(),
    });
  }, [t, i18n.language]);

  const initialValues: Values = {
    end_date: originalValues.end_date === null ? undefined : dayjs(originalValues.end_date).toDate(),
    registration_extra_id: originalValues.registration_extra_id,
    start_date: dayjs(originalValues.start_date).toDate(),
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<Values>) => {
          try {
            await updateRegisteredExtraProduct({
              ...values,
              end_date: values.end_date === undefined ? undefined : dayjs(values.end_date).format('DD.MM.YYYY'),
              start_date: dayjs(values.start_date).format('DD.MM.YYYY'),
            });

            toast.success(t('common:informationChangedSuccess'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
            toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{t('editExtraProducts:editExtraProductsTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <table className="table">
                  <tbody>
                    <tr>
                      <td style={{ borderTop: 'none' }}>
                        <Form.Group controlId="product">
                          <Form.Label>{t('editExtraProducts:product')}</Form.Label>
                          <Form.Text>
                            <span style={{ fontSize: '19px' }}>{originalValues.product_name}</span>
                          </Form.Text>
                        </Form.Group>
                      </td>
                      <td style={{ borderTop: 'none' }}>
                        <Form.Group controlId="startDay">
                          <Form.Label>
                            {t('registrations:startOfRegistration')}
                            <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <div className="dateWrapper">
                            <DatePicker
                              style={{ display: 'inline', width: '100%' }}
                              value={values.start_date}
                              selectedDay={values.start_date}
                              onDayChange={(day: Date | undefined) => {
                                setFieldValue('start_date', day || null);
                              }}
                            />
                          </div>
                          <ErrorMessage name="start_date" component="div" className="invalid-feedback d-block text-left" />
                        </Form.Group>
                      </td>
                      <td style={{ borderTop: 'none' }}>
                        <Form.Group controlId="endDate">
                          <Form.Label>{t('registrations:endOfRegistration')}</Form.Label>
                          <div className="dateWrapper">
                            <DatePicker
                              style={{ display: 'inline', width: '100%' }}
                              selectedDay={values.end_date}
                              month={values.end_date}
                              value={values.end_date}
                              onDayChange={(day: Date) => {
                                setFieldValue('end_date', day);
                              }}
                            />
                          </div>
                        </Form.Group>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:submit')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default EditExtraProducts;
