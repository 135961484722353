/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import { getVersions } from '../../api/version';
import { VersionsTextList } from '../../api/versions.types';

interface State {
  text: any | null;
  textLoading: 'idle' | 'pending';
  textError: null | SerializedError;
}

export const fetchPublishingText = createAsyncThunk<
  VersionsTextList,
  void,
  {
    state: RootState;
  }
>('versions/versions', async () => {
  const response = await getVersions();
  return response.data;
});

const initialState: State = {
  text: null,
  textLoading: 'idle',
  textError: null,
};

const slice = createSlice({
  name: 'getPublishingText',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /*
     * fetchUsers
     */
    builder.addCase(fetchPublishingText.pending, (state) => {
      state.text = null;
      state.textError = null;
      state.textLoading = 'pending';
    });
    builder.addCase(fetchPublishingText.fulfilled, (state, { payload }) => {
      state.text = payload;
      state.textLoading = 'idle';
    });
    builder.addCase(fetchPublishingText.rejected, (state, action) => {
      state.textError = action.error;
      state.textLoading = 'idle';
    });
  },
});

export default slice.reducer;
