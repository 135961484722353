import React, { useEffect, useState } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ISLAND_URL } from '../config';
// import islandLogo from '../assets/images/islandislogo.png';
import Logo from '../assets/vala-logo.svg';
import FoodImg from '../assets/images/matur.png';
import { checkLogin } from '../api/account';
import TokenStorage from '../utils/tokenUtils';
import { AppDispatch } from '../store/store';
import { initializeAuthCompleted } from '../store/auth/authSlice';
import { CheckLoginResponse } from '../api/account.types';

const Login: React.FC = () => {
  const { t } = useTranslation('login');
  const dispatch = useDispatch<AppDispatch>();
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const [initialized, setInitialized] = useState(false);
  const [urlToken, setUrlToken] = useState<string>();
  const [tokenResponse, setTokenResponse] = useState<CheckLoginResponse>();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const island = () => {
    window.location.href = ISLAND_URL;
  };

  const doCheckToken = async (token: string, companyId?: number) => {
    setInitialized(false);
    setTokenResponse(undefined);
    setUrlToken(undefined);

    try {
      const response = await checkLogin(token, companyId || null);

      if (response.data.successful === false) {
        throw new Error('successful false');
      }

      if (response.data.token) {
        TokenStorage.storeAccessToken(response.data.token);
        TokenStorage.storeAccessTokenExpire(response.data.timeleft);
        dispatch(initializeAuthCompleted(response.data.token));
        history.push('/');
      } else {
        setUrlToken(token);
        setTokenResponse(response.data);

        if (response.data.company_list.length === 1) {
          doCheckToken(token, response.data.company_list[0].id);
        } else {
          setInitialized(true);
        }
      }
    } catch (e) {
      setError(true);
      setInitialized(true);
    }
  };

  useEffect(() => {
    const token = query.get('token');
    const err = query.get('err');

    if (err !== null) {
      setError(true);
      setErrorMessage(err);
    }
    if (token) {
      doCheckToken(token);
    } else {
      setInitialized(true);
    }
  }, []);

  if (!initialized) {
    return null;
  }

  if (tokenResponse && urlToken) {
    return (
      <Container className="login company pt-5">
        <div className="logo-container">
          <img src={Logo} alt="Vala logo" className="logo logoPadding" />
          <span style={{ textTransform: 'uppercase' }}>Vala</span>
          <strong className="text-center mt-3 d-block" style={{ textTransform: 'uppercase' }}>
            {t('schoolMeal')}
          </strong>
        </div>
        <div className="companyList-wrapper">
          <div className="chooseCompany">{t('chooseCompany')}</div>
          <ul className="companyList">
            {tokenResponse.company_list.map((c) => (
              <li key={c.id}>
                <button type="button" onClick={() => doCheckToken(urlToken, c.id)}>
                  {c.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    );
  }

  return (
    <Container className="login pt-5">
      {error && <Alert variant="danger">{errorMessage !== '' ? errorMessage : t('errorMessage')}</Alert>}
      <div className="logo-container">
        <img src={Logo} alt="Vala logo" className="logoPadding logo" />
        <span style={{ textTransform: 'uppercase' }}>Vala</span>
      </div>
      <strong className="text-center mt-3 d-block" style={{ textTransform: 'uppercase' }}>
        {t('schoolMeal')}
      </strong>
      <div className="row" style={{ justifyContent: 'center' }}>
        <div className="col-md-9 col-lg-10 col-xl-10 all-cols">
          <div className="letter-box">
            <div className="row">
              <img src={FoodImg} alt={t('schoolMeal')} className="col-md-6 col-lg-6 hidden-sm hidden-xs foodImg" />
              <div className="col-md-6 col-lg-6 col-xs-12">
                <div className="inner-box">
                  <span className="title">{t('applicationSite')}</span>
                  <Button variant="primary" className="w-100" onClick={island}>
                    <span className="d-inline-block mt-1">{t('icekeylogin')}</span>
                    {/* <img src={islandLogo} alt="Ísland.is" height="30" style={{ marginTop: '-5px' }} className="pr-2" /> */}
                  </Button>
                  <hr className="sidebar-divider" />
                  <a href="https://www.vala.is/" rel="noopener noreferrer">
                    {t('backToVala')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Login;
