import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { restValidationToForm } from '../utils/formUtils';
import { getClasses } from '../api/bulkDiscounts';
import { getNatReg } from '../api/users';
import { DropdownType } from '../api/bulkDiscounts.types';
import { registerNamfusStudent } from '../api/registrations';
import { getSchoolsList } from '../api/schools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface Values {
  school_ssn: string;
  student_ssn: string | undefined;
  class: string;
  namfus_name: string | undefined;
}

const CreateNewStudentFromNamfus = (): React.ReactElement => {
  const { t, i18n } = useTranslation(['addNewStudentsFromNamfus', 'errors', 'common']);
  const history = useHistory();
  const [loadingClasses, setLoadingClasses] = useState<boolean>(false);
  const [loadingSchools, setLoadingSchools] = useState<boolean>(false);
  const [loadingSSN, setLoadingSSN] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [schoolList, setSchoolList] = useState<DropdownType[]>();
  const [selectedSchool, setSelectedSchool] = useState<DropdownType>();
  const [classesList, setClassesList] = useState<DropdownType[]>();
  const [selectedClass, setSelectedClass] = useState<DropdownType | null>();
  const [studentData, setStudentData] = useState<{ name: string; student_ssn: string | undefined }>();

  const loadClasses = useCallback(
    async (school_id: string) => {
      setLoadingClasses(true);
      setError(false);
      try {
        const response = await getClasses(school_id);
        setClassesList(
          response.data.items.map((item: { class: string }, idx: number | null) => ({
            value: idx,
            label: item.class,
          }))
        );
      } catch {
        setError(true);
      } finally {
        setLoadingClasses(false);
      }
    },
    [selectedSchool]
  );

  const loadSchoolList = useCallback(async () => {
    setLoadingSchools(true);
    setError(false);
    try {
      const response = await getSchoolsList({ str: '', enabled: null });
      setSchoolList(
        response.data.items.map((item: any) => ({
          value: item.id,
          label: item.name,
          extraValue: item.ssn,
        }))
      );
      if (response.data.items[0].id !== null) {
        setSelectedSchool({ value: response.data.items[0].id, label: response.data.items[0].name, extraValue: response.data.items[0].ssn });
        loadClasses(response.data.items[0].id.toString());
      }
    } catch {
      setError(true);
    } finally {
      setLoadingSchools(false);
    }
  }, []);

  const getSSNName = useCallback(async (ssn: string) => {
    setLoadingSSN(true);
    setError(false);
    try {
      const response = await getNatReg({ ssn });
      const data = response.data.items[0];
      if (data) {
        setStudentData({ ...studentData, student_ssn: data.ssn, name: data.name });
      } else {
        setStudentData({ ...studentData, name: t('errors:ssnInvalid'), student_ssn: '' });
      }
    } catch (e) {
      setError(true);
      console.error(e);
    } finally {
      setLoadingSSN(false);
    }
  }, []);

  useEffect(() => {
    loadSchoolList();
  }, [loadSchoolList]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      school_ssn: Yup.string().required(t('errors:emptyInputField')),
      student_ssn: Yup.string().required(t('errors:emptyInputField')).min(10, t('errors:ssnInvalidForm')).max(10, t('errors:ssnInvalidForm')),
      class: Yup.string().required(t('errors:emptyInputField')),
      namfus_name: Yup.string().required(t('errors:emptyInputField')),
    });
  }, [t, i18n.language]);

  const initialValues: Values = {
    school_ssn: selectedSchool?.extraValue || '',
    student_ssn: studentData?.student_ssn,
    class: selectedClass?.label || '',
    namfus_name: studentData?.name,
  };

  const debouncedSchoolSearch = useCallback(
    debounce((school_id: string) => loadClasses(school_id), 1000),
    [loadClasses]
  );

  const onSelectSchoolChanged = (value: string) => {
    debouncedSchoolSearch(value);
  };

  const debouncedSSN = useCallback(
    debounce((ssn: string) => getSSNName(ssn), 1000),
    []
  );
  const onCheckSSN = (value: Partial<string>) => {
    debouncedSSN(value);
  };

  return (
    <>
      <Alert variant="warning" className="alert-box">
        <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '5px' }} />
        <span>{t('addNewStudentsFromNamfus:onlyNamfusSchoolsAllowedMessage')}</span>
      </Alert>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await registerNamfusStudent(values);
            toast.success(t('studentCreated'));
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
            toast.error(`${t('common:informationChangedError')}. ${e.response.data.error ? e.response.data.error : ''}`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Form
            onReset={handleReset}
            onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
              setStatus('submitted');
              handleSubmit(e);
            }}
          >
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('student_ssn')}
                    <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    key="student_ssn"
                    type="text"
                    onChange={(e) => {
                      if (e.currentTarget.value.length === 10) {
                        onCheckSSN(e.currentTarget.value);
                      } else if (e.currentTarget.value.length > 10 || e.currentTarget.value.length < 10) {
                        setStudentData({ name: 'Kennitala er á röngu formi', student_ssn: e.currentTarget.value });
                      } else {
                        setStudentData({ name: '', student_ssn: '' });
                      }
                      setFieldValue('student_ssn', e.currentTarget.value);
                    }}
                  />
                  <ErrorMessage name="student_ssn" component="div" className="invalid-feedback d-block text-left" />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {t('school_ssn')}
                    <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Select
                    options={schoolList}
                    className="basic-multi-select"
                    value={selectedSchool}
                    onChange={(e) => {
                      if (e) {
                        setSelectedSchool(e);
                        setFieldValue('school_ssn', e.extraValue);
                        setFieldValue('class', '');
                        setSelectedClass(null);
                        onSelectSchoolChanged(e.value.toString());
                        setLoadingClasses(true);
                      }
                    }}
                    disabled={loadingSchools}
                  />
                  <ErrorMessage name="school_ssn" component="div" className="invalid-feedback d-block text-left" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('namfus_name')}
                    <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control type="text" disabled value={studentData?.name} />
                  <ErrorMessage name="namfus_name" component="div" className="invalid-feedback d-block text-left" />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {t('class')}
                    <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Select
                    isDisabled={loadingClasses}
                    options={classesList}
                    className="basic-multi-select"
                    value={selectedClass}
                    placeholder="Veljið bekk"
                    onChange={(e) => {
                      if (e) {
                        setFieldValue('class', e.label);
                        setSelectedClass(e);
                      }
                    }}
                  />
                  <ErrorMessage name="class" component="div" className="invalid-feedback d-block text-left" />
                </Form.Group>
              </Col>
            </Row>

            <div className="pull-right">
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                }}
              >
                {t('common:submit')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateNewStudentFromNamfus;
