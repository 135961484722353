import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import { restValidationToForm } from '../utils/formUtils';
import { updateDiscountType } from '../api/billing';
import { DiscountType } from '../api/billing.types';

interface Props {
  onClose: (created: boolean) => void;
  originalValues: DiscountType;
}

interface Values {
  enabled: string;
  name: string;
  description: string;
}

const EnableDiscountTypeModal = ({ onClose, originalValues }: Props): React.ReactElement => {
  const { t } = useTranslation(['discountTypes', 'errors']);

  const initialValues: Values = {
    enabled: originalValues.enabled,
    name: originalValues.name,
    description: originalValues.description,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await updateDiscountType({ ...values, enabled: '1' }, originalValues.discount_type_id);
            toast.success(t('discountTypeEnabled'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('enableDiscountType')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Form.Group controlId="makeSure">
                  <Form.Label>{t('makeSureEnable', { name: originalValues.name })}</Form.Label>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:submit')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default EnableDiscountTypeModal;
