import React, { useEffect } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import dayjs from 'dayjs';
import { restValidationToForm } from '../utils/formUtils';
import { createTariffPeriod } from '../api/tariffs';
import { TariffPeriodType } from '../api/tariffs.types';

interface Props {
  onClose: (created: boolean) => void;
  lastTariff: TariffPeriodType | undefined;
}

interface Values {
  year_from: string;
  month_from: number;
  year_to: string;
  month_to: number;
}

const CreateNewTariffModal = ({ onClose, lastTariff }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['tariff', 'common']);

  const months = dayjs.months();

  const getYear = lastTariff !== undefined && dayjs(lastTariff.valid_to).month() === 11 ? new Date().getFullYear() + 1 : new Date().getFullYear();
  const years = Array.from(new Array(10), (val, index) => index + getYear - 1);

  const getMonthFrom = (lt: TariffPeriodType): number => {
    if (dayjs(lt.valid_to).month() === 11) {
      return 1;
    }
    return dayjs(lt.valid_to).month() + 2;
  };

  const getYearFrom = (lt: TariffPeriodType): string => {
    if (dayjs(lt.valid_to).month() === 11) {
      return (dayjs(lt?.valid_to).year() + 1).toString();
    }
    return dayjs(lt?.valid_to)
      .year()
      .toString();
  };

  const initialValues = {
    year_from: lastTariff !== undefined ? getYearFrom(lastTariff) : getYear.toString(),
    month_from: lastTariff !== undefined ? getMonthFrom(lastTariff) : 1,
    year_to: lastTariff !== undefined ? getYearFrom(lastTariff) : getYear.toString(),
    month_to: lastTariff !== undefined ? getMonthFrom(lastTariff) : 1,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await createTariffPeriod(values);
            toast.success(t('tariffCreated'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
            toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('createNewTariffTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Form.Group controlId="dateFrom">
                  <Form.Label>{t('start_date')}</Form.Label>
                  <Row>
                    <Col>
                      <Form.Control
                        as="select"
                        disabled={lastTariff !== undefined}
                        value={values.month_from}
                        onChange={(event) => {
                          setFieldValue('month_from', event.target.value);
                        }}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {months.map((month: string, index: number) => {
                          return (
                            <option key={month} value={index + 1}>
                              {month}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                    <Col>
                      <Form.Control
                        as="select"
                        value={values.year_from}
                        disabled={lastTariff !== undefined}
                        onChange={(event) => {
                          setFieldValue('year_from', event.target.value);
                        }}
                      >
                        {years.map((y) => {
                          return (
                            <option key={y} value={y}>
                              {y}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group controlId="dateTo">
                  <Form.Label>{t('end_date')}</Form.Label>
                  <Row>
                    <Col>
                      <Form.Control
                        as="select"
                        value={values.month_to}
                        onChange={(event) => {
                          setFieldValue('month_to', parseInt(event.target.value, 10));
                        }}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {months.map((month: string, index: number) => {
                          return (
                            <option key={month} value={index + 1}>
                              {month}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                    <Col>
                      <Form.Control
                        as="select"
                        value={values.year_to}
                        onChange={(event) => {
                          setFieldValue('year_to', event.target.value);
                        }}
                      >
                        {years.map((y) => {
                          return (
                            <option key={y} value={y}>
                              {y}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <ErrorMessage name="month_to" component="div" className="invalid-feedback d-block text-left" />
                  </Row>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:create')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default CreateNewTariffModal;
