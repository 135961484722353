import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Spinner } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { currencyFormatter, dateFormatter, formatPercentage } from '../utils/tableUtils';
import { getRegistrationBilling } from '../api/registrations';
import { Billing } from '../api/registrations.types';

interface Props {
  registration_id: string;
}
const ChooseMonthYear = ({ registration_id }: Props): React.ReactElement => {
  const { t } = useTranslation(['chooseMonthYear']);
  const months = dayjs.months();

  const [monthYear, setMonthYear] = useState<{ month: number; year: number }>({
    month: dayjs().get('month'),
    year: dayjs().get('year'),
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<Billing[]>();

  const loadBillingInfo = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const values = { month: `0${monthYear.month + 1}`.slice(-2), year: monthYear.year, registration_id };
      const response = await getRegistrationBilling(values);
      setData(response.data.items);
      // onChange(true);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [monthYear.month, monthYear.year, registration_id]);

  useEffect(() => {
    loadBillingInfo();
  }, [loadBillingInfo, monthYear]);

  const billingInfo = [
    {
      dataField: 'description',
      text: t('description'),
    },
    {
      dataField: 'percentage',
      formatter: formatPercentage,
      text: '%',
    },
    {
      dataField: 'item_date_from',
      formatter: dateFormatter,
      text: t('item_date_from'),
    },
    {
      dataField: 'item_date_to',
      formatter: dateFormatter,
      text: t('item_date_to'),
    },
    {
      dataField: 'amount',
      text: t('amount'),
      formatter: currencyFormatter,
    },
  ];
  return (
    <>
      <div className="chooseMonthYear">
        <Button
          variant="default"
          type="submit"
          onClick={() => {
            if (monthYear.month > 0) {
              setMonthYear({ ...monthYear, month: monthYear.month - 1 });
            } else {
              setMonthYear({ month: 11, year: monthYear.year - 1 });
            }
          }}
        >
          <FontAwesomeIcon className="nav-link-icon" icon={faArrowLeft} fixedWidth />
        </Button>
        <span style={{ display: 'inline-flex', alignItems: 'center', textTransform: 'capitalize' }}>{`${months[monthYear.month]}  ${monthYear.year}`}</span>
        <Button
          variant="default"
          type="submit"
          onClick={() => {
            if (monthYear.month < 11) {
              setMonthYear({ ...monthYear, month: monthYear.month + 1 });
            } else {
              setMonthYear({ month: 0, year: monthYear.year + 1 });
            }
          }}
        >
          <FontAwesomeIcon className="nav-link-icon" icon={faArrowRight} fixedWidth />
        </Button>
      </div>
      <div>
        {error && <span>{t('internalError')}</span>}
        {loading && !data && (
          <div className="spinner">
            <Spinner animation="border" role="status">
              <span className="sr-only">{t('common:loading')}</span>
            </Spinner>
          </div>
        )}
        {data && !loading && <BootstrapTable keyField="created_date" data={data} noDataIndication={t('noData')} columns={billingInfo} striped bordered={false} />}
      </div>
    </>
  );
};

export default ChooseMonthYear;
