import React, { useCallback, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import { Equalize, EqualizerContainer } from 'react-equalize';
import { useTranslation } from 'react-i18next';
import { Col, Row, Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { RecentlyChangedRegistrationStudent, RegistrationsType } from '../api/registrations.types';
import { getRecentRegistrations, getRecentRegistrationsExtraProducts, getRecentUnRegistrations, getRecentUnRegistrationsExtraProducts } from '../api/registrations';
import usePermission, { Permission } from '../hooks/usePermission';
import ToolkitProvider from '../utils/csv/provider';
import CardHeader from '../components/CardHeader';
import { dateFormatter } from '../utils/tableUtils';

const Home = (): React.ReactElement => {
  const { t } = useTranslation('registrations');
  const { hasReadPermission, hasWritePermission } = usePermission();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [recentlyRegisteredData, setRecentlyRegisteredData] = useState<RecentlyChangedRegistrationStudent[]>();
  const [recentlyUnRegisteredData, setRecentlyUnRegisteredData] = useState<RecentlyChangedRegistrationStudent[]>();
  const [recentlyRegisteredFromExtraProductsData, setRecentlyRegisteredFromExtraProductsData] = useState<any[]>();
  const [recentlyUnRegisteredFromExtraProductsData, setRecentlyUnRegisteredFromExtraProductsData] = useState<any[]>();
  const [filter, setFilter] = useState<RegistrationsType>({ str: '', years: '', schools: '', allergies: '', intolerance: '' });
  const [currentPage, setCurrentPage] = useState<number>(1);

  const loadRegistrations = useCallback(async (filterValues: RegistrationsType) => {
    setLoading(true);
    setError(false);
    try {
      const response = await getRecentRegistrations(filterValues);
      setRecentlyRegisteredData(response.data.items);
      const response2 = await getRecentUnRegistrations(filterValues);
      setRecentlyUnRegisteredData(response2.data.items);
      const response3 = await getRecentRegistrationsExtraProducts();
      setRecentlyRegisteredFromExtraProductsData(response3.data.items);
      const response4 = await getRecentUnRegistrationsExtraProducts();
      setRecentlyUnRegisteredFromExtraProductsData(response4.data.items);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    loadRegistrations(filter);
  }, [loadRegistrations]);

  const customTotal = (from: number, to: number, size: number) => (
    // eslint-disable-next-line react/react-in-jsx-scope
    <span className="react-bootstrap-table-pagination-total">
      Raðir {from} til {to} af {size}
    </span>
  );

  const pagination = paginationFactory({
    page: currentPage,
    onPageChange: (page: number) => {
      setCurrentPage(page);
    },
    sizePerPage: 10,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
  });

  const recentlyRegisteredColumn = [
    {
      dataField: 'id',
      isDummyField: true,
      text: '',
      headerStyle: { width: '35px' },
      formatter: (cell: string, row: any, rowIndex: any) => {
        const rowNumber = (currentPage - 1) * 10 + (rowIndex + 1);
        return `${rowNumber}.`;
      },
    },
    {
      dataField: 'student_name',
      text: t('student_name'),
      sort: true,
      formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
        return (
          <Link to={`/student/${row.registration_id}`}>
            <div>{row.student_name}</div>
          </Link>
        );
      },
    },
    {
      dataField: 'start_date',
      text: t('registrationFrom'),
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: 'class',
      text: t('class'),
      sort: true,
    },
  ];

  const recentlyUnRegisteredColumn = [
    {
      dataField: 'id',
      isDummyField: true,
      text: '',
      headerStyle: { width: '35px' },
      formatter: (cell: string, row: any, rowIndex: any) => {
        const rowNumber = (currentPage - 1) * 10 + (rowIndex + 1);
        return `${rowNumber}.`;
      },
    },
    {
      dataField: 'student_name',
      text: t('student_name'),
      sort: true,
      formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
        return (
          <Link to={`/student/${row.registration_id}`}>
            <div>{row.student_name}</div>
          </Link>
        );
      },
    },
    {
      dataField: 'end_date',
      text: t('registrationTo'),
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: 'class',
      text: t('class'),
      sort: true,
    },
  ];

  return (
    <>
      {loading && !recentlyRegisteredData && !recentlyUnRegisteredData && (
        <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">{t('common:loading')}</span>
          </Spinner>
        </div>
      )}
      {hasReadPermission(Permission.SKRANINGAR) && recentlyRegisteredData && recentlyUnRegisteredData && !loading && (
        <>
          <Row>
            <EqualizerContainer>
              <Col lg={6} md={12} sm={12} style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                <Col lg={12} md={12} sm={12} className="placement-column has-heading">
                  <Equalize>
                    <CardHeader color="greenHeader">
                      <div className="cardHeader-title">
                        {t('recentlyRegistered')}
                        <span className="addItemInCardHeaderHomeGreen">{recentlyRegisteredData?.length}</span>
                      </div>
                    </CardHeader>
                    <div className="body">
                      <ToolkitProvider keyField="registration_id" bootstrap4 data={recentlyRegisteredData} columns={recentlyRegisteredColumn}>
                        {(props: any) => (
                          <BootstrapTable
                            {...props.baseProps}
                            keyField="student_school_id"
                            bordered={false}
                            pagination={pagination}
                            noDataIndication={t('common:emptyDataMessage')}
                            striped
                          />
                        )}
                      </ToolkitProvider>
                    </div>
                  </Equalize>
                </Col>
              </Col>
              <Col lg={6} md={12} sm={12} style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                <Col lg={12} md={12} sm={12} className="placement-column has-heading">
                  <Equalize>
                    <CardHeader color="purpleHeader">
                      <div className="cardHeader-title">
                        {t('recentlyUnRegistered')}
                        <span className="addItemInCardHeaderHomePurple">{recentlyUnRegisteredData?.length}</span>
                      </div>
                    </CardHeader>
                    <div className="body">
                      <ToolkitProvider keyField="registration_id" bootstrap4 data={recentlyUnRegisteredData} columns={recentlyUnRegisteredColumn}>
                        {(props: any) => (
                          <BootstrapTable
                            {...props.baseProps}
                            keyField="student_school_id"
                            bordered={false}
                            pagination={pagination}
                            noDataIndication={t('common:emptyDataMessage')}
                            striped
                          />
                        )}
                      </ToolkitProvider>
                    </div>
                  </Equalize>
                </Col>
              </Col>
              <Col lg={6} md={12} sm={12} style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                <Col lg={12} md={12} sm={12} className="placement-column has-heading">
                  <Equalize>
                    <CardHeader color="pinkHeader">
                      <div className="cardHeader-title">
                        {t('recentlyRegisteredFromExtraProducts')}
                        <span className="addItemInCardHeaderHomePink">{recentlyRegisteredFromExtraProductsData?.length}</span>
                      </div>
                    </CardHeader>
                    <div className="body">
                      <ToolkitProvider keyField="registration_id" bootstrap4 data={recentlyRegisteredFromExtraProductsData} columns={recentlyRegisteredColumn}>
                        {(props: any) => (
                          <BootstrapTable
                            {...props.baseProps}
                            keyField="student_school_id"
                            bordered={false}
                            pagination={pagination}
                            noDataIndication={t('common:emptyDataMessage')}
                            striped
                          />
                        )}
                      </ToolkitProvider>
                    </div>
                  </Equalize>
                </Col>
              </Col>
              <Col lg={6} md={12} sm={12} style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                <Col lg={12} md={12} sm={12} className="placement-column has-heading">
                  <Equalize>
                    <CardHeader color="greenHeader">
                      <div className="cardHeader-title">
                        {t('recentlyUnRegisteredFromExtraProducts')}
                        <span className="addItemInCardHeaderHomeGreen">{recentlyUnRegisteredFromExtraProductsData?.length}</span>
                      </div>
                    </CardHeader>
                    <div className="body">
                      <ToolkitProvider keyField="registration_id" bootstrap4 data={recentlyUnRegisteredFromExtraProductsData} columns={recentlyUnRegisteredColumn}>
                        {(props: any) => (
                          <BootstrapTable
                            {...props.baseProps}
                            keyField="student_school_id"
                            bordered={false}
                            pagination={pagination}
                            noDataIndication={t('common:emptyDataMessage')}
                            striped
                          />
                        )}
                      </ToolkitProvider>
                    </div>
                  </Equalize>
                </Col>
              </Col>
            </EqualizerContainer>
          </Row>
        </>
      )}
    </>
  );
};
export default Home;
