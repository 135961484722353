import { AxiosResponse } from 'axios';
import axios from './api';
import {
  BirthYearsResponseType,
  ListsFilterData,
  IntoleranceAllergiesStudentListResponse,
  PinNumbersListResponse,
  ExtraProductsListResponse,
  SpecialDietarysListResponse,
} from './lists.types';

export const getBirthYearsFromDate = (date: string): Promise<AxiosResponse<BirthYearsResponseType>> => {
  return axios.get(`/vala/lists/birth-year-from-date`, {
    params: { date },
  });
};

export const getClasses = (date: string, school_id: string): Promise<AxiosResponse<any>> => {
  return axios.get(`/vala/lists/classes`, {
    params: { date, school_id },
  });
};

export const getIntoleranceAndAllergies = (data: ListsFilterData): Promise<AxiosResponse<IntoleranceAllergiesStudentListResponse>> => {
  return axios.get(`/vala/lists/intolerance-allergies`, {
    params: data,
  });
};

export const getPinList = (data: ListsFilterData): Promise<AxiosResponse<PinNumbersListResponse>> => {
  return axios.get(`/vala/lists/pin_number`, {
    params: data,
  });
};

export const getExtraProducts = (data: ListsFilterData): Promise<AxiosResponse<ExtraProductsListResponse>> => {
  return axios.get(`/vala/lists/extra-products`, {
    params: data,
  });
};

export const getSpecialDietaries = (data: ListsFilterData): Promise<AxiosResponse<SpecialDietarysListResponse>> => {
  return axios.get(`/vala/lists/special-food-types`, {
    params: data,
  });
};

export const getExtraProductsForSpecificSchool = (data: { lookup_date: string; school_id: string }): Promise<AxiosResponse<ExtraProductsListResponse>> => {
  return axios.get(`vala/lists/extra-products-dropdown`, {
    params: data,
  });
};
