import React, { useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import { restValidationToForm } from '../utils/formUtils';
import { SchoolFoodTypes, SchoolItem } from '../api/schools.types';
import { disableSchool } from '../api/schools';

interface Props {
  onClose: (created: boolean) => void;
  originalValues: SchoolItem;
}

interface Values {
  can_be_disabled?: number;
  cost_place: string;
  email: string;
  enabled: string;
  id: number | null;
  name: string;
  ssn: string;
  food_types?: SchoolFoodTypes[];
}

const ChangeActivationSchoolAlertModal = ({ onClose, originalValues }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['schoolsView', 'errors']);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({});
  }, [t, i18n.language]);

  const initialValues: Values = {
    cost_place: originalValues.cost_place,
    email: originalValues.email,
    name: originalValues.name,
    ssn: originalValues.ssn,
    enabled: originalValues.enabled,
    id: originalValues.id,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await disableSchool({ ...values, enabled: initialValues.enabled === '1' ? '0' : '1' });
            toast.success(initialValues.enabled === '1' ? t('schoolDisabled') : t('schoolActivated'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{initialValues.enabled === '1' ? t('disableModalTitle') : t('activateModalTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Form.Group>
                  <Form.Label>
                    {initialValues.enabled === '1' ? t('disablingSchoolConfirmation') : t('activatingSchoolConfirmation')}
                    &nbsp;{initialValues.name} ?
                  </Form.Label>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant={initialValues.enabled === '1' ? 'danger' : 'primary'}
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {initialValues.enabled === '1' ? t('disable') : t('activate')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default ChangeActivationSchoolAlertModal;
