import { AxiosResponse } from 'axios';
import axios from './api';
import { MonthlyBillPeriodList, MonthlyBillSumList, MonthlyBillTotalSumList } from './monthlyBillOverview.types';

export const getMonthlyBillPeriods = (): Promise<AxiosResponse<MonthlyBillPeriodList>> => {
  return axios.get(`/vala/billing/monthly-bill-periods`);
};

export const getMonthlyBillSum = (period_id: number): Promise<AxiosResponse<MonthlyBillSumList>> => {
  return axios.get(`/vala/billing/monthly-bill-sum/${period_id}`);
};

export const getMonthlyBillTotalSum = (period_id: number): Promise<AxiosResponse<MonthlyBillTotalSumList>> => {
  return axios.get(`/vala/billing/monthly-bill-totalsum/${period_id}`);
};
