/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import { getStudent } from '../../api/registrations';

interface State {
  student: any | null;
  studentLoading: 'idle' | 'pending';
  studentError: null | SerializedError;
}

export const fetchStudent = createAsyncThunk<
  any,
  string,
  {
    state: RootState;
  }
>('student/student', async (id) => {
  const response = await getStudent(id);
  return response.data;
});

const initialState: State = {
  student: null,
  studentLoading: 'idle',
  studentError: null,
};

const slice = createSlice({
  name: 'getStudent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /*
     * fetchUsers
     */
    builder.addCase(fetchStudent.pending, (state) => {
      state.student = null;
      state.studentError = null;
      state.studentLoading = 'pending';
    });
    builder.addCase(fetchStudent.fulfilled, (state, { payload }) => {
      state.student = payload;
      state.studentLoading = 'idle';
    });
    builder.addCase(fetchStudent.rejected, (state, action) => {
      state.studentError = action.error;
      state.studentLoading = 'idle';
    });
  },
});

export default slice.reducer;
