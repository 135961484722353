import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { Registration, NotRegisteredStudentsResponse } from '../../api/registrations.types';
import ToolkitProvider from '../../utils/csv/provider';
import usePermission, { Permission } from '../../hooks/usePermission';

interface Props {
  loadingData: boolean;
  searchValue: string;
  initialPage: number;
  data: NotRegisteredStudentsResponse | undefined;
  setShowRegisterStudentModal: (v: boolean) => void;
  setSelectedStudentToRegister: (v: any) => void;
  onChangePage: (p: number) => void;
}

const NotRegisteredTab: React.FC<Props> = ({ loadingData, initialPage, searchValue, data, setShowRegisterStudentModal, setSelectedStudentToRegister, onChangePage }) => {
  const { t } = useTranslation('registrations');
  const { ExportCSVButton } = CSVExport;
  const { hasWritePermission } = usePermission();
  const [currentPage, setCurrentPage] = useState<number>(initialPage);

  const columns = useMemo(
    () => [
      {
        dataField: 'id',
        isDummyField: true,
        text: '',
        csvExport: false,
        headerStyle: { width: '35px' },
        formatter: (cell: string, row: any, rowIndex: any) => {
          const rowNumber = (currentPage - 1) * 50 + (rowIndex + 1);
          return `${rowNumber}.`;
        },
      },
      {
        dataField: 'student_name',
        text: t('student_name'),
        sort: true,
        formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
          if (searchValue !== '' && row.student_name && row.student_name.toUpperCase().includes(searchValue.toUpperCase())) {
            return <div dangerouslySetInnerHTML={{ __html: row.student_name.replace(new RegExp(searchValue, 'gi'), '<span class="highlight">$&</span>') }} />;
          }
          return <div>{row.student_name}</div>;
        },
      },
      {
        dataField: 'ssn',
        text: t('ssn'),
        sort: true,
        sortValue: (cell: string, row: Registration) => {
          return row.ssn_order;
        },
        csvFormatter: (cell: string) => `="${cell}"`,
        formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
          if (searchValue !== '' && row.ssn && row.ssn.includes(searchValue)) {
            return <div dangerouslySetInnerHTML={{ __html: row.ssn.replace(new RegExp(searchValue, 'gi'), '<span class="highlight">$&</span>') }} />;
          }
          return <div>{row.ssn}</div>;
        },
      },
      {
        dataField: 'get_birth_year',
        text: t('get_birth_year'),
        sort: true,
      },
      {
        dataField: 'class',
        text: t('className'),
        sort: true,
        csvFormatter: (cell: string | null) => (cell === null ? t('common:unknown') : cell),
      },
      {
        dataField: 'school_name',
        text: t('school_name'),
        sort: true,
      },
      {
        dataField: 'deletable',
        isDummyField: true,
        csvExport: false,
        text: '',
        formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
          if (hasWritePermission(Permission.SKRANINGAR)) {
            return (
              <OverlayTrigger
                placement="top-start"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip className="schoolTooltip" id="button-tooltip">
                    <div>{t('registerStudent')}</div>
                  </Tooltip>
                }
              >
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {
                    setShowRegisterStudentModal(true);
                    setSelectedStudentToRegister(row);
                  }}
                  onClick={() => {
                    setShowRegisterStudentModal(true);
                    setSelectedStudentToRegister(row);
                  }}
                >
                  <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faUserPlus} />
                </div>
              </OverlayTrigger>
            );
          }
          return '';
        },
      },
    ],
    [currentPage, searchValue, t, hasWritePermission, setShowRegisterStudentModal, setSelectedStudentToRegister]
  );

  const pagination = paginationFactory({
    page: currentPage,
    onPageChange: (page: number) => {
      setCurrentPage(page);
      onChangePage(page);
    },
    sizePerPage: 50,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: (from: number, to: number, size: number) => (
      <span className="react-bootstrap-table-pagination-total">
        Raðir {from} til {to} af {size}
      </span>
    ),
  });

  if (loadingData || !data) {
    return (
      <div className="spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">{t('common:loading')}</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="whiteColumn">
      <ToolkitProvider
        keyField="student_school_id"
        bootstrap4
        data={data.items}
        columns={columns}
        exportCSV={{
          fileName: `${t('notRegistered_prefix')}${dayjs().format('DD.MM.YYYY')}.csv`,
        }}
      >
        {(props: any) => (
          <div>
            <ExportCSVButton {...props.csvProps} style={{ float: 'right', color: 'green' }}>
              <FontAwesomeIcon v-if="icon" className="green-icon" fixedWidth icon={faFileExcel} />
            </ExportCSVButton>
            <BootstrapTable {...props.baseProps} keyField="student_school_id" bordered={false} pagination={pagination} noDataIndication={t('common:emptyDataMessage')} striped />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default NotRegisteredTab;
