import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { restValidationToForm } from '../utils/formUtils';
import { getDiscountType, updateDiscountType } from '../api/billing';
import { DiscountType } from '../api/billing.types';

interface Props {
  onClose: (created: boolean) => void;
  id: number;
}

interface Values {
  name: string | undefined;
  description: string | undefined;
  enabled: string | undefined;
}

const EditDiscountTypeModal = ({ onClose, id }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['discountTypes', 'errors']);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [currentDiscountType, setCurrentDiscountType] = useState<DiscountType>();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string().required(t('errors:emptyInputField')),
    });
  }, [t, i18n.language]);

  const initialValues: Values = {
    name: currentDiscountType ? currentDiscountType.name : undefined,
    description: currentDiscountType ? currentDiscountType.description : undefined,
    enabled: currentDiscountType ? currentDiscountType.enabled : undefined,
  };

  const loadDiscountType = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getDiscountType(id);
      setCurrentDiscountType(response.data.items[0]);
    } catch {
      toast.error(t('discountTypeError'));
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadDiscountType();
  }, [loadDiscountType]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await updateDiscountType(values, id);
            toast.success(t('discountTypeUpdated'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('updateDiscountType')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Form.Group>
                  <Form.Label>
                    {t('name')}
                    <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={values.name}
                    onChange={(event) => {
                      setFieldValue('name', event.target.value);
                    }}
                  />
                  <ErrorMessage name="name" component="div" className="invalid-feedback d-block text-left" />
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t('description')}</Form.Label>
                  <Form.Control
                    type="text"
                    value={values.description}
                    onChange={(event) => {
                      setFieldValue('description', event.target.value);
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:edit')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default EditDiscountTypeModal;
