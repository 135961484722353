import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTimes, faUserPlus, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Form, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import dayjs from 'dayjs';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getSchoolsList } from '../api/schools';
import { SchoolItem, SchoolsFilterType } from '../api/schools.types';
import CreateNewSchool from './CreateNewSchoolModal';
import EditSchoolModal from './EditSchoolModal';
import ChangeActivationSchoolAlertModal from './ChangeActivationSchoolAlertModal';
import EditExtraProductsModal from './EditExtraProductsModal';
import usePermission, { Permission } from '../hooks/usePermission';
import ToolkitProvider from '../utils/csv/provider';

const Schools = (): React.ReactElement => {
  const { t } = useTranslation(['schoolsView', 'common']);
  const { hasWritePermission, hasReadPermission } = usePermission();
  const [data, setData] = useState<SchoolItem[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [filter, setFilter] = useState<SchoolsFilterType>({ str: '', enabled: '' });
  const [searchValue, setSearchValue] = useState<string>('');
  const [showCreateNewSchoolModal, setShowCreateNewSchoolModal] = useState<boolean>(false);
  const [showEditSchoolModal, setShowEditSchoolModal] = useState<boolean>(false);
  const [showEditExtraProductsModal, setShowEditExtraProductsModal] = useState<boolean>(false);
  const [currentSchool, setCurrentSchool] = useState<SchoolItem>({ cost_place: '', email: '', enabled: '', id: null, name: '', ssn: '', food_types: [] });
  const [showDisableAlert, setShowDisableAlert] = useState<boolean>(false);
  const { ExportCSVButton } = CSVExport;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [key, setKey] = useState<string | null>('1');

  const loadSchools = useCallback(
    async (filterValues: SchoolsFilterType) => {
      setLoading(true);
      setError(false);
      try {
        const response = await getSchoolsList({ ...filterValues, enabled: key });
        setData(response.data.items);
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [key]
  );

  const onCreateNewSchoolModalClosed = (created: boolean) => {
    setShowCreateNewSchoolModal(false);
    if (created) {
      loadSchools(filter);
    }
  };

  const onEditSchoolModalClosed = (edited: boolean) => {
    setShowEditSchoolModal(false);
    if (edited) {
      loadSchools(filter);
    }
  };

  const onEditExtraProductsModalClosed = (edited: boolean) => {
    setShowEditExtraProductsModal(false);
    if (edited) {
      loadSchools(filter);
    }
  };

  const onDisableSchoolModalClosed = (disabled: boolean) => {
    setShowDisableAlert(false);
    if (disabled) {
      loadSchools(filter);
    }
  };

  useEffect(() => {
    loadSchools(filter);
  }, [filter, loadSchools]);

  const customTotal = (from: number, to: number, size: number) => (
    // eslint-disable-next-line react/react-in-jsx-scope
    <span className="react-bootstrap-table-pagination-total">
      Raðir {from} til {to} af {size}
    </span>
  );

  const pagination = paginationFactory({
    page: currentPage,
    onPageChange: (page: number) => {
      setCurrentPage(page);
    },
    sizePerPage: 50,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
  });

  const schoolColumn = [
    {
      dataField: 'id',
      isDummyField: true,
      text: '',
      csvExport: false,
      headerStyle: { width: '35px' },
      formatter: (cell: string, row: any, rowIndex: any) => {
        const rowNumber = (currentPage - 1) * 50 + (rowIndex + 1);
        return `${rowNumber}.`;
      },
    },
    {
      dataField: 'name',
      text: t('name'),
      sort: true,
      formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
        if (searchValue !== '' && row.name && row.name.toUpperCase().includes(searchValue.toUpperCase())) {
          return <div dangerouslySetInnerHTML={{ __html: row.name.replace(new RegExp(searchValue, 'gi'), '<span class="highlight">$&</span>') }} />;
        }
        return <div>{row.name}</div>;
      },
    },
    {
      dataField: 'ssn',
      text: t('ssn'),
      sort: true,
      csvFormatter: (cell: string) => `="${cell}"`,
      formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
        if (searchValue !== '' && row.ssn && row.ssn.includes(searchValue)) {
          return <div dangerouslySetInnerHTML={{ __html: row.ssn.replace(new RegExp(searchValue, 'gi'), '<span class="highlight">$&</span>') }} />;
        }
        return <div>{row.ssn}</div>;
      },
    },
    {
      dataField: 'email',
      text: t('email'),
      csvFormatter: (cell: string | null) => (cell === null ? t('common:unknown') : cell),
      sort: true,
      formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
        if (searchValue !== '' && row.email && row.email.toUpperCase().includes(searchValue.toUpperCase())) {
          return <div dangerouslySetInnerHTML={{ __html: row.email.replace(new RegExp(searchValue, 'gi'), '<span class="highlight">$&</span>') }} />;
        }
        return <div>{row.email}</div>;
      },
    },
    {
      dataField: 'edit',
      isDummyField: true,
      csvExport: false,
      text: '',
      formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
        if (hasWritePermission(Permission.SKOLAR) && key === '1') {
          return (
            <OverlayTrigger
              placement="top-start"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip className="schoolTooltip" id="button-tooltip">
                  <div>Breyta</div>
                </Tooltip>
              }
            >
              <Button
                variant="link"
                onClick={() => {
                  setCurrentSchool(row);
                  setShowEditSchoolModal(true);
                }}
              >
                <FontAwesomeIcon className="nav-link-icon" fixedWidth icon={faEdit} />
              </Button>
            </OverlayTrigger>
          );
        }
        return '';
      },
    },
    {
      dataField: 'extraProducts',
      isDummyField: true,
      text: '',
      formatter: (cell: any, row: any, rowIndex: any, extraData: any) => {
        if (hasWritePermission(Permission.SKOLAR) && hasWritePermission(Permission.AUKAVORUR) && key === '1') {
          return (
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip className="schoolTooltip" id="button-tooltip">
                  <div>{t('extraProducts')}</div>
                </Tooltip>
              }
            >
              <Button
                variant="link"
                onClick={() => {
                  setCurrentSchool(row);
                  setShowEditExtraProductsModal(true);
                }}
              >
                <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faUtensils} />
              </Button>
            </OverlayTrigger>
          );
        }
        return '';
      },
    },
    {
      dataField: 'action',
      isDummyField: true,
      csvExport: false,
      text: '',
      formatter: (cell: any, row: SchoolItem, rowIndex: any, extraData: any) => {
        if (row.can_be_disabled === 1 && hasWritePermission(Permission.SKOLAR)) {
          return (
            <OverlayTrigger
              placement="top-start"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip className="schoolTooltip" id="button-tooltip">
                  <div>{key === '1' ? t('common:disable') : t('activate')}</div>
                </Tooltip>
              }
            >
              <Button
                variant="link"
                onClick={() => {
                  setCurrentSchool(row);
                  if (key === '1') {
                    setShowDisableAlert(true);
                  } else if (key === '0') {
                    setShowDisableAlert(true);
                  }
                }}
              >
                {key === '1' ? (
                  <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faTimes} />
                ) : (
                  <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faPlus} />
                )}
              </Button>
            </OverlayTrigger>
          );
        }
        return '';
      },
    },
  ];
  const debouncedFilter = useCallback(
    debounce((filterValues: SchoolsFilterType) => loadSchools(filterValues), 1000),
    [loadSchools]
  );

  const onSearchChanged = (value: Partial<SchoolsFilterType>) => {
    const newFilter: SchoolsFilterType = {
      ...filter,
      ...value,
    };

    setFilter(newFilter);
    debouncedFilter(newFilter);
  };

  return (
    <>
      {hasReadPermission(Permission.SKOLAR) && (
        <>
          {showCreateNewSchoolModal && <CreateNewSchool onClose={onCreateNewSchoolModalClosed} />}
          {showEditSchoolModal && <EditSchoolModal id={currentSchool.id} onClose={onEditSchoolModalClosed} />}
          {showDisableAlert && <ChangeActivationSchoolAlertModal originalValues={currentSchool} onClose={onDisableSchoolModalClosed} />}
          {showEditExtraProductsModal && <EditExtraProductsModal onClose={onEditExtraProductsModalClosed} id={currentSchool.id} />}
          {!error && (
            <>
              {hasWritePermission(Permission.SKOLAR) && (
                <Button variant="primary" className="pull-right" onClick={() => setShowCreateNewSchoolModal(true)}>
                  <FontAwesomeIcon className="sidebarToggleIcon" icon={faUserPlus} style={{ display: 'inline', marginTop: '2px' }} />
                  &nbsp;{t('createNewSchool')}
                </Button>
              )}
              <Row>
                <Col>
                  <Form.Group controlId="search">
                    <Form.Control
                      type="text"
                      disabled={loading}
                      placeholder={t('search')}
                      autoComplete="off"
                      onChange={(e) => {
                        setSearchValue(e.currentTarget.value);
                        onSearchChanged({ str: e.currentTarget.value });
                      }}
                      value={searchValue}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
          {loading && (
            <div className="spinner">
              <Spinner animation="border" role="status">
                <span className="sr-only">{t('common:loading')}</span>
              </Spinner>
            </div>
          )}
          {data && !loading && (
            <Tabs
              activeKey={key || undefined}
              id="schools-list"
              onSelect={(k: string | null) => {
                setKey(k);
                setFilter({ str: '', enabled: key });
                setSearchValue('');
              }}
            >
              <Tab eventKey="1" title={t('enabledSchools')}>
                <div className="whiteColumn">
                  <ToolkitProvider
                    keyField="id"
                    bootstrap4
                    data={data}
                    columns={schoolColumn}
                    exportCSV={{
                      fileName: `${t('active_schools_prefix')}${dayjs().format('DD.MM.YYYY')}.csv`,
                    }}
                  >
                    {(props: any) => (
                      <div>
                        <ExportCSVButton {...props.csvProps} style={{ float: 'right', color: 'green' }}>
                          <FontAwesomeIcon v-if="icon" className="green-icon" fixedWidth icon={faFileExcel} />
                        </ExportCSVButton>
                        <BootstrapTable {...props.baseProps} keyField="id" bordered={false} pagination={pagination} noDataIndication={t('common:emptyDataMessage')} striped />
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </Tab>
              <Tab eventKey="0" title={t('disabledSchools')}>
                <div className="whiteColumn">
                  <ToolkitProvider
                    keyField="id"
                    bootstrap4
                    data={data}
                    columns={schoolColumn}
                    exportCSV={{
                      fileName: `${t('disabled_schools_prefix')}${dayjs().format('DD.MM.YYYY')}.csv`,
                    }}
                  >
                    {(props: any) => (
                      <div>
                        <ExportCSVButton {...props.csvProps} style={{ float: 'right', color: 'green' }}>
                          <FontAwesomeIcon v-if="icon" className="green-icon" fixedWidth icon={faFileExcel} />
                        </ExportCSVButton>
                        <BootstrapTable {...props.baseProps} keyField="id" bordered={false} pagination={pagination} noDataIndication={t('common:emptyDataMessage')} striped />
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </Tab>
            </Tabs>
          )}
        </>
      )}
    </>
  );
};
export default Schools;
