import React, { useMemo } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import { restValidationToForm } from '../utils/formUtils';
import { ProductAccKeys } from '../api/tariffs.types';
import { editProductAccKeys } from '../api/tariffs';

interface Props {
  onClose: (created: boolean) => void;
  originalValues: ProductAccKeys | undefined;
}

interface Values {
  discount_category: string | undefined;
  external_account_key: string | undefined;
  product_category: string | undefined;
  product_id: number | undefined;
}

const EditProductAccKeysTariffModal = ({ onClose, originalValues }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['tariff', 'common']);
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      discount_category: Yup.string().required(t('error')),
      external_account_key: Yup.string().required(t('error')),
      product_category: Yup.string().required(t('error')),
    });
  }, [t, i18n.language]);

  const initialValues = {
    discount_category: originalValues?.discount_category,
    external_account_key: originalValues?.external_account_key,
    product_category: originalValues?.product_category,
    product_id: originalValues?.product_id,
  };

  if (initialValues == null) {
    return <>{t('loading')}</>;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await editProductAccKeys(values);
            toast.success(t('accKeysUpdated'));
            onClose(true);
          } catch (e) {
            toast.error(`${t('common:informationChangedError')}. ${e.response.data.error}.`);
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
            console.error(e);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show size="lg" onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('editTariffAccKeysTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <table className="table">
                  <tbody>
                    <tr>
                      <td style={{ borderTop: 'none' }}>
                        <Form.Group>
                          <Form.Label>Vara</Form.Label>
                          <span style={{ fontWeight: 'bold', verticalAlign: 'sub' }}>{originalValues?.product}</span>
                        </Form.Group>
                      </td>
                      <td style={{ borderTop: 'none' }}>
                        <Form.Group>
                          <Form.Label>{t('discount_category')}</Form.Label>
                          <Form.Control
                            type="text"
                            {...getFieldProps('discount_category')}
                            onChange={(e) => {
                              setFieldValue('discount_category', e.currentTarget.value);
                            }}
                          />
                          <ErrorMessage name="discount_category" component="div" className="invalid-feedback d-block text-left" />
                        </Form.Group>
                      </td>
                      <td style={{ borderTop: 'none' }}>
                        <Form.Group>
                          <Form.Label>{t('external_account_key')}</Form.Label>
                          <Form.Control
                            type="text"
                            {...getFieldProps('external_account_key')}
                            onChange={(e) => {
                              setFieldValue('external_account_key', e.currentTarget.value);
                            }}
                          />
                          <ErrorMessage name="external_account_key" component="div" className="invalid-feedback d-block text-left" />
                        </Form.Group>
                      </td>
                      <td style={{ borderTop: 'none' }}>
                        <Form.Group>
                          <Form.Label>{t('product_category')}</Form.Label>
                          <Form.Control
                            type="text"
                            {...getFieldProps('product_category')}
                            onChange={(e) => {
                              setFieldValue('product_category', e.currentTarget.value);
                            }}
                          />
                          <ErrorMessage name="product_category" component="div" className="invalid-feedback d-block text-left" />
                        </Form.Group>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:save')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default EditProductAccKeysTariffModal;
