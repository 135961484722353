import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DatePicker from './DatePicker';
import { RootState } from '../store/rootReducer';
import { SchoolItem } from '../api/schools.types';
import { dateFormatter } from '../utils/tableUtils';
import {
  fetchBirthYears,
  fetchClasses,
  fetchExtraProductsForSchool,
  fetchExtraProductsListData,
  fetchIntoleranceAllergiesData,
  fetchPinNumbersListData,
  fetchSchools,
  fetchSpecialDietaryListData,
} from '../store/Lists/getListsSlice';
import { AppDispatch } from '../store/store';
import { ClassType, ListsType, BirthYearsType, ExtraProductsResponse } from '../api/lists.types';

interface Props {
  showData: ListsType;
  setShowData: (v: any) => void;
  type: string;
}

const ListsFilter = ({ showData, setShowData, type }: Props): React.ReactElement => {
  const { t } = useTranslation('lists');
  const dispatch = useDispatch<AppDispatch>();
  const [startDateFilter, setStartDateFilter] = useState(dayjs().toDate());
  const [yearsFilter, setYearsFilter] = useState<BirthYearsType[]>([]);
  const [schoolsFilter, setSchoolsFilter] = useState<SchoolItem[]>([]);
  const [classesFilter, setClassesFilter] = useState<ClassType[]>([]);
  const [extraProductsFilter, setExtraProductsFilter] = useState<ExtraProductsResponse[]>([]);
  const { birthYears, birthYearsLoading, schools, schoolsLoading, classes, classesLoading, extraProductsForSchool, extraProductsForSchoolLoading } = useSelector(
    (state: RootState) => state.lists
  );

  useEffect(() => {
    if (schools?.items.length === 1) {
      setSchoolsFilter(schools.items.map((s) => s));
    }
  }, [schools?.items]);
  useEffect(() => {
    dispatch(fetchBirthYears(dateFormatter(startDateFilter)));
  }, [dispatch, startDateFilter]);
  useEffect(() => {
    dispatch(fetchSchools({ str: '', enabled: null }));
  }, [dispatch]);

  useEffect(() => {
    if (schoolsFilter.length !== 0) {
      const formattedStartDate: string = dateFormatter(startDateFilter);
      let schoolsFormatted;
      if (schools?.items.length === 1) {
        schoolsFormatted = schools?.items.map((i: SchoolItem) => i.id).join();
      } else {
        schoolsFormatted = schoolsFilter.map((i: SchoolItem) => i.id).join();
      }
      dispatch(fetchClasses({ date: formattedStartDate, schoolId: schoolsFormatted }));
      if (type === 'extraProducts') {
        dispatch(fetchExtraProductsForSchool({ lookup_date: formattedStartDate, schoolId: schoolsFormatted }));
      }
    }
  }, [dispatch, schoolsFilter, startDateFilter, schools?.items, type]);

  const getData = () => {
    const formattedStartDate = dateFormatter(startDateFilter);
    const schoolsFormatted = schoolsFilter.map((i: SchoolItem) => i.id).join();
    const classesFormatted = classesFilter.map((i: ClassType) => i.class).join();
    const extraProductsForSchoolsFormatted = extraProductsFilter.map((i: ExtraProductsResponse) => i.product_id).join();
    const yearsFormatted = yearsFilter.map((i: BirthYearsType) => i.get_birth_year).join();
    if (type === 'intolerance') {
      dispatch(
        fetchIntoleranceAllergiesData({
          ssn: '',
          name: '',
          schools: schoolsFormatted,
          start_date: formattedStartDate,
          classes: classesFormatted,
          years: yearsFormatted,
          extraProducts: extraProductsForSchoolsFormatted,
        })
      );
      setShowData({ ...showData, intoleranceAllergies: true });
    }
    if (type === 'pin') {
      dispatch(
        fetchPinNumbersListData({
          ssn: '',
          name: '',
          schools: schoolsFormatted,
          start_date: formattedStartDate,
          classes: classesFormatted,
          years: yearsFormatted,
          extraProducts: extraProductsForSchoolsFormatted,
        })
      );
      setShowData({ ...showData, pin: true });
    }
    if (type === 'extraProducts') {
      dispatch(
        fetchExtraProductsListData({
          ssn: '',
          name: '',
          schools: schoolsFormatted,
          start_date: formattedStartDate,
          classes: classesFormatted,
          years: yearsFormatted,
          extraProducts: extraProductsForSchoolsFormatted,
        })
      );
      setShowData({ ...showData, extraProducts: true });
    }
    if (type === 'specialDietary') {
      dispatch(
        fetchSpecialDietaryListData({
          ssn: '',
          name: '',
          schools: schoolsFormatted,
          start_date: formattedStartDate,
          classes: classesFormatted,
          years: yearsFormatted,
          extraProducts: extraProductsForSchoolsFormatted,
        })
      );
      setShowData({ ...showData, specialDietary: true });
    }
  };

  return (
    <Row>
      <Col>
        <div className="dateWrapper">
          <DatePicker
            style={{ display: 'inline' }}
            value={startDateFilter}
            month={startDateFilter}
            selectedDay={startDateFilter}
            onDayChange={(day: Date) => {
              setStartDateFilter(day);
            }}
          />
        </div>
      </Col>
      <Col>
        <Select<BirthYearsType, true>
          placeholder={t('birthYears')}
          options={birthYears?.items}
          style={{ display: 'inline' }}
          isMulti
          closeMenuOnSelect={false}
          className="basic-multi-select"
          classNamePrefix={t('birthYears')}
          isClearable
          isDisabled={birthYearsLoading === 'pending'}
          value={yearsFilter}
          getOptionLabel={(e) => e.get_birth_year}
          getOptionValue={(e) => e.get_birth_year}
          onChange={(e) => {
            setYearsFilter(e.map((v) => v));
          }}
        />
      </Col>
      <Col className={schools?.items.length === 1 ? 'col d-flex align-items-center justify-content-start' : ''}>
        {schools?.items.length === 1 ? (
          <div className="d-flex align-items-center">{schools.items.map((i) => i.name)}</div>
        ) : (
          <Select<SchoolItem, true>
            placeholder={t('schools')}
            style={{ display: 'inline' }}
            options={schools?.items}
            isMulti
            closeMenuOnSelect={false}
            className="basic-multi-select"
            classNamePrefix={t('schools')}
            getOptionValue={(e) => `${e.id}`}
            getOptionLabel={(e) => e.name}
            isClearable
            isDisabled={schoolsLoading === 'pending'}
            value={schoolsFilter}
            onChange={(e) => {
              setSchoolsFilter(e.map((v) => v));
            }}
          />
        )}
      </Col>
      <Col>
        <Select<ClassType, true>
          placeholder={t('classes')}
          style={{ display: 'inline' }}
          options={classes?.items}
          isMulti
          closeMenuOnSelect={false}
          className="basic-multi-select"
          classNamePrefix={t('class')}
          isClearable
          isLoading={classesLoading === 'pending'}
          isDisabled={classesLoading === 'pending' || schoolsFilter.length === 0}
          value={classesFilter}
          getOptionValue={(e) => e.class}
          getOptionLabel={(e) => e.class}
          onChange={(e) => {
            setClassesFilter(e.map((v) => v));
          }}
        />
      </Col>
      {type === 'extraProducts' && (
        <Col>
          <Select<ExtraProductsResponse, true>
            placeholder={t('extraProducts')}
            style={{ display: 'inline' }}
            options={extraProductsForSchool?.items}
            isMulti
            closeMenuOnSelect={false}
            className="basic-multi-select"
            classNamePrefix={t('extraProducts')}
            isClearable
            isLoading={extraProductsForSchoolLoading === 'pending'}
            isDisabled={extraProductsForSchoolLoading === 'pending' || schoolsFilter.length === 0}
            value={extraProductsFilter}
            getOptionValue={(e) => e.product_id}
            getOptionLabel={(e) => e.product_name}
            onChange={(e) => {
              setExtraProductsFilter(e.map((v) => v));
            }}
          />
        </Col>
      )}

      <Col>
        <Button onClick={() => getData()}>{t('getData')}</Button>
      </Col>
    </Row>
  );
};

export default ListsFilter;
