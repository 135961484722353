import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';

interface Props {
  children: React.ReactNode;
}

const Page = ({ children }: Props): React.ReactElement => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div id="wrapper">
      <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="content-wrapper">{children}</div>
    </div>
  );
};

export default Page;
