import { useCallback } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { Permission } from '../api/account.types';
import { GetMeType } from '../api/account';

export { Permission };

const permissionsSelector = createSelector(
  (state: RootState) => state.auth.currentUser,
  (currentUser: GetMeType | null) => currentUser?.permissions ?? []
);

export default function usePermission(): {
  hasReadPermission: (systemModule: Permission) => boolean;
  hasWritePermission: (systemModule: Permission) => boolean;
} {
  const permissions = useSelector(permissionsSelector);
  const hasReadPermission = useCallback((systemModule: Permission) => permissions.some((p) => p.system_module === systemModule && p.access_type > 0), [permissions]);
  const hasWritePermission = useCallback((systemModule: Permission) => permissions.some((p) => p.system_module === systemModule && p.access_type > 1), [permissions]);

  return {
    hasReadPermission,
    hasWritePermission,
  };
}
