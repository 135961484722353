export const excelBillingHeader = [
  { label: 'Auðkenni færslu', key: 'audkenni_faerslu' },
  { label: 'Bókunar ár', key: 'bokunar_ar' },
  { label: 'Bókunar mánuður', key: 'bokunar_manudur' },
  { label: 'Bókunar byrjun', key: 'bokunar_byrjun' },
  { label: 'Bókunar endir', key: 'bokunar_endir' },
  { label: 'Bunki', key: 'bunki' },
  { label: 'Kostnaður', key: 'kostnstadur' },
  { label: 'Skóli', key: 'skoli' },
  { label: 'Nemandi', key: 'nemandi' },
  { label: 'Kennitala nemanda', key: 'kennitala_nemanda' },
  { label: 'Færsla ár', key: 'faersla_ar' },
  { label: 'Færsla mánuður', key: 'faersla_manudur' },
  { label: 'Gögn mynduð', key: 'gogn_myndud' },
  { label: 'Eining', key: 'eining' },
  { label: 'Lýsing', key: 'lysing' },
  { label: 'Upphæð', key: 'upphaed' },
  { label: 'Bókhaldslykill', key: 'bokhaldslykill' },
  { label: 'Hlutfall', key: 'hlutfall' },
  { label: 'Tímabil byrjar', key: 'timabil_byrjar' },
  { label: 'Tímabil endar', key: 'timabil_endar' },
  { label: 'Kennitala greiðanda', key: 'kennitala_greidanda' },
  { label: 'Netfang greiðanda', key: 'netfang_greidanda' },
  { label: 'Merking', key: 'merking' },
  { label: 'Dags. stofnað', key: 'dags_stofnad' },
  { label: 'Stofnað af', key: 'stofnad_af' },
  { label: 'Farið', key: 'farid' },
  { label: 'Farið dags.', key: 'farid_dags' },
  { label: 'Api key id', key: 'api_key_id' },
  { label: 'Customer', key: 'customer' },
  { label: 'Vöruflokkur', key: 'voruflokkur' },
];

export const excelMonthlyBillingOverview = [
  { label: 'Skóli', key: 'school' },
  { label: 'Óstaðfest', key: 'unverified_amount' },
  { label: 'Staðfest', key: 'verified_amount' },
  { label: 'Tilbúið fyrir bókhald', key: 'ready_amount' },
  { label: 'Sent í bókhald', key: 'total_amount' },
  { label: 'Heildarupphæð', key: 'total_sum' },
];

export default { excelBillingHeader, excelMonthlyBillingOverview };
