import React, { useCallback, useMemo, useState } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import * as Yup from 'yup';
import { restValidationToForm } from '../utils/formUtils';
import { getNatRegAll, postUnlistedStudent } from '../api/unlistedStudents';
import { PostUnlistedStudent, StudentNatReg, UnlistedStudent, UnlistedStudentMentor } from '../api/unlistedStudents.types';
import { sexFormatter } from '../utils/tableUtils';

interface Props {
  originalValues: UnlistedStudentMentor | undefined;
  onClose: (created: boolean, type: string) => void;
}
interface Values {
  ssn: string | undefined;
  name: string | undefined;
  familynumber: string;
  /*  address: string | undefined;
  postal_code: string | undefined;
  sex: string | undefined; */
}

const AdddRealSSNForUnlistedStudentModal = ({ originalValues, onClose }: Props): React.ReactElement => {
  const { t } = useTranslation(['unlistedStudents', 'common']);
  const [studentInfo, setStudentInfo] = useState<StudentNatReg | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const initialValues = {
    ssn: originalValues ? originalValues.student_ssn : undefined,
    name: originalValues ? originalValues.student_name : undefined,
    familynumber: '',
    /*    address: undefined,
    postal_code: undefined,
    sex: undefined, */
  };
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      ssn: Yup.string().required(t('errors:emptyInputField')),
      familynumber: Yup.string().required(t('errors:emptyInputField')),
    });
  }, [t]);

  const loadNatRegAll = useCallback(async (ssnNatReg: string) => {
    setLoading(true);
    setError(false);
    try {
      const response = await getNatRegAll(ssnNatReg);
      setStudentInfo(response.data.items[0]);
    } catch (e) {
      setError(true);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const debouncedFilter = useCallback(
    debounce((ssnNatReg: string) => loadNatRegAll(ssnNatReg), 500),
    [loadNatRegAll]
  );

  const onCheckSSN = (value: Partial<string>) => {
    debouncedFilter(value);
  };

  return (
    <>
      <Formik<Values>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<Values>) => {
          try {
            const postValues: PostUnlistedStudent = {
              ssn: values.ssn,
              name: values?.name ? values.name : studentInfo?.name,
              familynumber: values.familynumber,
              /*  address: studentInfo?.address,
              postalcode: studentInfo?.postal_code,
              sex: studentInfo?.sex,
              fake_ssn: '0', */
            };
            await postUnlistedStudent(postValues);
            toast.success(t('realSsnAdded'));
            onClose(true, 'real');
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
            toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps }) => (
          <Modal show onHide={() => onClose(false, 'fake')} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{t('addRealSSN')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Table>
                  <tbody>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('ssn')}*</Form.Label>
                      </td>
                      <td>
                        {originalValues && initialValues.ssn}
                        {!originalValues && (
                          <>
                            <Form.Control
                              type="text"
                              value={values.ssn}
                              onChange={(event) => {
                                setFieldValue('ssn', event.target.value);
                                if (event.target.value.length === 10) {
                                  onCheckSSN(event.target.value);
                                }
                              }}
                            />
                            <ErrorMessage name="ssn" component="div" className="invalid-feedback d-block text-left" />
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('name')}</Form.Label>
                      </td>
                      <td>
                        {originalValues && initialValues.name}
                        {!originalValues && (studentInfo?.name ? <span>{studentInfo.name}</span> : <div>{t('addSSNToGetInfo')}</div>)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('familynumber')}*</Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={values.familynumber}
                          onChange={(event) => {
                            setFieldValue('familynumber', event.target.value);
                          }}
                        />
                        <ErrorMessage name="familynumber" component="div" className="invalid-feedback d-block text-left" />
                      </td>
                    </tr>
                    {/*                <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('address')}</Form.Label>
                      </td>
                      <td>{studentInfo?.address ? <span>{studentInfo.address}</span> : <div>{t('addSSNToGetInfo')}</div>}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('postalcode')}</Form.Label>
                      </td>
                      <td>{studentInfo?.postal_code ? <span>{studentInfo.postal_code}</span> : <div>{t('addSSNToGetInfo')}</div>}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: '0', verticalAlign: 'inherit' }}>
                        <Form.Label>{t('sex')}</Form.Label>
                      </td>
                      <td>{studentInfo?.sex ? <span>{sexFormatter(studentInfo.sex)}</span> : <div>{t('addSSNToGetInfo')}</div>}</td>
                    </tr> */}
                  </tbody>
                </Table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:save')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false, 'real')}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AdddRealSSNForUnlistedStudentModal;
