import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEnvelope, faSync, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Spinner, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import usePermission, { Permission } from '../../hooks/usePermission';
import { BatchType } from '../../api/invoicing.types';
import { runBatches } from '../../api/billing';
import { useAppDispatch } from '../../hooks/useState';
import { fetchBatch, fetchBatchSummaries } from '../../store/invoicing/invoicingSlice';

interface Props {
  row: BatchType;
}

const InvoicingCellStatus: React.FC<Props> = ({ row }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['invoiceView']);
  const { hasWritePermission } = usePermission();
  const [loading, setLoading] = useState(false);

  const postSingleBatchData = useCallback(async () => {
    setLoading(true);
    try {
      await runBatches({
        run_last_month: '1',
        batches: JSON.stringify([{ batch_id: row.batch_id }]),
      });

      await dispatch(fetchBatch(row.batch_id));
      await dispatch(
        fetchBatchSummaries({
          batchId: row.batch_id,
        })
      );
    } catch (e) {
      toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
    } finally {
      setLoading(false);
    }
  }, [t, row.batch_id, dispatch]);

  return (
    <>
      {hasWritePermission(Permission.REIKNINGAGERD) && row.status === 3 && (
        <Button style={{ padding: '0', color: '#6e707e' }} variant="link" onClick={postSingleBatchData}>
          <FontAwesomeIcon icon={loading ? faSpinner : faSync} fixedWidth style={{ display: 'inline', marginTop: '3px' }} spin={loading} />
        </Button>
      )}
      {row.status === 1 && <FontAwesomeIcon icon={faCheck} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />}
      {row.status === 2 && <FontAwesomeIcon icon={faCheck} fixedWidth style={{ display: 'inline', marginTop: '3px', color: '#1cc88a' }} />}
      {row.status === 5 && <FontAwesomeIcon icon={faEnvelope} fixedWidth style={{ display: 'inline', marginTop: '3px', color: '#1cc88a' }} />}
      {row.status === 4 && <FontAwesomeIcon icon={faEnvelope} fixedWidth style={{ display: 'inline', marginTop: '3px' }} />}
      {row.status === 6 && (
        <div>
          <Spinner animation="border" role="status" size="sm">
            <span className="sr-only">{t('common:loading')}</span>
          </Spinner>
        </div>
      )}
    </>
  );
};

export default InvoicingCellStatus;
