import React, { useEffect, useMemo, useReducer, useState } from 'react';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Alert, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import * as Yup from 'yup';
import { restValidationToForm } from '../utils/formUtils';
import { BatchType } from '../api/invoicing.types';
import useDebounce from '../hooks/useDebounce';
import { AddManualDiscountMultiData, AddMultiStudentType, ManualDiscountMultiStudent } from '../api/billing.types';
import { addManualDiscountMulti } from '../api/billing';

interface Props {
  batch: BatchType | undefined;
  batchSummary: any;
  onClose: (created: boolean) => void;
}

interface Values {
  batch: string | undefined;
  batchSummary: any;
  description: string;
  jointDiscount: number;
}

type ListDataAction =
  | {
      type: 'updateState';
      payload: AddMultiStudentType[];
    }
  | {
      type: 'updateValue';
      payload: AddMultiStudentType;
    }
  | {
      type: 'updateAllValues';
      payload: string;
    }
  | {
      type: 'selectStudent';
      payload: AddMultiStudentType;
    }
  | {
      type: 'selectDeSelectAllStudents';
      payload: boolean;
    };

const AddMultiChildrenDiscountModal = ({ batch, batchSummary, onClose }: Props): React.ReactElement => {
  const { t } = useTranslation(['invoiceView', 'common']);
  const { debounce } = useDebounce();
  const [showExplanation, setShowExplanation] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      jointDiscount: Yup.number().typeError(t('errors:emptyInputField')).required(t('errors:discountMustBeNumber')),
      description: Yup.string().required(t('errors:emptyInputField')),
    });
  }, [t]);

  const listDataReducer = (state: AddMultiStudentType[], action: ListDataAction) => {
    if (action.type === 'updateValue') {
      const { discount, student } = action.payload;
      const newState = state.map((i: AddMultiStudentType) => {
        if (i.student === student) {
          return { ...i, discount };
        }
        return i;
      });
      return newState;
    }
    if (action.type === 'updateAllValues') {
      const discount = action.payload;
      const newState = state.map((i: AddMultiStudentType) => {
        return { ...i, discount };
      });
      return newState;
    }
    if (action.type === 'selectStudent') {
      const { selected, student } = action.payload;
      const newState = state.map((i: AddMultiStudentType) => {
        if (i.student === student) {
          return { ...i, selected };
        }
        return i;
      });
      return newState;
    }
    if (action.type === 'selectDeSelectAllStudents') {
      const selected = action.payload;
      const newState = state.map((i: AddMultiStudentType) => {
        return { ...i, selected };
      });
      return newState;
    }
    if (action.type === 'updateState') {
      return action.payload;
    }
    return state;
  };
  const [state, dispatch] = useReducer(listDataReducer, []);

  const initialValues = {
    batch: batch?.batch_id,
    batchSummary,
    description: '',
    jointDiscount: NaN,
  };

  useEffect(() => {
    dispatch({ type: 'updateState', payload: batchSummary });
  }, [batchSummary]);

  useEffect(() => (state.some((i) => i.selected) ? setIsDisabled(false) : setIsDisabled(true)), [state]);

  const bulkDiscountColumn = [
    {
      dataField: 'id',
      isDummyField: true,
      text: '',
      headerStyle: { width: '35px' },
      formatter: (cell: string, row: any, rowIndex: any) => {
        return `${rowIndex + 1}.`;
      },
      editable: false,
    },
    {
      dataField: 'student_name',
      text: t('name'),
      sort: true,
      editable: false,
    },
    {
      dataField: 'student_ssn',
      text: t('ssn'),
      sort: true,
      editable: false,
    },
    {
      dataField: 'discount',
      text: t('discount'),
      editable: true,
      editorRenderer: (editorProps: any, value: any, row: any, column: any, rowIndex: number, columnIndex: any) => {
        return (
          <input
            type="number"
            defaultValue={value}
            onChange={(e: any) => {
              if (state) {
                debounce(() => {
                  const newState: AddMultiStudentType = { ...row, discount: e.target.value };
                  dispatch({ type: 'updateValue', payload: newState });
                }, 1000);
              }
            }}
            onFocus={(e) => e.target.select()}
          />
        );
      },
    },
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<Values>) => {
          try {
            const newState: ManualDiscountMultiStudent[] = state
              .filter((item: AddMultiStudentType) => item.selected === true)
              .map((i: AddMultiStudentType) => {
                return { student: Number(i.student), student_ssn: i.student_ssn, discount: Number(i.discount) };
              });
            const formattedValues: AddManualDiscountMultiData = { batch: values.batch, discount_description: values.description, discount: newState };
            await addManualDiscountMulti(formattedValues);
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
            toast.error(`${t('common:informationError')}. ${e.response.data.error ? e.response.data.error : t('errors:tryAgainLater')}.`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setFieldValue, setStatus, values }) => (
          <Modal size="lg" show onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('multiAddDiscount')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Form.Group as={Row} className="mb-3" controlId="description">
                  <Form.Label column sm={3}>
                    {t('description')}
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      value={values.description}
                      onChange={(event) => {
                        setFieldValue('description', event.target.value);
                      }}
                    />
                    <ErrorMessage name="description" component="div" className="invalid-feedback d-block text-left" />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="jointDiscount">
                  <Form.Label column sm={3}>
                    {t('jointDiscount')}
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="number"
                      value={values.jointDiscount}
                      onChange={(event) => {
                        debounce(() => {
                          dispatch({ type: 'updateAllValues', payload: event.target.value });
                        }, 1000);
                        setFieldValue('jointDiscount', event.target.value);
                      }}
                    />
                    <ErrorMessage name="jointDiscount" component="div" className="invalid-feedback d-block text-left" />
                  </Col>
                </Form.Group>
                <Row className="pull-right">
                  <Col sm={{ span: 12 }} style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button className="mb-3 " variant="primary" onClick={() => setShowExplanation(!showExplanation)}>
                      {t('explanation')}
                    </Button>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={{ span: 12 }}>
                    {showExplanation && (
                      <Alert variant="warning" className="alert-box">
                        <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '5px' }} />
                        <span>{t('explanationText')}</span>
                      </Alert>
                    )}
                  </Col>
                </Row>
                {state && (
                  <div className="whiteColumn">
                    <BootstrapTable
                      bootstrap4
                      keyField="student_ssn"
                      data={state}
                      columns={bulkDiscountColumn}
                      noDataIndication={t('common:noDataFound')}
                      striped
                      bordered={false}
                      selectRow={{
                        mode: 'checkbox',
                        clickToSelect: false,
                        onSelect: (row, isSelect) => {
                          if (isSelect) {
                            const newState: AddMultiStudentType = { ...row, selected: true };
                            dispatch({ type: 'selectStudent', payload: newState });
                          } else {
                            const newState: AddMultiStudentType = { ...row, selected: false };
                            dispatch({ type: 'selectStudent', payload: newState });
                          }
                        },
                        onSelectAll: (isSelect, rows, e) => {
                          if (isSelect) {
                            dispatch({ type: 'selectDeSelectAllStudents', payload: true });
                          } else {
                            dispatch({ type: 'selectDeSelectAllStudents', payload: false });
                          }
                        },
                      }}
                      cellEdit={cellEditFactory({
                        mode: 'click',
                        blurToSave: false,
                      })}
                    />
                  </div>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                disabled={isDisabled}
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:confirm')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddMultiChildrenDiscountModal;
