import axios from 'axios';

declare module 'axios' {
  export interface AxiosRequestConfig {
    requireAuth?: boolean;
    skipRefresh?: boolean;
  }
}

const instance = axios.create({
  baseURL: `/`,
});

export default instance;
