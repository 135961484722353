import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Col } from 'react-bootstrap';
import { restValidationToForm } from '../utils/formUtils';
import { createNewSchool, getAvailableFoodTypes } from '../api/schools';
import { FoodType } from '../api/schools.types';

interface Props {
  onClose: (created: boolean) => void;
}

interface Values {
  cost_place: string;
  email: string;
  name: string;
  ssn: string;
  food_types: Record<number, boolean>;
}

const CreateNewSchoolModal = ({ onClose }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['schoolsView', 'errors']);
  const [loadingFoodTypes, setLoadingFoodTypes] = useState<boolean>(false);
  const [errorFoodTypes, setErrorFoodTypes] = useState<boolean>(false);
  const [availableFoodTypes, setAvailableFoodTypes] = useState<FoodType[]>();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      cost_place: Yup.string().required(t('errors:emptyInputField')),
      email: Yup.string().email(t('errors:invalidEmail')).required(t('errors:emptyInputField')),
      name: Yup.string().required(t('errors:emptyInputField')),
      ssn: Yup.string().required(t('errors:emptyInputField')),
      // ssn: Yup.string().required(t('errors:emptyInputField')).min(10, t('errors:ssnInvalidForm')).max(10, t('errors:ssnInvalidForm')),
    });
  }, [t, i18n.language]);

  const getFoodTypes = useCallback(async () => {
    setLoadingFoodTypes(true);
    setErrorFoodTypes(false);
    try {
      const response = await getAvailableFoodTypes();
      setAvailableFoodTypes(response.data.items);
    } catch {
      toast.error(t('resetPinError'));
      setErrorFoodTypes(true);
    } finally {
      setLoadingFoodTypes(false);
    }
  }, [t]);

  const initialValues: Values = {
    cost_place: '',
    email: '',
    name: '',
    ssn: '',
    food_types:
      availableFoodTypes?.reduce((acc: Record<number, boolean>, curr: FoodType) => {
        acc[curr.food_type_id] = false;
        return acc;
      }, {}) || {},
  };

  useEffect(() => {
    getFoodTypes();
  }, [getFoodTypes]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await createNewSchool({
              ...values,
              food_types: JSON.stringify(Object.entries(values.food_types).map(([k, v]) => ({ food_type_id: Number(k), enabled: v ? '1' : '0' }))),
            });
            toast.success(t('schoolCreated'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('createModalTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Form.Group>
                  <Form.Label>
                    {t('ssn')}
                    <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control type="text" onChange={(e) => setFieldValue('ssn', e.currentTarget.value)} />
                  <ErrorMessage name="ssn" component="div" className="invalid-feedback d-block text-left" />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {t('name')}
                    <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control type="text" onChange={(e) => setFieldValue('name', e.currentTarget.value)} />
                  <ErrorMessage name="name" component="div" className="invalid-feedback d-block text-left" />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {t('email')}
                    <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control type="text" onChange={(e) => setFieldValue('email', e.currentTarget.value)} />
                  <ErrorMessage name="email" component="div" className="invalid-feedback d-block text-left" />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {t('cost_place')}
                    <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control type="text" onChange={(e) => setFieldValue('cost_place', e.currentTarget.value)} />
                  <ErrorMessage name="cost_place" component="div" className="invalid-feedback d-block text-left" />
                </Form.Group>
                {availableFoodTypes && availableFoodTypes.length > 0 && (
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>{t('availableSpecialFoodRequests')}</Form.Label>
                      {availableFoodTypes?.map((type: FoodType) => (
                        <div key={`${type.food_type_id}`}>
                          <Form.Check
                            label={type.food_type}
                            name={type.food_type}
                            type="checkbox"
                            id={`${type.food_type_id}`}
                            checked={values.food_types[type.food_type_id]}
                            onChange={(event) => {
                              setFieldValue(`food_types.${type.food_type_id}`, event.currentTarget.checked);
                            }}
                          />
                        </div>
                      ))}
                    </Form.Group>
                  </Form.Row>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('create')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default CreateNewSchoolModal;
