import React, { useCallback, useEffect, useState } from 'react';

import { Button, Form, Modal, Spinner, Table } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { restValidationToForm } from '../utils/formUtils';
import { TemplateItem, Variables } from '../api/templates.types';
import { getVariables, updateTemplate } from '../api/templates';

interface Values {
  description: string;
  id: number;
  name: string;
  send_guardians: boolean;
  send_school: boolean;
  subject: string;
}

interface Props {
  onClose: (created: boolean) => void;
  originalValues: TemplateItem;
}
const EditTemplate = ({ originalValues, onClose }: Props): React.ReactElement => {
  const { t } = useTranslation(['templatesView', 'errors']);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [variables, setVariables] = useState<Variables[]>();
  const [text, setText] = useState<string | undefined>();

  const initialValues: Values = {
    description: originalValues.description,
    id: originalValues.id,
    name: originalValues.name,
    send_guardians: originalValues.send_guardians === '1',
    send_school: originalValues.send_school === '1',
    subject: originalValues.subject,
    // template: originalValues.template,
  };

  const loadVariables = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getVariables();
      setVariables(response.data.items);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const { quill, quillRef } = useQuill();

  useEffect(() => {
    if (quill) {
      if (originalValues.template !== undefined && originalValues.template !== null) {
        setText(originalValues.template.replace(/\n/g, '<br />'));
        quill.clipboard.dangerouslyPasteHTML(originalValues.template.replace(/\n/g, '<br />'));
      }
      quill.on('text-change', () => {
        setText(quill.getText());
      });
    }
  }, [quill]);

  const insertText = (description: any) => {
    const selection = quill?.getSelection(true);
    if (selection) {
      quill?.insertText(selection.index, description);
    }
  };

  useEffect(() => {
    loadVariables();
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<Values>) => {
          try {
            await updateTemplate({ ...values, send_guardians: values.send_guardians ? '1' : '0', send_school: values.send_school ? '1' : '0', template: text });
            toast.success(t('templateUpdated'));
            onClose(true);
          } catch (e) {
            toast.error(`${t('common:internalError')}. ${e.response.data.error}.`);
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
              errorFieldMap: {
                name: 'name',
              },
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{t('updateTemplate')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <Table className="templateTable borderTopNoneTable">
                  <tbody>
                    <tr>
                      <td style={{ borderTop: 'none' }}>
                        <Form.Group>
                          <Form.Label>
                            <span style={{ fontWeight: 'bold' }}>{t('name')}</span>
                          </Form.Label>
                          <Form.Control type="text" value={values.name} disabled />
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderTop: 'none' }}>
                        <Form.Group>
                          <Form.Label>
                            <span style={{ fontWeight: 'bold' }}>{t('description')}</span>
                          </Form.Label>
                          <Form.Control type="text" value={values.description} onChange={(e) => setFieldValue('description', e.currentTarget.value)} />
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderTop: 'none' }}>
                        <Form.Group>
                          <Form.Label>
                            <span style={{ fontWeight: 'bold' }}>{t('description')}</span>
                          </Form.Label>
                          <Form.Check
                            type="checkbox"
                            id="send_guardians"
                            name="send_guardians"
                            label={t('send_guardians')}
                            onChange={(e) => {
                              setFieldValue('send_guardians', e.currentTarget.checked);
                            }}
                            checked={values.send_guardians}
                          />
                          <Form.Check
                            type="checkbox"
                            id="send_school"
                            name="send_school"
                            label={t('send_school')}
                            onChange={(e) => {
                              setFieldValue('send_school', e.currentTarget.checked);
                            }}
                            checked={values.send_school}
                          />
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderTop: 'none' }}>
                        <Form.Group>
                          <Form.Label>
                            <span style={{ fontWeight: 'bold' }}>{t('subject')}</span>
                          </Form.Label>
                          <Form.Control type="text" value={values.subject} onChange={(e) => setFieldValue('subject', e.currentTarget.value)} />
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderTop: 'none' }}>
                        <Form.Group>
                          <Form.Label>
                            <span style={{ fontWeight: 'bold' }}>{t('template')}</span>
                          </Form.Label>
                          {/* <Form.Control> */}
                          <div style={{ width: 750, height: 750, paddingBottom: '50px' }}>
                            <div ref={quillRef} />
                          </div>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {loading && !variables && !error && (
                          <div className="spinner">
                            <Spinner animation="border" role="status">
                              <span className="sr-only">{t('common:loading')}</span>
                            </Spinner>
                          </div>
                        )}
                        {!loading && !variables && <div>{t('noDataFound')}</div>}
                        {!loading &&
                          variables &&
                          variables?.map((item, idx: number) => {
                            return (
                              <div
                                role="button"
                                tabIndex={idx}
                                key={idx}
                                onClick={() => {
                                  insertText(item.description);
                                }}
                                onKeyDown={() => {
                                  insertText(item.description);
                                }}
                              >
                                {item.description} <br />
                              </div>
                            );
                          })}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:submit')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default EditTemplate;
