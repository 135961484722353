/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import { getAuthorizationList } from '../../api/access';

interface State {
  authorizationList: any | null;
  authorizationListLoading: 'idle' | 'pending';
  authorizationListError: null | SerializedError;
}

export const fetchAuthorizationList = createAsyncThunk<
  any,
  void,
  {
    state: RootState;
  }
>('authorizationList/fetchAuthorizationList', async () => {
  const response = await getAuthorizationList();
  return response.data;
});

const initialState: State = {
  authorizationList: null,
  authorizationListLoading: 'idle',
  authorizationListError: null,
};

const slice = createSlice({
  name: 'getAuthorizationList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /*
     * fetchUsers
     */
    builder.addCase(fetchAuthorizationList.pending, (state) => {
      state.authorizationList = null;
      state.authorizationListError = null;
      state.authorizationListLoading = 'pending';
    });
    builder.addCase(fetchAuthorizationList.fulfilled, (state, { payload }) => {
      state.authorizationList = payload;
      state.authorizationListLoading = 'idle';
    });
    builder.addCase(fetchAuthorizationList.rejected, (state, action) => {
      state.authorizationListError = action.error;
      state.authorizationListLoading = 'idle';
    });
  },
});

export default slice.reducer;
