import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, Modal, Row, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { restValidationToForm } from '../utils/formUtils';
import { getExtraProducts, updateExtraProducts } from '../api/schools';
import DatePicker from '../components/DatePicker';

interface Props {
  onClose: (created: boolean) => void;
  id: number | null;
}

interface Values {
  items: {
    billing_type: string;
    discount_category: string;
    external_account_key: string;
    product_category: string;
    product_id: number;
    product_name: string;
    school_id: number | null;
    prices: {
      price: number | undefined;
      end_date: Date | undefined;
      product_price_id: number;
      start_date: Date | undefined;
    }[];
  }[];
}

const EditExtraProductsModal = ({ onClose, id }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['extraProducts', 'common']);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<Values | undefined>();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      items: Yup.array().of(
        Yup.object().shape({
          product_name: Yup.string().required(t('errors:emptyInputField')),
          discount_category: Yup.string().required(t('errors:emptyInputField')),
          external_account_key: Yup.string().required(t('errors:emptyInputField')),
          prices: Yup.array().of(
            Yup.object().shape({
              start_date: Yup.date().required(t('errors:emptyInputField')).nullable(),
              end_date: Yup.date().min(Yup.ref('start_date'), t('errors:endDateBeforeStartDate')),
              price: Yup.number().required(t('errors:emptyInputField')),
            })
          ),
          product_category: Yup.string().required(t('errors:emptyInputField')),
        })
      ),
    });
  }, [t, i18n.language]);
  const getExtraProductsInfo = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getExtraProducts(id);
      setInitialValues({
        items: response.data.items.map((item) => {
          return {
            billing_type: item.billing_type,
            discount_category: item.discount_category,
            external_account_key: item.external_account_key,
            product_id: item.product_id,
            product_category: item.product_category,
            product_name: item.product_name,
            school_id: id,
            prices: item.prices.map((p) => ({
              end_date: p.end_date === null ? undefined : dayjs(p.end_date).toDate(),
              price: p.price,
              product_price_id: p.product_price_id,
              start_date: dayjs(p.start_date).toDate(),
            })),
          };
        }),
      });
    } catch {
      toast.error(t('informationError'));
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getExtraProductsInfo();
  }, [getExtraProductsInfo]);

  const dateDisabledCheck = (values: Values, itemIdx: number, priceIdx: number) => {
    const len = values?.items.map((i) => i.prices)[itemIdx].length;
    if (len) {
      return len <= 1 ? false : len - 1 > priceIdx;
    }
    return false;
  };
  if (initialValues == null) {
    return (
      <div>
        <Spinner animation="border" role="status" size="sm">
          <span className="sr-only">{t('common:loading')}</span>
        </Spinner>
      </div>
    );
  }
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            const items = values.items.map((item) => ({
              billing_type: item.billing_type,
              discount_category: item.discount_category,
              external_account_key: item.external_account_key,
              product_category: item.product_category,
              product_id: item.product_id,
              product_name: item.product_name,
              school_id: id,
              prices: JSON.stringify(
                item.prices.map((p) => ({
                  end_date: p.end_date ? dayjs(p.end_date).format('DD.MM.YYYY') : null,
                  price: p.price,
                  product_price_id: p.product_price_id,
                  start_date: dayjs(p.start_date).format('DD.MM.YYYY'),
                }))
              ),
            }));
            const activePromises = items.map((i: any) => {
              return updateExtraProducts(i);
            });

            await Promise.all(activePromises);
            toast.success(t('editExtraProductsSuccess'));
            onClose(true);
          } catch (e) {
            toast.error(`${t('common:informationChangedError')}. ${e.response.data.error !== undefined ? e.response.data.error : ''}.`);
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps }) => (
          <Modal show size="xl" onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('extraProductsModalTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                {values.items.map((item, itemIdx) => {
                  return (
                    <div key={itemIdx + 1}>
                      {item.prices.map((it, priceIdx) => {
                        return (
                          <>
                            <Row>
                              <Col>
                                {priceIdx > 0 && !it.product_price_id && (
                                  <div style={{ textAlign: 'right' }}>
                                    <FontAwesomeIcon
                                      onClick={() => {
                                        const array = [...values.items[itemIdx].prices];
                                        array.splice(priceIdx, 1);
                                        setFieldValue(`items[${itemIdx}].prices`, array);
                                      }}
                                      v-if="icon"
                                      className="icon"
                                      fixedWidth
                                      icon={faTrashAlt}
                                    />
                                  </div>
                                )}
                                <>
                                  {priceIdx === 0 && (
                                    <Form.Group>
                                      <Form.Label>
                                        {t('extraProduct')}
                                        <span style={{ color: 'red' }}>*</span>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        value={item.product_name}
                                        disabled={item.product_id !== null}
                                        onChange={(e: any) => setFieldValue(`items[${itemIdx}].product_name`, e.currentTarget.value)}
                                      />
                                      <ErrorMessage name={`items[${itemIdx}].product_name`} component="div" className="invalid-feedback d-block text-left" />
                                    </Form.Group>
                                  )}
                                </>
                              </Col>
                              <Col>
                                {priceIdx > 0 && <Col />}
                                <Form.Group as={Col}>
                                  {priceIdx === 0 && (
                                    <Form.Label>
                                      {t('start_date')}
                                      <span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                  )}
                                  <div className="dateWrapper">
                                    <DatePicker
                                      style={{ display: 'inline', width: '100%' }}
                                      value={it.start_date}
                                      selectedDay={it.start_date}
                                      onDayChange={(day: Date | undefined) => {
                                        setFieldValue(`items[${itemIdx}].prices[${priceIdx}].start_date`, day || null);
                                        if (priceIdx > 0) {
                                          const newdate = dayjs(day).subtract(1, 'day').toDate();
                                          setFieldValue(`items[${itemIdx}].prices[${priceIdx - 1}].end_date`, newdate || null);
                                        }
                                      }}
                                    />
                                  </div>
                                  <ErrorMessage name={`items[${itemIdx}].start_date`} component="div" className="invalid-feedback d-block text-left" />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group as={Col}>
                                  {priceIdx === 0 && <Form.Label>{t('end_date')}</Form.Label>}
                                  <div className="dateWrapper">
                                    <DatePicker
                                      style={{ display: 'inline', width: '100%' }}
                                      value={it.end_date}
                                      selectedDay={it.end_date}
                                      inputProps={{ disabled: dateDisabledCheck(values, itemIdx, priceIdx) }}
                                      onDayChange={(day: Date) => {
                                        setFieldValue(`items[${itemIdx}].prices[${priceIdx}].end_date`, day);
                                      }}
                                    />
                                  </div>
                                  <ErrorMessage name={`items[${itemIdx}].prices[${priceIdx}].end_date`} component="div" className="invalid-feedback d-block text-left" />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group as={Col}>
                                  {priceIdx === 0 && (
                                    <Form.Label>
                                      {item.billing_type}
                                      <span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                  )}
                                  <Form.Control
                                    type="number"
                                    value={it.price}
                                    onChange={(e) => setFieldValue(`items[${itemIdx}].prices[${priceIdx}].price`, e.currentTarget.value)}
                                  />
                                  <ErrorMessage name={`items[${itemIdx}].prices[${priceIdx}].price`} component="div" className="invalid-feedback d-block text-left" />
                                </Form.Group>
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                      <Row>
                        <Col />
                        <Col md={6}>
                          <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{t('clickHereForNewProductPeriod')}</Tooltip>}>
                            <Button
                              variant="link"
                              onClick={() => {
                                setFieldValue(`items[${itemIdx}].prices`, [
                                  ...values.items[itemIdx].prices,
                                  {
                                    price: undefined,
                                    end_date: undefined,
                                    product_price_id: null,
                                    start_date: dayjs().toDate(),
                                  },
                                ]);
                                const len = values?.items.map((i) => i.prices)[itemIdx].length;
                                const newdate = dayjs().subtract(1, 'day').toDate();
                                setFieldValue(`items[${itemIdx}].prices[${len - 1}].end_date`, newdate || null);
                              }}
                            >
                              <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faPlus} />
                              {t('addNewPeriod')}
                            </Button>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Group as={Col}>
                          <Form.Label>
                            {t('external_account_key')}
                            <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={item.external_account_key}
                            onChange={(e: any) => setFieldValue(`items[${itemIdx}].external_account_key`, e.currentTarget.value)}
                          />
                          <ErrorMessage name={`items[${itemIdx}].external_account_key`} component="div" className="invalid-feedback d-block text-left" />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>
                            {t('product_category')}
                            <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={item.product_category}
                            onChange={(e: any) => setFieldValue(`items[${itemIdx}].product_category`, e.currentTarget.value)}
                          />
                          <ErrorMessage name={`items[${itemIdx}].product_category`} component="div" className="invalid-feedback d-block text-left" />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>
                            {t('discount_category')}
                            <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={item.discount_category}
                            onChange={(e: any) => setFieldValue(`items[${itemIdx}].discount_category`, e.currentTarget.value)}
                          />
                          <ErrorMessage name={`items[${itemIdx}].discount_category`} component="div" className="invalid-feedback d-block text-left" />
                        </Form.Group>
                      </Row>
                      {!item.product_id && (
                        <div style={{ textAlign: 'right' }}>
                          <FontAwesomeIcon
                            onClick={() => {
                              const array = [...values.items];
                              array.splice(itemIdx, 1);
                              setFieldValue('items', array);
                            }}
                            v-if="icon"
                            className="icon"
                            fixedWidth
                            icon={faTrashAlt}
                          />
                        </div>
                      )}
                      <hr />
                    </div>
                  );
                })}
                <>
                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{t('clickHereForNewProduct')}</Tooltip>}>
                    <button
                      className="btn btn-link"
                      type="button"
                      style={{ margin: '0 auto', display: 'block' }}
                      onClick={() => {
                        setFieldValue('items', [
                          ...values.items,
                          {
                            billing_type: initialValues?.items[0] ? initialValues?.items[0].billing_type : 'Gjald',
                            discount_category: '',
                            external_account_key: '',
                            product_id: null,
                            product_category: '',
                            product_name: '',
                            school_id: null,
                            prices: [
                              {
                                price: undefined,
                                end_date: undefined,
                                product_price_id: null,
                                start_date: undefined,
                              },
                            ],
                          },
                        ]);
                      }}
                    >
                      <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faPlus} />
                      {t('add')}
                    </button>
                  </OverlayTrigger>
                </>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('save')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};
export default EditExtraProductsModal;
