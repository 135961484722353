import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import ReleaseManagementModal from '../views/ReleaseManagementModal';

const ReleaseManagement = (): React.ReactElement => {
  const { t } = useTranslation('versions');
  const [showReleaseManagementModal, setShowReleaseManagementModal] = useState<boolean>(false);

  return (
    <>
      {showReleaseManagementModal && <ReleaseManagementModal onClose={() => setShowReleaseManagementModal(false)} />}
      <div className="nav-item">
        <Button variant="link" className="nav-link" onClick={() => setShowReleaseManagementModal(true)}>
          <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faStar} />
          {t('versionsSidebarTitle')}
        </Button>
      </div>
    </>
  );
};

export default ReleaseManagement;
