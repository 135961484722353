/* eslint-disable no-param-reassign */
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, EntityState, PayloadAction, SerializedError } from '@reduxjs/toolkit';

import { getAllBillingBatches, getBatchInfo, getBatchSummary, getBillingPeriods } from '../../api/billing';
import { BatchType } from '../../api/invoicing.types';
import { BatchSummary, BillingPeriodTypes } from '../../api/billing.types';
import { RootState } from '../rootReducer';
import { AppThunk } from '../store';

export interface BatchSummaryWithBatchId {
  batchId: string;
  summary: BatchSummary[];
}

interface State {
  batchesLoading: 'idle' | 'pending';
  batchesError: null | SerializedError;
  batches: EntityState<BatchType>;
  batchesSelected: Array<EntityId>;

  batchLoading: Record<EntityId, 'idle' | 'pending'>;
  batchError: Record<EntityId, null | SerializedError>;

  batchSummariesLoading: Record<EntityId, 'idle' | 'pending'>;
  batchSummariesError: Record<EntityId, null | SerializedError>;
  batchSummaries: EntityState<BatchSummaryWithBatchId>;

  billingPeriodsLoading: 'idle' | 'pending';
  billingPeriodsError: null | SerializedError;
  billingPeriods: EntityState<BillingPeriodTypes>;
  billingPeriodsChosen: string | null;
}

export const fetchBillingPeriods = createAsyncThunk<
  {
    data: BillingPeriodTypes[];
    initialChosen: string | null;
  },
  {
    initialChosen: string | null;
  },
  {
    state: RootState;
  }
>('invoicing/billingPeriods', async ({ initialChosen }) => {
  const response = await getBillingPeriods(null);
  return {
    initialChosen,
    data: response.data.items,
  };
});

export const fetchBatches = createAsyncThunk<
  BatchType[],
  {
    chosenPeriod: number | null | undefined;
    str: string | undefined;
  },
  {
    state: RootState;
  }
>('invoicing/batches', async ({ chosenPeriod, str }) => {
  const response = await getAllBillingBatches(chosenPeriod, str);
  return response.data.items;
});

export const fetchBatchSummaries = createAsyncThunk<
  BatchSummaryWithBatchId,
  {
    batchId: string;
    str?: string;
  },
  {
    state: RootState;
  }
>('invoicing/batchSummaries', async ({ batchId, str }, { rejectWithValue }) => {
  const response = await getBatchSummary(batchId, str);

  return {
    batchId,
    summary: response.data.items,
  };
});

export const fetchBatch = createAsyncThunk<
  BatchType,
  string,
  {
    state: RootState;
  }
>('invoicing/batch', async (id) => {
  const response = await getBatchInfo(id);
  return response.data.items[0];
});

const billingPeriodsAdapter = createEntityAdapter<BillingPeriodTypes>({
  selectId: (b) => `${b.id}`,
});

const batchesAdapter = createEntityAdapter<BatchType>({
  selectId: (batch) => batch.id,
});

const batchSummariesAdapter = createEntityAdapter<BatchSummaryWithBatchId>({
  selectId: (batchSummary) => batchSummary.batchId,
});

const initialState: State = {
  batchesLoading: 'idle',
  batchesError: null,
  batches: batchesAdapter.getInitialState(),
  batchesSelected: [],

  batchLoading: {},
  batchError: {},

  batchSummariesLoading: {},
  batchSummariesError: {},
  batchSummaries: batchSummariesAdapter.getInitialState(),

  billingPeriodsLoading: 'idle',
  billingPeriodsError: null,
  billingPeriods: billingPeriodsAdapter.getInitialState(),
  billingPeriodsChosen: null,
};

const slice = createSlice({
  name: 'invoicing',
  initialState,
  reducers: {
    batchReplaced(state, action: PayloadAction<BatchType>) {
      batchesAdapter.setOne(state.batches, action.payload);
    },
    updateBillingPeriodsChosen(state, action: PayloadAction<string | null>) {
      state.billingPeriodsChosen = action.payload;
    },
    updateBatchesSelected(state, action: PayloadAction<Array<EntityId>>) {
      state.batchesSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    /*
     * fetchBillingPeriods
     */
    builder.addCase(fetchBillingPeriods.pending, (state) => {
      billingPeriodsAdapter.setAll(state.billingPeriods, []);
      state.billingPeriodsError = null;
      state.billingPeriodsLoading = 'pending';
    });
    builder.addCase(fetchBillingPeriods.fulfilled, (state, { payload }) => {
      billingPeriodsAdapter.setAll(state.billingPeriods, payload.data);
      state.billingPeriodsLoading = 'idle';
      if (payload.initialChosen) {
        state.billingPeriodsChosen = payload.initialChosen;
      } else if (payload.data.length > 0) {
        state.billingPeriodsChosen = `${payload.data[0].id}`;
      } else {
        state.billingPeriodsChosen = null;
      }
    });

    builder.addCase(fetchBillingPeriods.rejected, (state, action) => {
      state.billingPeriodsError = action.error;
      state.billingPeriodsLoading = 'idle';
    });

    /*
     * fetchBatches
     */
    builder.addCase(fetchBatches.pending, (state) => {
      batchesAdapter.setAll(state.batches, []);
      state.batchesError = null;
      state.batchesLoading = 'pending';
    });
    builder.addCase(fetchBatches.fulfilled, (state, { payload }) => {
      batchesAdapter.setAll(state.batches, payload);
      state.batchesLoading = 'idle';
    });
    builder.addCase(fetchBatches.rejected, (state, action) => {
      state.batchesError = action.error;
      state.batchesLoading = 'idle';
    });

    /*
     * fetchBatchSummaries
     */
    builder.addCase(fetchBatchSummaries.pending, (state, action) => {
      batchSummariesAdapter.setAll(state.batchSummaries, []);
      state.batchSummariesError[action.meta.arg.batchId] = null;
      state.batchSummariesLoading[action.meta.arg.batchId] = 'pending';
    });
    builder.addCase(fetchBatchSummaries.fulfilled, (state, { payload, meta }) => {
      batchSummariesAdapter.setOne(state.batchSummaries, payload);
      state.batchSummariesLoading[meta.arg.batchId] = 'idle';
    });
    builder.addCase(fetchBatchSummaries.rejected, (state, action) => {
      state.batchSummariesError[action.meta.arg.batchId] = action.error;
      state.batchSummariesLoading[action.meta.arg.batchId] = 'idle';
    });

    /*
     * fetchBatch
     */
    builder.addCase(fetchBatch.pending, (state, action) => {
      state.batchError[action.meta.arg] = null;
      state.batchLoading[action.meta.arg] = 'pending';
    });
    builder.addCase(fetchBatch.fulfilled, (state, { payload, meta }) => {
      batchesAdapter.setOne(state.batches, payload);
      state.batchLoading[meta.arg] = 'idle';
    });
    builder.addCase(fetchBatch.rejected, (state, action) => {
      state.batchError[action.meta.arg] = action.error;
      state.batchLoading[action.meta.arg] = 'idle';
    });
  },
});

export const { batchReplaced, updateBillingPeriodsChosen, updateBatchesSelected } = slice.actions;

export default slice.reducer;

export const batchesSelector = batchesAdapter.getSelectors<RootState>((state) => state.invoicing.batches);
export const batchSummariesSelector = batchSummariesAdapter.getSelectors<RootState>((state) => state.invoicing.batchSummaries);
export const billingPeriodsSelector = billingPeriodsAdapter.getSelectors<RootState>((state) => state.invoicing.billingPeriods);

export const selectedBatchesSelector = createSelector(
  (state: RootState) => state.invoicing.batchesSelected,
  batchesSelector.selectAll,
  (batchesSelected, allBatches) => allBatches.filter((b) => batchesSelected.includes(b.id))
);

export const billingPeriodsChosenSelector = createSelector(
  (state: RootState) => state,
  (state) => (state.invoicing.billingPeriodsChosen ? billingPeriodsSelector.selectById(state, state.invoicing.billingPeriodsChosen) : null)
);

export const loadAllBatchesInfo =
  (str?: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const chosenPeriod = getState().invoicing.billingPeriodsChosen;

    if (chosenPeriod) {
      await dispatch(
        fetchBatches({
          chosenPeriod: Number(chosenPeriod),
          str,
        })
      );
    }
  };
