import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import dayjs from 'dayjs';
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory } from 'react-router-dom';
import { Permission } from '../api/account.types';
import usePermission from '../hooks/usePermission';
import { getSchoolList } from '../api/users';
import { DiscountTypesType, DropdownType, StudentType } from '../api/bulkDiscounts.types';
import DatePicker from '../components/DatePicker';
import { getBirthYear, getClasses, getDiscountTypes, getStudentList } from '../api/bulkDiscounts';
import ConfirmBulkDiscountModal from './ConfirmBulkDiscountModal';
import ConfirmBulkDiscountRecords from './ConfirmBulkDiscountRecords';

interface StudentFilter {
  school_id: string | undefined;
  start_date: Date | undefined;
  end_date: Date | undefined;
  str: string | null;
  class: string;
  birth_year: string;
}

const BulkDiscounts = (): React.ReactElement => {
  const { t } = useTranslation(['discounts', 'common']);
  const history = useHistory();
  const { hasWritePermission } = usePermission();
  const [showListToBulkDiscount, setShowListToBulkDiscount] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [showConfirmedBulkDiscountList, setShowConfirmedBulkDiscountList] = useState<{ show: boolean; discount_group_id: number | undefined; group_id: number | undefined }>({
    show: false,
    discount_group_id: undefined,
    group_id: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [loadingStudentList, setLoadingStudentList] = useState(false);
  const [error, setError] = useState(false);
  const [schoolList, setSchoolList] = useState<DropdownType[]>();
  const [selectedSchool, setSelectedSchool] = useState<DropdownType>();
  const [filter, setFilter] = useState<StudentFilter>({ school_id: undefined, start_date: dayjs().toDate(), end_date: dayjs().toDate(), str: null, class: '', birth_year: '' });
  const [listData, setListData] = useState<StudentType[]>();
  const [discountTypesList, setDiscountTypesList] = useState<DropdownType[]>();
  const [selectedDiscountType, setSelectedDiscountType] = useState<any>();
  const [selectedStudents, setSelectedStudents] = useState<StudentType[]>([]);
  const [showConfirmBulkDiscountModal, setShowConfirmBulkDiscountModal] = useState<boolean>(false);
  const [selectedClasses, setSelectedClasses] = useState<any>();
  const [selectedBirthYear, setSelectedBirthYear] = useState<any>();
  const [classesList, setClassesList] = useState<DropdownType[]>();
  const [birthYearList, setBirthYearList] = useState<DropdownType[]>();

  const loadSchoolList = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getSchoolList();
      setSchoolList(
        response.data.items.map((item: any) => ({
          value: item.school_id,
          label: item.school_name,
        }))
      );
      setSelectedSchool({ value: response.data.items[0].school_id, label: response.data.items[0].school_name });
      setFilter({ ...filter, school_id: response.data.items[0].school_id.toString() });
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);
  const loadStudentList = useCallback(async (values: StudentFilter) => {
    setLoadingStudentList(true);
    setError(false);
    try {
      const response = await getStudentList({ ...values, start_date: dayjs(values.start_date).format('DD.MM.YYYY'), end_date: dayjs(values.end_date).format('DD.MM.YYYY') });
      setListData(response.data.items);
    } catch {
      setError(true);
    } finally {
      setLoadingStudentList(false);
    }
  }, []);
  const loadDiscountTypes = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getDiscountTypes();
      setDiscountTypesList(
        response.data.items.map((item: DiscountTypesType) => ({
          value: item.id,
          label: `${item.category_name} - ${item.name}`,
        }))
      );
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadClasses = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getClasses(filter.school_id);
      setClassesList(
        response.data.items.map((item: { class: string }) => ({
          value: item.class,
          label: item.class,
        }))
      );
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [filter.school_id]);

  const loadBirthYears = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getBirthYear(filter.school_id);
      setBirthYearList(
        response.data.items.map((item: { get_birth_year: string }) => ({
          value: item.get_birth_year,
          label: item.get_birth_year,
        }))
      );
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [filter.school_id]);

  useEffect(() => {
    loadSchoolList();
  }, [loadSchoolList]);

  useEffect(() => {
    if (filter.school_id !== undefined) {
      loadStudentList(filter);
    }
  }, [filter.school_id, loadStudentList]);
  useEffect(() => {
    loadDiscountTypes();
  }, [loadDiscountTypes]);

  useEffect(() => {
    loadClasses();
  }, [loadClasses]);

  useEffect(() => {
    loadBirthYears();
  }, [loadBirthYears]);

  const onConfirmBulkDiscountModalClosed = (confirmed: boolean, discount_group_id: number | undefined, group_id: number | undefined) => {
    setShowConfirmBulkDiscountModal(false);

    if (confirmed && discount_group_id !== undefined) {
      setShowListToBulkDiscount(false);
      setShowConfirmedBulkDiscountList({ show: true, discount_group_id, group_id });
    }
  };

  const handleHideConfirmView = (hide: boolean) => {
    if (hide) {
      setShowConfirmedBulkDiscountList({ group_id: undefined, discount_group_id: undefined, show: false });
      setShowListToBulkDiscount(true);
      history.push('/magnskra-afslatt');
    }
  };

  const debouncedFilter = useCallback(
    debounce((filterValues: StudentFilter) => loadStudentList(filterValues), 1000),
    [loadStudentList]
  );

  const onSearchChanged = (value: Partial<StudentFilter>) => {
    const newFilter: StudentFilter = {
      ...filter,
      ...value,
    };
    setFilter(newFilter);
    debouncedFilter(newFilter);
  };
  const bulkDiscountColumn = [
    {
      dataField: 'id',
      isDummyField: true,
      text: '',
      headerStyle: { width: '35px' },
      formatter: (cell: string, row: any, rowIndex: any) => {
        return `${rowIndex + 1}.`;
      },
    },
    {
      dataField: 'name',
      text: t('name'),
      sort: true,
    },
    {
      dataField: 'ssn',
      text: t('ssn'),
      sort: true,
      csvFormatter: (cell: string) => `="${cell}"`,
    },
    {
      dataField: 'class',
      text: t('class'),
      sort: true,
    },
    {
      dataField: 'get_birth_year',
      text: t('get_birth_year'),
      sort: true,
    },
    {
      dataField: 'school',
      text: t('school'),
      sort: true,
    },
  ];
  return (
    <>
      {hasWritePermission(Permission.MAGNSKRAAFSLATT) && (
        <>
          {showConfirmBulkDiscountModal && (
            <ConfirmBulkDiscountModal
              data={{
                start_date: dayjs(filter.start_date).format('DD.MM.YYYY'),
                end_date: dayjs(filter.end_date).format('DD.MM.YYYY'),
                discount_type: selectedDiscountType.value,
                students: selectedStudents,
              }}
              onClose={onConfirmBulkDiscountModalClosed}
            />
          )}
          {showListToBulkDiscount && (
            <>
              <Row>
                <Col md={3}>
                  <label>{t('start_date')}</label>
                  <div className="dateWrapper">
                    <DatePicker
                      value={filter.start_date}
                      month={filter.start_date}
                      selectedDay={filter.start_date}
                      onDayChange={(day: Date) => {
                        setFilter({ ...filter, start_date: day });
                      }}
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <label>{t('end_date')}</label>
                  <div className="dateWrapper">
                    <DatePicker
                      value={filter.end_date}
                      month={filter.end_date}
                      selectedDay={filter.end_date}
                      onDayChange={(day: Date) => {
                        setFilter({ ...filter, end_date: day });
                      }}
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <label>{t('class')}</label>
                  <Select
                    placeholder={t('class')}
                    options={classesList}
                    isMulti
                    closeMenuOnSelect={false}
                    className="basic-multi-select"
                    isClearable
                    value={selectedClasses}
                    onChange={(e) => {
                      setSelectedClasses(e.map((v) => v));
                      onSearchChanged({ class: e.map((v) => v.value).join() });
                    }}
                  />
                </Col>
                <Col md={3}>
                  <label>{t('get_birth_year')}</label>
                  <Select
                    placeholder={t('get_birth_year')}
                    options={birthYearList}
                    isMulti
                    closeMenuOnSelect={false}
                    className="basic-multi-select"
                    isClearable
                    value={selectedBirthYear}
                    onChange={(e) => {
                      setSelectedBirthYear(e.map((v) => v));
                      onSearchChanged({ birth_year: e.map((v) => v.value).join() });
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={3}>
                  <label>{t('common:search')}</label>
                  <input
                    type="text"
                    className="form-control"
                    disabled={loading}
                    placeholder={t('search')}
                    autoComplete="off"
                    onChange={(e) => {
                      setSearchValue(e.currentTarget.value);
                      onSearchChanged({ str: e.currentTarget.value });
                    }}
                    value={searchValue}
                  />
                </Col>
                <Col md={3}>
                  <label>{t('schools')}</label>
                  <Select
                    options={schoolList}
                    className="basic-multi-select"
                    value={selectedSchool}
                    onChange={(e) => {
                      if (e) {
                        setSelectedSchool(e);
                        onSearchChanged({ school_id: e?.value.toString() });
                      }
                    }}
                  />
                </Col>
                <Col md={3}>
                  <label>{t('discountType')}</label>
                  <Select
                    options={discountTypesList}
                    placeholder={t('discountType')}
                    className="basic-multi-select"
                    onChange={(e) => {
                      if (e) {
                        setSelectedDiscountType(e);
                      }
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Button
                    onClick={() => setShowConfirmBulkDiscountModal(true)}
                    disabled={!selectedDiscountType || !filter.start_date || !filter.end_date || selectedStudents.length === 0}
                    style={{ position: 'absolute', bottom: '0', right: '0' }}
                  >
                    {t('bulkRegisterDiscount')}
                  </Button>
                </Col>
              </Row>
              <br />
              {loadingStudentList && !listData && (
                <div className="spinner">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">{t('common:loading')}</span>
                  </Spinner>
                </div>
              )}
              {listData && (
                <div className="whiteColumn">
                  <BootstrapTable
                    bootstrap4
                    keyField="ssn"
                    data={listData}
                    columns={bulkDiscountColumn}
                    noDataIndication={t('common:noDataFound')}
                    striped
                    bordered={false}
                    selectRow={{
                      mode: 'checkbox',
                      clickToSelect: true,
                      onSelect: (row, isSelect, rowIndex, e) => {
                        if (isSelect) {
                          setSelectedStudents((oldArray) => [...oldArray, row]);
                        } else {
                          setSelectedStudents(selectedStudents.filter((item) => item.student_id !== row.student_id));
                        }
                      },
                      onSelectAll: (isSelect, rows, e) => {
                        if (isSelect) {
                          setSelectedStudents(rows);
                        } else {
                          setSelectedStudents([]);
                        }
                      },
                    }}
                  />
                </div>
              )}
            </>
          )}
          {!loadingStudentList && showConfirmedBulkDiscountList && (
            <ConfirmBulkDiscountRecords
              discount_group_id={showConfirmedBulkDiscountList.discount_group_id}
              group_id={showConfirmedBulkDiscountList.group_id}
              hideConfirmView={handleHideConfirmView}
            />
          )}
        </>
      )}
    </>
  );
};

export default BulkDiscounts;
