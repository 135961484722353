import React, { useCallback, useEffect, useState, useMemo } from 'react';
import DualListBox from 'react-dual-listbox';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleUp, faAngleDoubleDown, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { OptionTypeBase } from 'react-select';
import debounce from 'lodash.debounce';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { restValidationToForm } from '../utils/formUtils';
import DatePicker from '../components/DatePicker';
import { createUser, getNatReg, getSchoolList, getUserExists, getUserJobTypes } from '../api/users';
import { JobTypeItem } from '../api/registrations.types';
import { UserAccess, UserDetails } from '../api/users.types';

interface Values {
  ssn: string;
  name: string;
  email: string;
  valid_from: string | undefined;
  valid_to: string | undefined;
  job_type: number | undefined;
  user_access: number[];
}

const CreateUser = (): React.ReactElement => {
  const { t, i18n } = useTranslation(['createUserView', 'registrations', 'errors', 'common']);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [jobTypeList, setJobTypeList] = useState<OptionTypeBase[]>();
  const [schoolList, setSchoolList] = useState<{ value: string; label: string }[]>();
  const [userExists, setUserExists] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<UserDetails>({
    created_by: '',
    email: '',
    id: undefined,
    job_type: undefined,
    name: '',
    ssn: '',
    usertype: undefined,
    valid_from: undefined,
    valid_to: undefined,
    user_access: [],
  });

  const initialValues = useMemo<Values>(
    () => ({
      ssn: userDetails.ssn,
      name: userDetails.name,
      email: userDetails.email,
      job_type: userDetails.job_type,
      user_access: userDetails.user_access.map((item) => item.school_id || 0),
      valid_from: userExists ? dayjs(userDetails.valid_from).format('DD.MM.YYYY') : undefined,
      valid_to: userExists ? dayjs(userDetails.valid_to).format('DD.MM.YYYY') : undefined,
    }),
    [userExists, userDetails]
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      ssn: Yup.string().required(t('errors:emptyInputField')).min(10, t('errors:ssnInvalid')).max(10, t('errors:ssnInvalid')),
      email: Yup.string().email(t('errors:invalidEmail')).required(t('errors:emptyInputField')),
      job_type: Yup.string().required(t('errors:emptyInputField')),
      user_access: Yup.array().min(1, t('errors:chooseSchool')).required(t('errors:emptyInputField')),
      valid_from: Yup.string().required(t('errors:emptyInputField')),
    });
  }, [t, i18n.language]);

  const loadJobTypes = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getUserJobTypes();
      setJobTypeList(
        response.data.items.map((item: JobTypeItem) => ({
          value: item.id,
          label: item.description,
          user_group: item.user_group,
        }))
      );
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const getSSNName = useCallback(async (ssn: string) => {
    setLoading(true);
    setError(false);
    try {
      const response = await getNatReg({ ssn });
      const data = response.data.items[0];
      setUserDetails({ ...userDetails, name: data.name, ssn: data.ssn });
    } catch (e) {
      setError(true);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const checkUserExists = useCallback(async (ssn: string) => {
    setLoading(true);
    setError(false);
    try {
      const response = await getUserExists({ ssn });
      const data = response.data.items[0];
      if (data) {
        setUserExists(true);
        setUserDetails({
          created_by: data.created_by,
          email: data.email,
          id: data.id,
          job_type: data.job_type,
          name: data.name,
          ssn: data.ssn,
          usertype: data.usertype,
          valid_from: data.valid_from,
          valid_to: data.valid_to === null ? undefined : data.valid_to,
          user_access: data.user_access.map((item: UserAccess) => ({
            can_change: item.can_change,
            created: item.created,
            id: item.id,
            school_id: item.school_id,
            userId: item.userid,
          })),
        });
      } else {
        setUserExists(false);
        setUserDetails({
          created_by: '',
          email: '',
          id: undefined,
          job_type: 0,
          name: '',
          ssn: '',
          usertype: undefined,
          valid_from: undefined,
          valid_to: undefined,
          user_access: [],
        });
        await getSSNName(ssn);
      }
    } catch (e) {
      setError(true);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadSchoolList = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getSchoolList();
      setSchoolList(
        response.data.items.map((item: any) => ({
          value: item.school_id,
          label: item.school_name,
        }))
      );
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadJobTypes();
  }, [loadJobTypes]);

  useEffect(() => {
    loadSchoolList();
  }, [loadSchoolList]);

  const debouncedFilter = useCallback(
    debounce((ssn: string) => checkUserExists(ssn), 500),
    [checkUserExists]
  );

  const onCheckSSN = (value: Partial<string>) => {
    debouncedFilter(value);
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await createUser({
              ...values,
              user_access: values.user_access.map((k) => ({ school_id: k })),
              valid_to: values.valid_to === undefined ? null : values.valid_to,
            });
            history.push('/notendur');
            toast.success(t('userCreated'));
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
            toast.error(`${t('common:informationChangedError')}. ${e.response.data.error}.`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <>
            <Form
              onReset={handleReset}
              onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                setStatus('submitted');
                handleSubmit(e);
              }}
            >
              <table className="table">
                <tr>
                  <td style={{ borderTop: 'none' }}>
                    <Form.Group>
                      <Form.Label>{t('registrations:ssn')}</Form.Label>
                      <span style={{ color: 'red' }}>*</span>
                      <Form.Control
                        type="text"
                        onChange={(e) => {
                          onCheckSSN(e.currentTarget.value);
                        }}
                      />
                      <ErrorMessage name="ssn" component="div" className="invalid-feedback d-block text-left" />
                    </Form.Group>
                  </td>
                  <td style={{ borderTop: 'none' }}>
                    <Form.Group>
                      <Form.Label>{t('registrations:name')}</Form.Label>
                      <Form.Control type="text" disabled value={values.name} />
                    </Form.Group>
                  </td>
                  <td style={{ borderTop: 'none' }}>
                    <Form.Group>
                      <Form.Label>{t('registrations:email')}</Form.Label>
                      <span style={{ color: 'red' }}>*</span>
                      <Form.Control type="text" value={values.email} onChange={(e) => setFieldValue('email', e.currentTarget.value)} />
                      <ErrorMessage name="email" component="div" className="invalid-feedback d-block text-left" />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderTop: 'none' }}>
                    <Form.Group>
                      <Form.Label>
                        {t('registrations:job_type')}
                        <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={values.job_type}
                        onChange={(event) => {
                          setFieldValue('job_type', event.target.value);
                        }}
                      >
                        <option key="empty" value="0">
                          {t('createUserView:chooseJobType')}
                        </option>
                        {jobTypeList?.map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <ErrorMessage name="job_type" component="div" className="invalid-feedback d-block text-left" />
                    </Form.Group>
                  </td>
                  <td style={{ borderTop: 'none' }}>
                    <Form.Group controlId="valid_from">
                      <Form.Label>
                        {t('registrations:startOfRegistration')}
                        <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <div className="dateWrapper">
                        <DatePicker
                          style={{ display: 'inline', width: '100%' }}
                          value={values.valid_from}
                          onDayChange={(day: Date) => {
                            setFieldValue('valid_from', dayjs(day).format('DD.MM.YYYY'));
                          }}
                        />
                      </div>
                      <ErrorMessage name="valid_from" component="div" className="invalid-feedback d-block text-left" />
                    </Form.Group>
                  </td>
                  <td style={{ borderTop: 'none' }}>
                    <Form.Group controlId="valid_to">
                      <Form.Label>{t('registrations:endOfRegistration')}</Form.Label>
                      <div className="dateWrapper">
                        <DatePicker
                          style={{ display: 'inline', width: '100%' }}
                          value={values.valid_to}
                          onDayChange={(day: Date) => {
                            setFieldValue('valid_to', dayjs(day).format('DD.MM.YYYY'));
                          }}
                        />
                      </div>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderTop: 'none' }}>
                    <Form.Group>
                      <Form.Label>
                        {t('schoolAccess')}
                        <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      {schoolList && (
                        <div>
                          <DualListBox
                            options={schoolList}
                            selected={values.user_access}
                            onChange={(e: string[]) => setFieldValue('user_access', e)}
                            icons={{
                              moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
                              moveAllLeft: [<FontAwesomeIcon key={0} icon={faChevronLeft} />, <FontAwesomeIcon key={1} icon={faChevronLeft} />],
                              moveRight: <FontAwesomeIcon icon={faChevronRight} />,
                              moveAllRight: [<FontAwesomeIcon key={0} icon={faChevronRight} />, <FontAwesomeIcon key={1} icon={faChevronRight} />],
                              moveTop: <FontAwesomeIcon icon={faAngleDoubleUp} />,
                              moveBottom: <FontAwesomeIcon icon={faAngleDoubleDown} />,
                              moveDown: <FontAwesomeIcon icon={faChevronDown} />,
                              moveUp: <FontAwesomeIcon icon={faChevronUp} />,
                            }}
                          />
                        </div>
                      )}
                      <ErrorMessage name="user_access" component="div" className="invalid-feedback d-block text-left" />
                    </Form.Group>
                  </td>
                </tr>
              </table>
              <div className="pull-right">
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => {
                    setStatus('submitted');
                    // handleSubmit();
                  }}
                >
                  {t('common:submit')}
                </Button>
                &nbsp;
                <Button variant="secondary" onClick={() => history.push('/notendur')}>
                  {t('common:cancel')}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default CreateUser;
