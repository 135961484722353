import React, { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import usePermission, { Permission } from '../../hooks/usePermission';
import { CheckBoxesType } from '../../api/billing.types';
import { selectedBatchesSelector } from '../../store/invoicing/invoicingSlice';
import { useAppSelector } from '../../hooks/useState';
import { addCorrectionBatch, confirmBatch, deleteRecords, makeBatchReadyForExternal, runBatches } from '../../api/billing';

interface Props {
  showCheckBoxes: CheckBoxesType | null;
  setShowCheckBoxes: (value: CheckBoxesType | null) => void;
  loadAllBatchesInfo: () => void;
}

const InvoicingActionBar: React.FC<Props> = ({ showCheckBoxes, setShowCheckBoxes, loadAllBatchesInfo }) => {
  const selectedItems = useAppSelector(selectedBatchesSelector);
  const { t } = useTranslation(['invoiceView', 'errors']);
  const { hasWritePermission } = usePermission();
  const [loading, setLoading] = useState(false);

  const postBatchData = useCallback(async () => {
    await runBatches({
      batches: JSON.stringify(
        selectedItems.map((k) => {
          return { batch_id: k.batch_id };
        })
      ),
      run_last_month: '1',
    });
    toast.success(t('runBatchSuccess'));
  }, [t, selectedItems]);

  const deleteBatchRecords = useCallback(async () => {
    const modifiedItem = selectedItems.map((item) => item.batch_id);

    if (modifiedItem.length > 0) {
      const promises = modifiedItem.map((i) => {
        return deleteRecords(i);
      });
      await Promise.all(promises);
      toast.success(t('deleteBatchesSuccess'));
    } else {
      toast.error(t('batchesFailure'));
    }
  }, [selectedItems, t]);

  const confirmBatchRecords = useCallback(async () => {
    const modifiedItem = selectedItems.map((item) => item.batch_id);
    if (modifiedItem.length > 0) {
      const promises = modifiedItem.map((i) => {
        return confirmBatch(i);
      });
      await Promise.all(promises);
      toast.success(t('confirmBatchesSuccess'));
    } else {
      toast.error(t('batchesFailure'));
    }
  }, [selectedItems, t]);

  const readyForExternal = useCallback(async () => {
    const modifiedItem = selectedItems.map((item) => item.batch_id);
    if (modifiedItem.length > 0) {
      const promises = modifiedItem.map((i) => {
        return makeBatchReadyForExternal(i);
      });
      await Promise.all(promises);
      toast.success(t('batchesReadyForExternalSuccess'));
    } else {
      toast.error(t('batchesFailure'));
    }
  }, [selectedItems, t]);

  const correctionBatch = useCallback(async () => {
    const modifiedItem = selectedItems.map((item) => {
      return { school_id: item.school_id, billing_period: item.billing_period };
    });
    if (modifiedItem.length > 0) {
      const promises = modifiedItem.map((i) => {
        return addCorrectionBatch(i);
      });
      await Promise.all(promises);
      toast.success(t('creatingCorrectionBatchesSuccess'));
    } else {
      toast.error(t('batchesFailure'));
    }
  }, [selectedItems, t]);

  const runSelectedBatches = useCallback(async () => {
    setLoading(true);

    try {
      if (showCheckBoxes === 'RUN') {
        await postBatchData();
      }

      if (showCheckBoxes === 'DELETE') {
        await deleteBatchRecords();
      }

      if (showCheckBoxes === 'CONFIRM') {
        await confirmBatchRecords();
      }

      if (showCheckBoxes === 'READY_FOR_EXTERNAL') {
        await readyForExternal();
      }

      if (showCheckBoxes === 'CORRECTION_BATCH') {
        await correctionBatch();
      }

      loadAllBatchesInfo();
      setShowCheckBoxes(null);
    } catch (e) {
      if (e.response) {
        toast.error(e.response.data.error);
      } else {
        toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
      }
    } finally {
      setLoading(false);
    }
  }, [t, loadAllBatchesInfo, postBatchData, deleteBatchRecords, readyForExternal, correctionBatch, confirmBatchRecords, setShowCheckBoxes, showCheckBoxes]);

  return (
    <div style={{ display: 'flex', justifyContent: 'right' }}>
      {loading && <FontAwesomeIcon icon={faSpinner} spin fixedWidth />}
      {showCheckBoxes == null && (
        <Button
          size="sm"
          className={classNames('invoiceButtons', {
            disabled: showCheckBoxes != null,
          })}
          disabled={showCheckBoxes != null}
          onClick={() => {
            setShowCheckBoxes('RUN');
          }}
        >
          {t('chooseBatchToRun')}
        </Button>
      )}
      {showCheckBoxes === 'RUN' && (
        <>
          <Button size="sm" className="invoiceButtons" onClick={runSelectedBatches}>
            {t('runBatch')}
          </Button>
          <Button variant="secondary" className="invoiceButtons" onClick={() => setShowCheckBoxes(null)}>
            {t('cancelRun')}
          </Button>
        </>
      )}
      {showCheckBoxes == null && (
        <Button
          size="sm"
          className={classNames('invoiceButtons', {
            disabled: showCheckBoxes != null,
          })}
          disabled={showCheckBoxes != null}
          onClick={() => {
            setShowCheckBoxes('DELETE');
          }}
        >
          {t('chooseBatchToDelete')}
        </Button>
      )}
      {showCheckBoxes === 'DELETE' && (
        <>
          <Button size="sm" className="invoiceButtons" onClick={runSelectedBatches}>
            {t('deleteBatch')}
          </Button>
          <Button size="sm" variant="secondary" className="invoiceButtons" onClick={() => setShowCheckBoxes(null)}>
            {t('cancelDelete')}
          </Button>
        </>
      )}
      {showCheckBoxes == null && (
        <Button
          size="sm"
          className={classNames('invoiceButtons', {
            disabled: showCheckBoxes != null,
          })}
          disabled={showCheckBoxes != null}
          onClick={() => {
            setShowCheckBoxes('CONFIRM');
          }}
        >
          {t('chooseBatchToConfirm')}
        </Button>
      )}
      {showCheckBoxes === 'CONFIRM' && (
        <>
          <Button size="sm" className="invoiceButtons" onClick={runSelectedBatches}>
            {t('confirmBatch')}
          </Button>
          <Button size="sm" variant="secondary" className="invoiceButtons" onClick={() => setShowCheckBoxes(null)}>
            {t('cancelConfirm')}
          </Button>
        </>
      )}
      {hasWritePermission(Permission.BUNKARTILBOKHALDS) && (
        <>
          {showCheckBoxes == null && (
            <Button
              size="sm"
              className={classNames('invoiceButtons', {
                disabled: showCheckBoxes != null,
              })}
              disabled={showCheckBoxes != null}
              onClick={() => {
                setShowCheckBoxes('READY_FOR_EXTERNAL');
              }}
            >
              {t('chooseBatchReadyForExternal')}
            </Button>
          )}
          {showCheckBoxes === 'READY_FOR_EXTERNAL' && (
            <>
              <Button size="sm" className="invoiceButtons" onClick={runSelectedBatches}>
                {t('confirmReadyForExternal')}
              </Button>
              <Button size="sm" variant="secondary" className="invoiceButtons" onClick={() => setShowCheckBoxes(null)}>
                {t('cancelReadyForExternal')}
              </Button>
            </>
          )}
          {showCheckBoxes == null && (
            <Button
              size="sm"
              className={classNames('invoiceButtons', {
                disabled: showCheckBoxes != null,
              })}
              disabled={showCheckBoxes != null}
              onClick={() => {
                setShowCheckBoxes('CORRECTION_BATCH');
              }}
            >
              {t('chooseBatchForCorrectionBatch')}
            </Button>
          )}
          {showCheckBoxes === 'CORRECTION_BATCH' && (
            <>
              <Button size="sm" className="invoiceButtons" onClick={runSelectedBatches}>
                {t('createCorrectionBatch')}
              </Button>
              <Button size="sm" variant="secondary" className="invoiceButtons" onClick={() => setShowCheckBoxes(null)}>
                {t('cancelCreatingCorrectionBatch')}
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default InvoicingActionBar;
