import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Alert, Button, ButtonGroup, Col, Form, Modal, Row, Table, ToggleButton } from 'react-bootstrap';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import debounce from 'lodash.debounce';
import { restValidationToForm } from '../utils/formUtils';
import { FileType, NotRegisteredStudents } from '../api/registrations.types';
import { getNatReg } from '../api/users';
import { getExtraProductsToRegister } from '../api/schools';
import { ExtraProductsToRegister } from '../api/schools.types';
import { registerNotRegisteredStudent, uploadFile } from '../api/registrations';
import FileInput from '../components/FileInput';

interface Values {
  ssn: string;
  school_id: number;
  intolerance: string | null;
  intolerance_reason: string;
  allergies: string | null;
  allergies_reason: string;
  payer_ssn: string | undefined;
  payer_email: string;
  products: Record<number, boolean>;
}

interface Props {
  onClose: (created: boolean) => void;
  student: NotRegisteredStudents;
}
const RegisterStudentModal = ({ onClose, student }: Props): React.ReactElement => {
  const { t, i18n } = useTranslation(['errors', 'registerStudentModal', 'common']);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([]);
  const [payerInfo, setPayerInfo] = useState<{ name: string; payer_ssn: string | undefined }>();
  const [extraProducts, setExtraProducts] = useState<ExtraProductsToRegister[] | undefined>();
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      allergies: Yup.string().required(t('errors:errorMessage')).nullable(),
      allergies_reason: Yup.string().when('allergies', {
        is: '1',
        then: Yup.string().required(t('errors:emptyInputAllergies')),
        otherwise: Yup.string(),
      }),
      intolerance: Yup.string().required(t('errors:errorMessage')).nullable(),
      intolerance_reason: Yup.string().when('intolerance', {
        is: '1',
        then: Yup.string().required(t('errors:emptyInputIntolerance')),
        otherwise: Yup.string(),
      }),
      payer_email: Yup.string().email(t('errors:invalidEmail')).required(t('errors:emptyInputEmail')),
      payer_ssn: Yup.string().required(t('errors:emptyInputField')),
    });
  }, [t, i18n.language]);

  const initialValues: Values = {
    ssn: student.ssn,
    school_id: student.school_id,
    intolerance: null,
    intolerance_reason: '',
    allergies: null,
    allergies_reason: '',
    payer_ssn: payerInfo?.payer_ssn,
    payer_email: '',
    products:
      extraProducts?.reduce((acc: Record<number, boolean>, curr: any) => {
        acc[curr.product_id] = false;
        return acc;
      }, {}) || {},
  };

  // const [price, setPrice] = useState<number>(0);
  const radios = [
    { name: t('registerStudentModal:yes'), value: '1' },
    { name: t('registerStudentModal:no'), value: '0' },
  ];
  const getSSNName = useCallback(async (ssn: string) => {
    setLoading(true);
    setError(false);
    try {
      const response = await getNatReg({ ssn });
      const data = response.data.items[0];
      if (data) {
        setPayerInfo({ ...payerInfo, name: data.name, payer_ssn: data.ssn });
      } else {
        setPayerInfo({ ...payerInfo, name: 'Þessi kennitala finnst ekki í þjóðskrá', payer_ssn: '' });
      }
    } catch (e) {
      setError(true);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadExtraProducts = useCallback(async (school_id: number | null) => {
    setLoading(true);
    setError(false);
    try {
      const response = await getExtraProductsToRegister(school_id);
      const data = response.data.items.filter((i) => i.active === 1);
      setExtraProducts(data);
    } catch (e) {
      setError(true);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    loadExtraProducts(student.school_id);
  }, [loadExtraProducts]);
  const debouncedFilter = useCallback(
    debounce((ssn: string) => getSSNName(ssn), 1000),
    []
  );
  const onCheckSSN = (value: Partial<string>) => {
    debouncedFilter(value);
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<Values>) => {
          try {
            const response = await registerNotRegisteredStudent({
              ...values,
              products: JSON.stringify(
                Object.entries(values.products)
                  .filter(([, v]) => v)
                  .map(([k]) => ({ product_id: Number(k) }))
              ),
            });
            const { registration_id } = response.data;
            if (files.length > 0) {
              const modifiedItems = files.map((item: FileType) => {
                return {
                  registration_id,
                  student_id: student.student_id,
                  name: item.name,
                  filename: item.name,
                  mime: item.type && item.type.length > 0 ? item.type : 'application/octet-stream',
                  body: item.data,
                };
              });
              const promises = modifiedItems.map((i) => {
                return uploadFile(i);
              });
              await Promise.all(promises);
            }
            toast.success(t('registerStudentModal:studentRegistered'));
            onClose(true);
          } catch (e) {
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
            toast.error(`${t('common:informationError')}. ${e.response.data.error}.`);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue, getFieldProps, errors }) => (
          <Modal show onHide={() => onClose(false)} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{t('registerStudentModal:title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <table className="table">
                  <tbody>
                    <tr>
                      <td>
                        <Form.Group controlId="name">
                          <Form.Text>{t('registerStudentModal:name')}</Form.Text>
                          <Form.Label style={{ marginBottom: '0' }}>{student.student_name}</Form.Label>
                          <br />
                          <Form.Label style={{ marginBottom: '0' }}>{student.ssn}</Form.Label>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId="school">
                          <Form.Text>{t('registerStudentModal:school')}</Form.Text>
                          <Form.Label className="margin-bottom-0">{student.school_name}</Form.Label>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Group controlId="registration">
                          <Form.Text>{t('registerStudentModal:registration')}</Form.Text>
                          <Form.Check type="checkbox" id="registration" name="registration" disabled label={t('registerStudentModal:schoolMeals')} checked />
                        </Form.Group>
                      </td>
                      <td>
                        {extraProducts?.length !== 0 && (
                          <Form.Group controlId="extra">
                            <Form.Text>{t('registerStudentModal:extra')}</Form.Text>
                            {extraProducts?.map((extra: ExtraProductsToRegister, idx: number) => {
                              return (
                                <Form.Check
                                  key={idx}
                                  type="checkbox"
                                  id={`products.${extra.product_id}`}
                                  checked={values.products[extra.product_id]}
                                  label={extra.product_name}
                                  onChange={(event) => {
                                    setFieldValue(`products.${extra.product_id}`, event.currentTarget.checked);
                                  }}
                                />
                              );
                            })}
                          </Form.Group>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Group controlId="payer">
                          <Form.Text>{t('registerStudentModal:payer')}</Form.Text>
                          <Form.Control type="text" disabled value={payerInfo?.name} />
                          <Form.Text>{t('registerStudentModal:ssn')}*</Form.Text>
                          <Form.Control
                            type="text"
                            onChange={(e) => {
                              if (e.currentTarget.value.length === 10) {
                                onCheckSSN(e.currentTarget.value);
                              } else {
                                setPayerInfo({ name: '', payer_ssn: '' });
                              }
                              setFieldValue('payer_ssn', e.currentTarget.value);
                            }}
                          />
                          <ErrorMessage name="payer_ssn" component="div" className="invalid-feedback d-block text-left" />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Text>{t('registerStudentModal:addEmail')}</Form.Text>
                          <Form.Control type="email" {...getFieldProps('payer_email')} />
                          <ErrorMessage name="payer_email" component="div" className="invalid-feedback d-block text-left" />
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Group className="allergiesRadio">
                          <Form.Label>{t('registerStudentModal:childHasAllergies')}</Form.Label>
                          <ButtonGroup toggle>
                            {radios.map((radio) => (
                              <ToggleButton
                                key={radio.value}
                                type="radio"
                                variant="secondary"
                                name="allergies"
                                className="buttonHover"
                                value={radio.value}
                                checked={values.allergies === radio.value}
                                onChange={(e) => {
                                  setFieldValue('allergies', e.currentTarget.value);
                                  if (e.currentTarget.value === '0') {
                                    setFieldValue('allergies_reason', '');
                                  }
                                }}
                              >
                                {radio.name}
                              </ToggleButton>
                            ))}
                          </ButtonGroup>
                          <ErrorMessage name="allergies" component="div" className="invalid-feedback d-block text-left" />
                          {values.allergies === '1' && (
                            <>
                              <Alert variant="warning" className="alert-box">
                                <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '5px' }} />
                                <span>{t('registerStudentModal:allergiesIntoleranceInfoText')}</span>
                              </Alert>
                              <Form.Group>
                                <Form.Control type="text" {...getFieldProps('allergies_reason')} />
                                <ErrorMessage name="allergies_reason" component="div" className="invalid-feedback d-block text-left" />
                              </Form.Group>
                            </>
                          )}
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group className="allergiesRadio">
                          <Form.Label>{t('registerStudentModal:childHasIntolerance')}</Form.Label>
                          <ButtonGroup toggle>
                            {radios.map((radio) => (
                              <ToggleButton
                                key={radio.value}
                                type="radio"
                                variant="secondary"
                                name="intolerance"
                                className="buttonHover"
                                value={radio.value}
                                checked={values.intolerance === radio.value}
                                onChange={(e) => {
                                  setFieldValue('intolerance', e.currentTarget.value);
                                  if (e.currentTarget.value === '0') {
                                    setFieldValue('intolerance_reason', '');
                                  }
                                }}
                              >
                                {radio.name}
                              </ToggleButton>
                            ))}
                          </ButtonGroup>
                          <ErrorMessage name="intolerance" component="div" className="invalid-feedback d-block text-left" />
                          {values.intolerance === '1' && (
                            <>
                              <Alert variant="warning" className="alert-box">
                                <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '5px' }} />
                                <span>{t('registerStudentModal:allergiesIntoleranceInfoText')}</span>
                              </Alert>
                              <Form.Group>
                                <Form.Control type="text" {...getFieldProps('intolerance_reason')} />
                                <ErrorMessage name="intolerance_reason" component="div" className="invalid-feedback d-block text-left" />
                              </Form.Group>
                            </>
                          )}
                        </Form.Group>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Row>
                  <Col>{(values.intolerance === '1' || values.allergies === '1') && <FileInput setFiles={setFiles} files={files} />}</Col>
                  <Col>
                    {(values.intolerance === '1' || values.allergies === '1') && (
                      <Table style={{ margin: 0 }}>
                        <thead>
                          <tr>
                            <th>{t('editStudentViewModals:attachments')}</th>
                            <th />
                          </tr>
                        </thead>
                        {files.length === 0 && <div style={{ textAlign: 'center', paddingTop: '1rem' }}>{t('studentView:clickToAddFiles')}</div>}
                        {files.length > 0 && (
                          <tbody>
                            {files.map((item: FileType, idx: number) => {
                              return (
                                <tr key={idx}>
                                  <td>{item.name}</td>
                                  <td>
                                    <Button variant="link" onClick={() => setFiles(files.filter((i) => i.name !== item.name))} style={{ float: 'right' }}>
                                      <FontAwesomeIcon icon={faTrashAlt} style={{ color: '#e74a3b' }} />
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )}
                      </Table>
                    )}
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:submit')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default RegisterStudentModal;
