import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Card, Table } from 'react-bootstrap';
import { faChevronDown, faChevronRight, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTemplateList } from '../api/templates';
import { TemplateItem } from '../api/templates.types';
import EditTemplate from './EditTemplate';
import usePermission, { Permission } from '../hooks/usePermission';

const Template = (): React.ReactElement => {
  const { t } = useTranslation('templatesView');
  const { hasWritePermission, hasReadPermission } = usePermission();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<TemplateItem[]>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<any>();
  const [currentActiveKey, setCurrentActiveKey] = useState<any>();

  const loadTemplateList = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getTemplateList();
      setData(response.data.items);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const onEditModalClosed = (edited: boolean) => {
    setShowEditModal(false);

    if (edited) {
      loadTemplateList();
    }
  };

  const toggleActiveKey = (key: any) => {
    setCurrentActiveKey(currentActiveKey === key ? null : key);
  };

  useEffect(() => {
    loadTemplateList();
  }, [loadTemplateList]);

  return (
    <>
      {hasReadPermission(Permission.SNIDMAT) && (
        <>
          {showEditModal && <EditTemplate onClose={onEditModalClosed} originalValues={currentItem} />}
          <div className="whiteColumn">
            <div style={{ marginTop: '15px' }}>
              <Accordion>
                {data &&
                  data?.map((item, idx) => {
                    return (
                      <div style={{ marginTop: '5px' }} key={idx}>
                        <Card>
                          <Card.Header className={currentActiveKey === idx ? 'activetab' : ''}>
                            <Accordion.Toggle
                              as={Card.Header}
                              variant="link"
                              style={{ color: '#6e707e' }}
                              eventKey={idx.toString()}
                              className={currentActiveKey === idx ? 'activetab' : ''}
                              onClick={() => {
                                toggleActiveKey(idx);
                              }}
                            >
                              {item.name}
                              <FontAwesomeIcon icon={currentActiveKey === idx ? faChevronDown : faChevronRight} style={{ float: 'right', marginTop: '5px', fontSize: '20px' }} />
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={idx.toString()}>
                            <Card.Body>
                              <Table className="templateTable borderTopNoneTable">
                                <tbody>
                                  <tr>
                                    <td>
                                      <span style={{ fontWeight: 'bold' }}>{t('name')}</span>
                                    </td>
                                    <td>{item.name}</td>
                                    <td>
                                      {hasWritePermission(Permission.SNIDMAT) && (
                                        <div
                                          role="button"
                                          onClick={() => {
                                            setCurrentItem(item);
                                            setShowEditModal(true);
                                          }}
                                          onKeyDown={() => {
                                            setCurrentItem(item);
                                            setShowEditModal(true);
                                          }}
                                          tabIndex={idx}
                                        >
                                          <FontAwesomeIcon v-if="icon" className="nav-link-icon" fixedWidth icon={faEdit} />
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span style={{ fontWeight: 'bold' }}>{t('description')}</span>
                                    </td>
                                    <td>{item.description}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span style={{ fontWeight: 'bold' }}>{t('recipients')}</span>
                                    </td>
                                    <td>
                                      {item.send_school === '1' ? t('send_school') : ''}
                                      <br />
                                      {item.send_guardians === '1' ? t('send_guardians') : ''}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span style={{ fontWeight: 'bold' }}>Titill</span>
                                    </td>
                                    <td>{item.subject}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span style={{ fontWeight: 'bold' }}>Efni</span>
                                    </td>
                                    <td>{item.template && <div dangerouslySetInnerHTML={{ __html: item.template.replace(/\n/g, '<br />') }} />}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </div>
                    );
                  })}
              </Accordion>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Template;
