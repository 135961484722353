import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { restValidationToForm } from '../utils/formUtils';
import { DiscountedStudentType } from '../api/bulkDiscounts.types';
import { deleteOriginalDiscount } from '../api/bulkDiscounts';

interface Props {
  onClose: (deleted: boolean) => void;
  originalValues: DiscountedStudentType;
}

interface Values {
  id: number;
}

const DeleteDiscountItemModal = ({ onClose, originalValues }: Props): React.ReactElement => {
  const { t } = useTranslation(['discounts', 'common', 'errors']);

  const initialValues = {
    id: originalValues.student_discount_id,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
          try {
            await deleteOriginalDiscount(values.id);
            toast.success(t('deletedDiscountModalSuccess'));
            onClose(true);
          } catch (e) {
            toast.error(`${t('common:informationChangedError')}. ${e.response.data.error}.`);
            restValidationToForm({
              t,
              errorResponse: e.response,
              setFieldError,
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleReset, handleSubmit, setStatus, values, setFieldValue }) => (
          <Modal show size="lg" onHide={() => onClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{t('deleteDiscountItemModalTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onReset={handleReset}
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                  setStatus('submitted');
                  handleSubmit(e);
                }}
              >
                <table className="table">
                  <tbody>
                    <tr>
                      <th style={{ borderTop: 'none' }}>{t('name')}</th>
                      <td style={{ borderTop: 'none' }}>{originalValues.student_name}</td>
                    </tr>
                    <tr>
                      <th style={{ borderTop: 'none' }}>{t('type')}</th>
                      <td style={{ borderTop: 'none' }}>{originalValues.discount_type}</td>
                    </tr>
                    {originalValues.has_been_billed === 1 && (
                      <tr>
                        <td colSpan={2}>
                          <Alert variant="warning" className="alert-box">
                            <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '5px' }} />
                            <span>{t('deleteDiscountItemAlert')}</span>
                          </Alert>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                type="submit"
                onClick={() => {
                  setStatus('submitted');
                  handleSubmit();
                }}
              >
                {t('common:delete')}
              </Button>
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common:cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default DeleteDiscountItemModal;
