const config = {
  islandUrl: {
    local: 'https://innskraning.island.is/?id=valais&path=/matur-dev',
    testing: 'https://innskraning.island.is/?id=valais&path=/matur-test',
    production: 'https://innskraning.island.is/?id=valais&path=/matur',
  },
};

const valaEnv = process.env.REACT_APP_VALA_ENV;
let islandUrl = '';

if (valaEnv === undefined || valaEnv.indexOf('local') > -1) {
  islandUrl = config.islandUrl.local;
} else if (valaEnv.indexOf('testing') > -1) {
  islandUrl = config.islandUrl.testing;
} else {
  islandUrl = config.islandUrl.production;
}

export const ISLAND_URL = islandUrl;

export default {
  ISLAND_URL,
};
